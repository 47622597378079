import {Link, useNavigate} from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import Header from "../Header";
import Button from "../component-assets/Button";
import ApiRequester from "../ApiRequester";
import {
    campusToGuid,
    formatDateIso,
    formCheck,
    formFail,
    formSuccess, getAccessSettings,
    getToday,
    handleSubmitActions,
    iAmHigherAuthority,
    setUserFilters,
    showModal,
    sortFunc,
    toggleCollapse
} from "../../libs";
import {Chart, registerables} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import TableBody from "../component-assets/TableBody";
import TableHeaders from "../component-assets/TableHeaders";

const UserProfile = (props) => {
    let dData, bData, startDate, endDate, eventHeaderData;

    const navigate = useNavigate();

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    Chart.register(...registerables);
    Chart.register(annotationPlugin);

    eventHeaderData = [
        {label: "Event Time", key: "event_datetime_local", align: "center", type: "datetime", sorts: false},
        {label: "Response Time (min)", key: "response_time", align: "center", sorts: false},
        {label: "Resident", key: "resident_name", align: "left", sorts: false,
            link: {enabled: true, type: "standard", linkto: "/profiles/resident", linkkey: "resident_id"}},
        {label: "Location", key: "area_name", align: "left", sorts: false,
            link: {enabled: true, type: "standard", linkto: "/profiles/area", linkkey: "area_id"}},
        {label: "Reason", key: "reason_code_description", align: "left", sorts: false},
    ];

    // Permission Variables
    const [editRights, setEditRights] = useState(iAmHigherAuthority(10, dData.role_weight));
    const [deleteRights, setDeleteRights] = useState(iAmHigherAuthority(10, dData.role_weight));
    // Main Page Variables
    const [userId, setUserId] = useState(props?.id);
    const [higherRole, setHigherRole] = useState(false);
    const [count, setCount] = useState(0);
    const [repCount, setRepCount] = useState(0);
    const [campusId, setCampusId] = useState(dData.acnt);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [tabItem, setTabItem] = useState("basic");
    // Basic Information Variables
    const [basicData, setBasicData] = useState({});
    const [roleData, setRoleData] = useState([]);
    const [roleLoaded, setRoleLoaded] = useState(false);
    const [roleOptions, setRoleOptions] = useState([]);
    const [basicLoaded, setBasicLoaded] = useState(false);
    const [userGroups, setUserGroups] = useState([]);
    const [username, setUsername] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [userRole, setUserRole] = useState("");
    const [userRoleId, setUserRoleId] = useState(1);
    const [cognitoId, setCognitoId] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    // Reporting Variables
    const [weekData, setWeekData] = useState({});
    const [hourData, setHourData] = useState({});
    const [hourDataLoaded, setHourDataLoaded] = useState(false);
    const [weekDataLoaded, setWeekDataLoaded] = useState(false);
    const [reasonData, setReasonData] = useState({});
    const [reasonRows, setReasonRows] = useState([]);
    const [eventData, setEventData] = useState([]);
    const [eventTableRows, setEventTableRows] = useState([]);

    startDate = formatDateIso(getToday(-28));
    endDate = formatDateIso(getToday());

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", payload: {user_filter: userId, campus_id: campusId},
                          procedure: "getuserprofile", reqType: "stored"}).then(data => {
                if (data){
                    setBasicData(data?.length > 0 ? data[0] : {});
                }
                setBasicLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId, Tbl: "att_user_roles",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setRoleData(data);
                setRoleLoaded(true);
            });
            setCount(() => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        if (![basicLoaded, roleLoaded].includes(false)){
            setPageLoaded(true);
        }
    }, [basicLoaded, roleLoaded]);

    useEffect(() => {
        let tmp = [];
        if (roleData){
            roleData.sort((a, b) => sortFunc(a, b, "user_role_weight", "asc"));
            for (let i=0; i < roleData.length; i++){
                if (iAmHigherAuthority(roleData[i]?.user_role_weight, dData?.role_weight)){
                    tmp.push(<option key={`role-option-${i}`} value={roleData[i]?.user_role_id}>{roleData[i]?.user_role_name}</option>);
                }
            }
            setRoleOptions(tmp);
        }
    }, [roleData]);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setRepCount(repCount => repCount - 1);
        }, 1000);
        if (repCount <= 0){
            ApiRequester({reqEndpoint: "get-mysql-report", rep_type: "dashboardhourchart",
                                campus_id: campusId, user_filter: userId.toString()}).then(data => {
                setHourData(data);
                setHourDataLoaded(true);
            });
            ApiRequester({reqEndpoint: "get-mysql-report", rep_type: "dashboardweekchart",
                                campus_id: campusId, user_filter: userId.toString()}).then(data => {
                setWeekData(data);
                setWeekDataLoaded(true);
            });
            ApiRequester({reqEndpoint: "get-mysql-report", rep_type: "eventReasonSummary",
                        campus_id: campusId, start_date: startDate, end_date: endDate,
                        device_category_filter: "", area_type_filter: "", reason_filter: "", user_filter: userId.toString(),
                        event_type_filter: "", resident_filter: "", device_filter: ""}).then(data => {
                setReasonData(() => data ? data : []);
            });
            ApiRequester({reqEndpoint: "myfetch", payload: {campus_id: campusId, user_id: userId.toString()},
                                procedure: "getlimitedevents", reqType: "stored"}).then(data => {
                setEventData(data);
            });
            setRepCount(repCount => 1200);
        }
        return () => clearInterval(interval);
    }, [repCount]);

    useEffect(() => {
        if (Object.keys(basicData).length > 0){
            setUsername(() => basicData?.username ? basicData.username : "");
            setDisplayName(() => basicData?.display_name ? basicData.display_name : "");
            setUserRole(() => basicData?.user_role_name ? basicData.user_role_name : "");
            setUserRoleId(() => basicData?.user_role_id ? basicData.user_role_id : "");
            setEmail(() => basicData?.email ? basicData.email : "");
            setPhone(() => basicData?.phone ? basicData.phone : "");
            setUserGroups(() => basicData?.groups ? basicData.groups : []);
            setCognitoId(() => basicData?.cognito_id ? basicData.cognito_id : "");
            setHigherRole(() => basicData?.user_role_weight ? iAmHigherAuthority(basicData?.user_role_weight, dData.role_weight) : false);
            if (basicData?.user_role_weight > 80){
                document.getElementById("passwordR").pattern = "^(?!123456$)[ -~]{6,}$";
            }else{
                document.getElementById("passwordR").pattern = "[ -~]{6,}$";
            }
        }
    }, [basicData]);

    useEffect(() => {
        setEventTableRows(<TableBody start={0} end={eventData.length} tableData={eventData} checkbox={false}
                            writeAccess={false} unk={"user-"}
                            actionAppearance={() => {}} parentComponent={"User"}
                            tableHeaders={eventHeaderData} editModal={() => {}} modal={false}
                            dData={dData} checkboxlist={[]}
                            setCheckboxId={() => {}} checkidkey={"user_id"} />);
    }, [eventData]);

    useEffect(() => {
        if (hourData !== undefined && hourDataLoaded){
            if (Chart.getChart("perhour") !== undefined){
                const curChart = Chart.getChart("perhour");
                curChart.data.labels = hourData?.labels ? hourData.labels : [];
                curChart.data.datasets[0].data = hourData?.events ? hourData.events : [];
                curChart.data.datasets[1].data = hourData?.responses ? hourData.responses : [];
                curChart.options.plugins.annotation.annotations.line1.value = hourData?.avgValue ? hourData.avgValue : 0
                curChart.options.plugins.annotation.annotations.line2.value = hourData?.avgResp ? hourData.avgResp : 0;
                curChart.resize();
                curChart.update();
            }
            else{
                new Chart('perhour', {
                    options: {
                        responsive: true,
                        plugins: {
                            annotation: {
                                drawTime: 'afterDatasetsDraw',
                                annotations: {
                                    line1: {
                                        type: 'line',
                                        borderDash: [8, 4],
                                        borderWidth: 4,
                                        scaleID: "a",
                                        borderColor: "#0081C778",
                                        value: hourData?.avgValue ? hourData.avgValue : 0,
                                        label: {
                                            display: false,
                                            backgroundColor: "#0081C7",
                                            borderColor: "#000000",
                                            borderWidth: 2,
                                            content: `Alarm Average: ${hourData?.avgValue ? hourData.avgValue : 0}`,
                                        },
                                        enter({element}, event) {
                                            element.label.options.display = true;
                                            return true;
                                        },
                                        leave({element}, event) {
                                            element.label.options.display = false;
                                            return true;
                                        }
                                    },
                                    line2: {
                                        type: 'line',
                                        borderDash: [8, 4],
                                        borderWidth: 4,
                                        scaleID: "b",
                                        borderColor: "#BE4D2578",
                                        value: hourData?.avgResp ? hourData.avgResp : 0,
                                        label: {
                                            display: false,
                                            backgroundColor: "#BE4D25",
                                            borderColor: "#000000",
                                            borderWidth: 2,
                                            content: `Response Time Average: ${hourData?.avgResp ? hourData.avgResp : 0}`,
                                        },
                                        enter({element}, event) {
                                            element.label.options.display = true;
                                            return true;
                                        },
                                        leave({element}, event) {
                                            element.label.options.display = false;
                                            return true;
                                        }
                                    }
                                }
                            },
                            legend: {
                                display: true
                            },
                        },
                        scales: {
                            x: {
                                title: {
                                    display: true,
                                    text: "Time of the Day"
                                },
                                grid: {
                                    display: false
                                },
                            },
                            a: {
                                id: "a",
                                position: "left",
                                grid: {
                                    display: false
                                },
                                title: {
                                    display: true,
                                    text: "Total Alarms"
                                },
                                suggestedMax: 10,
                                suggestedMin: 0,
                                ticks: {
                                    callback: function (value) {
                                        return value
                                    }
                                }
                            },
                            b: {
                                id: "b",
                                position: "right",
                                title: {
                                    display: true,
                                    text: "Average Response Time"
                                },
                                grid: {
                                    display: false
                                },
                                suggestedMax: 10,
                                suggestedMin: 0,
                                ticks: {
                                    callback: function (value) {
                                        return value
                                    }
                                }
                            }
                        }
                    },
                    data: {
                        labels: hourData?.labels?.length > 0 ? hourData.labels : ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12",
                                "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"],
                        datasets: [{
                            type: "bar",
                            label: 'Total Alarms',
                            data: hourData?.events ? hourData.events : [],
                            backgroundColor: ["#0081C7"],
                            hoverBackgroundColor: ["#0081C778"],
                            yAxisID: "a",
                            order: 2
                        },{
                            type: "line",
                            label: "Average Response Time",
                            data: hourData?.responses ? hourData.responses : [],
                            backgroundColor: ["#BE4D25"],
                            hoverBackgroundColor: ["#BE4D2578"],
                            borderColor: ["#BE4D2578"],
                            fill: false,
                            yAxisID: "b",
                            order: 1
                        }]
                    }
                });
            }
        }
    }, [hourData]);

    useEffect(() => {
        if (weekData !== undefined && weekDataLoaded){
            if (Chart.getChart("perweek") !== undefined){
                const curChart = Chart.getChart("perweek");
                curChart.data.labels = weekData?.labels ? weekData.labels : [];
                curChart.data.datasets[0].data = weekData?.events ? weekData.events : [];
                curChart.resize();
                curChart.update();
            }
            else{
                new Chart('perweek', {
                    options: {
                        responsive: true,
                        plugins: {
                            legend: {
                                display: true
                            },
                        },
                        scales: {
                            x: {
                                title: {
                                    display: true,
                                    text: "Weeks"
                                },
                                grid: {
                                    display: false
                                },
                            },
                            a: {
                                id: "a",
                                position: "left",
                                title: {
                                    display: true,
                                    text: "Total Alarms"
                                },
                                grid: {
                                    display: false
                                },
                                suggestedMax: 10,
                                suggestedMin: 0,
                                ticks: {
                                    callback: function (value) {
                                        return value
                                    }
                                }
                            }
                        }
                    },
                    data: {
                        labels: weekData?.labels?.length > 0 ? weekData.labels : [],
                        datasets: [{
                            type: "line",
                            label: "Total Alarms",
                            data: weekData?.events?.length > 0 ? weekData.events : [],
                            backgroundColor: ["#0081C7"],
                            hoverBackgroundColor: ["#0081C778"],
                            borderColor: ["#0081C778"],
                            fill: false,
                            yAxisID: "a",
                            order: 1
                        }]
                    }
                });
            }
        }
    }, [weekData]);

    function basicActionHandling(){
        document.getElementById("display-static").classList.toggle("d-none");
        document.getElementById("display-edit").classList.toggle("d-none");
        document.getElementById("userrole-static").classList.toggle("d-none");
        document.getElementById("userrole-edit").classList.toggle("d-none");
        document.getElementById("email-static")?.classList?.toggle("d-none");
        document.getElementById("email-edit")?.classList?.toggle("d-none");
        if (document.getElementById("edit-basic-btn").innerText === "Edit"){
            document.getElementById("edit-basic-btn").innerText = "Cancel";
            document.getElementById("save-basic-btn").classList.toggle("d-none");
            document.getElementById("display-edit").value = basicData?.display_name;
            document.getElementById("userrole-edit").value = basicData?.user_role_id;
            document.getElementById("email-edit").value = basicData?.email;
            document.getElementById("email-edit").required = ![6].includes(userRoleId);
        }else{
            document.getElementById("edit-basic-btn").innerText = "Edit";
            document.getElementById("save-basic-btn").classList.toggle("d-none");
        }
        formCheck("basic-form", "save-basic-btn");
    }

    function basicSubmit(){
        let dName, roleId, email, payload, pAccess, groupIdList = [];
        if (editRights && higherRole){
            handleSubmitActions("save-basic-btn", "load-basic-btn");
            dName = document.getElementById("display-edit").value;
            email = document.getElementById("email-edit").value;
            roleId = document.getElementById("userrole-edit").value;
            pAccess = getAccessSettings(parseInt(roleId));
            for (let i=0; i < userGroups.length; i++){
                groupIdList.push(userGroups[i].group_id);
            }
            payload = [{Action: "update", UserId: userId, DisplayName: dName, UserEmail: email.toLowerCase(),
                        UserRole: parseInt(roleId), CampusGuid: campusToGuid(dData, campusId),
                        PortalAccess: pAccess, GroupIds: groupIdList, Username: username, CampusId: campusId,
                        Migrated: true, CognitoId: cognitoId, ClientId: dData.sessionId}];
            ApiRequester({reqEndpoint: "user-manage", Payload: payload}).then(data => {
                if (data[0]){
                    setCount(0);
                    formSuccess("Basic information saved.", "save-basic-btn", "load-basic-btn", "edit-basic-btn")
                }
                else{
                    formFail(`Error during save, error code: ${data && data?.length > 1 ? data[1] : ""}`,
                        "save-basic-btn", "load-basic-btn");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function passSubmit(){
        let password, msg, payload;
        if (editRights && higherRole){
            handleSubmitActions("passSubmit", "loadPassSubmit");
            password = document.getElementById("passwordR").value;
            payload = [{Action: "reset", CampusId: campusId, Username: username, UserPass: password, Migrated: true}];
            ApiRequester({reqEndpoint: "user-manage", Payload: payload}).then(data => {
                if (data[0] === true){
                    formSuccess("Password reset successfully.", "passSubmit", "loadPassSubmit", "cancelPassBtn", "passForm");
                    setCount(0);
                }
                else{
                    if (data[1] === "3"){
                        msg = "Current password does not match records.";
                    }
                    else if (data[1] === "4"){
                        msg = "User not authorized.";
                    }
                    else if (data[1] === "5"){
                        msg = "Error encountered authenticating user. Please contact support for assistance if error persists.";
                    }
                    else if (data[1] === "6"){
                        msg = "New password failed to be set, check password and try again. Contact support if failure persists.";
                    }
                    else{
                        msg = "Unknown failure, request not processed.";
                    }
                    formFail(msg, "passSubmit", "loadPassSubmit");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function deletePerform(){
        let payload;
        if (deleteRights && higherRole){
            handleSubmitActions("deleteConfirmSubmit", "loadDeleteConfirmSubmit");
            payload = [{Action: "remove", UserId: userId, Username: username, CampusId: campusId,
                        RequestedBy: dData?.username, Migrated: true, CognitoId: cognitoId, ClientId: dData.sessionId}];
            ApiRequester({reqEndpoint: "user-manage", Payload: payload}).then(data => {
                if (data[0]){
                    document.getElementById("deleteConfirmCancelBtn").click();
                    navigate("/users", {state: {message: {success: `${displayName} has been deleted.`}}});
                }
                else{
                    formFail(`Error during delete, error code: ${data[1]}`,
                        "deleteConfirmSubmit", "loadDeleteConfirmSubmit");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    const eventTableHeaders = <TableHeaders checkbox={false} headerData={eventHeaderData} checkAll={() => {}}
                                            writeAccess={false} sortOrder={""}
                                            checkboxHeaderId={"uCheckHead"} sortColumn={""} sorter={{}}
                                            setSorter={() => {}} modal={false} checkboxlist={[]}
                                            actionAppearance={() => {}}/>

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div></div>
            {pageLoaded && higherRole &&
                <div>
                    {editRights &&
                        <Button text={"Reset Password"} class={"btn btn-primary me-3"} id={"passBtn"}
                             style={{marginTop: "1.1rem"}} onClick={() => {
                                 formCheck("passForm", "passSubmit");
                                 showModal("passmodal");
                            }} />
                    }
                    {deleteRights &&
                        <Button text={"Delete"} class={"btn btn-danger"} id={"deleteBtn"}
                            style={{marginTop: "1.1rem"}} onClick={() => {
                                 showModal("deleteconfirmmodal");
                            }} />
                    }
                </div>
            }
        </div>
    );

    return (
        <>
            <div className={"main-content"}>
                <Header preTitle={"User Profile"} Title={displayName} content={headerContent}/>
                <div className={"container-fluid"}>
                    <div className={"col"}>
                        <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                            <li key={"set-basic"} className={"nav-item"}>
                                <Button text={"Basic Info"} class={tabItem === "basic" ? "nav-link active" : "nav-link"}
                                        onClick={() => setTabItem("basic")}/>
                            </li>
                            <li key={"set-reporting"} className={"nav-item"}>
                                <Button text={"Reporting"}
                                        class={tabItem === "reporting" ? "nav-link active" : "nav-link"}
                                        onClick={() => setTabItem("reporting")}/>
                            </li>
                        </ul>
                    </div>
                    <div className={tabItem === "basic" ? "d-block" : "d-none"}>
                        <div className={"card"} style={{minWidth: "550px"}}>
                            <div className={"card-header d-flex justify-content-between"}>
                                <div>
                                    <h3 className={"card-header-title d-inline-block"}>Basic Information</h3>
                                </div>
                                <div>
                                    <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"basic-card-btn"}
                                       onClick={() => toggleCollapse("basic-card-btn", "basic-card")}></i>
                                </div>
                            </div>
                            <div className={"card-body collapse collapse-content show"} id={"basic-card"}>
                                <form className={"d-inline align-top form-control-plaintext was-validated"}
                                      id={"basic-form"}>
                                    <div className={"mx-5 mt-2 align-top d-inline-block"} id={"basic-col1-div"}>
                                        <div className={"d-flex"}>
                                            <p style={{minWidth: "125px"}}>Username:</p>
                                            <p className={"ms-4"} id={"username-static"}>{username}</p>
                                        </div>
                                        <div className={"d-flex"}>
                                            <p style={{minWidth: "125px"}}>Display Name:</p>
                                            <p className={"ms-4"}
                                               id={"display-static"}>{displayName}</p>
                                            <input className={"ms-4 d-none form-control form-control-sm"}
                                                   style={{height: "30px", width: "256px"}} id={"display-edit"}
                                                   defaultValue={displayName} pattern={"[a-zA-Z0-9 ]+"}/>
                                        </div>
                                        <div className={"d-flex"}>
                                            <p style={{minWidth: "125px"}}>Email:</p>
                                            <p className={"ms-4"} id={"email-static"}>{email}</p>
                                            <input className={"ms-4 d-none form-control form-control-sm"}
                                                   style={{height: "30px", width: "256px"}} id={"email-edit"}
                                                   defaultValue={email}
                                                   pattern={"[A-Za-z0-9._%+\\-]+@[A-Za-z0-9.\\-]+\\.[A-Za-z]{2,}$"}/>
                                        </div>
                                        <div className={"d-flex"}>
                                            <p style={{minWidth: "125px"}}>Role:</p>
                                            <p className={"ms-4"} id={"userrole-static"}>{userRole}</p>
                                            <select className={"ms-4 d-none form-select form-select-sm mb-1"}
                                                    required={true} id={"userrole-edit"} defaultValue={userRoleId}
                                                    onChange={(e) => {
                                                        document.getElementById("email-edit").required = !["caregiver", "6"].includes(e?.target?.value?.toString());
                                                        if (["1", "2"].includes(e.target.value)) {
                                                            document.getElementById("passwordR").pattern = "^(?!123456$)[ -~]{6,}$";
                                                        } else {
                                                            document.getElementById("passwordR").pattern = "[ -~]{6,}$";
                                                        }
                                                        formCheck("basic-form", "save-basic-btn");
                                                    }}>
                                                {roleOptions}
                                            </select>
                                        </div>
                                    </div>
                                    <div className={"mx-5 mt-2 d-inline-block align-top"} id={"basic-col2-div"}>
                                        <div className={"d-flex"}>
                                            <ul className={"ps-0 d-inline-block align-top mb-0"}
                                                style={{listStyle: "none"}}>
                                                <li><p style={{minWidth: "75px"}} className={"me-4"}>Groups:</p></li>
                                            </ul>
                                            <ul className={"ps-0 d-inline-block mb-0"} style={{listStyle: "none"}}>
                                                {userGroups.map((group, i) => {
                                                    return <li
                                                        key={`user-group-name-item-${i}`}>{group?.group_name}</li>
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </form>
                                {pageLoaded && editRights && higherRole &&
                                    <>
                                        <Button text={"Edit"} class={"btn btn-primary float-end"} id={"edit-basic-btn"}
                                                disabled={!editRights} onClick={() => {
                                            if (editRights) {
                                                basicActionHandling()
                                            }
                                        }}/>
                                        <Button text={"Save"} class={"btn btn-primary float-end me-3 d-none"}
                                            id={"save-basic-btn"}
                                            disabled={!editRights} onClick={() => {
                                            if (editRights) {
                                                basicSubmit()
                                            }
                                        }}/>
                                        <Button type={"button"} class={"btn btn-primary float-end mx-3 d-none"} disabled={true}
                                                spinner={true} text={""} id={"load-basic-btn"}/>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={tabItem === "reporting" ? "d-block" : "d-none"}>
                        <div className={"card"} style={{minWidth: "500px"}}>
                            <div className={"card-header d-flex justify-content-between"}>
                                <div>
                                    <h3 className={"card-header-title d-inline-block"} style={{minWidth: "80px"}}>
                                        Reporting
                                    </h3>
                                    <div className={"d-none"} style={{display: "inline-block"}}
                                         id={"reporting-header-data"}>
                                        <h3 className={"ms-6 my-0 d-inline-block text-success"}>{!isNaN(hourData?.avgValue) ? hourData.avgValue : "?"} Alert/Week</h3>
                                        <h3 className={"mx-4 my-0 d-inline-block text-success"}>{!isNaN(weekData?.avgValue) ? weekData.avgValue : "?"} Avg/Resp</h3>
                                    </div>
                                </div>
                                <div>
                                    <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"reporting-card-btn"}
                                       onClick={() => {
                                           toggleCollapse("reporting-card-btn", "reporting-card")
                                           document.getElementById("reporting-header-data").classList.toggle("d-none");
                                       }}></i>
                                </div>
                            </div>
                            <div className={"card-body collapse collapse-content show"} id={"reporting-card"}>
                                <div className={"row"}>
                                    <Link to={"/reports/alerts/run"} onClick={() => {
                                        setUserFilters(dData, userId, basicData?.username);
                                    }}>
                                        <Button text={"Run Detailed Report"} class={"btn btn-primary mb-4 float-end"}
                                                icon={true} iconClass={"fe fe-arrow-right-circle float-end ms-3"}/>
                                    </Link>
                                </div>
                                <div className={"row"}>
                                    <div className={"chart-container w-50 text-end align-top d-inline-block"}>
                                        {hourDataLoaded
                                            ?
                                            (<div className={"chart-container"}>
                                                <canvas className={"chart-canvas p-1"} style={{maxHeight: "425px"}}
                                                        id={"perhour"}></canvas>
                                            </div>)
                                            :
                                            (<div className={"text-center"}>
                                                <Button text={""} class={"border-0 bg-transparent btn"}
                                                        spinner={true}></Button>
                                            </div>)
                                        }
                                    </div>
                                    <div className={"chart-container w-50 text-center align-top d-inline-block"}>
                                        {weekDataLoaded
                                            ?
                                            (<div className={"chart-container"}>
                                                <canvas className={"chart-canvas p-1"} style={{maxHeight: "425px"}}
                                                        id={"perweek"}></canvas>
                                            </div>)
                                            :
                                            (<div className={"text-center"}>
                                                <Button text={""} class={"border-0 bg-transparent btn"}
                                                        spinner={true}></Button>
                                            </div>)
                                        }
                                    </div>
                                </div>
                                <div className={"row mt-5"}>
                                    <div className={"w-50 px-4"}>
                                        <table className={"table table-sm table-white table-hover"}>
                                            <thead>
                                            <tr>
                                                <th className={"table table-header w-auto py-1"} colSpan={"4"}>
                                                    Event Reason Breakdown
                                                </th>
                                            </tr>
                                            <tr>
                                                <th className={"table table-header w-auto py-1"}>
                                                    Reason
                                                </th>
                                                <th className={"table table-header w-auto py-1"}>
                                                    Total
                                                </th>
                                                <th className={"table table-header w-auto py-1"}>
                                                    % Total Volume
                                                </th>
                                                <th className={"table table-header w-auto py-1"}>
                                                    Average Response (min)
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td className={"ps-1 fw-bold"}>Total Events</td>
                                                <td className={"ps-1 fw-bold text-center"}>{reasonData?.count ? reasonData.count : 0}</td>
                                                <td className={"ps-1 text-right"}></td>
                                                <td className={"ps-1 fw-bold text-center"}>{reasonData?.avg_response_time ? reasonData.avg_response_time : 0.0}</td>
                                            </tr>
                                            {reasonRows}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={"w-50"} style={{maxHeight: "400px", overflowY: "auto"}}>
                                        <table className={"table table-sm table-white table-hover d-sm-table"}>
                                            <thead className={"sticky-table-head"} style={{top: 0}}>
                                            {eventTableHeaders}
                                            </thead>
                                            <tbody>
                                            {eventTableRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"modal fade"} id={"passmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                     tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                    <div className={"modal-dialog"}>
                        <div className={"modal-content"}>
                            <div className={"modal-header bg-pal"}>
                                <h2 className={"modal-title"} id={"staticBackdropLabel"}>Reset Password</h2>
                                <button type={"button"} className={"btn-close"} id={"passClose"}
                                        data-bs-dismiss={"modal"} aria-label={"Close"}/>
                            </div>
                            <form id={"passForm"} className={"was-validated"}>
                                <div className={"modal-body"}>
                                    <div className={"form-floating mb-3"}>
                                        <input type={"text"} className={"form-control"} id={"passwordR"}
                                               onKeyUp={() => formCheck("passForm", "passSubmit")}
                                               required={true}/>
                                        <label htmlFor={"passwordR"}>New Password</label>
                                        <div className={"invalid-feedback"}>
                                            Please enter a valid password! 6 Character minimum.
                                        </div>
                                    </div>
                                    <div className={"form-floating mb-3"}>
                                        <input type={"text"} className={"form-control"} id={"passwordCR"}
                                               onKeyUp={() => formCheck("passForm", "passSubmit")} required={true}
                                               pattern={"[ -~]{6,}"}/>
                                        <label htmlFor={"passwordCR"}>Confirm Password</label>
                                        <div className={"invalid-feedback"}>
                                            Password does not match.
                                        </div>
                                    </div>
                                </div>
                                <div className={"modal-footer"}>
                                    <div>
                                        <button type={"button"} className={"btn btn-secondary"}
                                                id={"cancelPassBtn"} data-bs-dismiss={"modal"}>Cancel
                                        </button>
                                        {editRights && higherRole &&
                                            <>
                                                <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                                        spinner={true} text={"Loading"} id={"loadPassSubmit"}/>
                                                <button type={"button"} className={"btn btn-danger ms-3"} id={"passSubmit"}
                                                        onClick={() => passSubmit()}>Submit
                                                </button>
                                            </>
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className={"modal fade"} id={"deleteconfirmmodal"} data-bs-backdrop={"static"}
                     data-bs-keyboard={"false"}
                     tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                    <div className={"modal-dialog"}>
                        <div className={"modal-content"}>
                            <div className={"modal-header bg-pal"}>
                                <h2 className={"modal-title"} id={"staticBackdropLabel"}>Confirm Delete</h2>
                                <button type={"button"} className={"btn-close"} id={"deleteConfirmClose"}
                                        data-bs-dismiss={"modal"} aria-label={"Close"}/>
                            </div>
                            <div className={"modal-body"}>
                                <p>Are you sure you wish to <span className={"fw-bold"}>permanently delete {displayName}</span> from the system.
                                    They will no longer have access to the PalCare Portal or Mobile app?</p>
                            </div>
                            <div className={"modal-footer"}>
                                <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                        id={"deleteConfirmCancelBtn"} data-bs-dismiss={"modal"}>No
                                </button>
                                {deleteRights && higherRole &&
                                    <>
                                        <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                                spinner={true}
                                                text={"Loading"} id={"loadDeleteConfirmSubmit"}/>
                                        <button type={"button"} className={"btn btn-primary ms-3"} id={"deleteConfirmSubmit"}
                                                onClick={() => deletePerform()}>YES
                                        </button>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true}
                                autoClose={10000}/>
            </Suspense>
        </>
    );
};

export default UserProfile;