import {Link, useLocation, useNavigate} from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import Header from "../Header";
import Button from "../component-assets/Button";
import ApiRequester from "../ApiRequester";
import {
    actionAppearance,
    checkAll,
    convertIsoToLocal,
    formatPhone,
    formCheck,
    formFail,
    formSuccess,
    getAccessSettings,
    getCountryData,
    getStatesByCountry,
    getZipRegexByCountry,
    handleSubmitActions, iAmHigherAuthority,
    paginateData,
    reloadSessionCorp, showModal,
    sortFunc,
    toggleCollapse
} from "../../libs";
import MaterialIcon from "material-icons-react";
import TableBody from "../component-assets/TableBody";
import TableHeaders from "../component-assets/TableHeaders";
import {Modal} from "bootstrap";
import {Pagination} from "react-bootstrap";
import PaginationItems from "../component-assets/PaginationItems";
import Papa from 'papaparse'

const CampusProfile = (props) => {
    let dData, bData, countryOptions = [], userHeaderData, groupHeaderData;

    const useLocationData = useLocation();
    const navigate = useNavigate();

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    userHeaderData = [
        {label: "Username", key: "UserName", align: "left"},
        {label: "Display Name", key: "DisplayName", align: "left"},
        {label: "Email", key: "Email", align: "left"},
        {label: "Role", key: "UserRole", align: "left"}
    ];

    groupHeaderData = [
        {label: "Group Name", key: "GroupName", align: "left"},
        {label: "CapCode", key: "CapCode", align: "left"}
    ];

    // Permission Variables
    const [addRights, setAddRights] = useState(iAmHigherAuthority(80, dData.role_weight));
    const [editRights, setEditRights] = useState(iAmHigherAuthority(80, dData.role_weight));
    // Main Page Variables
    const [campusId, setCampusId] = useState(props?.id);
    const [count, setCount] = useState(0);
    const [count2, setCount2] = useState(0);
    const [stateData, setStateData] = useState(useLocationData.state);
    const [corpData, setCorpData] = useState([]);
    const [deviceData, setDeviceData] = useState([]);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [timezoneData, setTimezoneData] = useState([]);
    // Basic Information Variables
    const [basicData, setBasicData] = useState([]);
    const [campusName, setCampusName] = useState("");
    const [corporateId, setCorporateId] = useState("");
    const [corporateName, setCorporateName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [zipcode, setZip] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("US");
    const [streetAddr, setStreetAddr] = useState("");
    const [timezone, setTimezone] = useState("");
    const [timezoneId, setTimezoneId] = useState(0);
    const [servicePlan, setServicePlan] = useState("");
    const [campusGuid, setCampusGuid] = useState("");
    const [basicLoaded, setBasicLoaded] = useState(false);
    // User 2.0 Table To Migrate Card Variables
    const [userData, setUserData] = useState([]);
    const [userLoaded, setUserLoaded] = useState(false);
    const [userActiveData, setUserActiveData] = useState([]);
    const [myUserLoaded, setMyUserLoaded] = useState(false);
    const [myUserData, setMyUserData] = useState([]);
    const [userRows, setUserRows] = useState([]);
    const [userIdList, setUserIdList] = useState([]);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(dData.perPage);
    const [sorter, setSorter] = useState({
        sortColumn: "UserName",
        sortOrder: "asc"
    });
    const [ppRules, setPRules] = useState({
        start: 0,
        end: 1,
        beginDot: false,
        endDot: false,
        pageMin: 1,
        pageMax: 1,
        max: 1
    });
    const [dContent, setDContent] = useState("");
    // Group 2.0 Table to Migrate Card Variables
    const [groupData, setGroupData] = useState([]);
    const [groupLoaded, setGroupLoaded] = useState(false);
    const [groupActiveData, setGroupActiveData] = useState([]);
    const [myGroupData, setMyGroupData] = useState([]);
    const [myGroupLoaded, setMyGroupLoaded] = useState(false);
    const [groupRows, setGroupRows] = useState([]);
    const [groupIdList, setGroupIdList] = useState([]);
    const [groupSearch, setGroupSearch] = useState("");
    const [groupPage, setGroupPage] = useState(1);
    const [groupSorter, setGroupSorter] = useState({
        sortColumn: "config_name",
        sortOrder: "asc"
    });
    const [groupPPRules, setGroupPRules] = useState({
        start: 0,
        end: 1,
        beginDot: false,
        endDot: false,
        pageMin: 1,
        pageMax: 1,
        max: 1
    });
    const [dContent2, setDContent2] = useState("");
    const [timezoneOptions, setTimezoneOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const [zipReg, setZipReg] = useState(getZipRegexByCountry("US"));
    const [corporateOptions, setCorporateOptions] = useState([]);
    const [newFile, setNewFile] = useState("");
    const [newFileExt, setNewFileExt] = useState("");
    const [newFileName, setNewFileName] = useState("");
    const [newFileData, setNewFileData] = useState("");
    const [fileValid, setFileValid] = useState(true);
    const [uploadKey, setUploadKey] = useState("");
    const [uploadTimer, setUploadTimer] = useState(-1);
    const [stgPresent, setStgPresent] = useState(false);
    const [addDevices, setAddDevices] = useState(true);
    const [addAreas, setAddAreas] = useState(true);
    const [addResidents, setAddResidents] = useState(true);

    const countries = getCountryData();
    for (let i2=0; i2 < countries?.length; i2++){
        countryOptions.push(
            <option key={"country-option-"+i2} value={countries[i2][0]}>{countries[i2][1]}</option>
        );
    }

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId,
                                payload: {campus_filter: campusId.toString()},
                                procedure: "getcampuses", reqType: "stored"}).then(data => {
                setBasicData(data ? data : []);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId, Tbl: "core_corporate",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setCorpData(data ? data : []);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId, Tbl: "att_timezones",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setTimezoneData(data ? data : []);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId,
                                procedure: "getserverstatus", reqType: "stored"}).then(data => {
                setDeviceData(data ? data : []);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId, Tbl: "core_user",
                                procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                setMyUserData(data ? data : []);
                setMyUserLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId,
                                procedure: "sp_dev_getgroupsbycampusid", reqType: "stored"}).then(data => {
                setMyGroupData(data ? data : []);
                setMyGroupLoaded(true);
            });
            setCount(() => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setUploadTimer(count => count - 1);
        }, 1000);
        if (uploadTimer > 0 && uploadTimer%5 === 0 && uploadKey !== ""){
            ApiRequester({reqEndpoint: "myfetch", pKey: "unique_key", pVal: uploadKey, Tbl: "stg_upload_keys",
                                procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                if (data && data.length > 0){
                    if (data[0]?.upload_complete === 1){
                        setUploadTimer(-1);
                        handleSubmitActions("loadUploadSubmit", "uploadSubmit");
                        document.getElementById("uploadCancel").click();
                        navigate(`/profiles/campus/${campusId}/${uploadKey}`,
                            {state: {message: {success: "Upload data staged for preview.", error: ""}}});
                    }else if (data[0]?.upload_error === 1){
                        setUploadTimer(-1);
                        handleSubmitActions("loadUploadSubmit", "uploadSubmit");
                        toast.error("Error encountered, run the file pre-check, if ok please notify software support.");
                    }
                }else{
                    console.log(data);
                }
            });
        }
        if (uploadTimer === 0 && stgPresent === false){
            handleSubmitActions("loadUploadSubmit", "uploadSubmit");
            toast.error("Timed out performing upload, please try again.");
        }
        return () => clearInterval(interval);
    }, [uploadTimer]);

    useEffect(() => {
        if (stateData && stateData?.message){
            if (stateData.message?.error){
                toast.error(stateData.message.error);
            }else if (stateData.message?.success){
                toast.success(stateData.message.success);
            }
        }
    }, [stateData]);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount2(count => count - 1);
        }, 1000);
        if (count2 <= 0 && campusGuid){
            ApiRequester({reqEndpoint: "getitems", Table: "User", PKey: "CampusId", PVal: campusGuid,
                        SecondIndex: "CampusId-index", CampusId: campusId,
                        Projection: "UserId,DisplayName,Email,UserName,UserRole,GroupIds"}).then(data => {
                setUserData(data);
                setUserLoaded(true);
            });
            ApiRequester({reqEndpoint: "getitems", Table: "Group", PKey: "CampusId", PVal: campusGuid,
                        SecondIndex: "CampusId-index", CampusId: campusId,
                        Projection: "GroupId,CapCode,GroupName"}).then(data => {
                setGroupData(data);
                setGroupLoaded(true);
            });
            setCount2(() => 600);
        }
        return () => clearInterval(interval);
    }, [count2, campusGuid]);

    useEffect(() => {
        if (![basicLoaded].includes(false)){
            setPageLoaded(true);
        }
    }, [basicLoaded]);

    useEffect(() => {
        if (timezoneData.length > 0){
            let tmpOptions = [];
            for (let i=0; i<timezoneData.length; i++){
                tmpOptions.push(
                    <option key={"timezone-option-"+timezoneData[i].timezone_id.toString()}
                            value={timezoneData[i].timezone_id}>{timezoneData[i].timezone_name}
                    </option>
                );
            }
            setTimezoneOptions(() => tmpOptions);
        }
    }, [timezoneData]);

    useEffect(() => {
        let tmpData = [], tmpList = [];
        if (userLoaded && myUserLoaded){
            for (let i=0; i < myUserData.length; i++){
                tmpList.push(myUserData[i]?.cognito_id);
            }
            for (let i2=0; i2 < userData.length; i2++){
                if (userData[i2]?.UserId && !tmpList.includes(userData[i2]?.UserId)){
                    tmpData.push(userData[i2]);
                }
            }
            setUserActiveData(tmpData)
        }
    }, [userData, myUserData, userLoaded, myUserLoaded]);

    useEffect(() => {
        let tmpData = [], tmpList = [];
        if (groupLoaded && myGroupLoaded){
            for (let i=0; i < myGroupData.length; i++){
                tmpList.push(myGroupData[i]?.group_guid);
            }

            for (let i2=0; i2 < groupData.length; i2++){
                if (groupData[i2]?.GroupId && !tmpList.includes(groupData[i2]?.GroupId)){
                    tmpData.push(groupData[i2]);
                }
            }
            setGroupActiveData(tmpData)
        }
    }, [groupData, myGroupData, groupLoaded, myGroupLoaded]);

    useEffect(() => {
        let pRules = paginateData(userActiveData, page, perPage);
        setPRules(pRules);
        if (userActiveData.length > 0) {
            userActiveData.sort((a, b) => sortFunc(a, b, sorter.sortColumn, sorter.sortOrder));
        }
        setUserRows(<TableBody start={pRules.start} end={pRules.end} tableData={userActiveData} checkbox={addRights} unk={"b-"}
                                     writeAccess={addRights} addlEditKey={""} addlEditCheck={false} checkboxId={"uCheckUser"}
                                     actionAppearance={() => actionAppearance(pRules.start, pRules.end, ["migrateBtn"], "uCheckUser")}
                                     tableHeaders={userHeaderData} editModal={() => {}} parentComponent={"Campus"} dData={dData}
                                     modal={false} checkboxlist={userIdList} setCheckboxId={(value) => {setUserIdList(value)}}
                                     checkidkey={"UserId"} />);
    }, [userActiveData, sorter, page, perPage, userIdList]);

    useEffect(() => {
        let pRules = paginateData(groupActiveData, groupPage, perPage);
        setGroupPRules(pRules);
        if (groupActiveData.length > 0) {
            groupActiveData.sort((a, b) => sortFunc(a, b, groupSorter.sortColumn, groupSorter.sortOrder));
        }
        setGroupRows(<TableBody start={pRules.start} end={pRules.end} tableData={groupActiveData} checkbox={addRights} unk={"c-"}
                                writeAccess={addRights} addlEditKey={""} addlEditCheck={false} checkboxId={"uCheckGroup"}
                                actionAppearance={() => actionAppearance(pRules.start, pRules.end, ["migrateGroupBtn"], "uCheckGroup")}
                                tableHeaders={groupHeaderData} editModal={() => {}} parentComponent={"Campus"} dData={dData}
                                modal={false} checkboxlist={groupIdList} setCheckboxId={(value) => {setGroupIdList(value)}}
                                checkidkey={"GroupId"} />);
    }, [groupActiveData, groupSorter, groupPage, perPage, groupIdList]);

    useEffect(() => {
        if (basicData?.length > 0){
            setCampusName(() => basicData[0]?.campus_name ? basicData[0]?.campus_name : "");
            setCorporateName(() => basicData[0]?.corporate_name ? basicData[0]?.corporate_name : "");
            setCorporateId(() => basicData[0]?.corporate_id ? basicData[0]?.corporate_id : "");
            setEmail(() => basicData[0]?.campus_email ? basicData[0]?.campus_email : "");
            setPhone(() => basicData[0]?.campus_phone ? basicData[0]?.campus_phone : "");
            setLatitude(() => basicData[0]?.campus_latitude ? basicData[0]?.campus_latitude : "");
            setLongitude(() => basicData[0]?.campus_longitude ? basicData[0]?.campus_longitude : "");
            setZip(() => basicData[0]?.campus_zip ? basicData[0]?.campus_zip : "");
            setCity(() => basicData[0]?.campus_city ? basicData[0]?.campus_city : "");
            setState(() => basicData[0]?.campus_state ? basicData[0]?.campus_state : "");
            setCountry(() => basicData[0]?.campus_country ? basicData[0]?.campus_country : "");
            setStreetAddr(() => basicData[0]?.campus_street_address ? basicData[0]?.campus_street_address : "");
            setServicePlan(() => basicData[0]?.date_service_plan_expire ? basicData[0]?.date_service_plan_expire : "");
            setCampusGuid(() => basicData[0]?.campus_guid ? basicData[0]?.campus_guid : "");
            setTimezone(() => basicData[0]?.timezone ? basicData[0]?.timezone : "");
            setTimezoneId(() => basicData[0]?.timezone_id ? basicData[0]?.timezone_id : "");
            setBasicLoaded(true);
        }
    }, [basicData]);

    useEffect(() => {
        setCountryOptionsFunction(country);
    }, [country]);

    useEffect(() => {
        let i, tmp = [];
        for (i = 0; i < corpData?.length; i++){
            tmp.push(
                <option key={"location-option"+i.toString()} value={corpData[i].corporate_id}>{corpData[i].corporate_name}</option>
            );
        }
        setCorporateOptions(() => tmp);
    }, [corpData]);

    function setCountryOptionsFunction(inCountry){
        let tmp = [];
        let stateData = getStatesByCountry(inCountry);
        for (let i=0; i < stateData?.length; i++){
            tmp.push(
                <option key={"state-option"+i} value={stateData[i][0]}>{stateData[i][1]}</option>
            );
        }
        setStateOptions(tmp);
        setZipReg(getZipRegexByCountry(inCountry));
    }

   function basicActionHandling(){
        document.getElementById("name-static").classList.toggle("d-none");
        document.getElementById("name-edit").classList.toggle("d-none");
        document.getElementById("corporate-static").classList.toggle("d-none");
        document.getElementById("corporate-edit").classList.toggle("d-none");
        document.getElementById("country-static").classList.toggle("d-none");
        document.getElementById("country-edit").classList.toggle("d-none");
        document.getElementById("street-static").classList.toggle("d-none");
        document.getElementById("street-edit").classList.toggle("d-none");
        document.getElementById("state-static").classList.toggle("d-none");
        document.getElementById("state-edit").classList.toggle("d-none");
        document.getElementById("city-static").classList.toggle("d-none");
        document.getElementById("city-edit").classList.toggle("d-none");
        document.getElementById("zipcode-static").classList.toggle("d-none");
        document.getElementById("zipcode-edit").classList.toggle("d-none");
        document.getElementById("timezone-static").classList.toggle("d-none");
        document.getElementById("timezone-edit").classList.toggle("d-none");
        document.getElementById("email-static").classList.toggle("d-none");
        document.getElementById("email-edit").classList.toggle("d-none");
        document.getElementById("phone-static").classList.toggle("d-none");
        document.getElementById("phone-edit").classList.toggle("d-none");
        if (document.getElementById("edit-basic-btn").innerText === "Edit"){
            document.getElementById("edit-basic-btn").innerText = "Cancel";
            document.getElementById("save-basic-btn").classList.toggle("d-none");
            document.getElementById("name-edit").value = campusName;
            document.getElementById("corporate-edit").value = corporateId;
            document.getElementById("country-edit").value = country;
            document.getElementById("street-edit").value = streetAddr;
            document.getElementById("state-edit").value = state;
            document.getElementById("city-edit").value = city;
            document.getElementById("zipcode-edit").value = zipcode;
            document.getElementById("timezone-edit").value = timezoneId;
            document.getElementById("email-edit").value = email;
            document.getElementById("phone-edit").value = phone.replaceAll("-", "");
        }else{
            document.getElementById("edit-basic-btn").innerText = "Edit";
            document.getElementById("save-basic-btn").classList.toggle("d-none");
        }
        formCheck("basic-form", "save-basic-btn");
   }

    function basicSubmit(){
        let name, countryX, stateX, cityX, zipX, emailX, phoneX, corpId, payload, tzId;
        if (editRights){
            handleSubmitActions("save-basic-btn", "load-basic-btn");
            name = document.getElementById("name-edit")?.value;
            countryX = document.getElementById("country-edit")?.value;
            stateX = document.getElementById("state-edit")?.value;
            cityX = document.getElementById("city-edit")?.value;
            zipX = document.getElementById("zipcode-edit")?.value;
            emailX = document.getElementById("email-edit")?.value;
            phoneX = document.getElementById("phone-edit")?.value;
            corpId = document.getElementById("corporate-edit")?.value;
            tzId = document.getElementById("timezone-edit").value;
            payload = [{campus_id: parseInt(campusId), corporate_id: parseInt(corpId), campus_country: countryX,
                        campus_state: stateX, campus_name: name, campus_city: cityX, campus_zip: zipX,
                        campus_email: emailX, campus_phone: phoneX, timezone_id: parseInt(tzId)}];
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_campus", PKey: "campus_id", ClientId: dData.sessionId,
                                Condition: "primary", urlType: "POST", Payload: payload, User: dData.user, CampusId: campusId}).then(data => {
                if (data && data[0]){
                    setCount(0);
                    formSuccess("Basic information saved.", "save-basic-btn", "load-basic-btn",
                        "edit-basic-btn");
                }
                else{
                    formFail(`Error during save, error code: ${data && data?.length > 1 ? data[1] : ""}`,
                        "save-basic-btn", "load-basic-btn");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function migrateModal(start, end, tableData, displayItem, recIdItem, nameItem, setDContent, checkboxId="uCheck"){
        let modal, display, recIdList, modalContent, nameList, tmpRecs;
        if (addRights){
            display = "";
            recIdList = [];
            nameList = [];
            for (let i=start; i < end; i++){
                let nxtCheck = document.getElementById(checkboxId+i.toString());
                if (nxtCheck?.checked){
                    display += tableData[i][displayItem] + ", ";
                    tmpRecs = [];
                    for (let i2 = 0; i2 < recIdItem.length; i2++){
                        tmpRecs.push(tableData[i][recIdItem[i2]]);
                    }
                    recIdList.push(tmpRecs);
                    nameList.push(tableData[i][nameItem]);
                }
            }
            setDContent(recIdList);
            display = display.slice(0, -2);
            modalContent = document.getElementById("migrateModalContent");
            modalContent.innerText = `The following users will be migrated to 4.0. This cannot be undone, do you wish to proceed? \n\n${display}`;
            document.getElementById("uContent").value = nameList;
            modal = new Modal(document.getElementById("migratemodal"), {});
            modal.show();
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function migrateGroupModal(start, end, tableData, displayItem, recIdItem, nameItem, setDContent, checkboxId="uCheck"){
        let modal, display, recIdList, modalContent, nameList, tmpRecs;
        if (addRights){
            display = "";
            recIdList = [];
            nameList = [];
            for (let i=start; i < end; i++){
                let nxtCheck = document.getElementById(checkboxId+i.toString());
                if (nxtCheck?.checked){
                    display += tableData[i][displayItem] + ", ";
                    tmpRecs = [];
                    for (let i2 = 0; i2 < recIdItem.length; i2++){
                        tmpRecs.push(tableData[i][recIdItem[i2]]);
                    }
                    recIdList.push(tmpRecs);
                    nameList.push(tableData[i][nameItem]);
                }
            }
            setDContent(recIdList);
            display = display.slice(0, -2);
            modalContent = document.getElementById("migrateGroupModalContent");
            modalContent.innerText = `The following groups will be migrated to 4.0. This cannot be undone, do you wish to proceed? \n\n${display}`;
            document.getElementById("uContent2").value = nameList;
            modal = new Modal(document.getElementById("migrategroupmodal"), {});
            modal.show();
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function getGroupIdListByGuidList(userRec){
       let idList = [];
       for (let i=0; i < myGroupData?.length; i++){
           if (userRec?.GroupIds && userRec?.GroupIds.includes(myGroupData[i]?.group_guid)){
               if (!idList.includes(myGroupData[i]?.group_id)){
                   idList.push(myGroupData[i]?.group_id);
               }
           }
       }
       return idList;
    }

    function migratePerformSubmit(){
        let payload = [], role, pAccess, groupIds;
        if (addRights){
            handleSubmitActions("migrateSubmit", "loadMigrateSubmit");
            for (let i=0; i < userActiveData.length; i++){
                if (userIdList.includes(userActiveData[i]?.UserId)){
                    switch (userActiveData[i]?.UserRole) {
                        case "admin": role = 5; break;
                        case "corporate": role = 4; break;
                        case "corporate-admin": role = 3; break;
                        case "palcare": role = 3; break;
                        case "palcare-admin": role = 1; break;
                        default: role = 6; break;
                    }
                    pAccess = getAccessSettings(parseInt(role));
                    groupIds = getGroupIdListByGuidList(userActiveData[i]);
                    payload.push({Action: "migrate", DisplayName: userActiveData[i]?.DisplayName,
                        UserEmail: userActiveData[i]?.UserEmail ? userActiveData[i]?.UserEmail : "", UserRole: parseInt(role), UserId: userActiveData[i]?.UserId,
                        Migrated: true, CampusGuid: basicData[0]?.campus_guid, PortalAccess: pAccess,
                        GroupIds: groupIds, Username: userActiveData[i]?.UserName, CampusId: campusId, ClientId: dData.sessionId})
                }
            }
            ApiRequester({reqEndpoint: "user-manage", Payload: payload}).then(data => {
                if (data[0]){
                    formSuccess("All users migrated.", "migrateSubmit", "loadMigrateSubmit", "migrateConfirmClose");
                    setCount(0);
                    setCount2(0);
                    document.getElementById("uCheckHead").checked = false;
                    checkAll(0, ppRules.end);
                    actionAppearance(0, ppRules.end, ["migrateBtn"]);
                }
                else{
                    formFail(`Error during migration, check user table.`, "migrateSubmit", "migrateConfirmClose");
                    setCount(0);
                    setCount2(0);
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function migrateGroupPerformSubmit(){
        let payload = [];
        if (addRights){
            handleSubmitActions("migrateGroupSubmit", "loadMigrateGroupSubmit");
            for (let i=0; i < groupActiveData.length; i++){
                if (groupIdList.includes(groupActiveData[i]?.GroupId)){
                    payload.push({campus_id: campusId, group_name: groupActiveData[i]?.GroupName,
                        capcode: groupActiveData[i]?.CapCode, group_guid: groupActiveData[i]?.GroupId})
                }
            }
            ApiRequester({reqEndpoint: "mytransaction", TransType: "group_add", Payload: payload,
                                CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
                if (data[0]){
                    formSuccess("Groups migrated successfully.", "migrateGroupSubmit", "loadMigrateGroupSubmit", "migrateGroupConfirmClose");
                    setCount(0);
                    setCount2(0);
                    document.getElementById("uCheckHead2").checked = false;
                    checkAll(0, ppRules.end);
                    actionAppearance(0, ppRules.end, ["migrateBtn"]);
                }
                else{
                    formFail(`Error during migration, check group table.`, "migrateGroupSubmit", "migrateGroupConfirmClose");
                    setCount(0);
                    setCount2(0);
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function handleFileUpload(){
        let file, filereader, filedatareader;
        if (editRights){
            file = document.getElementById('file-upload').files[0];
            if (file){
                setNewFileName(() => file?.name ? file.name : "");
                setNewFileExt(() => file?.name ? file.name.split(".")[1] : "");
                setFileValid(false);
                filedatareader = new FileReader();
                filedatareader.onload = function(event) {
                    let csvData = event?.target?.result;
                    let parsedData = csvData ? Papa.parse(csvData, {header: true}).data : [];
                    setNewFileData(() => parsedData);
                }
                filedatareader.readAsText(file);
                filereader = new FileReader();
                filereader.onload = function(event) {
                    let csvData = event.target.result;
                    setNewFile(() => Papa.parse(csvData).data[0][1]);
                }
                filereader.readAsDataURL(file);
            }
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function uploadSubmit(){
        if (editRights){
            handleSubmitActions("uploadSubmit", "loadUploadSubmit");
            let payload = {upload_file: newFile, upload_areas: addAreas, upload_residents: addResidents,
                            upload_devices: addDevices};
            ApiRequester({reqEndpoint: "uploadprocess", Payload: payload, User: dData.user,
                                CampusId: campusId, Process: "init"}).then(data => {
                if (data && data !== []){
                    toast.info("File upload in process, awaiting results");
                    setUploadKey(() => data);
                    setUploadTimer(() => 120);
                }
                else{
                    formFail(`Error during file upload: ${data[1]}`, "uploadSubmit", "loadUploadSubmit");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function uploadPrecheck(){
        let error = false;
        if (newFileData.length <= 0){
            toast.error("File empty");
            error = true;
        }
        if (!error){
            for (let i=0; i < newFileData.length; i++){
                if (newFileData[i]?.room !== undefined && newFileData[i].room !== ""){
                    if (newFileData[i]?.areatype === undefined || newFileData[i]?.areatype === ""){
                        toast.error(`Missing areatype for row ${i+2}`);
                        error = true;
                        break;
                    }else if (!["other", "access", "mc", "al", "il", "snf", "common"].includes(newFileData[i]?.areatype?.toLowerCase())){
                        toast.error(`Invalid areatype for row ${i+2}`);
                        error = true;
                        break;
                    }else if (["'", '"', ".", ",", "\\", "/"].includes(newFileData[i]?.room)){
                        toast.error(`Erroneous character in room for row ${i+2}`);
                        error = true;
                        break;
                    }
                }else{
                    if ([newFileData[i]?.areatype === undefined || newFileData[i]?.areatype === "",
                         newFileData[i]?.lastname === undefined || newFileData[i]?.lastname === "",
                         newFileData[i]?.firstname === undefined || newFileData[i]?.firstname === "",
                         newFileData[i]?.serial === undefined || newFileData[i]?.serial === "",
                         newFileData[i]?.deviceuse === undefined || newFileData[i]?.deviceuse === ""].includes(false)){
                        toast.error(`Missing room for row ${i+2}`);
                        error = true;
                        break;
                    }
                }
                if (newFileData[i]?.firstname !== undefined && newFileData[i]?.firstname !== ""){
                    if (newFileData[i]?.lastname === undefined || newFileData[i]?.lastname === ""){
                        toast.error(`Missing lastname for row ${i+2}`);
                        error = true;
                        break;
                    }else if (["'", '"', ".", ",", "\\", "/"].includes(newFileData[i]?.firstname)){
                        toast.error(`Erroneous character in firstname for row ${i+2}`);
                        error = true;
                        break;
                    }else if (["'", '"', ".", ",", "\\", "/"].includes(newFileData[i]?.lastname)){
                        toast.error(`Erroneous character in lastname for row ${i+2}`);
                        error = true;
                        break;
                    }
                }
                if (newFileData[i]?.lastname !== undefined && newFileData[i]?.lastname !== ""){
                    if (newFileData[i]?.firstname === undefined || newFileData[i]?.firstname === ""){
                        toast.error(`Missing firstname for row ${i+2}`);
                        error = true;
                        break;
                    }else if (["'", '"', ".", ",", "\\", "/"].includes(newFileData[i]?.lastname)){
                        toast.error(`Erroneous character in lastname for row ${i+2}`);
                        error = true;
                        break;
                    }else if (["'", '"', ".", ",", "\\", "/"].includes(newFileData[i]?.firstname)){
                        toast.error(`Erroneous character in firstname for row ${i+2}`);
                        error = true;
                        break;
                    }
                }
                if (newFileData[i]?.deviceuse !== undefined && newFileData[i]?.deviceuse !== ""){
                    if (newFileData[i]?.serial === undefined || newFileData[i]?.serial === ""){
                        toast.error(`Missing serial for row ${i+2}`);
                        error = true;
                        break;
                    }
                }
                if (newFileData[i]?.serial !== undefined && newFileData[i]?.serial !== ""){
                    if (newFileData[i]?.deviceuse === undefined || newFileData[i]?.deviceuse === ""){
                        toast.error(`Missing deviceuse for row ${i+2}`);
                        error = true;
                        break;
                    }
                }
            }
        }
        if (!error){
            toast.info("File good, ready for upload.");
            setFileValid(true);
        }
    }

    const userHeaders = (<TableHeaders checkbox={addRights} headerData={userHeaderData}
                                       checkAll={() => checkAll(ppRules.start, ppRules.end, userIdList, setUserIdList, "uCheckHead", "uCheckUser")}
                                       writeAccess={addRights} sortOrder={sorter.sortOrder}
                                       sortColumn={sorter.sortColumn} sorter={sorter} setSorter={setSorter}
                                       modal={false} checkboxlist={userIdList}
                                       actionAppearance={() => actionAppearance(ppRules.start, ppRules.end, ["migrateBtn"], "uCheckUser")} />);

   const groupHeaders = (<TableHeaders checkbox={addRights} headerData={groupHeaderData} checkboxHeaderId={"uCheckHead2"}
                                       checkAll={() => checkAll(groupPPRules.start, groupPPRules.end, groupIdList, setGroupIdList, "uCheckHead2", "uCheckGroup")}
                                       writeAccess={addRights} sortOrder={groupSorter.sortOrder}
                                       sortColumn={groupSorter.sortColumn} sorter={groupSorter} setSorter={setGroupSorter}
                                       modal={false} checkboxlist={groupIdList}
                                       actionAppearance={() => actionAppearance(groupPPRules.start, groupPPRules.end, ["migrateGroupBtn"], "uCheckGroup")} />);


    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div><Button text={"Upload Masterfile"} type={"button"} class={"btn btn-primary mx-3 mt-1"}
                    onClick={() => {
                        setNewFile("");
                        setNewFileName("");
                        setNewFileExt("");
                        setUploadKey("");
                        showModal("uploadmodal");
                    }} />
                <Link to={`/logs/campus/${campusId}`}>
                    <Button text={"View Audit Logs"} type={"button"} class={"btn btn-primary mt-1 mx-3"} />
                </Link>
            </div>
            <div>
                {dData.corp.toString() === corporateId.toString()
                    ?   dData.myCorp.toString() === corporateId.toString()
                        ? <></>
                        : <Button text={"Impersonating"} type={"button"} class={"btn btn-success ms-2 mt-1"} icon={true}
                                  iconClass={"fe fe-eye me-2"} disabled={true} />
                    :   <Button text={"Impersonate"} type={"button"} class={"btn btn-primary ms-2 mt-1"}
                                icon={true} iconClass={"fe fe-eye me-2"}
                                onClick={() => {reloadSessionCorp(corporateId, 0, dData.cognito, dData, campusId)}} />
                }
            </div>
        </div>
    );

    return (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Campus Profile"} Title={campusName} content={headerContent}/>
                <div className={"container-fluid mt-4"}>
                    <div className={"card"} style={{minWidth: "550px"}}>
                        <div className={"card-header d-flex justify-content-between"}>
                            <div>
                                <h3 className={"card-header-title d-inline-block"}>Basic Information</h3>
                            </div>
                            <div>
                                <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"basic-card-btn"}
                                   onClick={() => toggleCollapse("basic-card-btn", "basic-card")}></i>
                            </div>
                        </div>
                        <div className={"card-body collapse collapse-content show"} id={"basic-card"}>
                            <form className={"d-inline align-top form-control-plaintext was-validated"}
                                  id={"basic-form"}>
                                <div className={"mx-5 mt-2 align-top d-inline-block"} id={"basic-col1-div"}>
                                    <div className={"d-flex"}>
                                        <p className={"mb-1"} style={{minWidth: "125px"}}>Id:</p>
                                        <p className={"ms-4 mb-1"} id={"id-static"}>{campusId}</p>
                                    </div>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "125px"}}>Guid:</p>
                                        <p className={"ms-4"} id={"guid-static"}>{campusGuid}</p>
                                    </div>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "125px"}}>Name:</p>
                                        <p className={"ms-4"} id={"name-static"}>{campusName}</p>
                                        <input className={"ms-4 d-none form-control form-control-sm"} required={true}
                                               style={{height: "30px", width: "225px"}} id={"name-edit"}
                                               defaultValue={campusName} pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                    </div>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "125px"}}>Corporation:</p>
                                        <Link to={`/profiles/corporate/${corporateId}`}>
                                            <p className={"ms-4"} id={"corporate-static"}>{corporateName}</p>
                                        </Link>
                                        <select className={"ms-4 d-none form-select form-select-sm mb-1"}
                                                onChange={() => {
                                                    formCheck("basic-form", "save-basic-btn");
                                                }}
                                                style={{height: "30px", width: "225px"}} id={"corporate-edit"}
                                                defaultValue={corporateId}>
                                            {corporateOptions}
                                        </select>
                                    </div>
                                    <div className={"d-flex"}>
                                        <p className={"mb-1"} style={{minWidth: "125px"}}>Street Address:</p>
                                        <p className={"ms-4 mb-1"} id={"street-static"}>{streetAddr}</p>
                                        <input className={"ms-4 d-none form-control form-control-sm mb-1"}
                                               style={{height: "30px", width: "225px"}} id={"street-edit"}
                                               defaultValue={streetAddr} pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                    </div>
                                    <div className={"d-flex"}>
                                        <p className={"mb-1"} style={{minWidth: "125px"}}>Country:</p>
                                        <p className={"ms-4 mb-1"} id={"country-static"}>{country}</p>
                                        <select className={"ms-4 d-none form-select form-select-sm mb-1"}
                                                onChange={(e) => {
                                                    formCheck("basic-form", "save-basic-btn");
                                                    setCountryOptionsFunction(e?.target?.value?.toString())
                                                }}
                                                style={{height: "30px", width: "225px"}} id={"country-edit"}
                                                defaultValue={country}>
                                            {countryOptions}
                                        </select>
                                    </div>
                                    <div className={"d-flex"}>
                                        <p className={"mb-1"} style={{minWidth: "125px"}}>State:</p>
                                        <p className={"ms-4 mb-1"} id={"state-static"}>{state}</p>
                                        <select className={"ms-4 d-none form-select form-select-sm mb-1"}
                                                onChange={() => formCheck("basic-form", "save-basic-btn")}
                                                style={{height: "30px", width: "225px"}} id={"state-edit"}
                                                defaultValue={state}>
                                            {stateOptions}
                                        </select>
                                    </div>
                                    <div className={"d-flex"}>
                                        <p className={"mb-1"} style={{minWidth: "125px"}}>City:</p>
                                        <p className={"ms-4 mb-1"} id={"city-static"}>{city}</p>
                                        <input className={"ms-4 d-none form-control form-control-sm mb-1"}
                                               style={{height: "30px", width: "225px"}} id={"city-edit"}
                                               defaultValue={city} pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                    </div>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "125px"}}>Zip Code:</p>
                                        <p className={"ms-4"} id={"zipcode-static"}>{zipcode}</p>
                                        <input className={"ms-4 d-none form-control form-control-sm"}
                                               style={{height: "30px", width: "225px"}} id={"zipcode-edit"}
                                               defaultValue={zipcode} pattern={zipReg}/>
                                    </div>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "125px"}}>Timezone:</p>
                                        <p className={"ms-4"} id={"timezone-static"}>{timezone}</p>
                                        <select className={"ms-4 d-none form-select form-select-sm mb-1"}
                                                onChange={() => formCheck("basic-form", "save-basic-btn")}
                                                style={{height: "30px", width: "225px"}} id={"timezone-edit"}
                                                defaultValue={timezoneId}>
                                            {timezoneOptions}
                                        </select>
                                    </div>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "125px"}}>Email:</p>
                                        <p className={"ms-4"} id={"email-static"}>{email}</p>
                                        <input className={"ms-4 d-none form-control form-control-sm"}
                                               style={{height: "30px", width: "225px"}} id={"email-edit"}
                                               defaultValue={email}
                                               pattern={"[A-Za-z0-9._%+\\-]+@[A-Za-z0-9.\\-]+\\.[A-Za-z]{2,}$"}/>
                                    </div>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "125px"}}>Phone #:</p>
                                        <p className={"ms-4"} id={"phone-static"}>{formatPhone(phone)}</p>
                                        <input className={"ms-4 d-none form-control form-control-sm"}
                                               style={{height: "30px", width: "225px"}} id={"phone-edit"}
                                               defaultValue={phone} pattern={"[0-9]{3}-?[0-9]{3}-?[0-9]{4}"}/>
                                    </div>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "125px"}}>Lat/Long:</p>
                                        <p className={"ms-4"}
                                           id={"latlong-static"}>{latitude ? latitude : "?"} / {longitude ? longitude : "?"}</p>
                                    </div>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "125px"}}>Service Plan Date:</p>
                                        <p className={"ms-4"} id={"service-static"}>{convertIsoToLocal(servicePlan)}</p>
                                    </div>
                                </div>
                            </form>
                            {pageLoaded && editRights &&
                                <>
                                    <Button text={"Edit"} class={"btn btn-primary float-end"} id={"edit-basic-btn"}
                                            disabled={!editRights} onClick={() => {
                                        if (editRights) {
                                            basicActionHandling()
                                        }
                                    }}/>
                                    <Button text={"Save"} class={"btn btn-primary float-end me-3 d-none"} id={"save-basic-btn"}
                                        disabled={!editRights} onClick={() => {
                                        if (editRights) {
                                            basicSubmit()
                                        }
                                    }}/>
                                    <Button type={"button"} class={"btn btn-primary float-end mx-3 d-none"} disabled={true}
                                            spinner={true} text={""} id={"load-basic-btn"}/>
                                </>
                            }
                        </div>
                    </div>
                    {groupActiveData.length > 0
                        ? <>
                            <div className={"card"} style={{minWidth: "500px"}}>
                                <div className={"card-header d-flex justify-content-between"}>
                                    <div>
                                        <h3 className={"card-header-title d-inline-block"}
                                            style={{minWidth: "80px"}}>2.0 Groups</h3>
                                        <div className={"d-inline"} style={{display: "inline-block"}}
                                             id={"group-header-data"}>
                                            <h3 className={"ms-6 my-0 d-inline-block text-success"}>{groupActiveData?.length} Groups
                                                available to migrate</h3>
                                        </div>
                                    </div>
                                    <div>
                                        <i className={"fe fe-plus as-btn collapse-toggle p-2"} id={"group-card-btn"}
                                           onClick={() => {
                                               toggleCollapse("group-card-btn", "group-card");
                                               document.getElementById("group-header-data").classList.toggle("d-none");
                                           }}></i>
                                    </div>
                                </div>
                                <div className={"card-body collapse collapse-content hide"} id={"group-card"}
                                     style={{maxHeight: "500px", overflowY: "auto"}}>
                                    <div className={"d-flex justify-content-between"}>
                                        <div>

                                        </div>
                                        <div style={{minHeight: "55px"}}>
                                            {addRights && <Button text={"Migrate"} type={"button"}
                                                                 class={"btn btn-primary me-2 hide mb-3"}
                                                                 id={"migrateGroupBtn"} icon={true}
                                                                 iconClass={"fe fe-upload-cloud me-2"}
                                                                 onClick={() => migrateGroupModal(groupPPRules.start, groupPPRules.end, groupActiveData,
                                                                     "GroupName", ["GroupId", ""], "", setDContent2, "uCheckGroup")}/>}
                                        </div>
                                    </div>
                                    <div>
                                        <table className={"table table-sm table-white table-hover"}>
                                            <thead className={"sticky-table-head"} style={{top: "-25px"}}>
                                            {groupHeaders}
                                            </thead>
                                            <tbody>
                                            {groupRows}
                                            </tbody>
                                        </table>
                                        <div className={"text-center"}>
                                            <Pagination>
                                                <PaginationItems setPage={setGroupPage} page={groupPage}
                                                                 beginDot={groupPPRules.beginDot}
                                                                 endDot={groupPPRules.endDot}
                                                                 pageMin={groupPPRules.pageMin}
                                                                 pageMax={groupPPRules.pageMax}
                                                                 max={groupPPRules.max}/>
                                            </Pagination>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        : <></>}
                    {userActiveData.length > 0
                        ? <>
                            <div className={"card"} style={{minWidth: "500px"}}>
                                <div className={"card-header d-flex justify-content-between"}>
                                    <div>
                                        <h3 className={"card-header-title d-inline-block"}
                                            style={{minWidth: "80px"}}>2.0 Users</h3>
                                        <div className={"d-inline"} style={{display: "inline-block"}}
                                             id={"user-header-data"}>
                                            <h3 className={"ms-6 my-0 d-inline-block text-success"}>
                                                {userActiveData?.length} Users available to migrate
                                            </h3>
                                        </div>
                                    </div>
                                    <div>
                                        <i className={"fe fe-plus as-btn collapse-toggle p-2"} id={"user-card-btn"}
                                           onClick={() => {
                                               toggleCollapse("user-card-btn", "user-card");
                                               document.getElementById("user-header-data").classList.toggle("d-none");
                                           }}></i>
                                    </div>
                                </div>
                                <div className={"card-body collapse collapse-content hide"} id={"user-card"}
                                     style={{maxHeight: "500px", overflowY: "auto"}}>
                                    <div className={"d-flex justify-content-between"}>
                                        <div>

                                        </div>
                                        <div style={{minHeight: "55px"}}>
                                            {addRights && <Button text={"Migrate"} type={"button"}
                                                                 class={"btn btn-primary me-2 hide mb-3"}
                                                                 id={"migrateBtn"} icon={true}
                                                                 iconClass={"fe fe-upload-cloud me-2"}
                                                                 onClick={() => migrateModal(ppRules.start, ppRules.end, userActiveData,
                                                                     "UserName", ["UserId", ""], "", setDContent, "uCheckUser")}/>}
                                        </div>
                                    </div>
                                    <div>
                                        <table className={"table table-sm table-white table-hover"}>
                                            <thead className={"sticky-table-head"} style={{top: "-25px"}}>
                                            {userHeaders}
                                            </thead>
                                            <tbody>
                                            {userRows}
                                            </tbody>
                                        </table>
                                        <div className={"text-center"}>
                                            <Pagination>
                                                <PaginationItems setPage={setPage} page={page}
                                                                 beginDot={ppRules.beginDot}
                                                                 endDot={ppRules.endDot} pageMin={ppRules.pageMin}
                                                                 pageMax={ppRules.pageMax}
                                                                 max={ppRules.max}/>
                                            </Pagination>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        : <></>}
                    <div className={"card"} style={{minWidth: "500px"}}>
                        <div className={"card-header d-flex justify-content-between"}>
                            <div>
                                <h3 className={"card-header-title d-inline-block"} style={{minWidth: "80px"}}>Server
                                    Status's</h3>
                                <div className={"d-inline"} style={{display: "inline-block"}} id={"db-header-data"}>
                                    <h3 className={"ms-6 my-0 d-inline-block text-success"}></h3>
                                </div>
                            </div>
                            <div>
                                <i className={"fe fe-plus as-btn collapse-toggle p-2"} id={"devices-card-btn"}
                                   onClick={() => {
                                       toggleCollapse("db-card-btn", "db-card");
                                       document.getElementById("db-header-data").classList.toggle("d-none");
                                   }}></i>
                            </div>
                        </div>
                        <div className={"card-body collapse collapse-content hide"} id={"db-card"}>
                            <table className={"table table-sm table-responsive-sm table-hover"}>
                                <thead className={"table-header"}>
                                <tr>
                                    <td className={"text-center"}>Server</td>
                                    <td className={"text-center"}>Process Count</td>
                                    <td className={"text-center"}>Aggregate Count</td>
                                    <td className={"text-center"}>Eman Count</td>
                                    <td className={"text-center"}>Maint. Count</td>
                                    <td className={"text-center"}>Other Count</td>
                                    <td className={"text-center"}>Alarm Time</td>
                                    <td className={"text-center"}>Alarm Parsed</td>
                                    <td className={"text-center"}>Last Updated</td>
                                </tr>
                                </thead>
                                <tbody>
                                {deviceData.map((device) => {
                                    if (corporateId.toString() === dData.corp.toString()) {
                                        return (<tr>
                                            <td><Link
                                                to={`/profiles/server/${device?.palcare_device_id}`}>{device?.description}</Link>
                                            </td>
                                            <td className={"text-center"}>{device?.count_process_queue}</td>
                                            <td className={"text-center"}>{device?.count_aggregate_repeater_queue}</td>
                                            <td className={"text-center"}>{device?.count_eman_event_queue}</td>
                                            <td className={"text-center"}>{device?.count_maint_queue}</td>
                                            <td className={"text-center"}>{device?.count_other_queue}</td>
                                            <td className={"text-center"}>{device?.recent_alarm_input_time}</td>
                                            <td className={"text-center"}>
                                                <MaterialIcon className={"material-icons as-btn-2x me-2"}
                                                              onClick={() => {
                                                                  toast.info(device?.recent_alarm_input_raw);
                                                                  navigator?.clipboard?.writeText(device?.recent_alarm_input_raw);
                                                              }}
                                                              icon={"raw_on"} title={device?.recent_alarm_input_raw}/>
                                                <MaterialIcon className={"material-icons as-btn-2x"}
                                                              onClick={() => {
                                                                  toast.info(device?.recent_alarm_input_parsed);
                                                                  navigator?.clipboard?.writeText(device?.recent_alarm_input_parsed);
                                                              }}
                                                              icon={"data_object"}
                                                              title={device?.recent_alarm_input_parsed}/>
                                            </td>
                                            <td className={"text-center"}>{device?.modify_time}</td>
                                        </tr>);
                                    } else {
                                        return <tr title={"Impersonate this campus for server linking."}>
                                            <td>{device?.description}</td>
                                            <td className={"text-center"}>{device?.count_process_queue}</td>
                                            <td className={"text-center"}>{device?.count_aggregate_repeater_queue}</td>
                                            <td className={"text-center"}>{device?.count_eman_event_queue}</td>
                                            <td className={"text-center"}>{device?.count_maint_queue}</td>
                                            <td className={"text-center"}>{device?.count_other_queue}</td>
                                            <td className={"text-center"}>{device?.recent_alarm_input_time}</td>
                                            <td className={"text-center"}>
                                                <MaterialIcon className={"material-icons as-btn-2x me-2"}
                                                              onClick={() => toast.info(device?.recent_alarm_input_raw)}
                                                              icon={"raw_on"} title={device?.recent_alarm_input_raw}/>
                                                <MaterialIcon className={"material-icons as-btn-2x"}
                                                              onClick={() => toast.info(device?.recent_alarm_input_parsed)}
                                                              icon={"data_object"}
                                                              title={device?.recent_alarm_input_parsed}/>
                                            </td>
                                            <td className={"text-center"}>{device?.modify_time}</td>
                                        </tr>
                                    }
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"uploadmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={"-1"} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Upload Master File CSV</h2>
                            <button type={"button"} className={"btn-close"} id={"uploadConfirmClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <div className={"modal-body"}>
                            <div className={"d-inline-block align-bottom"}>
                                <input className={"d-none"} id={"file-upload"} type={"file"} accept={".csv"}
                                       onChange={() => handleFileUpload()}/>
                                <Button type={"button"} text={"Set File"}
                                        class={"btn btn-outline-primary me-4 d-inline"}
                                        onClick={() => {
                                            document.getElementById("file-upload").click();
                                        }}/>
                                <div className={"form-floating mb-3 d-inline-block"} style={{width: "250px"}}>
                                    <input type={"text"} id={"filename-item"} className={"form-control"}
                                           readOnly={true} value={newFileName}></input>
                                    <label htmlFor={"filename-item"}>Selected File</label>
                                </div>
                            </div>
                            <div className={"d-inline-block ms-4"}>
                                <div className={"form-check form-switch mt-1"}>
                                    <input className={"form-check-input"} type={"checkbox"} checked={addAreas}
                                           id={"upload_areas"} onChange={(e) => setAddAreas(e.target.checked)}/>
                                    <label className={"form-check-label text-dark"}
                                           htmlFor={"upload_areas"}>Add Areas</label>
                                </div>
                                <div className={"form-check form-switch mt-1"}>
                                    <input className={"form-check-input"} type={"checkbox"} checked={addResidents}
                                           id={"upload_residents"} onChange={(e) => setAddResidents(e.target.checked)}/>
                                    <label className={"form-check-label text-dark"}
                                           htmlFor={"upload_residents"}>Add Residents</label>
                                </div>
                                <div className={"form-check form-switch mt-1"}>
                                    <input className={"form-check-input"} type={"checkbox"} checked={addDevices}
                                           id={"upload_devices"} onChange={(e) => {
                                        setAddDevices(e.target.checked)
                                    }}/>
                                    <label className={"form-check-label text-dark"}
                                           htmlFor={"upload_devices"}>Add Devices</label>
                                </div>
                            </div>
                        </div>
                        <div className={"modal-footer d-flex justify-content-between"}>
                            <div>
                                <Button type={"button"} class={"btn btn-primary"} text={"Check File"}
                                        onClick={() => uploadPrecheck()}
                                        disabled={newFile === ""}/>
                            </div>
                            <div>
                                <button type={"button"} className={"btn btn-secondary mx-2"} style={{width: "75px"}}
                                        data-bs-dismiss={"modal"} id={"uploadCancel"}>Cancel
                                </button>
                                <Button type={"button"} class={"btn btn-primary d-none ms-2"} style={{width: "100px"}}
                                        disabled={true} spinner={true} text={"Uploading"} id={"loadUploadSubmit"}/>
                                <button type={"button"} className={newFile === "" || !fileValid ? "btn btn-danger ms-2" : "btn btn-primary ms-2"}
                                        style={{width: "100px"}} id={"uploadSubmit"} disabled={newFile === "" || !fileValid}
                                        onClick={() => uploadSubmit()}>Upload
                                </button>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"migratemodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={"-1"} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>{props?.header}</h2>
                            <button type={"button"} className={"btn-close"} id={"migrateConfirmClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <div className={"modal-body"}>
                            <h2 className={"m-3"} style={{textAlign: "left"}} id={"migrateModalContent"}></h2>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                    data-bs-dismiss={"modal"} id={"migrateCancel"}>No
                            </button>
                            <Button type={"button"} class={"btn btn-primary d-none"} style={{width: "75px"}}
                                    disabled={true} spinner={true} text={"Loading"} id={"loadMigrateSubmit"}/>
                            <button type={"button"} className={"btn btn-primary"} style={{width: "75px"}}
                                    id={"migrateSubmit"} onClick={() => migratePerformSubmit()}>Yes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"migrategroupmodal"} data-bs-backdrop={"static"}
                 data-bs-keyboard={"false"}
                 tabIndex={"-1"} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>{props?.header}</h2>
                            <button type={"button"} className={"btn-close"} id={"migrateGroupConfirmClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <div className={"modal-body"}>
                            <h2 className={"m-3"} style={{textAlign: "left"}} id={"migrateGroupModalContent"}></h2>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                    data-bs-dismiss={"modal"} id={"migrateGroupCancel"}>No
                            </button>
                            <Button type={"button"} class={"btn btn-primary d-none"} style={{width: "75px"}}
                                    disabled={true} spinner={true} text={"Loading"} id={"loadMigrateGroupSubmit"}/>
                            <button type={"button"} className={"btn btn-primary"} style={{width: "75px"}}
                                    id={"migrateGroupSubmit"} onClick={() => migrateGroupPerformSubmit()}>Yes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <input type={"hidden"} id={"dContent"}/>
            <input type={"hidden"} id={"dContent2"}/>
            <input type={"hidden"} id={"uContent"}/>
            <input type={"hidden"} id={"uContent2"}/>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true}
                                autoClose={10000}/>
            </Suspense>
        </>
    );
};

export default CampusProfile;