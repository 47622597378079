import {Link} from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import Header from "../Header";
import Button from "../component-assets/Button";
import ApiRequester from "../ApiRequester";
import {
    convertIsoToLocal,
    convertSectoHHMM,
    formatDateIso, iAmHigherAuthority,
    formCheck, formFail, formSuccess, getLocalTimeFromEpochMillis, getToday,
    handleSubmitActions, HHMMtoLocalTime, setDeviceFilters, toggleCollapse
} from "../../libs";
import MaterialIcon from "material-icons-react";
import {Chart, registerables} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import TableBody from "../component-assets/TableBody";
import TableHeaders from "../component-assets/TableHeaders";

const InovonicsDevice = (props) => {
    let dData, bData, startDate, endDate, eventHeaderData;

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    Chart.register(...registerables);
    Chart.register(annotationPlugin);

    eventHeaderData = [
        {label: "Event Time", key: "event_datetime_local", align: "center", type: "datetime", sorts: false},
        {label: "Response Time (min)", key: "response_time", align: "center", sorts: false},
        {label: "Resident", key: "resident_name", align: "left", sorts: false,
            link: {enabled: true, type: "standard", linkto: "/profiles/resident", linkkey: "resident_id"}},
        {label: "Location", key: "area_name", align: "left", sorts: false,
            link: {enabled: true, type: "standard", linkto: "/profiles/area", linkkey: "area_id"}},
        {label: "Reason", key: "reason_code_description", align: "left", sorts: false},
    ];

    // Permission Variables
    const [editRights, setEditRights] = useState(iAmHigherAuthority(10, dData.role_weight));
    // Main Page Variables
    const [deviceId, setDeviceId] = useState(props?.id);
    const [count, setCount] = useState(0);
    const [repCount, setRepCount] = useState(0);
    const [campusId, setCampusId] = useState(dData.acnt);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [tabItem, setTabItem] = useState("basic");
    // Basic Information Variables
    const [basicData, setBasicData] = useState([]);
    const [debugData, setDebugData] = useState([]);
    const [areaData, setAreaData] = useState([]);
    const [residentData, setResidentData] = useState([]);
    const [basicLoaded, setBasicLoaded] = useState(false);
    const [resLoaded, setResLoaded] = useState(false);
    const [areaGroups, setAreaGroups] = useState([]);
    const [resGroups, setResGroups] = useState([]);
    // Reporting Variables
    const [weekData, setWeekData] = useState({});
    const [hourData, setHourData] = useState({});
    const [hourDataLoaded, setHourDataLoaded] = useState(false);
    const [weekDataLoaded, setWeekDataLoaded] = useState(false);
    const [reasonData, setReasonData] = useState({});
    const [reasonRows, setReasonRows] = useState([]);
    const [eventData, setEventData] = useState([]);
    const [eventTableRows, setEventTableRows] = useState([]);
    // Wellness Variables
    const [areaWellData, setAreaWellData] = useState([]);
    const [careData, setCareData] = useState([]);
    const [careflow, setCareflow] = useState([]);

    startDate = formatDateIso(getToday(-28));
    endDate = formatDateIso(getToday());

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", payload: {device_filter: deviceId, campus_id: campusId},
                          procedure: "getinovonicsdeviceprofile", reqType: "stored"}).then(data => {
                setBasicData(data?.length > 0 ? data[0] : {});
                setBasicLoaded(true)
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "sp_dev_getareasbycampusid", reqType: "stored"}).then(data => {
                setAreaData(data ? data : []);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "sp_dev_getresidentsbycampusid", reqType: "stored"}).then(data => {
                setResidentData(data ? data : []);
                setResLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch",
                          payload: {campus_id: campusId, care_types: "1,3,4,5", care_event_types: "1"},
                          procedure: "getcareflowwindows", reqType: "stored"}).then(data => {
                setCareData(data);
            });
            setCount(() => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        if (![basicLoaded, resLoaded].includes(false)){
            setPageLoaded(true);
        }
    }, [basicLoaded, resLoaded]);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setRepCount(repCount => repCount - 1);
        }, 1000);
        if (repCount <= 0){
            ApiRequester({reqEndpoint: "get-mysql-report", rep_type: "dashboardhourchart",
                                campus_id: campusId, device_filter: deviceId.toString()}).then(data => {
                setHourData(data);
                setHourDataLoaded(true);
            });
            ApiRequester({reqEndpoint: "get-mysql-report", rep_type: "dashboardweekchart",
                                campus_id: campusId, device_filter: deviceId.toString()}).then(data => {
                setWeekData(data);
                setWeekDataLoaded(true);
            });
            ApiRequester({reqEndpoint: "get-mysql-report", rep_type: "eventReasonSummary",
                        campus_id: campusId, start_date: startDate, end_date: endDate,
                        device_category_filter: "", area_type_filter: "", reason_filter: "", user_filter: "",
                        event_type_filter: "", resident_filter: "", device_filter: deviceId.toString()}).then(data => {
                setReasonData(() => data ? data : []);
            });
            ApiRequester({reqEndpoint: "myfetch", payload: {campus_id: campusId, device_id: deviceId.toString()},
                                procedure: "getlimitedevents", reqType: "stored"}).then(data => {
                setEventData(data);
            });
            setRepCount(repCount => 1200);
        }
        return () => clearInterval(interval);
    }, [repCount]);

    useEffect(() => {
        if (Object.keys(basicData).length > 0){
            if (basicData?.manufacture_uuid){
                ApiRequester({reqEndpoint: "getitems", Table: "DebugSerialNumber", PKey: "SerialNum", CampusId: campusId,
                                    PVal: basicData.manufacture_uuid.toString()}).then(data => {
                    let tmpData = []
                    for (let i=0; i < data.length; i++){
                        data[i].Parsed = JSON.parse(data[i]?.Parsed);
                        tmpData.push(data[i])
                    }
                    setDebugData(tmpData ? tmpData : []);
                });
            }
            setAreaGroups(() => basicData?.area_groups ? basicData.area_groups : []);
            setResGroups(() => basicData?.resident_groups ? basicData.resident_groups : []);
        }
    }, [basicData]);

    useEffect(() => {
        let tmpAflows = [], tmpDflows = [], tmpDevflows = [], tmpRflows = [], allFlows;
        if (pageLoaded && careData?.length > 0){
            for (let i=0; i < careData?.length; i++){
                if (careData[i].escalation_type_id === 1){
                    tmpDflows.push(<>
                        <div key={`cfd-1-${i}`} className={"d-inline-block"} style={{minWidth: "175px", maxWidth: "175px", textOverflow: "ellipsis"}}>
                            {careData[i].careflow_description}
                        </div>
                        <div key={`cft-1-${i}`} className={"d-inline-block align-top ms-1"}>
                            {HHMMtoLocalTime(convertSectoHHMM(careData[i].enable_start))} - {HHMMtoLocalTime(convertSectoHHMM(careData[i].enable_end))} (Default)
                        </div>
                    </>);
                }else if (careData[i].escalation_type_id === 3){
                    if (careData[i]?.references.split(",").includes(deviceId.toString())){
                        tmpDevflows.push(<>
                            <div key={`cfd-3-${i}`} className={"d-inline-block"} style={{minWidth: "175px", maxWidth: "175px", textOverflow: "ellipsis"}}>
                                {careData[i].careflow_description}
                            </div>
                            <div key={`cft-3-${i}`} className={"d-inline-block align-top ms-1"}>
                                {HHMMtoLocalTime(convertSectoHHMM(careData[i].enable_start))} - {HHMMtoLocalTime(convertSectoHHMM(careData[i].enable_end))} (Device)
                            </div>
                        </>);
                    }
                }else if (careData[i].escalation_type_id === 4){
                    for (let i2=0; i2 < resGroups?.length; i2++){
                        if (careData[i]?.references.split(",").includes(resGroups[i2]?.resident_group_id?.toString())){
                            tmpRflows.push(<>
                                <div key={`cfd-4-${i}`} className={"d-inline-block"} style={{minWidth: "175px", maxWidth: "175px", textOverflow: "ellipsis"}}>
                                    {careData[i].careflow_description}
                                </div>
                                <div key={`cft-4-${i}`} className={"d-inline-block align-top ms-1"}>
                                    {HHMMtoLocalTime(convertSectoHHMM(careData[i].enable_start))} - {HHMMtoLocalTime(convertSectoHHMM(careData[i].enable_end))} (Resident)
                                </div>
                            </>);
                        }
                    }
                }else if (careData[i].escalation_type_id === 5){
                    for (let i3=0; i3 < areaGroups?.length; i3++){
                        if (careData[i]?.references.split(",").includes(areaGroups[i3]?.area_group_id?.toString())){
                            tmpAflows.push(<>
                                <div key={`cfd-5-${i}`} className={"d-inline-block"} style={{minWidth: "175px", maxWidth: "175px", textOverflow: "ellipsis"}}>
                                    {careData[i].careflow_description}
                                </div>
                                <div key={`cft-5-${i}`} className={"d-inline-block align-top ms-1"}>
                                    {HHMMtoLocalTime(convertSectoHHMM(careData[i].enable_start))} - {HHMMtoLocalTime(convertSectoHHMM(careData[i].enable_end))} (Area)
                                </div>
                            </>);
                        }
                    }
                }
            }
            allFlows = tmpDevflows.concat(tmpRflows, tmpAflows, tmpDflows);
            setCareflow(allFlows.map((item, i) => {return <div key={`cf-item-${i}`}>{item}<br/></div>}));
        }
    }, [careData, pageLoaded]);

    useEffect(() => {
        setEventTableRows(<TableBody start={0} end={eventData.length} tableData={eventData} checkbox={false}
                            writeAccess={false} unk={"dev-"}
                            actionAppearance={() => {}} parentComponent={"InovonicsDevice"}
                            tableHeaders={eventHeaderData} editModal={() => {}} modal={false}
                            dData={dData} checkboxlist={[]}
                            setCheckboxId={() => {}} checkidkey={"device_id"} />);
    }, [eventData]);

    useEffect(() => {
        if (hourData !== undefined && hourDataLoaded){
            if (Chart.getChart("perhour") !== undefined){
                const curChart = Chart.getChart("perhour");
                curChart.data.labels = hourData?.labels ? hourData.labels : [];
                curChart.data.datasets[0].data = hourData?.events ? hourData.events : [];
                curChart.data.datasets[1].data = hourData?.responses ? hourData.responses : [];
                curChart.options.plugins.annotation.annotations.line1.value = hourData?.avgValue ? hourData.avgValue : 0
                curChart.options.plugins.annotation.annotations.line2.value = hourData?.avgResp ? hourData.avgResp : 0;
                curChart.resize();
                curChart.update();
            }
            else{
                new Chart('perhour', {
                    options: {
                        responsive: true,
                        plugins: {
                            annotation: {
                                drawTime: 'afterDatasetsDraw',
                                annotations: {
                                    line1: {
                                        type: 'line',
                                        borderDash: [8, 4],
                                        borderWidth: 4,
                                        scaleID: "a",
                                        borderColor: "#0081C778",
                                        value: hourData?.avgValue ? hourData.avgValue : 0,
                                        label: {
                                            display: false,
                                            backgroundColor: "#0081C7",
                                            borderColor: "#000000",
                                            borderWidth: 2,
                                            content: `Alarm Average: ${hourData?.avgValue ? hourData.avgValue : 0}`,
                                        },
                                        enter({element}, event) {
                                            element.label.options.display = true;
                                            return true;
                                        },
                                        leave({element}, event) {
                                            element.label.options.display = false;
                                            return true;
                                        }
                                    },
                                    line2: {
                                        type: 'line',
                                        borderDash: [8, 4],
                                        borderWidth: 4,
                                        scaleID: "b",
                                        borderColor: "#BE4D2578",
                                        value: hourData?.avgResp ? hourData.avgResp : 0,
                                        label: {
                                            display: false,
                                            backgroundColor: "#BE4D25",
                                            borderColor: "#000000",
                                            borderWidth: 2,
                                            content: `Response Time Average: ${hourData?.avgResp ? hourData.avgResp : 0}`,
                                        },
                                        enter({element}, event) {
                                            element.label.options.display = true;
                                            return true;
                                        },
                                        leave({element}, event) {
                                            element.label.options.display = false;
                                            return true;
                                        }
                                    }
                                }
                            },
                            legend: {
                                display: true
                            },
                        },
                        scales: {
                            x: {
                                title: {
                                    display: true,
                                    text: "Time of the Day"
                                },
                                grid: {
                                    display: false
                                },
                            },
                            a: {
                                id: "a",
                                position: "left",
                                grid: {
                                    display: false
                                },
                                title: {
                                    display: true,
                                    text: "Total Alarms"
                                },
                                suggestedMax: 10,
                                suggestedMin: 0,
                                ticks: {
                                    callback: function (value) {
                                        return value
                                    }
                                }
                            },
                            b: {
                                id: "b",
                                position: "right",
                                title: {
                                    display: true,
                                    text: "Average Response Time"
                                },
                                grid: {
                                    display: false
                                },
                                suggestedMax: 10,
                                suggestedMin: 0,
                                ticks: {
                                    callback: function (value) {
                                        return value
                                    }
                                }
                            }
                        }
                    },
                    data: {
                        labels: hourData?.labels?.length > 0 ? hourData.labels : ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12",
                                "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"],
                        datasets: [{
                            type: "bar",
                            label: 'Total Alarms',
                            data: hourData?.events ? hourData.events : [],
                            backgroundColor: ["#0081C7"],
                            hoverBackgroundColor: ["#0081C778"],
                            yAxisID: "a",
                            order: 2
                        },{
                            type: "line",
                            label: "Average Response Time",
                            data: hourData?.responses ? hourData.responses : [],
                            backgroundColor: ["#BE4D25"],
                            hoverBackgroundColor: ["#BE4D2578"],
                            borderColor: ["#BE4D2578"],
                            fill: false,
                            yAxisID: "b",
                            order: 1
                        }]
                    }
                });
            }
        }
    }, [hourData]);

    useEffect(() => {
        if (weekData !== undefined && weekDataLoaded){
            if (Chart.getChart("perweek") !== undefined){
                const curChart = Chart.getChart("perweek");
                curChart.data.labels = weekData?.labels ? weekData.labels : [];
                curChart.data.datasets[0].data = weekData?.events ? weekData.events : [];
                curChart.resize();
                curChart.update();
            }
            else{
                new Chart('perweek', {
                    options: {
                        responsive: true,
                        plugins: {
                            legend: {
                                display: true
                            },
                        },
                        scales: {
                            x: {
                                title: {
                                    display: true,
                                    text: "Weeks"
                                },
                                grid: {
                                    display: false
                                },
                            },
                            a: {
                                id: "a",
                                position: "left",
                                title: {
                                    display: true,
                                    text: "Total Alarms"
                                },
                                grid: {
                                    display: false
                                },
                                suggestedMax: 10,
                                suggestedMin: 0,
                                ticks: {
                                    callback: function (value) {
                                        return value
                                    }
                                }
                            }
                        }
                    },
                    data: {
                        labels: weekData?.labels?.length > 0 ? weekData.labels : [],
                        datasets: [{
                            type: "line",
                            label: "Total Alarms",
                            data: weekData?.events?.length > 0 ? weekData.events : [],
                            backgroundColor: ["#0081C7"],
                            hoverBackgroundColor: ["#0081C778"],
                            borderColor: ["#0081C778"],
                            fill: false,
                            yAxisID: "a",
                            order: 1
                        }]
                    }
                });
            }
        }
    }, [weekData]);

    function basicActionHandling(){
        document.getElementById("description-static").classList.toggle("d-none");
        document.getElementById("description-edit").classList.toggle("d-none");
        document.getElementById("serialnum-static").classList.toggle("d-none");
        document.getElementById("serialnum-edit").classList.toggle("d-none");
        document.getElementById("linked-static")?.classList?.toggle("d-none");
        document.getElementById("linked-edit")?.classList?.toggle("d-none");
        if (document.getElementById("edit-basic-btn").innerText === "Edit"){
            document.getElementById("edit-basic-btn").innerText = "Cancel";
            document.getElementById("save-basic-btn").classList.toggle("d-none");
            document.getElementById("serialnum-edit").value = basicData?.manufacture_uuid;
            document.getElementById("description-edit").value = basicData?.descriptions;
            if (basicData?.device_type === "location"){
                document.getElementById("linked-edit").value = basicData?.area_id;
            }else{
                document.getElementById("linked-edit").value = basicData?.resident_id;
            }
        }else{
            document.getElementById("edit-basic-btn").innerText = "Edit";
            document.getElementById("save-basic-btn").classList.toggle("d-none");
        }
        formCheck("basic-form", "save-basic-btn");
    }

    function basicSubmit(){
        let description, linkedId, payload, linkedEntity, serialnum;
        if (editRights){
            handleSubmitActions("save-basic-btn", "load-basic-btn");
            description = document.getElementById("description-edit").value;
            linkedEntity = document.getElementById("linked-edit");
            linkedId = linkedEntity?.value ? parseInt(linkedEntity.value) : 0;
            serialnum = document.getElementById("serialnum-edit").value;
            payload = [{device_id: parseInt(deviceId), descriptions: description, is_active: linkedId !== 0,
                        area_id: basicData?.is_area_device === 1 ? parseInt(linkedId) : 0,
                        resident_id: basicData?.is_resident ? parseInt(linkedId) : 0, manufacture_uuid: serialnum.toString()}];
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_device", PKey: "device_id", ClientId: dData.sessionId,
                                Condition: "primary", urlType: "POST", User: dData.user, Payload: payload, CampusId: campusId}).then(data => {
                if (data && data[0]){
                    setCount(0);
                    formSuccess("Basic information saved.", "save-basic-btn", "load-basic-btn", "edit-basic-btn")
                }
                else{
                    formFail(`Error during save, error code: ${data && data?.length > 1 ? data[1] : ""}`,
                        "save-basic-btn", "load-basic-btn");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function performLogging(){
        let msg, payload, logTime;
        handleSubmitActions("log-btn", "load-log-btn");
        logTime = document.getElementById("logtimer").value;
        payload = [{manufacture_uuid: basicData?.manufacture_uuid, duration: parseInt(logTime)}];
        ApiRequester({reqEndpoint: "mqtt", Operation: "deviceLogging", CampusId: campusId,
                            ClientId: dData.sessionId, Payload: payload}).then(data => {
            if(data[0]){
                msg = "Logging has begun, refresh screen or come back later to see newest logs.";
                formSuccess(msg, "log-btn", "load-log-btn");
            }
            else{
                msg = "Error occured starting the logging process with local server. Check server and contact customer support if issue does not resolve.";
                formFail(msg, "log-btn", "load-log-btn");
            }
        });
    }

    const eventTableHeaders = <TableHeaders checkbox={false} headerData={eventHeaderData} checkAll={() => {}}
                                            writeAccess={false} sortOrder={""}
                                            checkboxHeaderId={"uCheckHead"} sortColumn={""} sorter={{}}
                                            setSorter={() => {}} modal={false} checkboxlist={[]}
                                            actionAppearance={() => {}}/>

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
        </div>
    );

    return (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Device Profile"} Title={basicData?.manufacture_uuid ? `${basicData?.device_name} (${basicData.manufacture_uuid})` : "Device Missing"}
                        content={headerContent}/>
                <div className={"container-fluid"}>
                    <div className={"col"}>
                        <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                            <li key={"set-basic"} className={"nav-item"}>
                                <Button text={"Basic Info"} class={tabItem === "basic" ? "nav-link active" : "nav-link"}
                                        onClick={() => setTabItem("basic")}/>
                            </li>
                            <li key={"set-reporting"} className={"nav-item"}>
                                <Button text={"Reporting"}
                                        class={tabItem === "reporting" ? "nav-link active" : "nav-link"}
                                        onClick={() => setTabItem("reporting")}/>
                            </li>
                            <li key={"set-wellness"} className={"nav-item"}>
                                <Button text={"Wellness"}
                                        class={tabItem === "wellness" ? "nav-link active" : "nav-link"}
                                        onClick={() => setTabItem("wellness")}/>
                            </li>
                            <li key={"set-debug"} className={"nav-item"}>
                                <Button text={"Troubleshoot"}
                                        class={tabItem === "debug" ? "nav-link active" : "nav-link"}
                                        onClick={() => setTabItem("debug")}/>
                            </li>
                        </ul>
                    </div>
                    <div className={tabItem === "basic" ? "d-block" : "d-none"}>
                        <div className={"card"} style={{minWidth: "550px"}}>
                            <div className={"card-header d-flex justify-content-between"}>
                                <div>
                                    <h3 className={"card-header-title d-inline-block"}>Basic Information</h3>
                                </div>
                                <div>
                                    <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"basic-card-btn"}
                                       onClick={() => toggleCollapse("basic-card-btn", "basic-card")}></i>
                                </div>
                            </div>
                            <div className={"card-body collapse collapse-content show"} id={"basic-card"}>
                                <form className={"d-inline align-top form-control-plaintext was-validated"}
                                      id={"basic-form"}>
                                    <div className={"mx-5 mt-2 align-top d-inline-block"} id={"basic-col1-div"}>
                                        <div className={"d-flex"}>
                                            <p style={{minWidth: "125px"}}>Device Name:</p>
                                            <p className={"ms-4"}
                                               id={"name-static"}>{basicData?.device_name ? basicData.device_name : ""}</p>
                                        </div>
                                        <div className={"d-flex"}>
                                            <p style={{minWidth: "125px"}}>Description:</p>
                                            <p className={"ms-4"}
                                               id={"description-static"}>{basicData?.descriptions ? basicData.descriptions : ""}</p>
                                            <input className={"ms-4 d-none form-control form-control-sm"}
                                                   style={{height: "30px", width: "256px"}} id={"description-edit"}
                                                   defaultValue={basicData?.descriptions ? basicData.descriptions : ""}
                                                   pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                        </div>
                                        <div className={"d-flex"}>
                                            <p style={{minWidth: "125px"}}>Category:</p>
                                            <p className={"ms-4"}
                                               id={"category-static"}>{basicData?.device_category ? basicData.device_category : ""}</p>
                                        </div>
                                        <div className={"d-flex"}>
                                            <p style={{minWidth: "125px"}}>Serial Number:</p>
                                            <p className={"ms-4"}
                                               id={"serialnum-static"}>{basicData?.manufacture_uuid ? basicData.manufacture_uuid : ""}</p>
                                            <input className={"ms-4 d-none form-control form-control-sm"}
                                                   required={true}
                                                   style={{height: "30px", width: "256px"}} id={"serialnum-edit"}
                                                   defaultValue={basicData?.manufacture_uuid ? basicData.manufacture_uuid : ""}
                                                   pattern={"^[0-9]+$"}/>
                                        </div>
                                        <div className={"d-flex"}>
                                            <p style={{minWidth: "125px"}}>Model Number:</p>
                                            <p className={"ms-4"}
                                               id={"model-name-static"}>{basicData?.inovonics_model_name ? basicData.inovonics_model_name : ""}</p>
                                        </div>
                                        <div className={"d-flex"}>
                                            <p style={{minWidth: "125px"}}>Attached To:</p>
                                            {basicData?.device_type
                                                ? basicData.device_type === "location"
                                                    ? <>
                                                        <p className={"ms-4"} id={"linked-static"}><Link
                                                            to={`/profiles/area/${basicData?.area_id}`}>{basicData?.area_name ? basicData.area_name : ""}</Link>
                                                        </p>
                                                        <select className={"ms-4 d-none form-select form-select-sm"}
                                                                onChange={() => formCheck("basic-form", "save-basic-btn")}
                                                                style={{height: "30px", width: "256px"}}
                                                                id={"linked-edit"}
                                                                defaultValue={basicData?.area_id ? basicData.area_id : 0}>
                                                            <option value={0}>Unassigned</option>
                                                            {areaData.map((aItem, i) => {
                                                                return <option key={`linked-item-${i}`}
                                                                               value={aItem?.area_id}>{aItem?.area_name}</option>
                                                            })}
                                                        </select>
                                                    </>
                                                    : <>
                                                        <p className={"ms-4"} id={"linked-static"}><Link
                                                            to={`/profiles/resident/${basicData?.resident_id}`}>{basicData?.resident_name ? basicData.resident_name : ""}</Link>
                                                        </p>
                                                        <select className={"ms-4 d-none form-select form-select-sm"}
                                                                onChange={() => formCheck("basic-form", "save-basic-btn")}
                                                                style={{height: "30px", width: "256px"}}
                                                                id={"linked-edit"}
                                                                defaultValue={basicData?.resident_id ? basicData.resident_id : 0}>
                                                            <option value={0}>Unassigned</option>
                                                            {residentData.map((rItem, i) => {
                                                                return <option key={`linked-item-${i}`}
                                                                               value={rItem?.resident_id}>{rItem?.resident_first_name} {rItem?.resident_last_name}</option>
                                                            })}
                                                        </select>
                                                    </>
                                                : <></>
                                            }
                                        </div>
                                    </div>
                                    <div className={"mx-5 mt-2 d-inline-block align-top"} id={"basic-col2-div"}
                                         style={{minWidth: "300px"}}>
                                        <div className={"d-flex"}>
                                            <p style={{minWidth: "100px"}}>Last Repeater:</p>
                                            <p className={"ms-4"} id={"last-repeater-static"}>
                                                {basicData?.last_repeater ? basicData.last_repeater : "No Record"}
                                            </p>
                                        </div>
                                        <div className={"d-flex"}>
                                            <p style={{minWidth: "100px"}}>Last Alarmed:</p>
                                            <p className={"ms-4"} id={"last-alarm-static"}>
                                                {basicData?.last_alarm ? convertIsoToLocal(basicData.last_alarm) : "No Record"}
                                            </p>
                                        </div>
                                        <div className={"d-flex"}>
                                            <p style={{minWidth: "100px"}}>Last Active:</p>
                                            <p className={"ms-4"} id={"last-active-static"}>
                                                {basicData?.last_active ? convertIsoToLocal(basicData.last_active) : "No Record"}
                                            </p>
                                        </div>
                                    </div>
                                </form>
                                {pageLoaded && editRights &&
                                    <>
                                        <Button text={"Edit"} class={"btn btn-primary float-end"} id={"edit-basic-btn"}
                                                disabled={!editRights} onClick={() => {
                                                if (editRights) {
                                                    basicActionHandling()
                                                }
                                            }}/>
                                        <Button text={"Save"} class={"btn btn-primary float-end me-3 d-none"}
                                                id={"save-basic-btn"}
                                                disabled={!editRights} onClick={() => {
                                            if (editRights) {
                                                basicSubmit()
                                            }
                                        }}/>
                                        <Button type={"button"} class={"btn btn-primary float-end mx-3 d-none"} disabled={true}
                                                spinner={true} text={""} id={"load-basic-btn"}/>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={tabItem === "reporting" ? "d-block" : "d-none"}>
                        <div className={"card"} style={{minWidth: "500px"}}>
                            <div className={"card-header d-flex justify-content-between"}>
                                <div>
                                    <h3 className={"card-header-title d-inline-block"} style={{minWidth: "80px"}}>
                                        Reporting
                                    </h3>
                                    <div className={"d-none"} style={{display: "inline-block"}}
                                         id={"reporting-header-data"}>
                                        <h3 className={"ms-6 my-0 d-inline-block text-success"}>{!isNaN(hourData?.avgValue) ? hourData.avgValue : "?"} Alert/Week</h3>
                                        <h3 className={"mx-4 my-0 d-inline-block text-success"}>{!isNaN(weekData?.avgValue) ? weekData.avgValue : "?"} Avg/Resp</h3>
                                    </div>
                                </div>
                                <div>
                                    <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"reporting-card-btn"}
                                       onClick={() => {
                                           toggleCollapse("reporting-card-btn", "reporting-card")
                                           document.getElementById("reporting-header-data").classList.toggle("d-none");
                                       }}></i>
                                </div>
                            </div>
                            <div className={"card-body collapse collapse-content show"} id={"reporting-card"}>
                                <div className={"row"}>
                                    <Link to={"/reports/alerts/run"} onClick={() => {
                                        setDeviceFilters(dData, deviceId, basicData?.device_name);
                                    }}>
                                        <Button text={"Run Detailed Report"} class={"btn btn-primary mb-4 float-end"}
                                                icon={true} iconClass={"fe fe-arrow-right-circle float-end ms-3"}/>
                                    </Link>
                                </div>
                                <div className={"row"}>
                                    <div className={"chart-container w-50 text-end align-top d-inline-block"}>
                                        {hourDataLoaded
                                            ?
                                            (<div className={"chart-container"}>
                                                <canvas className={"chart-canvas p-1"} style={{maxHeight: "425px"}}
                                                        id={"perhour"}></canvas>
                                            </div>)
                                            :
                                            (<div className={"text-center"}>
                                                <Button text={""} class={"border-0 bg-transparent btn"}
                                                        spinner={true}></Button>
                                            </div>)
                                        }
                                    </div>
                                    <div className={"chart-container w-50 text-center align-top d-inline-block"}>
                                        {weekDataLoaded
                                            ?
                                            (<div className={"chart-container"}>
                                                <canvas className={"chart-canvas p-1"} style={{maxHeight: "425px"}}
                                                        id={"perweek"}></canvas>
                                            </div>)
                                            :
                                            (<div className={"text-center"}>
                                                <Button text={""} class={"border-0 bg-transparent btn"}
                                                        spinner={true}></Button>
                                            </div>)
                                        }
                                    </div>
                                </div>
                                <div className={"row mt-5"}>
                                    <div className={"w-50 px-4"}>
                                        <table className={"table table-sm table-white table-hover"}>
                                            <thead>
                                            <tr>
                                                <th className={"table table-header w-auto py-1"} colSpan={"4"}>
                                                    Event Reason Breakdown
                                                </th>
                                            </tr>
                                            <tr>
                                                <th className={"table table-header w-auto py-1"}>
                                                    Reason
                                                </th>
                                                <th className={"table table-header w-auto py-1"}>
                                                    Total
                                                </th>
                                                <th className={"table table-header w-auto py-1"}>
                                                    % Total Volume
                                                </th>
                                                <th className={"table table-header w-auto py-1"}>
                                                    Average Response (min)
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td className={"ps-1 fw-bold"}>Total Events</td>
                                                <td className={"ps-1 fw-bold text-center"}>{reasonData?.count ? reasonData.count : 0}</td>
                                                <td className={"ps-1 text-right"}></td>
                                                <td className={"ps-1 fw-bold text-center"}>{reasonData?.avg_response_time ? reasonData.avg_response_time : 0.0}</td>
                                            </tr>
                                            {reasonRows}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={"w-50"} style={{maxHeight: "400px", overflowY: "auto"}}>
                                        <table className={"table table-sm table-white table-hover d-sm-table"}>
                                            <thead className={"sticky-table-head"} style={{top: 0}}>
                                            {eventTableHeaders}
                                            </thead>
                                            <tbody>
                                            {eventTableRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={tabItem === "wellness" ? "d-block" : "d-none"}>
                        <div className={"card"} style={{minWidth: "500px"}}>
                            <div className={"card-header d-flex justify-content-between"}>
                                <div>
                                    <h3 className={"card-header-title d-inline-block"}
                                        style={{minWidth: "80px"}}>Wellness</h3>
                                </div>
                                <div>
                                    <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"wellness-card-btn"}
                                       onClick={() => toggleCollapse("wellness-card-btn", "wellness-card")}></i>
                                </div>
                            </div>
                            <div className={"card-body collapse collapse-content show"} id={"wellness-card"}>
                                <div className={"ms-4"}>
                                    <div className={"d-block"}>
                                        <div className={"d-inline-block align-top me-4"}
                                             style={{minWidth: "125px"}}>Careflow Plan:
                                        </div>
                                        <div
                                            className={"d-inline-block"}>{careflow && careflow?.length > 0 ? careflow : "No Careflow"}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={tabItem === "debug" ? "d-block" : "d-none"}>
                        <div className={"card"} style={{minWidth: "500px"}}>
                            <div className={"card-header d-flex justify-content-between"}>
                                <div>
                                    <h3 className={"card-header-title d-inline-block"}
                                        style={{minWidth: "80px", width: "175px"}}>Troubleshoot Device</h3>
                                    <div className={"d-none"} style={{display: "inline-block"}}
                                         id={"debug-header-data"}>
                                        <h3 className={"ms-6 my-0 d-inline-block text-success"}>{debugData?.length ? debugData.length : 0} Logs</h3>
                                    </div>
                                </div>
                                <div>
                                    <i className={"fe fe-plus as-btn collapse-toggle p-2"} id={"debug-card-btn"}
                                       onClick={() => {
                                           toggleCollapse("debug-card-btn", "debug-card");
                                           document.getElementById("debug-header-data").classList.toggle("d-none");
                                       }}></i>
                                </div>
                            </div>
                            <div className={"card-body collapse collapse-content show"} id={"debug-card"}>
                                <div>
                                    <form id={"debugForm"} className={"was-validated"}>
                                        <h4>Local server device logging.</h4>
                                        <div className={"flex form-floating mb-3"} style={{maxWidth: "350px"}}>
                                            <select className={"form-select"} id={"logtimer"} value={undefined}
                                                    required={true}>
                                                <option value={"600"}>10 Minutes</option>
                                                <option value={"1800"}>30 Minutes</option>
                                                <option value={"3600"}>1 Hour</option>
                                                <option value={"10800"}>3 Hours</option>
                                                <option value={"21600"}>6 Hours</option>
                                                <option value={"86400"}>24 Hours</option>
                                            </select>
                                            <label htmlFor={"logtimer"}>Log Time Length</label>
                                        </div>
                                        <Button text={"Start Logging"} class={"btn btn-primary mt-2"} id={"log-btn"}
                                                title={"Starts logging device debug data for troubleshooting, will clear and restart log if logging already running."}
                                                onClick={() => {
                                                    performLogging();
                                                }}/>
                                        <Button type={"button"} class={"btn btn-primary mt-2 d-none"}
                                                disabled={true} spinner={true} text={"Starting. . ."}
                                                id={"load-log-btn"}/>
                                    </form>
                                </div>
                                <table className={"table table-sm mt-4"}>
                                    <thead>
                                    <tr>
                                        <th className={"text-center"}>Timestamp</th>
                                        <th className={"text-center"}>Raw Bytes</th>
                                        <th className={"text-center"}>Alarm 0</th>
                                        <th className={"text-center"}>Alarm 1</th>
                                        <th className={"text-center"}>No Change</th>
                                        <th className={"text-center"}>Reset</th>
                                        <th className={"text-center"}>Stat 1</th>
                                        <th className={"text-center"}>Stat 2</th>
                                        <th className={"text-center"}>Supervision</th>
                                        <th className={"text-center"}>Hop 1st / Count</th>
                                        <th className={"text-center"}>Signal</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {debugData?.length > 0
                                        ? debugData?.map((dItem, index) => {
                                            return <tr key={`debug-row-${index}`}>
                                                <td className={"text-center"}>{getLocalTimeFromEpochMillis(dItem.EventTime, dData?.campusData[campusId.toString()]?.tz_offset)}</td>
                                                <td className={"text-center"}>
                                                    <MaterialIcon className={"material-icons as-btn-2x"} icon={"raw_on"}
                                                                  title={dItem?.ByteString}
                                                                  onClick={() => {
                                                                      toast.info(dItem?.ByteString);
                                                                      navigator?.clipboard?.writeText(dItem?.ByteString);
                                                                  }}/>
                                                </td>
                                                <td className={"text-center"}>{dItem?.Parsed?.alarm0 != null ? dItem.Parsed.alarm0.toString() : "N/A"}</td>
                                                <td className={"text-center"}>{dItem?.Parsed?.alarm1 != null ? dItem.Parsed.alarm1.toString() : "N/A"}</td>
                                                <td className={"text-center"}>{dItem?.Parsed?.noChange != null ? dItem.Parsed.noChange.toString() : "N/A"}</td>
                                                <td className={"text-center"}>{dItem?.Parsed?.reset != null ? dItem.Parsed.reset.toString() : "N/A"}</td>
                                                <td className={"text-center"}>{dItem?.Parsed?.stat1 != null ? dItem.Parsed.stat1.toString() : "N/A"}</td>
                                                <td className={"text-center"}>{dItem?.Parsed?.stat2 != null ? dItem.Parsed.stat2.toString() : "N/A"}</td>
                                                <td className={"text-center"}>{dItem?.Parsed?.supervision != null ? dItem.Parsed.supervision.toString() : "N/A"}</td>
                                                <td className={"text-center"}>
                                                    {dItem?.Parsed?.uuidFirstHop != null ? dItem.Parsed.uuidFirstHop : "N/A"}
                                                    <br/>
                                                    {dItem?.Parsed?.hopCount != null ? dItem.Parsed.hopCount : "N/A"}
                                                </td>
                                                <td className={"text-center"}>{dItem?.Parsed?.signal != null ? dItem.Parsed.signal : "N/A"}</td>
                                            </tr>
                                        })
                                        : <tr>
                                            <td colSpan={11}>No logs to display</td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true}
                                autoClose={10000}/>
            </Suspense>
        </>
    );
};

export default InovonicsDevice;