import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import Button from "../component-assets/Button";
import {toast, ToastContainer} from "react-toastify";
import {
    getYear, formatTimestampIso, toggleCollapse, sortFunc, showModal, formClear, checkAll,
    handleSubmitActions, swapModal, convertSectoHHMM, convertHHMMtoSec, formSuccess, formFail, formCheck
} from "../../libs";
import ApiRequester from "../ApiRequester";
import {v4 as uuidv4} from "uuid";
import TableHeaders from "../component-assets/TableHeaders";
import TableBody from "../component-assets/TableBody";

const EscalationSettings = (props) => {

    let dData, headerData, headerDataAdd, headerDataWiz, headerData2, headerData3, headerData4;

    if (ReactSession.get("PAL") != null) {
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    headerData = [
        {label: "Name", key: "device_name", align: "left",
            link: {enabled: true, type: " ", linkto: "/profiles/inovonics", linkkey: "device_id",
                    inmodal: true, modalKey: "cancelBtn-device"}},
        {label: "Category", key: "device_category", align: "left"},
        {label: "Description", key: "descriptions", align: "left"},
        {label: "TX ID", key: "manufacture_uuid"},
        {label: "Connected To", key: "entity_name", align: "left",
            link: {enabled: true, type: "conditional", inmodal: true, modalKey: "cancelBtn-device", conditionKey: "is_area_device",
                condition: {"1": {linkto: "/profiles/area", linkkey: "area_id"},
                            "0": {linkto: "/profiles/resident", linkkey: "resident_id"}}}}
    ];
    headerDataAdd = [
        {label: "Name", key: "device_name", align: "left",
            link: {enabled: true, type: " ", linkto: "/profiles/inovonics", linkkey: "device_id",
                    inmodal: true, modalKey: "cancelAddBtn"}},
        {label: "Category", key: "device_category", align: "left"},
        {label: "Description", key: "descriptions", align: "left"},
        {label: "TX ID", key: "manufacture_uuid"},
        {label: "Connected To", key: "entity_name", align: "left",
            link: {enabled: true, type: "conditional", inmodal: true, modalKey: "cancelAddBtn", conditionKey: "is_area_device",
                condition: {"1": {linkto: "/profiles/area", linkkey: "area_id"},
                            "0": {linkto: "/profiles/resident", linkkey: "resident_id"}}}}
    ];
    headerDataWiz = [
        {label: "Name", key: "device_name", align: "left",
            link: {enabled: true, type: " ", linkto: "/profiles/inovonics", linkkey: "device_id",
                    inmodal: true, modalKey: "wiz4-2CancelBtn"}},
        {label: "Category", key: "device_category", align: "left"},
        {label: "Description", key: "descriptions", align: "left"},
        {label: "TX ID", key: "manufacture_uuid"},
        {label: "Connected To", key: "entity_name", align: "left",
            link: {enabled: true, type: "conditional", inmodal: true, modalKey: "wiz4-2CancelBtn", conditionKey: "is_area_device",
                condition: {"1": {linkto: "/profiles/area", linkkey: "area_id"},
                            "0": {linkto: "/profiles/resident", linkkey: "resident_id"}}}}
    ];

    headerData2 = [
        {label: "Group Name", key: "resident_group_name"},
        {label: "Description", key: "resident_description"}
    ];

    headerData3 = [
        {label: "Group Name", key: "group_name"},
        {label: "CapCode", key: "capcode"}
    ];

    headerData4 = [
        {label: "Group Name", key: "area_group_name"},
        {label: "Description", key: "area_group_description"}
    ];

    // These are data variables that update on the fly when changes happen and thus need to not be state based as this
    // page does not regenerate from it's parent so the following need update everytime the page is called.
    const campusData = props?.campusData;
    const groupData = props?.groupData;
    const escData = props?.escData;
    const escTypeData = props?.escTypeData;
    const resGroupData = props?.resGroupData;
    const devData = props?.devData;
    const areaGroupData = props?.areaGroupData;

    // The following are sets of data used to make the page function properly. This is the default escalations, the
    // custom escalations and then global settings items.
    const [globalOutputs, setGlobalOutputs] = useState([]);
    const [defaultCnt, setDefaultCnt] = useState(0);

    // The following usestates are used to keep track of which rows from the sets of data that are being edited.
    const [editDefRecords, setEditDefRecords] = useState([]);
    const [editAlertRecords, setEditAlertRecords] = useState([]);
    const [editResRecords, setEditResRecords] = useState([]);
    const [editAreaRecords, setEditAreaRecords] = useState([]);
    const [editDevRecords, setEditDevRecords] = useState([]);

    // The following variables control the table display for the defaults and customs.
    const [deleteId, setDeleteId] = useState("");

    // Area Group Table for Pickers variables
    const [areaGroupIdList, setAreaGroupIdList] = useState([]);
    const [areaGroupDataCnt, setAreaGroupDataCnt] = useState(0);
    const [areaGroupTableRows, setAreaGroupTableRows] = useState([]);
    const [search4, setSearch4] = useState("");
    const [sorter4, setSorter4] = useState({
        sortColumn: "area_group_name",
        sortOrder: "asc"
    });

    const [groupIdList, setGroupIdList] = useState([]);
    const [groupDataCnt, setGroupDataCnt] = useState(0);
    const [groupTableRows, setGroupTableRows] = useState([]);
    const [escTypeOptions, setEscTypeOptions] = useState([]);
    const [wizEscTypeOptions, setWizEscTypeOptions] = useState([]);
    const [search3, setSearch3] = useState("");
    const [sorter3, setSorter3] = useState({
        sortColumn: "group_name",
        sortOrder: "asc"
    });

    // Resident Table for Pickers variables
    const [resGroupIdList, setResGroupIdList] = useState([]);
    const [resGroupDataCnt, setResGroupDataCnt] = useState(0);
    const [resTableRows, setResTableRows] = useState([]);
    const [search2, setSearch2] = useState("");
    const [sorter2, setSorter2] = useState({
        sortColumn: "resident_group_name",
        sortOrder: "asc"
    });

    // Device Table for Pickers variables
    const [devIdList, setDevIdList] = useState([]);
    const [devDataCnt, setDevDataCnt] = useState(0);
    const [devTableRows, setDevTableRows] = useState([]);
    const [devAddTableRows, setDevAddTableRows] = useState([]);
    const [devWizTableRows, setDevWizTableRows] = useState([]);
    const [search, setSearch] = useState("");
    const [sorter, setSorter] = useState({
        sortColumn: "description",
        sortOrder: "asc"
    });
    const [childId, setChildId] = useState("");
    const [careflowId, setCareflowId] = useState(0);
    const [rowIndex, setRowIndex] = useState("");
    const [campusId, setCampusId] = useState(dData.acnt);
    const [staticLabel, setStaticLabel] = useState("");

    // Add entities storage variables, this is straight lists of items, group id's or phone numbers used to create
    // the final payload for saving the add modal with its selected child entities.
    const [mobileEntities, setMobileEntities] = useState([]);
    const [emailEntities, setEmailEntities] = useState([]);
    const [textEntities, setTextEntities] = useState([]);
    const [pagerEntities, setPagerEntities] = useState([]);
    const [voiceEntities, setVoiceEntities] = useState([]);

    // The following items are used to handle the states of setting phone numbers for editing outputs in the tables.
    const [curNumbers, setCurNumbers] = useState([]); // Keeps track of current phone number list as array of strings.
    const [newNumbers, setNewNumbers] = useState([]); // Keeps track of new phone numbers added to the list as array strings.
    const [curEmails, setCurEmails] = useState([]);
    const [newEmails, setNewEmails] = useState([]);

    // The following items are used to handle the states of setting phone numbers for the add modal. The following
    // stores dictionary with id's and numbers and then uses the display items to display the jsx and listeners.
    const [addCurNumbers, setAddCurNumbers] = useState([]);
    const [addNewNumbers, setAddNewNumbers] = useState([]);
    const [addCurEmails, setAddCurEmails] = useState([]);
    const [addNewEmails, setAddNewEmails] = useState([]);

    // Wizard State Storage Variables
    const [wizDescription, setWizDescription] = useState("");
    const [wizOutput, setWizOutput] = useState("");
    const [wizCareflowType, setWizCareflowType] = useState("");
    const [wizEventType, setWizEventType] = useState(0);
    const [wizEntities, setWizEntities] = useState([]);
    const [wizStart, setWizStart] = useState("");
    const [wizEnd, setWizEnd] = useState("");
    const [wizMsgTime, setWizMsgTime] = useState(0);
    const [wizInterval, setWizInterval] = useState(0);
    const [newWizEmails, setNewWizEmails] = useState([]);
    const [curWizEmails, setCurWizEmails] = useState([]);
    const [newWizNumbers, setNewWizNumbers] = useState([]);
    const [curWizNumbers, setCurWizNumbers] = useState([]);

    useEffect(() => {
        let i, tmp = [], input;
        input = search4?.toString()?.toLowerCase();
        for (i = 0; i < areaGroupData.length; i++){
            if ((areaGroupData[i]?.area_group_name && areaGroupData[i].area_group_name?.toLowerCase()?.search(input) !== -1) ||
                (areaGroupData[i]?.area_group_description && areaGroupData[i].area_group_description?.toLowerCase()?.search(input) !== -1)){
                tmp.push(areaGroupData[i]);
            }
        }
        setAreaGroupDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, sorter4.sortColumn, sorter4.sortOrder));
        setAreaGroupTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                     actionAppearance={() => {}} tableHeaders={headerData4} checkboxId={"uCheckAreaGroup"}
                                     parentComponent={"Escalation"} dData={dData} checkboxlist={areaGroupIdList}
                                     modal={false} editModal={() => {}} unk={"x4-"}
                                     setCheckboxId={(value) => {setAreaGroupIdList(value)}} checkidkey={"area_group_id"} />);
    }, [areaGroupData, areaGroupIdList, sorter4, search4]);

    useEffect(() => {
        let i, tmp = [], input;
        input = search3?.toString()?.toLowerCase();
        for (i = 0; i < groupData.length; i++){
            if ((groupData[i]?.group_name && groupData[i].group_name?.toLowerCase()?.search(input) !== -1) ||
                (groupData[i]?.capcode && groupData[i].capcode?.toLowerCase()?.search(input) !== -1)){
                tmp.push(groupData[i]);
            }
        }
        setGroupDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, sorter3.sortColumn, sorter3.sortOrder));
        setGroupTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                     actionAppearance={() => {}} tableHeaders={headerData3} checkboxId={"uCheckGroup"}
                                     parentComponent={"Escalation"} dData={dData} checkboxlist={groupIdList}
                                     modal={false} editModal={() => {}} unk={"x3-"}
                                     setCheckboxId={(value) => {setGroupIdList(value)}} checkidkey={"group_id"} />);
    }, [groupData, groupIdList, sorter3, search3]);

    useEffect(() => {
        let i, tmp = [], input;
        input = search2?.toString()?.toLowerCase();
        for (i = 0; i < resGroupData?.length; i++){
            if ((resGroupData[i]?.resident_group_name && resGroupData[i].resident_group_name?.toLowerCase()?.search(input) !== -1) ||
                (resGroupData[i]?.resident_description && resGroupData[i].resident_description?.toLowerCase()?.search(input) !== -1)){
                tmp.push(resGroupData[i]);
            }
        }
        setResGroupDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, sorter2.sortColumn, sorter2.sortOrder));
        setResTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                   actionAppearance={() => {}} tableHeaders={headerData2} checkboxId={"uCheckResGroup"}
                                   parentComponent={"Escalation"} dData={dData} checkboxlist={resGroupIdList}
                                   modal={false} editModal={() => {}} unk={"x2-"}
                                   setCheckboxId={(value) => {setResGroupIdList(value)}} checkidkey={"resident_group_id"} />);
    }, [resGroupData, resGroupIdList, sorter2, search2]);

    useEffect(() => {
        let i, tmp = [], input;
        input = search?.toString()?.toLowerCase();
        for (i=0; i < devData?.length; i++){
            if ((devData[i]?.device_name && devData[i].device_name?.toLowerCase()?.search(input) !== -1) ||
                (devData[i]?.descriptions && devData[i].descriptions?.toLowerCase()?.search(input) !== -1) ||
                (devData[i]?.manufacture_uuid && devData[i].manufacture_uuid?.toLowerCase()?.search(input) !== -1) ||
                (devData[i]?.device_category && devData[i].device_category?.toLowerCase()?.search(input) !== -1) ||
                (devData[i]?.entity_name && devData[i].entity_name?.toLowerCase()?.search(input) !== -1)){
                tmp.push(devData[i]);
            }
        }
        setDevDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, sorter.sortColumn, sorter.sortOrder));
        setDevTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                   actionAppearance={() => {}} tableHeaders={headerData} checkboxId={"uCheckDev"}
                                   parentComponent={"Escalation"} dData={dData} checkboxlist={devIdList}
                                   modal={false} editModal={() => {}} unk={"x-"}
                                   setCheckboxId={(value) => {setDevIdList(value)}} checkidkey={"device_id"} />);
        setDevAddTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                      actionAppearance={() => {}} tableHeaders={headerDataAdd} checkboxId={"uCheckDev2"}
                                      parentComponent={"Escalation"} dData={dData} checkboxlist={devIdList}
                                      modal={false} editModal={() => {}} unk={"x-add-"}
                                      setCheckboxId={(value) => {setDevIdList(value)}} checkidkey={"device_id"} />);
        setDevWizTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                      actionAppearance={() => {}} tableHeaders={headerDataWiz} checkboxId={"uCheckDev3"}
                                      parentComponent={"Escalation"} dData={dData} checkboxlist={devIdList}
                                      modal={false} editModal={() => {}} unk={"x-wiz-"}
                                      setCheckboxId={(value) => {setDevIdList(value)}} checkidkey={"device_id"} />);
    }, [devData, devIdList, sorter, search]);

    useEffect(() => {
        let tmp = [], tmp2 = [];
        if (escTypeData?.length > 0){
            escTypeData.sort((a, b) => sortFunc(a, b, "event_category_name", "asc"));
            for (let i=0; i < escTypeData.length; i++){
                if (escTypeData[i]?.event_category_id !== 1){
                    tmp.push(<option key={`escalation-type-option${i}`} value={escTypeData[i]?.event_category_id}>{escTypeData[i]?.event_category_name}</option>);
                    tmp2.push(<option key={`escalation-type-option2${i}`} value={escTypeData[i]?.event_category_id}>{escTypeData[i]?.event_category_name}</option>);
                }
            }
            setEscTypeOptions(tmp);
            setWizEscTypeOptions(tmp2);
        }
    }, [escTypeData]);

    useEffect(() => {
        let tmp = [], tmp2 = [];
        if (escTypeData?.length > 0){
            escTypeData.sort((a, b) => sortFunc(a, b, "escalation_category_id", "asc"));
            for (let i=0; i < escTypeData.length; i++){
                if (escTypeData[i]?.event_category_id !== 1){
                    tmp.push(<option key={`escalation-type-option${i}`} value={escTypeData[i]?.event_category_id}>{escTypeData[i]?.event_category_name}</option>);
                    tmp2.push(<option key={`escalation-type-option2${i}`} value={escTypeData[i]?.event_category_id}>{escTypeData[i]?.event_category_name}</option>);
                }
            }
            setEscTypeOptions(tmp);
            setWizEscTypeOptions(tmp2);
        }
    }, [escTypeData]);

    useEffect(() => {
        let cnt = 0;
        if (escData?.length > 0){
            for (let i=0; i < escData.length; i++){
                if (escData[i]?.escalation_type_id === 1 && escData[i]?.event_category_id === 1){
                    cnt++
                }
            }
        }
        setDefaultCnt(cnt);
    }, [escData]);

    useEffect(() => {
        let tmp = [];
        if (campusData?.length > 0){
            if (campusData[0]?.app_active === 1){
                tmp.push("palmobile");
            }
            if (campusData[0]?.text_messaging_active){
                tmp.push("text");
            }
            if (campusData[0]?.voice_active){
                tmp.push("voice");
            }
            if (campusData[0]?.email_active){
                tmp.push("email");
            }
            if (campusData[0]?.pager_active){
                tmp.push("pager");
            }
            if (campusData[0]?.serial_active){
                tmp.push("serial");
            }
            if (campusData[0]?.offsite_active){
                tmp.push("offsite");
            }
            setGlobalOutputs(() => tmp);
        }
    }, [campusData]);

    useEffect(() => {
        formCheck("outputPhoneForm", "outputPhoneSubmit");
    }, [newNumbers, curNumbers]);

    useEffect(() => {
        formCheck("addPhoneForm", "addPhoneSubmit");
    }, [addNewNumbers, addCurNumbers]);

    useEffect(() => {
        formCheck("outputEmailForm", "outputEmailSubmit");
    }, [newEmails, curEmails]);

    useEffect(() => {
        formCheck("addEmailForm", "addEmailSubmit");
    }, [addNewEmails, addCurEmails]);

    useEffect(() => {
        if (editAlertRecords?.length > 0){
            document.getElementById("editSubmit-alert").classList.remove("d-none");
        }else{
            document.getElementById("editSubmit-alert").classList.add("d-none");
        }
    }, [editAlertRecords]);

    useEffect(() => {
        if (editDevRecords?.length > 0){
            document.getElementById("editSubmit-device").classList.remove("d-none");
        }else{
            document.getElementById("editSubmit-device").classList.add("d-none");
        }
    }, [editDevRecords]);

    useEffect(() => {
        if (editResRecords?.length > 0){
            document.getElementById("editSubmit-resident").classList.remove("d-none");
        }else{
            document.getElementById("editSubmit-resident").classList.add("d-none");
        }
    }, [editResRecords]);

    useEffect(() => {
        if (editAreaRecords?.length > 0){
            document.getElementById("editSubmit-area").classList.remove("d-none");
        }else{
            document.getElementById("editSubmit-area").classList.add("d-none");
        }
    }, [editAreaRecords]);

    useEffect(() => {
        if (editDefRecords?.length > 0){
            document.getElementById("editSubmit-default").classList.remove("d-none");
        }else{
            document.getElementById("editSubmit-default").classList.add("d-none");
        }
    }, [editDefRecords]);

    // _________________________________________________________
    // FOLLOWING FUNCTION ITEMS SERVE AS UTILITY ONLY
    // _________________________________________________________

    function toggleOutputs(output){
        if (globalOutputs.includes(output)){
            setGlobalOutputs((prev) => {
                return prev.filter((item) => item !== output);
            });
        }else{
            setGlobalOutputs((prev) => {
                return [...prev, output];
            });
        }
    }

    function recordRecordEdit(keyDiff, i){
        if (keyDiff === "alert"){
            if (!editAlertRecords.includes(i)){
                setEditAlertRecords((prev) => [...prev, i]);
            }
        }else if (keyDiff === "resident"){
            if (!editResRecords.includes(i)){
                setEditResRecords((prev) => [...prev, i]);
            }
        }else if (keyDiff === "device"){
            if (!editDevRecords.includes(i)){
                setEditDevRecords((prev) => [...prev, i]);
            }
        }else if (keyDiff === "area"){
            if (!editAreaRecords.includes(i)){
                setEditAreaRecords((prev) => [...prev, i]);
            }
        }else if (keyDiff === "default"){
            if (!editDefRecords.includes(i)){
                setEditDefRecords((prev) => [...prev, i]);
            }
        }
    }


    // _________________________________________________________
    // FOLLOWING FUNCTION ITEMS PAIRS WITH THE ROW ITSELF
    // _________________________________________________________

    function performSaveGlobals(){
        let palmobile, text, email, voice, payload, serial, pager, offsite;
        handleSubmitActions("editSubmit-globals", "loadEditSubmit-globals");
        palmobile = document.getElementById("mobile-on").checked;
        text = document.getElementById("text-on").checked;
        email = document.getElementById("email-on").checked;
        voice = document.getElementById("voice-on").checked;
        pager = document.getElementById("pager-on").checked;
        serial = document.getElementById("serial-on").checked;
        offsite = document.getElementById("offsite-on").checked;
        payload = [{
            app_active: palmobile ? 1 : 0,
            text_messaging_active: text ? 1 : 0,
            voice_active: voice ? 1 : 0,
            email_active: email ? 1 : 0,
            pager_active: pager ? 1 : 0,
            serial_active: serial ? 1 : 0,
            offsite_active: offsite ? 1 : 0,
            campus_id: campusId
        }];
        ApiRequester({reqEndpoint: "mytransaction", TransType: "careflow_global_edit", Payload: payload,
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
            if (data[0]) {
                formSuccess("Global outputs updated.", "editSubmit-globals", "loadEditSubmit-globals");
                document.getElementById("editSubmit-globals").classList.add("d-none");
                props?.setCount(0);
            } else {
                formFail("Update failed.", "editSubmit-globals", "loadEditSubmit-globals");
            }
        });
    }

    function performSave(alertType){
        let payload = [], iterable, endTime, endParsed, type;
        handleSubmitActions(`editSubmit-${alertType}`, `loadEditSubmit-${alertType}`);
        if (alertType === "alert") {
            iterable = editAlertRecords;
        }else if (alertType === "resident"){
            iterable = editResRecords;
        }else if (alertType === "device"){
            iterable = editDevRecords;
        }else if (alertType === "area"){
            iterable = editAreaRecords;
        }else if (alertType === "default"){
            iterable = editDefRecords;
        }
        for (let i=0; i < iterable?.length; i++){
            type = document.getElementById(`careflow-event-type-${alertType}${iterable[i]}`).value
            endTime = document.getElementById(`end-time-${alertType}${iterable[i]}`).value
            endParsed = endTime !== "23:59" ? parseInt(convertHHMMtoSec(endTime)) : 86400
            payload.push({
                escalation_entity_id: escData[parseInt(iterable[i])].escalation_entity_id,
                escalation_event_id: type && !isNaN(type) ? parseInt(type) : 1,
                careflow_id: escData[parseInt(iterable[i])].careflow_id,
                references: escData[parseInt(iterable[i])].references,
                enabled: document.getElementById(`on-checkbox-${alertType}${iterable[i]}`)?.checked ? 1 : 0,
                enable_start: parseInt(convertHHMMtoSec(document.getElementById(`start-time-${alertType}${iterable[i]}`).value)),
                enable_end: endParsed,
                careflow_description: document.getElementById(`careflow-description-${alertType}${iterable[i]}`).value,
                mobile_enabled: document.getElementById(`palmobile-on-${alertType}${iterable[i]}`)?.checked ? 1 : 0,
                text_enabled: document.getElementById(`text-on-${alertType}${iterable[i]}`)?.checked ? 1 : 0,
                voice_enabled: document.getElementById(`voice-on-${alertType}${iterable[i]}`)?.checked ? 1 : 0,
                email_enabled: document.getElementById(`email-on-${alertType}${iterable[i]}`)?.checked ? 1 : 0,
                pager_enabled: document.getElementById(`pager-on-${alertType}${iterable[i]}`)?.checked ? 1 : 0,
                reporting_enabled: document.getElementById(`reporting-${alertType}${iterable[i]}`)?.checked ? 1 : 0,
                offsite_enabled: document.getElementById(`offsite-on-${alertType}${iterable[i]}`)?.checked ? 1 : 0,
                mute_enabled: document.getElementById(`mute-on-${alertType}${iterable[i]}`)?.checked ? 1 : 0,
                escalation_type_id: escData[parseInt(iterable[i])].escalation_type_id,
                campus_id: campusId,
            });
        }
        ApiRequester({reqEndpoint: "mytransaction", TransType: "careflow_edit", Payload: payload,
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
            if (data && data[0]){
                formSuccess("Careflow profiles updated.", `editSubmit-${alertType}`, `loadEditSubmit-${alertType}`);
                if (alertType === "alert"){
                    setEditAlertRecords(() => []);
                }else if (alertType === "resident"){
                    setEditResRecords(() => []);
                }else if (alertType === "device"){
                    setEditDevRecords(() => []);
                }else if (alertType === "area"){
                    setEditAreaRecords(() => []);
                }else if (alertType === "default"){
                    setEditDefRecords(() => []);
                }
                props?.setCount(0);
            }
            else{
                formFail("Error encountered, save failed.", `editSubmit-${alertType}`, `loadEditSubmit-${alertType}`);
            }
        });
    }

    function performSaveEntities(alertType){
        let refIds = [], escTypeId = 0, payload;
        handleSubmitActions(`refSubmit-${alertType}`, `loadRefSubmit-${alertType}`);
        if (alertType === "resident"){
            escTypeId = 4;
            refIds = resGroupIdList;
        }else if (alertType === "device"){
            escTypeId = 3;
            refIds = devIdList;
        }else if (alertType === "area"){
            escTypeId = 5;
            refIds = areaGroupIdList;
        }
        payload = [{
            careflow_id: careflowId,
            references: refIds.join(","),
            escalation_type_id: escTypeId,
            campus_id: campusId,
            escalation_event_id: escData[rowIndex].event_category_id,
            escalation_entity_id: escData[rowIndex].escalation_entity_id,
            enabled: escData[rowIndex].enabled,
            enable_start: escData[rowIndex].enable_start,
            enable_end: escData[rowIndex].enable_end,
            careflow_description: escData[rowIndex].careflow_description,
            mobile_enabled: escData[rowIndex]?.outputs?.palmobile?.enabled ? escData[rowIndex].outputs.palmobile.enabled : 0,
            text_enabled: escData[rowIndex]?.outputs?.textmessages?.enabled ? escData[rowIndex].outputs.textmessages.enabled : 0,
            voice_enabled: escData[rowIndex]?.outputs?.voicemessages?.enabled ? escData[rowIndex].outputs.voicemessages.enabled : 0,
            email_enabled: escData[rowIndex]?.outputs?.email?.enabled ? escData[rowIndex].outputs.email.enabled : 0,
            pager_enabled: escData[rowIndex]?.outputs?.pager?.enabled ? escData[rowIndex].outputs.pager.enabled : 0,
            reporting_enabled: escData[rowIndex]?.outputs?.reporting?.enabled ? escData[rowIndex].outputs.reporting.enabled : 0,
            offsite_enabled: escData[rowIndex]?.outputs?.offsitemonitoring?.enabled ? escData[rowIndex].outputs.offsitemonitoring.enabled : 0,
            mute_enabled: escData[rowIndex]?.outputs?.mute?.enabled ? escData[rowIndex].outputs.mute.enabled : 0
        }];
        ApiRequester({reqEndpoint: "mytransaction", TransType: "careflow_edit", Payload: payload,
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
            if (data && data[0]){
                setResGroupIdList([]);
                setAreaGroupIdList([]);
                setDevIdList([]);
                formSuccess("Careflow references updated.", `refSubmit-${alertType}`,
                    `loadRefSubmit-${alertType}`, `cancelBtn-${alertType}`);
                props?.setCount(0);
            }
            else{
                formFail("Error encountered, save failed.", `refSubmit-${alertType}`,
                    `loadRefSubmit-${alertType}`);
            }
        });
    }

    function deleteSubmit(){
        let payload;
        handleSubmitActions("deleteConfirmSubmit", "loadDeleteConfirmSubmit");
        payload = [{careflow_id: deleteId}];
        ApiRequester({reqEndpoint: "mytransaction", TransType: "careflow_delete", Payload: payload,
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
            if (data && data[0]){
                formSuccess("Careflow has been removed.", "deleteConfirmSubmit", "loadDeleteConfirmSubmit", "deleteConfirmCancelBtn");
                props?.setCount(0);
            }
            else{
                formFail("Error encountered, remove failed.", "deleteConfirmSubmit", "loadDeleteConfirmSubmit");
            }
        });
    }

    function performOutputSave(){
        // Handles Mobile, Pager, and Serial Out
        let payload, messageTime, messageRepeat, repeatEnabled;
        handleSubmitActions("outputSubmit", "loadOutputSubmit");
        messageTime = document.getElementById("messageTime").value;
        messageRepeat = document.getElementById("repeatTime").value;
        repeatEnabled = document.getElementById("repeatEnabled").checked;
        payload = [{escalation_child_id: childId, reference: groupIdList, enabled: 1,
                    campus_id: campusId, event_time: parseInt(messageTime)*60,
                    send_next_message: parseInt(messageRepeat)*60, send_max: repeatEnabled}];
        ApiRequester({reqEndpoint: "mytransaction", TransType: "careflow_output_edit", Payload: payload,
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
            if (data && data[0]){
                formSuccess("Careflow output setting updated.", "outputSubmit", "loadOutputSubmit", "cancelOutputBtn");
                props?.setCount(0);
            }
            else{
                formFail("Error encountered, save failed.", "outputSubmit", "loadOutputSubmit");
            }
        });
    }

    function performOutputTimeSave(){
        // Handles offsite monitoring
        let payload, messageTime;
        handleSubmitActions("outputTimeSubmit", "loadOutputTimeSubmit");
        messageTime = document.getElementById("messageTime0").value;
        payload = [{escalation_child_id: childId, reference: [], enabled: 1,
                    campus_id: campusId, event_time: parseInt(messageTime)*60,
                    send_next_message: 0, send_max: false}];
        ApiRequester({reqEndpoint: "mytransaction", TransType: "careflow_output_edit", Payload: payload,
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
            if (data && data[0]){
                formSuccess("Careflow output setting updated.", "outputTimeSubmit", "loadOutputTimeSubmit", "cancelOutputTimeBtn");
                props?.setCount(0);
            }
            else{
                formFail("Error encountered, save failed.", "outputTimeSubmit", "loadOutputTimeSubmit");
            }
        });
    }

    function performOutputPhoneSave(){
        // Handles Text and Voice
        let messageTime, payload, numberList = [];
        handleSubmitActions("outputPhoneSubmit", "loadOutputPhoneSubmit");
        messageTime = document.getElementById("messageTime2").value;
        for (let i=0; i < curNumbers.length; i++){
            numberList.push(curNumbers[i].number.replaceAll("-", "").replaceAll(" ", ""));
        }
        for (let i2=0; i2 < newNumbers.length; i2++){
            numberList.push(newNumbers[i2].number.replaceAll("-", "").replaceAll(" ", ""));
        }
        payload = [{escalation_child_id: childId, reference: numberList, campus_id: campusId,
                    event_time: parseInt(messageTime)*60, send_next_message: 0, send_max: false}];
        ApiRequester({reqEndpoint: "mytransaction", TransType: "careflow_output_edit", Payload: payload,
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
            if (data && data[0]){
                formSuccess("Careflow output setting updated.", "outputPhoneSubmit", "loadOutputPhoneSubmit", "cancelOutputPhoneBtn");
                props?.setCount(0);
            }
            else{
                formFail("Error encountered, save failed.", "outputPhoneSubmit", "loadOutputPhoneSubmit");
            }
        });
    }

    function performOutputEmailSave(){
        // Handles emails
        let messageTime, payload, emailList = [];
        handleSubmitActions("outputEmailSubmit", "loadOutputEmailSubmit");
        messageTime = document.getElementById("messageTime3").value;
        for (let i=0; i < curEmails.length; i++){
            emailList.push(curEmails[i].email);
        }
        for (let i2=0; i2 < newEmails.length; i2++){
            emailList.push(newEmails[i2].email);
        }
        payload = [{escalation_child_id: childId, reference: emailList, campus_id: campusId,
                    event_time: parseInt(messageTime)*60, send_next_message: 0, send_max: false}];
        ApiRequester({reqEndpoint: "mytransaction", TransType: "careflow_output_edit", Payload: payload,
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
            if (data && data[0]){
                formSuccess("Careflow output setting updated.", "outputEmailSubmit", "loadOutputEmailSubmit", "cancelOutputEmailBtn");
                props?.setCount(0);
            }
            else{
                formFail("Error encountered, save failed.", "outputEmailSubmit", "loadOutputEmailSubmit");
            }
        });
    }

    function performAddSave(){
        // Pulls all entities from the add form field, builds the payload and calls the save backend endpoint for
        // adding the new escalation with children to the database.
        let payload, description, start, end, mobile, text, voice, email, reporting, type, addType, pager, escType,
            refIds = [], offsite, endVal, mute, serial;
        handleSubmitActions("addSubmit", "loadAddSubmit");
        addType = document.getElementById("add-escalation-type").value;
        description = document.getElementById("add-description").value;
        start = convertHHMMtoSec(document.getElementById("add-starttime").value);
        endVal = document.getElementById("add-endtime").value;
        end = endVal !== "23:59" ? convertHHMMtoSec(endVal) : 86400;
        mobile = document.getElementById("add-palmobile-on")?.checked;
        text = document.getElementById("add-text-on")?.checked;
        voice = document.getElementById("add-voice-on")?.checked;
        email = document.getElementById("add-email-on")?.checked;
        pager = document.getElementById("add-pager-on")?.checked;
        reporting = document.getElementById("add-report-on")?.checked;
        offsite = document.getElementById("add-offsite-on")?.checked;
        serial = document.getElementById("add-serial-on")?.checked;
        mute = document.getElementById("add-mute-on")?.checked;
        type = document.getElementById("add-event-type")?.value;
        if (addType === "alert"){
            escType = 1;
        }else if (addType === "resident"){
            escType = 4;
            type = 1;
            refIds = resGroupIdList;
        }else if (addType === "area"){
            escType = 5;
            type = 1;
            refIds = areaGroupIdList;
        }else if (addType === "default"){
            escType = 1;
            type = 1;
        }else{
            escType = 3;
            type = 1;
            refIds = devIdList;
        }
        payload = [{
            careflow_description: description,
            enable_start: start,
            enable_end: end,
            escalation_event_id: type && !isNaN(type) ? parseInt(type) : 1,
            escalation_type_id: escType,
            references: refIds.join(","),
            frequency_id: 1,
            mobile_enabled: mobile,
            mobile_time: mobile ? parseInt(document.getElementById("add-palmobile-time").value)*60 : 0,
            mobile_entities: mobile ? mobileEntities : [],
            mobile_repeat: mobile ? parseInt(document.getElementById("add-palmobile-repeat").value)*60 : 0,
            mobile_max_time: mobile && parseInt(document.getElementById("add-palmobile-repeat").value) !== 0,
            text_enabled: text,
            text_time: text ? parseInt(document.getElementById("add-text-time").value)*60 : 0,
            text_entities: text ? textEntities : [],
            voice_enabled: voice,
            voice_time: voice ? parseInt(document.getElementById("add-voice-time").value)*60 : 0,
            voice_entities: voice ? voiceEntities : [],
            email_enabled: email,
            email_time: email ? parseInt(document.getElementById("add-email-time").value)*60 : 0,
            email_entities: email ? emailEntities : [],
            pager_enabled: pager,
            pager_time: pager ? parseInt(document.getElementById("add-pager-time").value)*60 : 0,
            pager_entities: pager ? pagerEntities : [],
            pager_repeat: pager ? parseInt(document.getElementById("add-pager-repeat").value)*60 : 0,
            pager_max_time: pager && parseInt(document.getElementById("add-pager-repeat").value) !== 0,
            offsite_time: offsite ? parseInt(document.getElementById("add-offsite-time").value)*60 : 0,
            offsite_enabled: offsite,
            offsite_entities: [],
            serial_enabled: serial,
            serial_repeat: serial ? parseInt(document.getElementById("add-serial-repeat").value)*60 : 0,
            serial_max_time: serial && parseInt(document.getElementById("add-serial-repeat").value) !== 0,
            report_enabled: reporting,
            mute_enabled: mute,
            ignore_until: formatTimestampIso(getYear(0)[0]),
            campus_id: campusId
        }];
        ApiRequester({reqEndpoint: "mytransaction", TransType: "careflow_add", Payload: payload,
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
            if (data && data[0]){
                setResGroupIdList([]);
                setDevIdList([]);
                setAreaGroupIdList([]);
                props?.setCount(0);
                formSuccess("Careflow added successfully.", "addSubmit", "loadAddSubmit", "cancelAddBtn");
            }
            else{
                formFail("Error encountered, save failed.", "addSubmit", "loadAddSubmit");
            }
        });
    }

    function showRefModal(alertType, rowIndex){
        // let options;
        setSearch("");
        setSearch2("");
        setSearch3("");
        setSearch4("");
        if (alertType === "resident"){
            setResGroupIdList(escData[parseInt(rowIndex)]?.references?.split(","));
            showModal("resgroupmodal");
        }else if (alertType === "device"){
            setDevIdList(escData[parseInt(rowIndex)]?.references?.split(","));
            showModal("devgroupmodal");
        }else if (alertType === "area"){
            setAreaGroupIdList(escData[parseInt(rowIndex)]?.references?.split(","));
            showModal("areagroupmodal");
        }
    }


    // _________________________________________________________
    // FOLLOWING FUNCTION ITEMS PAIRS MODALS ON TABLE ITSELF
    // _________________________________________________________

    function showOutputModal(output, rowId){
        let cId;
        setSearch("");
        setSearch2("");
        setSearch3("");
        setSearch4("");
        try{
            setGroupIdList(escData[rowId]?.outputs[output]?.reference ? escData[rowId].outputs[output].reference.replaceAll(" ", "").split(",") : []);
            cId = escData[rowId]?.outputs[output]?.escalation_child_id ? escData[rowId]?.outputs[output]?.escalation_child_id : 0;
            let eventTime = escData[rowId]?.outputs[output]?.event_time ? escData[rowId].outputs[output].event_time : 0
            document.getElementById("messageTime").value = Math.round(eventTime/60);
            document.getElementById("messageTime").disabled = false;
            if (["palmobile", "pager"].includes(output)){
                document.getElementById("repeatContainer").classList.add("d-flex");
                document.getElementById("repeatContainer").classList.remove("d-none");
                document.getElementById("repeatTime").value = escData[rowId].outputs[output]?.next_event_time ? Math.round(escData[rowId].outputs[output].next_event_time/60) : 0
                document.getElementById("repeatEnabled").checked = escData[rowId].outputs[output]?.repeat_enabled ? escData[rowId].outputs[output].repeat_enabled : false
                document.getElementById("repeatTime").required = escData[rowId].outputs[output]?.repeat_enabled ? escData[rowId].outputs[output].repeat_enabled : false
                document.getElementById("repeatTime").readOnly = escData[rowId].outputs[output]?.repeat_enabled ? !escData[rowId].outputs[output].repeat_enabled : true
            }else{
                document.getElementById("repeatContainer").classList.remove("d-flex");
                document.getElementById("repeatContainer").classList.add("d-none");
                document.getElementById("repeatTime").value = 0;
                document.getElementById("repeatEnabled").checked = false;
                document.getElementById("repeatTime").required = false;
            }
        }catch (e){
            console.log(e)
            document.getElementById("messageTime").value = 0;
            setGroupIdList([]);
            cId = 0
        }
        setChildId(() => cId);
        setStaticLabel(output);
        formCheck("outputForm", "outputSubmit");
        showModal("outputmodal");
    }

    function showOutputTimeModal(output, rowId){
        let cId;
        setSearch("");
        setSearch2("");
        setSearch3("");
        setSearch4("");
        try{
            cId = escData[rowId]?.outputs[output]?.escalation_child_id;
            document.getElementById("messageTime0").value = Math.round(escData[rowId].outputs[output].event_time/60);
            document.getElementById("messageTime0").disabled = false;
        }catch (e){
            console.log(e)
            document.getElementById("messageTime0").value = 0;
            cId = 0
        }
        setChildId(() => cId);
        setStaticLabel(output);
        formCheck("outputTimeForm", "outputTimeSubmit");
        showModal("outputtimemodal");
    }

    function showOutputPhoneModal(output, rowId){
        let entityList, tmp = [], cId;
        setSearch("");
        setSearch2("");
        setSearch3("");
        setSearch4("");
        setNewNumbers(() => []);
        try{
            entityList = escData[rowId]?.outputs[output]?.reference ? escData[rowId].outputs[output].reference.split(",") : [];
            cId = escData[rowId]?.outputs[output]?.escalation_child_id;
            document.getElementById("messageTime2").value = Math.round(escData[rowId].outputs[output].event_time/60);
            document.getElementById("messageTime2").disabled = false;
        }catch (e){
            document.getElementById("messageTime2").value = 0;
            entityList = [];
            cId = 0;
        }
        for (let i=0; i < entityList.length; i++){
            tmp.push({id: uuidv4(), number: entityList[i]});
        }
        setCurNumbers(() => tmp);
        setChildId(() => cId);
        setStaticLabel(output);
        showModal("outputphonemodal");
    }

    function showOutputEmailModal(output, rowId){
        let entityList, tmp = [], cId;
        setSearch("");
        setSearch2("");
        setSearch3("");
        setSearch4("");
        setNewEmails(() => []);
        try{
            entityList = escData[rowId]?.outputs[output]?.reference ? escData[rowId].outputs[output].reference.split(",") : [];
            cId = escData[rowId]?.outputs[output]?.escalation_child_id;
            document.getElementById("messageTime3").value = Math.round(escData[rowId].outputs[output].event_time/60);
            document.getElementById("messageTime3").disabled = false;
        }catch (e){
            document.getElementById("messageTime3").value = 0;
            entityList = [];
            cId = 0;
        }
        for (let i=0; i < entityList.length; i++){
            tmp.push({id: uuidv4(), email: entityList[i]});
        }
        setCurEmails(() => tmp);
        setChildId(() => cId);
        showModal("outputemailmodal");
    }


    // _________________________________________________________
    // FOLLOWING FUNCTION ITEMS PAIRS WITH THE ADD MODAL
    // _________________________________________________________

    function showAddModalHandler(escType){
        let start, end, block;
        formClear("addForm", "addSubmit");
        if (escType === "alert"){
            document.getElementById("add-time-pick-div").classList.remove("d-none");
            document.getElementById("add-event-type").disabled = false;
            document.getElementById("add-event-type-div").classList.remove("d-none");
            document.getElementById("resDivAddOptions").classList.add("d-none");
            document.getElementById("devDivAddOptions").classList.add("d-none");
            document.getElementById("areaDivAddOptions").classList.add("d-none");
        }else{
            document.getElementById("add-event-type").disabled = true;
            document.getElementById("add-event-type-div").classList.add("d-none");
            if (escType === "device"){
                setDevIdList([])
                document.getElementById("add-time-pick-div").classList.remove("d-none");
                document.getElementById("uCheckHead").checked = false;
                document.getElementById("uCheckHead2").checked = false;
                document.getElementById("devDivAddOptions").classList.remove("d-none");
                document.getElementById("resDivAddOptions").classList.add("d-none");
                document.getElementById("areaDivAddOptions").classList.add("d-none");
            }else if (escType === "resident"){
                setResGroupIdList([]);
                document.getElementById("add-time-pick-div").classList.remove("d-none");
                document.getElementById("uCheckHead3").checked = false;
                document.getElementById("uCheckHead4").checked = false;
                document.getElementById("resDivAddOptions").classList.remove("d-none");
                document.getElementById("areaDivAddOptions").classList.add("d-none");
                document.getElementById("devDivAddOptions").classList.add("d-none");
            }else if (escType === "area"){
                setAreaGroupIdList([]);
                document.getElementById("add-time-pick-div").classList.remove("d-none");
                document.getElementById("uCheckHead7").checked = false;
                document.getElementById("uCheckHead8").checked = false;
                document.getElementById("areaDivAddOptions").classList.remove("d-none");
                document.getElementById("resDivAddOptions").classList.add("d-none");
                document.getElementById("devDivAddOptions").classList.add("d-none");
            }else if (escType === "default"){
                document.getElementById("resDivAddOptions").classList.add("d-none");
                document.getElementById("devDivAddOptions").classList.add("d-none");
                document.getElementById("areaDivAddOptions").classList.add("d-none");
                document.getElementById("add-time-pick-div").classList.add("d-none");
            }
        }
        start = document.getElementById("add-starttime");
        end = document.getElementById("add-endtime");
        block = document.getElementById("time-div");
        document.getElementById("add-palmobile-on").checked = globalOutputs.includes("palmobile");
        document.getElementById("add-text-on").checked = globalOutputs.includes("text");
        document.getElementById("add-email-on").checked = globalOutputs.includes("email");
        document.getElementById("add-voice-on").checked = globalOutputs.includes("voice");
        document.getElementById("add-pager-on").checked = globalOutputs.includes("pager");
        document.getElementById("add-offsite-on").checked = globalOutputs.includes("offsite");
        addOutputHandler("palmobile", globalOutputs.includes("palmobile"));
        addOutputHandler("text", globalOutputs.includes("text"));
        addOutputHandler("email", globalOutputs.includes("email"));
        addOutputHandler("voice", globalOutputs.includes("voice"));
        addOutputHandler("pager", globalOutputs.includes("pager"));
        addOutputHandler("offsite", globalOutputs.includes("offsite"));
        document.getElementById("add-mute-on").checked = false;
        start.value = "";
        end.value = "";
        start.readOnly = false;
        end.readOnly = false;
        block.classList.remove("d-none");
        setMobileEntities(() => []);
        setTextEntities(() => []);
        setPagerEntities(() => []);
        setEmailEntities(() => []);
        setVoiceEntities(() => []);
        if (escType === "default"){
            document.getElementById("toggle-startend").checked = false;
            document.getElementById("toggle-startend").click();
        }else{
            document.getElementById("toggle-startend").checked = true;
            document.getElementById("toggle-startend").click();
        }
        formCheck("addForm", "addSubmit");
        showModal("addmodal");
    }

    function addOutputHandler(output, checked){
        let setting = document.getElementById(`add-${output}-time`);
        let icon = document.getElementById(`add-${output}-icon`);
        let repeat = document.getElementById(`add-${output}-repeat`);
        if (checked){
            setting.value = 0;
            icon?.classList?.remove("d-none");
            if (repeat !== null){
                repeat.disabled = false;
                if (output === "palmobile"){
                    repeat.value = 5;
                }else{
                    repeat.value = 3;
                }
            }
        }else{
            icon?.classList?.add("d-none");
            if (repeat !== null){
                repeat.value = 0
                repeat.disabled = true
            }
        }
        setting.disabled = !checked;
    }

    function showGroupAddModal(output){
        // This function is called from the add modal when clicking on the palmobile or email gear. This triggers
        // the modal to populate to show a list of groups and checks the boxes for the group id matching the output
        // type passed into this function. Button onclick is set to call the appropriate function with output text.
        setSearch("");
        setSearch2("");
        setSearch3("");
        setSearch4("");
        document.getElementById("add-output-type").value = output;
        if (output === "palmobile"){
            setGroupIdList(mobileEntities);
        }else if (output === "pager"){
            setGroupIdList(pagerEntities);
        }
        swapModal("cancelAddBtn", "addgroupmodal");
    }

    function setAddGroupItems(){
        // This function is called from the add output type modals specifically for palmobile and email atm.
        // This extracts the checked boxes, builds a list and stores it into the appropriate entity type.
        let output;
        setSearch("");
        setSearch2("");
        setSearch3("");
        setSearch4("");
        handleSubmitActions("addGroupSubmit", "loadAddGroupSubmit");
        output = document.getElementById("add-output-type").value;
        if (output === "palmobile"){
            setMobileEntities(() => groupIdList);
        }
        else if (output === "pager"){
            setPagerEntities(() => groupIdList);
        }
        document.getElementById("cancelAddGroupBtn").click();
        handleSubmitActions("loadAddGroupSubmit", "addGroupSubmit");
    }

    function showPhoneAddModal(output){
        // This function is called from the add modal when clicking on text or voice calling to set a list of phone
        // numbers to be saved with record add. This function triggers the modal to populate and builds the entity
        // list of phone numbers to add to the system.
        let tmp = [];
        document.getElementById("add-output-type").value = output;
        if (output === "text"){
            for (let i=0; i < textEntities.length; i++){
                tmp.push(
                    {id: uuidv4(), number: textEntities[i]}
                );
            }
        } else if (output === "voice"){
            for (let i=0; i < voiceEntities.length; i++){
                tmp.push(
                    {id: uuidv4(), number: voiceEntities[i]}
                );
            }
        }
        setAddNewNumbers(() => []);
        setAddCurNumbers(() => tmp);
        swapModal("cancelAddBtn", "addphonemodal");
    }

    function setAddPhoneItems(){
        // This function is triggered from the phone number add modal save which takes the current phone numbers
        // display items and then new numbers display items and sets those values into their appropriate output
        // entity holder text or voice.
        let tmp = [], output;
        handleSubmitActions("addPhoneSubmit", "loadAddPhoneSubmit");
        output = document.getElementById("add-output-type").value;
        for (let i=0; i < addCurNumbers.length; i++){
            tmp.push(addCurNumbers[i].number.replaceAll("-", "").replaceAll(" ", ""));
        }
        for (let i2=0; i2 < addNewNumbers.length; i2++){
            tmp.push(addNewNumbers[i2].number.replaceAll("-", "").replaceAll(" ", ""));
        }
        if (output === "text"){
            setTextEntities(() => tmp);
        }else if (output === "voice"){
            setVoiceEntities(() => tmp);
        }
        document.getElementById("cancelAddPhoneBtn").click();
        handleSubmitActions("loadAddPhoneSubmit", "addPhoneSubmit");
    }

    function showEmailAddModal(output){
        // This function is called from the add modal when clicking on text or voice calling to set a list of phone
        // numbers to be saved with record add. This function triggers the modal to populate and builds the entity
        // list of phone numbers to add to the system.
        let tmp = [];
        for (let i=0; i < emailEntities.length; i++){
            tmp.push(
                {id: uuidv4(), email: emailEntities[i]}
            );
        }
        setAddNewEmails(() => []);
        setAddCurEmails(() => tmp);
        swapModal("cancelAddBtn", "addemailmodal");
    }

    function setAddEmailItems() {
        // This function is triggered from the phone number add modal save which takes the current phone numbers
        // display items and then new numbers display items and sets those values into their appropriate output
        // entity holder text or voice.
        let tmp = [];
        handleSubmitActions("addEmailSubmit", "loadAddEmailSubmit");
        for (let i = 0; i < addCurEmails.length; i++) {
            tmp.push(addCurEmails[i].email);
        }
        for (let i2 = 0; i2 < addNewEmails.length; i2++) {
            tmp.push(addNewEmails[i2].email);
        }
        setEmailEntities(() => tmp);
        document.getElementById("cancelAddEmailBtn").click();
        handleSubmitActions("loadAddEmailSubmit", "addEmailSubmit");
    }

    function dataLength(idType, check="escalation_type_id"){
        let counter = 0
        if (check === "escalation_type_id"){
            for (let i=0; i < escData.length; i++){
                if (escData[i]?.escalation_type_id === idType && idType !== 1){
                    counter++
                }else if (idType === 1 && escData[i]?.escalation_type_id === idType && escData[i]?.event_category_id !== 1){
                    counter++
                }
            }
        }else if (check === "event_category_id"){
            for (let i=0; i < escData.length; i++){
                if (escData[i]?.escalation_type_id === 1 && escData[i]?.event_category_id === idType){
                    counter++
                }
            }
        }
        return counter.toString();
    }

    // * * * * * * * * * * * * * * * * * * * *
    // ALL WIZARD BASED FUNCTIONALITY HERE * *
    // * * * * * * * * * * * * * * * * * * * *

    function performWizSubmit(){
        let payload, refIds;
        handleSubmitActions("wiz8Submit", "loadWiz8Submit");
        if (wizCareflowType.toString() === "3"){
            refIds = devIdList;
        }else if (wizCareflowType.toString() === "4"){
            refIds = resGroupIdList;
        }else if (wizCareflowType.toString() === "5"){
            refIds = areaGroupIdList;
        }else{
            refIds = [];
        }
        payload = [{
            careflow_description: wizDescription,
            enable_start: convertHHMMtoSec(wizStart),
            enable_end: convertHHMMtoSec(wizEnd),
            escalation_event_id: parseInt(wizEventType),
            escalation_type_id: parseInt(wizCareflowType),
            references: refIds.join(","),
            frequency_id: 1,
            mobile_enabled: wizOutput === "mobile",
            mobile_time: wizOutput === "mobile" ? wizMsgTime*60 : 0,
            mobile_entities: wizOutput === "mobile" ? mobileEntities : [],
            text_enabled: wizOutput === "text",
            text_time: wizOutput === "text" ? wizMsgTime*60 : 0,
            text_entities: wizOutput === "text" ? textEntities : [],
            voice_enabled: wizOutput === "voice",
            voice_time: wizOutput === "voice" ? wizMsgTime*60 : 0,
            voice_entities: wizOutput === "voice" ? voiceEntities : [],
            email_enabled: wizOutput === "email",
            email_time: wizOutput === "email" ? wizMsgTime*60 : 0,
            email_entities: wizOutput === "email" ? emailEntities : [],
            pager_enabled: wizOutput === "pager",
            pager_time: wizOutput === "pager" ? wizMsgTime*60 : 0,
            pager_entities: wizOutput === "pager" ? pagerEntities : [],
            pager_interval: wizOutput === "pager" ? wizInterval*60 : 0,
            offsite_enabled: wizOutput === "offsite",
            offsite_time: wizOutput === "offsite" ? wizMsgTime*60 : 0,
            offsite_entities: [],
            report_enabled: true,
            mute_enabled: wizOutput === "mute",
            ignore_until: formatTimestampIso(getYear(0)[0]),
            campus_id: campusId
        }];
        ApiRequester({reqEndpoint: "mytransaction", TransType: "careflow_add", Payload: payload,
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
            if (data && data[0]){
                setResGroupIdList([]);
                setDevIdList([]);
                setAreaGroupIdList([]);
                props?.setCount(0);
                formSuccess("Careflow added successfully.", "wiz8Submit", "loadWiz8Submit");
                formCheck("wizard9Form", "wiz9Submit");
                swapModal("wiz8CancelBtn", "wizard9");
            }
            else{
                formFail("Error encountered, save failed.", "wiz8Submit", "loadWiz8Submit");
            }
        });
    }

    function clearWizard(){
        let careflowTypes, interval;
        setSearch("");
        setSearch2("");
        setSearch3("");
        setSearch4("");
        setWizDescription("");
        setWizOutput("");
        setWizCareflowType("");
        setWizEventType(0);
        setWizEntities([]);
        setWizStart("");
        setWizEnd("");
        setWizMsgTime(0);
        setWizInterval(0);
        setNewWizEmails([]);
        setCurWizEmails([]);
        setNewWizNumbers([]);
        setCurWizNumbers([]);
        document.getElementById("wiz-description").value = "";
        careflowTypes = document.getElementsByName("wiz-careflow-type");
        for (let i=0; i < careflowTypes?.length; i++){
            if (careflowTypes[i] !== undefined){
                careflowTypes[i].checked = false;
            }
        }
        setAreaGroupIdList([]);
        setResGroupIdList([]);
        setDevIdList([]);
        setGroupIdList([]);
        setTextEntities([]);
        setMobileEntities([]);
        setEmailEntities([]);
        setPagerEntities([]);
        setVoiceEntities([]);
        document.getElementById("wiz-specific").classList.remove("btn-active");
        document.getElementById("wiz-allday").classList.remove("btn-active");
        document.getElementById("wiz-delayed").classList.remove("btn-active");
        document.getElementById("wiz-immediate").classList.remove("btn-active");
        document.getElementById("wiz-starttime").value = "";
        document.getElementById("wiz-endtime").value = "";
        document.getElementById("wiz-messageTime").value = "";
        interval = document.getElementById("wiz-interval");
        if (interval !== undefined && interval !== null){
            interval.value = 0;
        }
    }

    function getCareflowTypeName(careflowTypeId, eventCategoryId){
        if (careflowTypeId.toString() === "0"){
            return "Default";
        }else if (careflowTypeId.toString() === "1"){
            if (eventCategoryId.toString() === "1"){
                return "Default";
            }else{
                return "Alert Type";
            }
        }else if (careflowTypeId.toString() === "3"){
            return "Device";
        }else if (careflowTypeId.toString() === "4"){
            return "Resident";
        }else if (careflowTypeId.toString() === "5"){
            return "Area";
        }else{
            return 'Invalid';
        }
    }

    function getEventCategoryById(eventCategoryId){
        for (let i=0; i < escTypeData.length; i++){
            if (escTypeData[i]?.event_category_id?.toString() === eventCategoryId?.toString()){
                return escTypeData[i]?.event_category_name
            }
        }
        return "Invalid"
    }

    function unpackGroupNames(inIdList){
        let tmp = [];
        for (let i=0; i < groupData.length; i++){
            if (inIdList.includes(groupData[i]?.group_id.toString())){
                tmp.push(groupData[i]?.group_name);
            }
        }
        return tmp.join(", ");
    }

    function unpackCapcodes(inIdList){
        let tmp = [];
        for (let i=0; i < groupData.length; i++){
            if (inIdList.includes(groupData[i]?.group_id.toString())){
                tmp.push(groupData[i]?.capcode);
            }
        }
        return tmp.join(", ")
    }

    function makeTimeDisplay(start, end){
        if (start === "00:00" && end === "23:59"){
            return "Immediately"
        }else{
            return `From ${start} to ${end}`
        }
    }

    // * * * * * * * * * *
    // Content Constants *
    // * * * * * * * * * *

    const devTableHead = <TableHeaders checkbox={true} headerData={headerData}
                                 checkAll={() => checkAll(0, devDataCnt ? devDataCnt : 0, devIdList, setDevIdList, "uCheckHead", "uCheckDev")} writeAccess={true}
                                 sortOrder={sorter.sortOrder} sortColumn={sorter.sortColumn} sorter={sorter}
                                 setSorter={setSorter} actionAppearance={() => {}} />
    const devAddTableHead = <TableHeaders checkbox={true} headerData={headerDataAdd} checkboxHeaderId={"uCheckHead2"}
                                 checkAll={() => checkAll(0, devDataCnt ? devDataCnt : 0, devIdList, setDevIdList, "uCheckHead2", "uCheckDev2")} writeAccess={true}
                                 sortOrder={sorter.sortOrder} sortColumn={sorter.sortColumn} sorter={sorter}
                                 setSorter={setSorter} actionAppearance={() => {}} />
    const devWizTableHead = <TableHeaders checkbox={true} headerData={headerDataWiz} checkboxHeaderId={"uCheckHead9"}
                                 checkAll={() => checkAll(0, devDataCnt ? devDataCnt : 0, devIdList, setDevIdList, "uCheckHead9", "uCheckDev3")} writeAccess={true}
                                 sortOrder={sorter.sortOrder} sortColumn={sorter.sortColumn} sorter={sorter}
                                 setSorter={setSorter} actionAppearance={() => {}} />

    const resTableHead = <TableHeaders checkbox={true} headerData={headerData2} checkboxHeaderId={"uCheckHead3"}
                                 checkAll={() => checkAll(0, resGroupDataCnt ? resGroupDataCnt : 0, resGroupIdList, setResGroupIdList, "uCheckHead3", "uCheckRes")} writeAccess={true}
                                 sortOrder={sorter2.sortOrder} sortColumn={sorter2.sortColumn} sorter={sorter2}
                                 setSorter={setSorter2} actionAppearance={() => {}} />
    const resAddTableHead = <TableHeaders checkbox={true} headerData={headerData2} checkboxHeaderId={"uCheckHead4"}
                                 checkAll={() => checkAll(0, resGroupDataCnt ? resGroupDataCnt : 0, resGroupIdList, setResGroupIdList, "uCheckHead4", "uCheckRes")} writeAccess={true}
                                 sortOrder={sorter2.sortOrder} sortColumn={sorter2.sortColumn} sorter={sorter2}
                                 setSorter={setSorter2} actionAppearance={() => {}} />
    const resWizTableHead = <TableHeaders checkbox={true} headerData={headerData2} checkboxHeaderId={"uCheckHead10"}
                                 checkAll={() => checkAll(0, resGroupDataCnt ? resGroupDataCnt : 0, resGroupIdList, setResGroupIdList, "uCheckHead10", "uCheckRes")} writeAccess={true}
                                 sortOrder={sorter2.sortOrder} sortColumn={sorter2.sortColumn} sorter={sorter2}
                                 setSorter={setSorter2} actionAppearance={() => {}} />

    const groupTableHead = <TableHeaders checkbox={true} headerData={headerData3} checkboxHeaderId={"uCheckHead5"}
                                 checkAll={() => checkAll(0, groupDataCnt ? groupDataCnt : 0, groupIdList, setGroupIdList, "uCheckHead5", "uCheckGroup")} writeAccess={true}
                                 sortOrder={sorter3.sortOrder} sortColumn={sorter3.sortColumn} sorter={sorter3}
                                 setSorter={setSorter3} actionAppearance={() => {}} />
    const groupAddTableHead = <TableHeaders checkbox={true} headerData={headerData3} checkboxHeaderId={"uCheckHead6"}
                                 checkAll={() => checkAll(0, groupDataCnt ? groupDataCnt : 0, groupIdList, setGroupIdList, "uCheckHead6", "uCheckGroup")} writeAccess={true}
                                 sortOrder={sorter3.sortOrder} sortColumn={sorter3.sortColumn} sorter={sorter3}
                                 setSorter={setSorter3} actionAppearance={() => {}} />
    const groupWizTableHead = <TableHeaders checkbox={true} headerData={headerData3} checkboxHeaderId={"uCheckHead12"}
                                 checkAll={() => checkAll(0, groupDataCnt ? groupDataCnt : 0, groupIdList, setGroupIdList, "uCheckHead12", "uCheckGroup")} writeAccess={true}
                                 sortOrder={sorter3.sortOrder} sortColumn={sorter3.sortColumn} sorter={sorter3}
                                 setSorter={setSorter3} actionAppearance={() => {}} />

    const areaTableHead = <TableHeaders checkbox={true} headerData={headerData4} checkboxHeaderId={"uCheckHead7"}
                                        checkAll={() => checkAll(0, areaGroupDataCnt ? areaGroupDataCnt : 0, areaGroupIdList, setAreaGroupIdList, "uCheckHead7", "uCheckAreaGroup")}
                                        writeAccess={true} sortOrder={sorter4.sortOrder} sortColumn={sorter4.sortColumn}
                                        sorter={sorter4} setSorter={setSorter4} actionAppearance={() => {}} />
    const areaAddTableHead = <TableHeaders checkbox={true} headerData={headerData4} checkboxHeaderId={"uCheckHead8"}
                                           checkAll={() => checkAll(0, areaGroupDataCnt ? areaGroupDataCnt : 0, areaGroupIdList, setAreaGroupIdList, "uCheckHead8", "uCheckAreaGroup")}
                                           writeAccess={true} sortOrder={sorter4.sortOrder} sortColumn={sorter4.sortColumn}
                                           sorter={sorter4} setSorter={setSorter4} actionAppearance={() => {}} />
    const areaWizTableHead = <TableHeaders checkbox={true} headerData={headerData4} checkboxHeaderId={"uCheckHead11"}
                                           checkAll={() => checkAll(0, areaGroupDataCnt ? areaGroupDataCnt : 0, areaGroupIdList, setAreaGroupIdList, "uCheckHead11", "uCheckAreaGroup")}
                                           writeAccess={true} sortOrder={sorter4.sortOrder} sortColumn={sorter4.sortColumn}
                                           sorter={sorter4} setSorter={setSorter4} actionAppearance={() => {}} />

    return (
        <>
            <div className={"mb-4"}>
                <Button text={"Careflow Wizard"} class={"btn btn-primary me-3"} style={{height: "40.5px"}} iconPosition={"end"}
                        icon={true} iconSize={"tiny"} iconColor={"#FFFFFF"} iconType={"google"} iconName={"school"}
                        iconClass={"material-icons md-18 md-dark ms-2 align-text-bottom"} onClick={() => {
                            clearWizard();
                            formCheck("wizard1Form", "wiz1Submit");
                            showModal("wizard1");
                        }}/>
                <Button text={"Careflow Guide"} class={"btn btn-primary"} style={{height: "40.5px"}} iconPosition={"end"}
                        icon={true} iconSize={"tiny"} iconColor={"#FFFFFF"} iconType={"google"} iconName={"help_outline"}
                        iconClass={"material-icons md-18 md-dark ms-2 align-text-bottom"} onClick={() => {toast.info("Careflow guide coming soon.")}}/>
            </div>
            <div className={"card d-inline-block"}>
                <div className={"card-header d-flex justify-content-between h-auto"} style={{backgroundColor: "#f0f8ff"}}>
                    <div>
                        <h4 className={"card-header-title d-inline-block"} style={{minWidth: "225px"}}>Global Output Settings</h4>
                        <div className={"ms-2"} style={{display: "inline-block"}} id={"output-header-data"}>
                            <h3 className={"my-0 ms-0 me-3 text-success"}>{globalOutputs.length}/6 On</h3>
                        </div>
                        <div className={"ms-2 d-none"} style={{display: "inline-block"}} id={"output-header-data-show"}>
                            <h4 className={"my-0 ms-0 me-3 text-danger"}>Warning: Changing output settings will reboot the local server</h4>
                        </div>
                    </div>
                    <div>
                        <Button type={"button"} class={"btn btn-primary d-none me-5"} disabled={true} spinner={true} text={"Loading"} id={"loadEditSubmit-globals"} />
                        <Button id={"editSubmit-globals"} style={{width: "75px"}} text={"Save"} class={"btn btn-primary me-5 d-none"} onClick={() => performSaveGlobals()} />
                        <i className={"fe fe-plus as-btn collapse-toggle p-2"} style={{verticalAlign: "middle"}} id={"card-btn-globals"}
                                       onClick={() => {
                                           toggleCollapse("card-btn-globals", "card-body-globals");
                                           document.getElementById("output-header-data").classList.toggle("d-none");
                                           document.getElementById("output-header-data-show").classList.toggle("d-none");
                        }}></i>
                    </div>
                </div>
                <div className={"card-body collapse collapse-content"} id={"card-body-globals"} style={{backgroundColor: "#f7fbff"}}>
                    <div className={"list-group list-group-flush"}>
                        <ul style={{listStyle: "none", columnCount: 2, width: "max-content"}}>
                            <li>
                                <div className={"form-check form-switch ms-4 d-inline-block"}>
                                    <input className={"form-check-input"} type={"checkbox"} role={"switch"} id={"mobile-on"}
                                           checked={globalOutputs.includes("palmobile")} onChange={() => {
                                        toggleOutputs("palmobile");
                                        document.getElementById("editSubmit-globals").classList.remove("d-none");
                                    }} />
                                    <label className={"form-check-label"} htmlFor={"mobile-on"}>Palcare Mobile</label>
                                </div>
                            </li>
                            <li className={"my-3"}>
                                <div className={"form-check form-switch ms-4 d-inline-block"}>
                                    <input className={"form-check-input"} type={"checkbox"} role={"switch"} id={"text-on"}
                                           checked={globalOutputs.includes("text")} onChange={() => {
                                        toggleOutputs("text");
                                        document.getElementById("editSubmit-globals").classList.remove("d-none");
                                    }} />
                                    <label className={"form-check-label"} htmlFor={"text-on"}>Text Messaging</label>
                                </div>
                            </li>
                            <li className={"my-3"}>
                                <div className={"form-check form-switch ms-4 d-inline-block"}>
                                    <input className={"form-check-input"} type={"checkbox"} role={"switch"} id={"voice-on"}
                                           checked={globalOutputs.includes("voice")} onChange={() => {
                                        toggleOutputs("voice");
                                        document.getElementById("editSubmit-globals").classList.remove("d-none");
                                    }} />
                                    <label className={"form-check-label"} htmlFor={"voice-on"}>Voice Calls</label>
                                </div>
                            </li>
                            <li className={"my-3"}>
                                <div className={"form-check form-switch ms-4 d-inline-block"}>
                                    <input className={"form-check-input"} type={"checkbox"} role={"switch"} id={"email-on"}
                                           checked={globalOutputs.includes("email")} onChange={() => {
                                        toggleOutputs("email");
                                        document.getElementById("editSubmit-globals").classList.remove("d-none");
                                    }} />
                                    <label className={"form-check-label"} htmlFor={"email-on"}>Emails</label>
                                </div>
                            </li>
                            <li className={"my-3"}>
                                <div className={"form-check form-switch ms-4 d-inline-block"}>
                                    <input className={"form-check-input"} type={"checkbox"} role={"switch"} id={"pager-on"}
                                           checked={globalOutputs.includes("pager")} onChange={() => {
                                        toggleOutputs("pager");
                                        document.getElementById("editSubmit-globals").classList.remove("d-none");
                                    }} />
                                    <label className={"form-check-label"} htmlFor={"pager-on"}>Pagers</label>
                                </div>
                            </li>
                            <li className={"my-3"}>
                                <div className={"form-check form-switch ms-4 d-inline-block"}>
                                    <input className={"form-check-input"} type={"checkbox"} role={"switch"} id={"serial-on"}
                                           checked={globalOutputs.includes("serial")} onChange={() => {
                                        toggleOutputs("serial");
                                        document.getElementById("editSubmit-globals").classList.remove("d-none");
                                    }} />
                                    <label className={"form-check-label"} htmlFor={"serial-on"}>Serial Out</label>
                                </div>
                            </li>
                            <li className={"my-3"}>
                                <div className={"form-check form-switch ms-4 d-inline-block"}>
                                    <input className={"form-check-input"} type={"checkbox"} role={"switch"} id={"offsite-on"}
                                           checked={globalOutputs.includes("offsite")} onChange={() => {
                                        toggleOutputs("offsite");
                                        document.getElementById("editSubmit-globals").classList.remove("d-none");
                                    }} />
                                    <label className={"form-check-label"} htmlFor={"offsite-on"}>Offsite Monitoring</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={"card"}>
                <div className={"card-header d-flex justify-content-between h-auto"}>
                    <div>
                        <h4 className={"card-header-title d-inline-block"} style={{minWidth: "225px"}}>Alert Careflow</h4>
                        <div className={"ms-2"} style={{display: "inline-block"}} id={"alert-header-data"}>
                            <h3 className={"text-success m-0"}>{dataLength(1)} Profiles</h3>
                        </div>
                    </div>
                    <div>
                        <Button type={"button"} class={"btn btn-primary d-none me-5"} disabled={true} spinner={true} text={"Loading"} id={"loadEditSubmit-alert"}/>
                        <Button id={"editSubmit-alert"} style={{width: "75px"}} text={"Save"} class={"btn btn-primary me-5 d-none"} onClick={() => performSave("alert")} />
                        <i className={"fe fe-plus as-btn collapse-toggle p-2"} style={{verticalAlign: "middle"}} id={"card-btn-alert"}
                                       onClick={() => {
                                           toggleCollapse("card-btn-alert", "card-body-alert");
                                           document.getElementById("alert-header-data").classList.toggle("d-none");
                                       }}></i>
                    </div>
                </div>
                <div className={"card-body collapse collapse-content"} id={"card-body-alert"}>
                    <div className={"list-group list-group-flush my-n3"}>
                        <div className={"list-group-item"}>
                            <Button text={"Add"} icon={true} class={"btn btn-primary mb-3 float-end"} style={{height: "40.5px"}} iconClass={"fe fe-plus me-2"} onClick={() => {
                                showAddModalHandler("alert");
                                document.getElementById("add-escalation-type").value = "alert";
                            }}/>
                            <form id={"mainform-alert"} className={"was-validated"}>
                                <table className={"table"}>
                                    <thead>
                                        <tr>
                                            <th className={"text-center"} scope={"col"}></th>
                                            <th className={"text-center"} scope={"col"}>Enable</th>
                                            <th className={"text-center"} scope={"col"}>Description</th>
                                            <th className={"text-center"} scope={"col"}>Category</th>
                                            <th className={"text-center"} scope={"col"}>All{<br/>}Day</th>
                                            <th className={"text-center"} scope={"col"}>Active Start</th>
                                            <th className={"text-center"} scope={"col"}>Active End</th>
                                            {globalOutputs.includes("palmobile")
                                                ? <th className={"text-center"} scope={"col"}>Mobile</th>
                                                : <></>
                                            }
                                            {globalOutputs.includes("text")
                                                ? <th className={"text-center"} scope={"col"}>Text</th>
                                                : <></>
                                            }
                                            {globalOutputs.includes("voice")
                                                ? <th className={"text-center"} scope={"col"}>Voice</th>
                                                : <></>
                                            }
                                            {globalOutputs.includes("email")
                                                ? <th className={"text-center"} scope={"col"}>Email</th>
                                                : <></>
                                            }
                                            {globalOutputs.includes("pager")
                                                ? <th className={"text-center"} scope={"col"}>Pager</th>
                                                : <></>
                                            }
                                            {globalOutputs.includes("serial")
                                                ? <th className={"text-center"} scope={"col"}>Serial Out</th>
                                                : <></>
                                            }
                                            {globalOutputs.includes("offsite")
                                                ? <th className={"text-center"} scope={"col"}>Offsite Monitoring</th>
                                                : <></>
                                            }
                                            <th className={"text-center d-none"} scope={"col"}>Reporting</th>
                                            <th className={"text-center"} scope={"col"}>Mute</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {escTypeOptions.length > 0 && escData.map((rowItem, i) => {
                                            if (rowItem?.escalation_type_id === 1 && rowItem?.event_category_id !== 1){
                                                return (
                                                    <tr key={`custom1-row-${rowItem.careflow_id}`}>
                                                        <td className={"text-center"}
                                                            style={{minWidth: "50px", maxWidth: "100px"}}>
                                                            <div className={"d-inline-block"}>
                                                                <i className={"fe fe-trash-2 as-btn red-hover"}
                                                                   onClick={() => {
                                                                       setDeleteId(() => rowItem.careflow_id);
                                                                       showModal("deleteconfirmmodal");
                                                                   }}></i>
                                                            </div>
                                                        </td>
                                                        <td className={"text-center"}
                                                            style={{minWidth: "75px", maxWidth: "100px"}}>
                                                            <div className={"d-inline-block"}>
                                                                <input style={{height: "1.25rem", width: "1.25rem"}}
                                                                       name={`on-checkbox-alert${i}`}
                                                                       id={`on-checkbox-alert${i}`}
                                                                       className={"form-check-input mx-3"}
                                                                       type={"checkbox"}
                                                                       defaultChecked={rowItem?.enabled}
                                                                       onChange={() => {
                                                                           recordRecordEdit("alert", i);
                                                                       }}/>
                                                            </div>
                                                        </td>
                                                        <td className={"text-center"} style={{maxWidth: "225px"}}>
                                                            <input className={"form-control"} type={"text"} style={{minWidth: "175px"}}
                                                                   name={`careflow-description-alert${i}`}
                                                                   id={`careflow-description-alert${i}`}
                                                                   defaultValue={rowItem?.careflow_description}
                                                                   pattern={"[a-zA-Z0-9_ \\-\\/]+"}
                                                                   onChange={() => {
                                                                       recordRecordEdit("alert", i);
                                                                   }}/>
                                                            <div className={"invalid-feedback"}>
                                                                Enter a valid description. Characters, numbers, and - _
                                                                / characters allowed.
                                                            </div>
                                                        </td>
                                                        <td className={"text-center"}>
                                                            <div>
                                                                <select className={"form-select"} style={{minWidth: "175px"}}
                                                                        id={`careflow-event-type-alert${i}`}
                                                                        defaultValue={rowItem?.event_category_id}
                                                                        onChange={(e) => {
                                                                            recordRecordEdit("alert", i);
                                                                        }}
                                                                        required={true}>
                                                                    {escTypeOptions}
                                                                </select>
                                                            </div>
                                                        </td>
                                                        <td className={"text-center"}>
                                                            <div className={"d-inline-block"}>
                                                                <input style={{height: "1.25rem", width: "1.25rem"}}
                                                                       name={`allday-checkbox-alert${i}`}
                                                                       id={`allday-checkbox-alert${i}`}
                                                                       className={"form-check-input mx-3"}
                                                                       type={"checkbox"}
                                                                       defaultChecked={rowItem?.enable_start === 0 && rowItem?.enable_end === 86400}
                                                                       onChange={(e) => {
                                                                           let startItem, endItem;
                                                                           startItem = document.getElementById(`start-time-alert${i}`);
                                                                           endItem = document.getElementById(`end-time-alert${i}`);
                                                                           if (e.target.checked) {
                                                                               startItem.max = "23:59";
                                                                               startItem.value = "00:00";
                                                                               startItem.readOnly = true;
                                                                               endItem.step = "0";
                                                                               endItem.readOnly = true;
                                                                           } else {
                                                                               startItem.readOnly = false;
                                                                               endItem.readOnly = false;
                                                                               startItem.value = convertSectoHHMM(rowItem?.enable_start);
                                                                               endItem.value = convertSectoHHMM(rowItem?.enable_end);
                                                                               if (rowItem?.enable_end === 86400) {
                                                                                   endItem.step = "0"
                                                                               } else {
                                                                                   endItem.step = "300"
                                                                                   endItem.stepUp()
                                                                               }
                                                                           }
                                                                           recordRecordEdit("alert", i);
                                                                       }}/>
                                                            </div>
                                                        </td>
                                                        <td className={"text-center"}>
                                                            <div className={"me-3"} style={{maxWidth: "185px"}}>
                                                                <input className={"form-control"} type={"time"}
                                                                       id={`start-time-alert${i}`}
                                                                       defaultValue={rowItem?.enable_start ? convertSectoHHMM(rowItem.enable_start) : "00:00"}
                                                                       readOnly={rowItem?.enable_start === 0 && rowItem?.enable_end === 86400}
                                                                       step={"300"} min={"00:00"} max={"23:59"}
                                                                       onFocus={(e) => {
                                                                           e.target.showPicker();
                                                                       }}
                                                                       onBlur={(e) => {
                                                                           if (!e.target.checkValidity()) {
                                                                               e.target.stepUp();
                                                                           }
                                                                           recordRecordEdit("alert", i);
                                                                       }}/>
                                                                <div className={"invalid-feedback"}>
                                                                    Time required, must be in 5 minute increments.
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className={"text-center"}>
                                                            <div className={"me-3"} style={{maxWidth: "185px"}}>
                                                                <input className={"form-control"} type={"time"}
                                                                       id={`end-time-alert${i}`}
                                                                       defaultValue={rowItem?.enable_end !== 86400 ? convertSectoHHMM(rowItem.enable_end) : "23:59"}
                                                                       readOnly={rowItem?.enable_start === 0 && rowItem?.enable_end === 86400}
                                                                       step={![86400, 86340].includes(rowItem?.enable_end) ? "300" : "0"}
                                                                       min={"00:00"} max={"23:59"}
                                                                       onFocus={(e) => {
                                                                           e.target.showPicker();
                                                                       }}
                                                                       onBlur={(e) => {
                                                                           if (e.target.value === "23:59") {
                                                                               e.target.step = "0";
                                                                           } else {
                                                                               e.target.step = "300";
                                                                           }
                                                                           if (!e.target.checkValidity()) {
                                                                               e.target.stepUp();
                                                                           }
                                                                           recordRecordEdit("alert", i);
                                                                       }}/>
                                                                <div className={"invalid-feedback"}>
                                                                    Time required, must be in 5 minute increments.
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {globalOutputs.includes("palmobile")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`palmobile-on-alert${i}`}
                                                                           id={`palmobile-on-alert${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.palmobile?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("alert", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputModal("palmobile", i);
                                                                                   if (document.getElementById(`mute-on-alert${i}`).checked === true) {
                                                                                       document.getElementById(`mute-on-alert${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`palmobile-icon-alert${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`palmobile-on-alert${i}`).checked && showOutputModal("palmobile", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("text")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`text-on-alert${i}`}
                                                                           id={`text-on-alert${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.textmessages?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("alert", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputPhoneModal("textmessages", i);
                                                                                   if (document.getElementById(`mute-on-alert${i}`).checked === true) {
                                                                                       document.getElementById(`mute-on-alert${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`text-icon-alert${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`text-on-alert${i}`).checked && showOutputPhoneModal("textmessages", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("voice")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`voice-on-alert${i}`}
                                                                           id={`voice-on-alert${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.voicemessages?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("alert", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputPhoneModal("voicemessages", i);
                                                                                   if (document.getElementById(`mute-on-alert${i}`).checked === true) {
                                                                                       document.getElementById(`mute-on-alert${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`voice-icon-alert${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`voice-on-alert${i}`).checked && showOutputPhoneModal("voicemessages", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("email")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`email-on-alert${i}`}
                                                                           id={`email-on-alert${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.email?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("alert", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputEmailModal("email", i);
                                                                                   if (document.getElementById(`mute-on-alert${i}`).checked === true) {
                                                                                       document.getElementById(`mute-on-alert${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`email-icon-alert${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`email-on-alert${i}`).checked && showOutputEmailModal("email", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("pager")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`pager-on-alert${i}`}
                                                                           id={`pager-on-alert${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.pager?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("alert", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputModal("pager", i);
                                                                                   if (document.getElementById(`mute-on-alert${i}`).checked === true) {
                                                                                       document.getElementById(`mute-on-alert${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`pager-icon-alert${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`pager-on-alert${i}`).checked && showOutputModal("pager", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("serial")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`serial-on-alert${i}`}
                                                                           id={`serial-on-alert${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.serial?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("alert", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputModal("serial", i);
                                                                                   if (document.getElementById(`mute-on-alert${i}`).checked === true) {
                                                                                       document.getElementById(`mute-on-alert${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`serial-icon-alert${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`serial-on-alert${i}`).checked && showOutputModal("serial", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("offsite")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`offsite-on-alert${i}`}
                                                                           id={`offsite-on-alert${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.offsitemonitoring?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("alert", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputTimeModal("offsitemonitoring", i);
                                                                                   if (document.getElementById(`mute-on-alert${i}`).checked === true) {
                                                                                       document.getElementById(`mute-on-alert${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`offsite-icon-alert${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`offsite-on-alert${i}`).checked && showOutputTimeModal("offsitemonitoring", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        <td className={"text-center d-none"}>
                                                            <div>
                                                                <input style={{height: "1.25rem", width: "1.25rem"}}
                                                                       name={`reporting-alert${i}`}
                                                                       id={`reporting-alert${i}`}
                                                                       className={"form-check-input mx-2"}
                                                                       defaultChecked={rowItem?.outputs?.reporting?.enabled}
                                                                       type={"checkbox"}
                                                                       onChange={(e) => {
                                                                           recordRecordEdit("alert", i);
                                                                           if (e.target.checked === true) {
                                                                               if (document.getElementById(`mute-on-alert${i}`).checked === true) {
                                                                                   document.getElementById(`mute-on-alert${i}`).click();
                                                                               }
                                                                           }
                                                                       }}/>
                                                            </div>
                                                        </td>
                                                        <td className={"text-center"}>
                                                            <div>
                                                                <input style={{height: "1.25rem", width: "1.25rem"}}
                                                                       name={`mute-on-alert${i}`}
                                                                       id={`mute-on-alert${i}`}
                                                                       className={"form-check-input mx-2"}
                                                                       defaultChecked={rowItem?.outputs?.mute?.enabled}
                                                                       type={"checkbox"}
                                                                       onChange={(e) => {
                                                                           recordRecordEdit("alert", i);
                                                                           if (e.target.checked === true) {
                                                                               document.getElementById(`reporting-alert${i}`).checked = false;
                                                                               if (globalOutputs.includes("offsite")) {
                                                                                   document.getElementById(`offsite-on-alert${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("serial")) {
                                                                                   document.getElementById(`serial-on-alert${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("pager")) {
                                                                                   document.getElementById(`pager-on-alert${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("email")) {
                                                                                   document.getElementById(`email-on-alert${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("voice")) {
                                                                                   document.getElementById(`voice-on-alert${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("text")) {
                                                                                   document.getElementById(`text-on-alert${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("palmobile")) {
                                                                                   document.getElementById(`palmobile-on-alert${i}`).checked = false;
                                                                               }
                                                                           }
                                                                       }}/>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            } else {
                                                return <></>
                                            }
                                        })}
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"card"}>
                <div className={"card-header d-flex justify-content-between h-auto"}>
                    <div>
                        <h4 className={"card-header-title d-inline-block"} style={{minWidth: "225px"}}>Device
                            Careflow</h4>
                        <div className={"ms-2"} style={{display: "inline-block"}} id={"device-header-data"}>
                            <h3 className={"text-success m-0"}>{dataLength(3)} Profiles</h3>
                        </div>
                    </div>
                    <div>
                        <Button type={"button"} class={"btn btn-primary d-none me-5"} disabled={true} spinner={true} text={"Loading"} id={"loadEditSubmit-device"}/>
                        <Button id={"editSubmit-device"} style={{width: "75px"}} text={"Save"} class={"btn btn-primary me-5 d-none"} onClick={() => performSave("device")} />
                        <i className={"fe fe-plus as-btn collapse-toggle p-2"} style={{verticalAlign: "middle"}} id={"card-btn-device"}
                           onClick={() => {
                               toggleCollapse("card-btn-device", `card-body-device`);
                               document.getElementById("device-header-data").classList.toggle("d-none");
                           }}></i>
                    </div>
                </div>
                <div className={"card-body collapse collapse-content"} id={`card-body-device`}>
                    <div className={"list-group list-group-flush my-n3"}>
                        <div className={"list-group-item"}>
                            <Button text={"Add"} icon={true} class={"btn btn-primary mb-3 float-end"} style={{height: "40.5px"}} iconClass={"fe fe-plus me-2"} onClick={() => {
                                showAddModalHandler("device");
                                document.getElementById("add-escalation-type").value = "device";
                            }}/>
                            <form id={"mainform-device"} className={"was-validated"}>
                                <table className={"table"}>
                                    <thead>
                                    <tr>
                                        <th className={"text-center"} scope={"col"}></th>
                                        <th className={"text-center"} scope={"col"}>Enable</th>
                                        <th className={"text-center"} scope={"col"}>Devices</th>
                                        <th className={"text-center"} scope={"col"}>Description</th>
                                        <th className={"text-center"} scope={"col"}>All{<br/>}Day</th>
                                        <th className={"text-center"} scope={"col"}>Active Start</th>
                                        <th className={"text-center"} scope={"col"}>Active End</th>
                                        {globalOutputs.includes("palmobile")
                                            ? <th className={"text-center"} scope={"col"}>Mobile</th>
                                            : <></>
                                        }
                                        {globalOutputs.includes("text")
                                            ? <th className={"text-center"} scope={"col"}>Text</th>
                                            : <></>
                                        }
                                        {globalOutputs.includes("voice")
                                            ? <th className={"text-center"} scope={"col"}>Voice</th>
                                            : <></>
                                        }
                                        {globalOutputs.includes("email")
                                            ? <th className={"text-center"} scope={"col"}>Email</th>
                                            : <></>
                                        }
                                        {globalOutputs.includes("pager")
                                            ? <th className={"text-center"} scope={"col"}>Pager</th>
                                            : <></>
                                        }
                                        {globalOutputs.includes("serial")
                                            ? <th className={"text-center"} scope={"col"}>Serial Out</th>
                                            : <></>
                                        }
                                        {globalOutputs.includes("offsite")
                                            ? <th className={"text-center"} scope={"col"}>Offsite Monitoring</th>
                                            : <></>
                                        }
                                        <th className={"text-center d-none"} scope={"col"}>Reporting</th>
                                        <th className={"text-center"} scope={"col"}>Mute</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {escData.map((rowItem, i) => {
                                            if (rowItem.escalation_type_id === 3){
                                                return (
                                                    <tr key={`custom3-row-${rowItem.careflow_id}`}>
                                                        <td className={"text-center"}
                                                            style={{minWidth: "50px", maxWidth: "100px"}}>
                                                            <div className={"d-inline-block"}>
                                                                <i className={"fe fe-trash-2 as-btn red-hover"}
                                                                   onClick={() => {
                                                                       setDeleteId(() => rowItem.careflow_id);
                                                                       showModal("deleteconfirmmodal");
                                                                   }}></i>
                                                            </div>
                                                        </td>
                                                        <td className={"text-center"}
                                                            style={{minWidth: "75px", maxWidth: "100px"}}>
                                                            <div className={"d-inline-block"}>
                                                                <input style={{height: "1.25rem", width: "1.25rem"}}
                                                                       name={`on-checkbox-device${i}`}
                                                                       id={`on-checkbox-device${i}`}
                                                                       className={"form-check-input mx-3"}
                                                                       type={"checkbox"}
                                                                       defaultChecked={rowItem?.enabled}
                                                                       onChange={() => {
                                                                           recordRecordEdit("device", i);
                                                                       }}/>
                                                            </div>
                                                        </td>
                                                        <td className={"text-center"}>
                                                            <i className={"fe fe-settings as-btn"} onClick={() => {
                                                                setCareflowId(() => rowItem.careflow_id);
                                                                setRowIndex(() => i);
                                                                showRefModal("device", i);
                                                            }}/>
                                                        </td>
                                                        <td className={"text-center"} style={{maxWidth: "225px"}}>
                                                            <input className={"form-control"} type={"text"}
                                                                   style={{minWidth: "175px"}}
                                                                   name={`careflow-description-device${i}`}
                                                                   id={`careflow-description-device${i}`}
                                                                   defaultValue={rowItem?.careflow_description}
                                                                   pattern={"[a-zA-Z0-9_ \\-\\/]+"}
                                                                   onChange={() => {
                                                                       recordRecordEdit("device", i);
                                                                   }}/>
                                                            <div className={"invalid-feedback"}>
                                                                Enter a valid description. Characters, numbers, and - _
                                                                / characters allowed.
                                                            </div>
                                                            <input className={"d-none"} type={"hidden"}
                                                                   id={`careflow-event-type-device${i}`}
                                                                   value={rowItem?.event_category_id} />
                                                        </td>
                                                        <td className={"text-center"}>
                                                            <div className={"d-inline-block"}>
                                                                <input style={{height: "1.25rem", width: "1.25rem"}}
                                                                       name={`allday-checkbox-device${i}`}
                                                                       id={`allday-checkbox-device${i}`}
                                                                       className={"form-check-input mx-3"}
                                                                       type={"checkbox"}
                                                                       defaultChecked={rowItem?.enable_start === 0 && rowItem?.enable_end === 86400}
                                                                       onChange={(e) => {
                                                                           let startItem, endItem;
                                                                           startItem = document.getElementById(`start-time-device${i}`);
                                                                           endItem = document.getElementById(`end-time-device${i}`);
                                                                           if (e.target.checked) {
                                                                               startItem.value = "00:00";
                                                                               startItem.readOnly = true;
                                                                               endItem.value = "23:59";
                                                                               endItem.step = "0";
                                                                               endItem.readOnly = true;
                                                                           } else {
                                                                               startItem.readOnly = false;
                                                                               endItem.readOnly = false;
                                                                               startItem.value = convertSectoHHMM(rowItem?.enable_start);
                                                                               endItem.value = convertSectoHHMM(rowItem?.enable_end);
                                                                               if (rowItem?.enable_end === 86400) {
                                                                                   endItem.step = "0"
                                                                               } else {
                                                                                   endItem.step = "300"
                                                                                   endItem.stepUp()
                                                                               }
                                                                           }
                                                                           recordRecordEdit("device", i);
                                                                       }}/>
                                                            </div>
                                                        </td>
                                                        <td className={"text-center"}>
                                                            <div className={"me-3"} style={{maxWidth: "185px"}}>
                                                                <input className={"form-control"} type={"time"}
                                                                       id={`start-time-device${i}`}
                                                                       defaultValue={rowItem?.enable_start ? convertSectoHHMM(rowItem.enable_start) : "00:00"}
                                                                       readOnly={rowItem?.enable_start === 0 && rowItem?.enable_end === 86400}
                                                                       step={"300"} min={"00:00"} max={"23:59"}
                                                                       onFocus={(e) => {
                                                                           e.target.showPicker();
                                                                       }}
                                                                       onBlur={(e) => {
                                                                           if (!e.target.checkValidity()) {
                                                                               e.target.stepUp();
                                                                           }
                                                                           recordRecordEdit("device", i);
                                                                       }}/>
                                                                <div className={"invalid-feedback"}>
                                                                    Time required, must be in 5 minute increments.
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className={"text-center"}>
                                                            <div className={"me-3"} style={{maxWidth: "185px"}}>
                                                                <input className={"form-control"} type={"time"}
                                                                       id={`end-time-device${i}`}
                                                                       defaultValue={rowItem?.enable_end !== 86400 ? convertSectoHHMM(rowItem.enable_end) : "23:59"}
                                                                       readOnly={rowItem?.enable_start === 0 && rowItem?.enable_end === 86400}
                                                                       step={![86400, 86340].includes(rowItem?.enable_end) ? "300" : "0"}
                                                                       min={"00:00"} max={"23:59"}
                                                                       onFocus={(e) => {
                                                                           e.target.showPicker();
                                                                       }}
                                                                       onBlur={(e) => {
                                                                           if (e.target.value === "23:59") {
                                                                               e.target.step = "0";
                                                                           } else {
                                                                               e.target.step = "300";
                                                                           }
                                                                           if (!e.target.checkValidity()) {
                                                                               e.target.stepUp();
                                                                           }
                                                                           recordRecordEdit("device", i);
                                                                       }}/>
                                                                <div className={"invalid-feedback"}>
                                                                    Time required, must be in 5 minute increments.
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {globalOutputs.includes("palmobile")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`palmobile-on-device${i}`}
                                                                           id={`palmobile-on-device${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.palmobile?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("device", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputModal("palmobile", i);
                                                                                   if (document.getElementById(`mute-on-device${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-device${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`palmobile-icon-device${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`palmobile-on-device${i}`).checked && showOutputModal("palmobile", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("text")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`text-on-device${i}`}
                                                                           id={`text-on-device${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.textmessages?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("device", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputPhoneModal("textmessages", i);
                                                                                   if (document.getElementById(`mute-on-device${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-device${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`text-icon-device${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`text-on-device${i}`).checked && showOutputPhoneModal("textmessages", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("voice")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`voice-on-device${i}`}
                                                                           id={`voice-on-device${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.voicemessages?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("device", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputPhoneModal("voicemessages", i);
                                                                                   if (document.getElementById(`mute-on-device${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-device${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`voice-icon-device${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`voice-on-device${i}`).checked && showOutputPhoneModal("voicemessages", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("email")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`email-on-device${i}`}
                                                                           id={`email-on-device${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.email?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("device", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputEmailModal("email", i);
                                                                                   if (document.getElementById(`mute-on-device${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-device${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`email-icon-device${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`email-on-device${i}`).checked && showOutputEmailModal("email", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("pager")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`pager-on-device${i}`}
                                                                           id={`pager-on-device${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.pager?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("device", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputModal("pager", i);
                                                                                   if (document.getElementById(`mute-on-device${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-device${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`pager-icon-device${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`pager-on-device${i}`).checked && showOutputModal("pager", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("serial")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`serial-on-device${i}`}
                                                                           id={`serial-on-device${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.serial?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("device", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputModal("serial", i);
                                                                                   if (document.getElementById(`mute-on-device${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-device${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`serial-icon-device${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`serial-on-device${i}`).checked && showOutputModal("serial", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("offsite")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`offsite-on-device${i}`}
                                                                           id={`offsite-on-device${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.offsitemonitoring?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("device", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputTimeModal("offsitemonitoring", i);
                                                                                   if (document.getElementById(`mute-on-device${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-device${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`offsite-icon-device${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`offsite-on-device${i}`).checked && showOutputTimeModal("offsitemonitoring", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        <td className={"text-center d-none"}>
                                                            <div>
                                                                <input style={{height: "1.25rem", width: "1.25rem"}}
                                                                       name={`reporting-device${i}`}
                                                                       id={`reporting-device${i}`}
                                                                       className={"form-check-input mx-2"}
                                                                       defaultChecked={rowItem?.outputs?.reporting?.enabled}
                                                                       type={"checkbox"}
                                                                       onChange={(e) => {
                                                                           recordRecordEdit("device", i);
                                                                           if (e.target.checked){
                                                                               if (document.getElementById(`mute-on-device${i}`).checked === true){
                                                                                   document.getElementById(`mute-on-device${i}`).click();
                                                                               }
                                                                           }
                                                                       }}/>
                                                            </div>
                                                        </td>
                                                        <td className={"text-center"}>
                                                            <div>
                                                                <input style={{height: "1.25rem", width: "1.25rem"}}
                                                                       name={`mute-on-device${i}`}
                                                                       id={`mute-on-device${i}`}
                                                                       className={"form-check-input mx-2"}
                                                                       defaultChecked={rowItem?.outputs?.mute?.enabled}
                                                                       type={"checkbox"}
                                                                       onChange={(e) => {
                                                                           recordRecordEdit("device", i);
                                                                           if (e.target.checked === true){
                                                                               document.getElementById(`reporting-device${i}`).checked = false;
                                                                               if (globalOutputs.includes("offsite")){
                                                                                   document.getElementById(`offsite-on-device${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("serial")){
                                                                                   document.getElementById(`serial-on-device${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("pager")){
                                                                                   document.getElementById(`pager-on-device${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("email")){
                                                                                   document.getElementById(`email-on-device${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("voice")){
                                                                                   document.getElementById(`voice-on-device${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("text")){
                                                                                   document.getElementById(`text-on-device${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("palmobile")){
                                                                                   document.getElementById(`palmobile-on-device${i}`).checked = false;
                                                                               }
                                                                           }
                                                                       }}/>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            } else {
                                                return <></>
                                            }
                                        })}
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"card"}>
                <div className={"card-header d-flex justify-content-between h-auto"}>
                    <div>
                        <h4 className={"card-header-title d-inline-block"} style={{minWidth: "225px"}}>Resident
                            Careflow</h4>
                        <div className={"ms-2"} style={{display: "inline-block"}} id={"resident-header-data"}>
                            <h3 className={"text-success m-0"}>{dataLength(4)} Profiles</h3>
                        </div>
                    </div>
                    <div>
                        <Button type={"button"} class={"btn btn-primary d-none me-5"} disabled={true} spinner={true}
                                text={"Loading"} id={"loadEditSubmit-resident"}/>
                        <Button id={"editSubmit-resident"} style={{width: "75px"}} text={"Save"} class={"btn btn-primary me-5 d-none"} onClick={() => performSave("resident")} />
                        <i className={"fe fe-plus as-btn collapse-toggle p-2"} style={{verticalAlign: "middle"}} id={"card-btn-resident"}
                           onClick={() => {
                               toggleCollapse("card-btn-resident", `card-body-resident`);
                               document.getElementById("resident-header-data").classList.toggle("d-none");
                           }}></i>
                    </div>
                </div>
                <div className={"card-body collapse collapse-content"} id={`card-body-resident`}>
                    <div className={"list-group list-group-flush my-n3"}>
                        <div className={"list-group-item"}>
                            <Button text={"Add"} icon={true} class={"btn btn-primary mb-3 float-end"} style={{height: "40.5px"}} iconClass={"fe fe-plus me-2"} onClick={() => {
                                showAddModalHandler("resident");
                                document.getElementById("add-escalation-type").value = "resident";
                            }}/>
                            <form id={"mainform-resident"} className={"was-validated"}>
                                <table className={"table"}>
                                    <thead>
                                        <tr>
                                            <th className={"text-center"} scope={"col"}></th>
                                            <th className={"text-center"} scope={"col"}>Enable</th>
                                            <th className={"text-center"} scope={"col"}>Residents</th>
                                            <th className={"text-center"} scope={"col"}>Description</th>
                                            <th className={"text-center"} scope={"col"}>All{<br/>}Day</th>
                                            <th className={"text-center"} scope={"col"}>Active Start</th>
                                            <th className={"text-center"} scope={"col"}>Active End</th>
                                            {globalOutputs.includes("palmobile")
                                                ? <th className={"text-center"} scope={"col"}>Mobile</th>
                                                : <></>
                                            }
                                            {globalOutputs.includes("text")
                                                ? <th className={"text-center"} scope={"col"}>Text</th>
                                                : <></>
                                            }
                                            {globalOutputs.includes("voice")
                                                ? <th className={"text-center"} scope={"col"}>Voice</th>
                                                : <></>
                                            }
                                            {globalOutputs.includes("email")
                                                ? <th className={"text-center"} scope={"col"}>Email</th>
                                                : <></>
                                            }
                                            {globalOutputs.includes("pager")
                                                ? <th className={"text-center"} scope={"col"}>Pager</th>
                                                : <></>
                                            }
                                            {globalOutputs.includes("serial")
                                                ? <th className={"text-center"} scope={"col"}>Serial Out</th>
                                                : <></>
                                            }
                                            {globalOutputs.includes("offsite")
                                                ? <th className={"text-center"} scope={"col"}>Offsite Monitoring</th>
                                                : <></>
                                            }
                                            <th className={"text-center d-none"} scope={"col"}>Reporting</th>
                                            <th className={"text-center"} scope={"col"}>Mute</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {escData.map((rowItem, i) => {
                                            if (rowItem.escalation_type_id === 4){
                                                return (
                                                    <tr key={`custom4-row-${rowItem.careflow_id}`}>
                                                        <td className={"text-center"}
                                                            style={{minWidth: "50px", maxWidth: "100px"}}>
                                                            <div className={"d-inline-block"}>
                                                                <i className={"fe fe-trash-2 as-btn red-hover"}
                                                                   onClick={() => {
                                                                       setDeleteId(() => rowItem.careflow_id);
                                                                       showModal("deleteconfirmmodal");
                                                                   }}></i>
                                                            </div>
                                                        </td>
                                                        <td className={"text-center"}
                                                            style={{minWidth: "75px", maxWidth: "100px"}}>
                                                            <div className={"d-inline-block"}>
                                                                <input style={{height: "1.25rem", width: "1.25rem"}}
                                                                       name={`on-checkbox-resident${i}`}
                                                                       id={`on-checkbox-resident${i}`}
                                                                       className={"form-check-input mx-3"}
                                                                       type={"checkbox"}
                                                                       defaultChecked={rowItem?.enabled}
                                                                       onChange={() => {
                                                                           recordRecordEdit("resident", i);
                                                                       }}/>
                                                            </div>
                                                        </td>
                                                        <td className={"text-center"}>
                                                            <i className={"fe fe-settings as-btn"} onClick={() => {
                                                                setCareflowId(() => rowItem.careflow_id);
                                                                setRowIndex(() => i);
                                                                showRefModal("resident", i);
                                                            }}/>
                                                        </td>
                                                        <td className={"text-center"} style={{maxWidth: "225px"}}>
                                                            <input className={"form-control"} type={"text"}
                                                                   style={{minWidth: "175px"}}
                                                                   name={`careflow-description-resident${i}`}
                                                                   id={`careflow-description-resident${i}`}
                                                                   defaultValue={rowItem?.careflow_description}
                                                                   pattern={"[a-zA-Z0-9_ \\-\\/]+"}
                                                                   onChange={() => {
                                                                       recordRecordEdit("resident", i);
                                                                   }}/>
                                                            <div className={"invalid-feedback"}>
                                                                Enter a valid description. Characters, numbers, and - _
                                                                / characters allowed.
                                                            </div>
                                                            <input className={"d-none"} type={"hidden"}
                                                                   id={`careflow-event-type-resident${i}`}
                                                                   value={rowItem?.event_category_id}/>
                                                        </td>
                                                        <td className={"text-center"}>
                                                            <div className={"d-inline-block"}>
                                                                <input style={{height: "1.25rem", width: "1.25rem"}}
                                                                       name={`allday-checkbox-resident${i}`}
                                                                       id={`allday-checkbox-resident${i}`}
                                                                       className={"form-check-input mx-3"}
                                                                       type={"checkbox"}
                                                                       defaultChecked={rowItem?.enable_start === 0 && rowItem?.enable_end === 86400}
                                                                       onChange={(e) => {
                                                                           let startItem, endItem;
                                                                           startItem = document.getElementById(`start-time-resident${i}`);
                                                                           endItem = document.getElementById(`end-time-resident${i}`);
                                                                           if (e.target.checked) {
                                                                               startItem.value = "00:00";
                                                                               startItem.readOnly = true;
                                                                               endItem.value = "23:59";
                                                                               endItem.step = "0";
                                                                               endItem.readOnly = true;
                                                                           } else {
                                                                               startItem.readOnly = false;
                                                                               endItem.readOnly = false;
                                                                               startItem.value = convertSectoHHMM(rowItem?.enable_start);
                                                                               endItem.value = convertSectoHHMM(rowItem?.enable_end);
                                                                               if (rowItem?.enable_end === 86400) {
                                                                                   endItem.step = "0"
                                                                               } else {
                                                                                   endItem.step = "300"
                                                                                   endItem.stepUp()
                                                                               }
                                                                           }
                                                                           recordRecordEdit("resident", i);
                                                                       }}/>
                                                            </div>
                                                        </td>
                                                        <td className={"text-center"}>
                                                            <div className={"me-3"} style={{maxWidth: "185px"}}>
                                                                <input className={"form-control"} type={"time"}
                                                                       id={`start-time-resident${i}`}
                                                                       defaultValue={rowItem?.enable_start ? convertSectoHHMM(rowItem.enable_start) : "00:00"}
                                                                       readOnly={rowItem?.enable_start === 0 && rowItem?.enable_end === 86400}
                                                                       step={"300"} min={"00:00"} max={"23:59"}
                                                                       onFocus={(e) => {
                                                                           e.target.showPicker();
                                                                       }}
                                                                       onBlur={(e) => {
                                                                           if (!e.target.checkValidity()) {
                                                                               e.target.stepUp();
                                                                           }
                                                                           recordRecordEdit("resident", i);
                                                                       }}/>
                                                                <div className={"invalid-feedback"}>
                                                                    Time required, must be in 5 minute increments.
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className={"text-center"}>
                                                            <div className={"me-3"} style={{maxWidth: "185px"}}>
                                                                <input className={"form-control"} type={"time"}
                                                                       id={`end-time-resident${i}`}
                                                                       defaultValue={rowItem?.enable_end !== 86400 ? convertSectoHHMM(rowItem.enable_end) : "23:59"}
                                                                       readOnly={rowItem?.enable_start === 0 && rowItem?.enable_end === 86400}
                                                                       step={![86400, 86340].includes(rowItem?.enable_end) ? "300" : "0"}
                                                                       min={"00:00"} max={"23:59"}
                                                                       onFocus={(e) => {
                                                                           e.target.showPicker();
                                                                       }}
                                                                       onBlur={(e) => {
                                                                           if (e.target.value === "23:59") {
                                                                               e.target.step = "0";
                                                                           } else {
                                                                               e.target.step = "300";
                                                                           }
                                                                           if (!e.target.checkValidity()) {
                                                                               e.target.stepUp();
                                                                           }
                                                                           recordRecordEdit("resident", i);
                                                                       }}/>
                                                                <div className={"invalid-feedback"}>
                                                                    Time required, must be in 5 minute increments.
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {globalOutputs.includes("palmobile")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`palmobile-on-resident${i}`}
                                                                           id={`palmobile-on-resident${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.palmobile?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("resident", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputModal("palmobile", i);
                                                                                   if (document.getElementById(`mute-on-resident${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-resident${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`palmobile-icon-resident${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`palmobile-on-resident${i}`).checked && showOutputModal("palmobile", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("text")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`text-on-resident${i}`}
                                                                           id={`text-on-resident${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.textmessages?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("resident", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputPhoneModal("textmessages", i);
                                                                                   if (document.getElementById(`mute-on-resident${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-resident${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`text-icon-resident${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`text-on-resident${i}`).checked && showOutputPhoneModal("textmessages", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("voice")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`voice-on-resident${i}`}
                                                                           id={`voice-on-resident${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.voicemessages?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("resident", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputPhoneModal("voicemessages", i);
                                                                                   if (document.getElementById(`mute-on-resident${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-resident${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`voice-icon-resident${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`voice-on-resident${i}`).checked && showOutputPhoneModal("voicemessages", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("email")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`email-on-resident${i}`}
                                                                           id={`email-on-resident${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.email?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("resident", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputEmailModal("email", i);
                                                                                   if (document.getElementById(`mute-on-resident${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-resident${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`email-icon-resident${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`email-on-resident${i}`).checked && showOutputEmailModal("email", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("pager")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`pager-on-resident${i}`}
                                                                           id={`pager-on-resident${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.pager?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("resident", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputModal("pager", i);
                                                                                   if (document.getElementById(`mute-on-resident${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-resident${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`pager-icon-resident${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`pager-on-resident${i}`).checked && showOutputModal("pager", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("serial")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`serial-on-resident${i}`}
                                                                           id={`serial-on-resident${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.serial?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("resident", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputModal("serial", i);
                                                                                   if (document.getElementById(`mute-on-resident${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-resident${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`serial-icon-resident${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`serial-on-resident${i}`).checked && showOutputModal("serial", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("offsite")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`offsite-on-resident${i}`}
                                                                           id={`offsite-on-resident${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.offsitemonitoring?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("resident", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputTimeModal("offsitemonitoring", i);
                                                                                   if (document.getElementById(`mute-on-resident${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-resident${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`offsite-icon-resident${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`offsite-on-resident${i}`).checked && showOutputTimeModal("offsitemonitoring", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        <td className={"text-center d-none"}>
                                                            <div>
                                                                <input style={{height: "1.25rem", width: "1.25rem"}}
                                                                       name={`reporting-resident${i}`}
                                                                       id={`reporting-resident${i}`}
                                                                       className={"form-check-input mx-2"}
                                                                       defaultChecked={rowItem?.outputs?.reporting?.enabled}
                                                                       type={"checkbox"}
                                                                       onChange={(e) => {
                                                                           recordRecordEdit("resident", i);
                                                                           if (e.target.checked){
                                                                               if (document.getElementById(`mute-on-resident${i}`).checked === true){
                                                                                   document.getElementById(`mute-on-resident${i}`).click();
                                                                               }
                                                                           }
                                                                       }}/>
                                                            </div>
                                                        </td>
                                                        <td className={"text-center"}>
                                                            <div>
                                                                <input style={{height: "1.25rem", width: "1.25rem"}}
                                                                       name={`mute-on-resident${i}`}
                                                                       id={`mute-on-resident${i}`}
                                                                       className={"form-check-input mx-2"}
                                                                       defaultChecked={rowItem?.outputs?.mute?.enabled}
                                                                       type={"checkbox"}
                                                                       onChange={(e) => {
                                                                           recordRecordEdit("resident", i);
                                                                           if (e.target.checked === true){
                                                                               document.getElementById(`reporting-resident${i}`).checked = false;
                                                                               if (globalOutputs.includes("offsite")){
                                                                                   document.getElementById(`offsite-on-resident${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("serial")){
                                                                                   document.getElementById(`serial-on-resident${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("pager")){
                                                                                   document.getElementById(`pager-on-resident${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("email")){
                                                                                   document.getElementById(`email-on-resident${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("voice")){
                                                                                   document.getElementById(`voice-on-resident${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("text")){
                                                                                   document.getElementById(`text-on-resident${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("palmobile")){
                                                                                   document.getElementById(`palmobile-on-resident${i}`).checked = false;
                                                                               }
                                                                           }
                                                                       }}/>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            } else {
                                                return <></>
                                            }
                                        })}
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"card"}>
                <div className={"card-header d-flex justify-content-between h-auto"}>
                    <div>
                        <h4 className={"card-header-title d-inline-block"} style={{minWidth: "225px"}}>Area
                            Careflow</h4>
                        <div className={"ms-2"} style={{display: "inline-block"}} id={"area-header-data"}>
                            <h3 className={"text-success m-0"}>{dataLength(5)} Profiles</h3>
                        </div>
                    </div>
                    <div>
                        <Button type={"button"} class={"btn btn-primary d-none me-5"} disabled={true} spinner={true}
                                text={"Loading"} id={"loadEditSubmit-area"}/>
                        <Button id={"editSubmit-area"} style={{width: "75px"}} text={"Save"} class={"btn btn-primary me-5 d-none"} onClick={() => performSave("area")} />
                        <i className={"fe fe-plus as-btn collapse-toggle p-2"} style={{verticalAlign: "middle"}} id={"card-btn-area"}
                           onClick={() => {
                               toggleCollapse("card-btn-area", `card-body-area`);
                               document.getElementById("area-header-data").classList.toggle("d-none");
                           }}></i>
                    </div>
                </div>
                <div className={"card-body collapse collapse-content"} id={`card-body-area`}>
                    <div className={"list-group list-group-flush my-n3"}>
                        <div className={"list-group-item"}>
                            <Button text={"Add"} icon={true} class={"btn btn-primary mb-3 float-end"} style={{height: "40.5px"}}
                                    iconClass={"fe fe-plus me-2"} onClick={() => {
                                showAddModalHandler("area");
                                document.getElementById("add-escalation-type").value = "area";
                            }}/>
                            <form id={"mainform-area"} className={"was-validated"}>
                                <table className={"table"}>
                                    <thead>
                                        <tr>
                                            <th className={"text-center"} scope={"col"}></th>
                                            <th className={"text-center"} scope={"col"}>Enable</th>
                                            <th className={"text-center"} scope={"col"}>Areas</th>
                                            <th className={"text-center"} scope={"col"}>Description</th>
                                            <th className={"text-center"} scope={"col"}>All{<br/>}Day</th>
                                            <th className={"text-center"} scope={"col"}>Active Start</th>
                                            <th className={"text-center"} scope={"col"}>Active End</th>
                                            {globalOutputs.includes("palmobile")
                                                ? <th className={"text-center"} scope={"col"}>Mobile</th>
                                                : <></>
                                            }
                                            {globalOutputs.includes("text")
                                                ? <th className={"text-center"} scope={"col"}>Text</th>
                                                : <></>
                                            }
                                            {globalOutputs.includes("voice")
                                                ? <th className={"text-center"} scope={"col"}>Voice</th>
                                                : <></>
                                            }
                                            {globalOutputs.includes("email")
                                                ? <th className={"text-center"} scope={"col"}>Email</th>
                                                : <></>
                                            }
                                            {globalOutputs.includes("pager")
                                                ? <th className={"text-center"} scope={"col"}>Pager</th>
                                                : <></>
                                            }
                                            {globalOutputs.includes("serial")
                                                ? <th className={"text-center"} scope={"col"}>Serial Out</th>
                                                : <></>
                                            }
                                            {globalOutputs.includes("offsite")
                                                ? <th className={"text-center"} scope={"col"}>Offsite Monitoring</th>
                                                : <></>
                                            }
                                            <th className={"text-center d-none"} scope={"col"}>Reporting</th>
                                            <th className={"text-center"} scope={"col"}>Mute</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {escData.map((rowItem, i) => {
                                            if (rowItem.escalation_type_id === 5){
                                                return (
                                                    <tr key={`custom5-row-${rowItem.careflow_id}`}>
                                                        <td className={"text-center"}
                                                            style={{minWidth: "50px", maxWidth: "100px"}}>
                                                            <div className={"d-inline-block"}>
                                                                <i className={"fe fe-trash-2 as-btn red-hover"}
                                                                   onClick={() => {
                                                                       setDeleteId(() => rowItem.careflow_id);
                                                                       showModal("deleteconfirmmodal");
                                                                   }}></i>
                                                            </div>
                                                        </td>
                                                        <td className={"text-center"}
                                                            style={{minWidth: "75px", maxWidth: "100px"}}>
                                                            <div className={"d-inline-block"}>
                                                                <input style={{height: "1.25rem", width: "1.25rem"}}
                                                                       name={`on-checkbox-area${i}`}
                                                                       id={`on-checkbox-area${i}`}
                                                                       className={"form-check-input mx-3"}
                                                                       type={"checkbox"}
                                                                       defaultChecked={rowItem?.enabled}
                                                                       onChange={() => {
                                                                           recordRecordEdit("area", i);
                                                                       }}/>
                                                            </div>
                                                        </td>
                                                        <td className={"text-center"}>
                                                            <i className={"fe fe-settings as-btn"} onClick={() => {
                                                                setCareflowId(() => rowItem.careflow_id);
                                                                setRowIndex(() => i);
                                                                showRefModal("area", i);
                                                            }}/>
                                                        </td>
                                                        <td className={"text-center"} style={{maxWidth: "225px"}}>
                                                            <input className={"form-control"} type={"text"}
                                                                   style={{minWidth: "175px"}}
                                                                   name={`careflow-description-area${i}`}
                                                                   id={`careflow-description-area${i}`}
                                                                   defaultValue={rowItem?.careflow_description}
                                                                   pattern={"[a-zA-Z0-9_ \\-\\/]+"}
                                                                   onChange={() => {
                                                                       recordRecordEdit("area", i);
                                                                   }}/>
                                                            <div className={"invalid-feedback"}>
                                                                Enter a valid description. Characters, numbers, and - _
                                                                / characters allowed.
                                                            </div>
                                                            <input className={"d-none"} type={"hidden"}
                                                                   id={`careflow-event-type-area${i}`}
                                                                   value={rowItem?.event_category_id}/>
                                                        </td>
                                                        <td className={"text-center"}>
                                                            <div className={"d-inline-block"}>
                                                                <input style={{height: "1.25rem", width: "1.25rem"}}
                                                                       name={`allday-checkbox-area${i}`}
                                                                       id={`allday-checkbox-area${i}`}
                                                                       className={"form-check-input mx-3"}
                                                                       type={"checkbox"}
                                                                       defaultChecked={rowItem?.enable_start === 0 && rowItem?.enable_end === 86400}
                                                                       onChange={(e) => {
                                                                           let startItem, endItem;
                                                                           startItem = document.getElementById(`start-time-area${i}`);
                                                                           endItem = document.getElementById(`end-time-area${i}`);
                                                                           if (e.target.checked) {
                                                                               startItem.value = "00:00";
                                                                               startItem.readOnly = true;
                                                                               endItem.value = "23:59";
                                                                               endItem.step = "0";
                                                                               endItem.readOnly = true;
                                                                           } else {
                                                                               startItem.readOnly = false;
                                                                               endItem.readOnly = false;
                                                                               startItem.value = convertSectoHHMM(rowItem?.enable_start);
                                                                               endItem.value = convertSectoHHMM(rowItem?.enable_end);
                                                                               if (rowItem?.enable_end === 86400) {
                                                                                   endItem.step = "0"
                                                                               } else {
                                                                                   endItem.step = "300"
                                                                                   endItem.stepUp()
                                                                               }
                                                                           }
                                                                           recordRecordEdit("area", i);
                                                                       }}/>
                                                            </div>
                                                        </td>
                                                        <td className={"text-center"}>
                                                            <div className={"me-3"} style={{maxWidth: "185px"}}>
                                                                <input className={"form-control"} type={"time"}
                                                                       id={`start-time-area${i}`}
                                                                       defaultValue={rowItem?.enable_start ? convertSectoHHMM(rowItem.enable_start) : "00:00"}
                                                                       readOnly={rowItem?.enable_start === 0 && rowItem?.enable_end === 86400}
                                                                       step={"300"} min={"00:00"} max={"23:59"}
                                                                       onFocus={(e) => {
                                                                           e.target.showPicker();
                                                                       }}
                                                                       onBlur={(e) => {
                                                                           if (!e.target.checkValidity()) {
                                                                               e.target.stepUp();
                                                                           }
                                                                           recordRecordEdit("area", i);
                                                                       }}/>
                                                                <div className={"invalid-feedback"}>
                                                                    Time required, must be in 5 minute increments.
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className={"text-center"}>
                                                            <div className={"me-3"} style={{maxWidth: "185px"}}>
                                                                <input className={"form-control"} type={"time"}
                                                                       id={`end-time-area${i}`}
                                                                       defaultValue={rowItem?.enable_end !== 86400 ? convertSectoHHMM(rowItem.enable_end) : "23:59"}
                                                                       readOnly={rowItem?.enable_start === 0 && rowItem?.enable_end === 86400}
                                                                       step={![86400, 86340].includes(rowItem?.enable_end) ? "300" : "0"}
                                                                       min={"00:00"} max={"23:59"}
                                                                       onFocus={(e) => {
                                                                           e.target.showPicker();
                                                                       }}
                                                                       onBlur={(e) => {
                                                                           if (e.target.value === "23:59") {
                                                                               e.target.step = "0";
                                                                           } else {
                                                                               e.target.step = "300";
                                                                           }
                                                                           if (!e.target.checkValidity()) {
                                                                               e.target.stepUp();
                                                                           }
                                                                           recordRecordEdit("area", i);
                                                                       }}/>
                                                                <div className={"invalid-feedback"}>
                                                                    Time required, must be in 5 minute increments.
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {globalOutputs.includes("palmobile")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`palmobile-on-area${i}`}
                                                                           id={`palmobile-on-area${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.palmobile?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("area", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputModal("palmobile", i);
                                                                                   if (document.getElementById(`mute-on-area${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-area${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`palmobile-icon-area${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`palmobile-on-area${i}`).checked && showOutputModal("palmobile", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("text")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`text-on-area${i}`} id={`text-on-area${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.textmessages?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("area", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputPhoneModal("textmessages", i);
                                                                                   if (document.getElementById(`mute-on-area${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-area${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`text-icon-area${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`text-on-area${i}`).checked && showOutputPhoneModal("textmessages", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("voice")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`voice-on-area${i}`}
                                                                           id={`voice-on-area${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.voicemessages?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("area", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputPhoneModal("voicemessages", i);
                                                                                   if (document.getElementById(`mute-on-area${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-area${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`voice-icon-area${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`voice-on-area${i}`).checked && showOutputPhoneModal("voicemessages", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("email")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`email-on-area${i}`}
                                                                           id={`email-on-area${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.email?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("area", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputEmailModal("email", i);
                                                                                   if (document.getElementById(`mute-on-area${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-area${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`email-icon-area${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`email-on-area${i}`).checked && showOutputEmailModal("email", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("pager")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`pager-on-area${i}`}
                                                                           id={`pager-on-area${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.pager?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("area", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputModal("pager", i);
                                                                                   if (document.getElementById(`mute-on-area${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-area${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`pager-icon-area${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`pager-on-area${i}`).checked && showOutputModal("pager", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("serial")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`serial-on-area${i}`}
                                                                           id={`serial-on-area${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.serial?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("area", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputModal("serial", i);
                                                                                   if (document.getElementById(`mute-on-area${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-area${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`serial-icon-area${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`serial-on-area${i}`).checked && showOutputModal("serial", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("offsite")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`offsite-on-area${i}`}
                                                                           id={`offsite-on-area${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.offsitemonitoring?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("area", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputTimeModal("offsitemonitoring", i);
                                                                                   if (document.getElementById(`mute-on-area${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-area${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`offsite-icon-area${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`offsite-on-area${i}`).checked && showOutputTimeModal("offsitemonitoring", i)
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        <td className={"text-center d-none"}>
                                                            <div>
                                                                <input style={{height: "1.25rem", width: "1.25rem"}}
                                                                       name={`reporting-area${i}`}
                                                                       id={`reporting-area${i}`}
                                                                       className={"form-check-input mx-2"}
                                                                       defaultChecked={rowItem?.outputs?.reporting?.enabled}
                                                                       type={"checkbox"}
                                                                       onChange={(e) => {
                                                                           recordRecordEdit("area", i);
                                                                           if (e.target.checked){
                                                                               if (document.getElementById(`mute-on-area${i}`).checked === true){
                                                                                   document.getElementById(`mute-on-area${i}`).click();
                                                                               }
                                                                           }
                                                                       }}/>
                                                            </div>
                                                        </td>
                                                        <td className={"text-center"}>
                                                            <div>
                                                                <input style={{height: "1.25rem", width: "1.25rem"}}
                                                                       name={`mute-on-area${i}`}
                                                                       id={`mute-on-area${i}`}
                                                                       className={"form-check-input mx-2"}
                                                                       defaultChecked={rowItem?.outputs?.mute?.enabled}
                                                                       type={"checkbox"}
                                                                       onChange={(e) => {
                                                                           recordRecordEdit("area", i);
                                                                           if (e.target.checked === true){
                                                                               document.getElementById(`reporting-area${i}`).checked = false;
                                                                               if (globalOutputs.includes("offsite")){
                                                                                   document.getElementById(`offsite-on-area${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("serial")){
                                                                                   document.getElementById(`serial-on-area${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("pager")){
                                                                                   document.getElementById(`pager-on-area${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("email")){
                                                                                   document.getElementById(`email-on-area${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("voice")){
                                                                                   document.getElementById(`voice-on-area${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("text")){
                                                                                   document.getElementById(`text-on-area${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("palmobile")){
                                                                                   document.getElementById(`palmobile-on-area${i}`).checked = false;
                                                                               }
                                                                           }
                                                                       }}/>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            } else {
                                                return <></>
                                            }
                                        })}
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"card"}>
                <div className={"card-header d-flex justify-content-between h-auto"}>
                    <div>
                        <h4 className={"card-header-title d-inline-block"} style={{minWidth: "225px"}}>Default
                            Emergency</h4>
                        <div className={"ms-2"} style={{display: "inline-block"}} id={"default-header-data"}>
                            <h3 className={"text-success m-0"}>{dataLength(1, "event_category_id")} Profiles</h3>
                        </div>
                    </div>
                    <div>
                        <Button type={"button"} class={"btn btn-primary d-none me-5"} disabled={true} spinner={true}
                                text={"Loading"} id={"loadEditSubmit-default"}/>
                        <Button id={"editSubmit-default"} style={{width: "75px"}} text={"Save"} class={"btn btn-primary me-5 d-none"} onClick={() => performSave("default")} />
                        <i className={"fe fe-plus as-btn collapse-toggle p-2"} style={{verticalAlign: "middle"}} id={"card-btn-default"}
                                       onClick={() => {
                                           toggleCollapse("card-btn-default", "card-body-default");
                                           document.getElementById("default-header-data").classList.toggle("d-none");
                                       }}></i>
                    </div>
                </div>
                <div className={"card-body collapse collapse-content"} id={"card-body-default"}>
                    <div className={"list-group list-group-flush my-n3"}>
                        <div className={"list-group-item"}>
                            <Button text={"Add"} icon={true} class={"btn btn-primary mb-3 float-end"} style={{height: "40.5px"}}
                                    iconClass={"fe fe-plus me-2"} onClick={() => {
                                showAddModalHandler("default");
                                document.getElementById("add-escalation-type").value = "default";
                            }}/>
                            <form id={"mainform-default"} className={"was-validated"}>
                                <table className={"table"}>
                                    <thead>
                                        <tr>
                                            <th className={"text-center"} scope={"col"}></th>
                                            <th className={"text-center"} scope={"col"}>Enable</th>
                                            <th className={"text-center"} scope={"col"}>Description</th>
                                            <th className={"text-center"} scope={"col"}>All{<br/>}Day</th>
                                            <th className={"text-center"} scope={"col"}>Active Start</th>
                                            <th className={"text-center"} scope={"col"}>Active End</th>
                                            {globalOutputs.includes("palmobile")
                                                ? <th className={"text-center"} scope={"col"}>Mobile</th>
                                                : <></>
                                            }
                                            {globalOutputs.includes("text")
                                                ? <th className={"text-center"} scope={"col"}>Text</th>
                                                : <></>
                                            }
                                            {globalOutputs.includes("voice")
                                                ? <th className={"text-center"} scope={"col"}>Voice</th>
                                                : <></>
                                            }
                                            {globalOutputs.includes("email")
                                                ? <th className={"text-center"} scope={"col"}>Email</th>
                                                : <></>
                                            }
                                            {globalOutputs.includes("pager")
                                                ? <th className={"text-center"} scope={"col"}>Pager</th>
                                                : <></>
                                            }
                                            {globalOutputs.includes("serial")
                                                ? <th className={"text-center"} scope={"col"}>Serial Out</th>
                                                : <></>
                                            }
                                            {globalOutputs.includes("offsite")
                                                ? <th className={"text-center"} scope={"col"}>Offsite Monitoring</th>
                                                : <></>
                                            }
                                            <th className={"text-center d-none"} scope={"col"}>Reporting</th>
                                            <th className={"text-center"} scope={"col"}>Mute</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {escData.map((rowItem, i) => {
                                            if (rowItem?.escalation_type_id === 1 && rowItem?.event_category_id === 1){
                                                return (
                                                    <tr key={`custom11-row-${rowItem.careflow_id}`}>
                                                        {defaultCnt > 1
                                                            ? <td className={"text-center"}
                                                                  style={{minWidth: "50px", maxWidth: "100px"}}>
                                                                <div className={"d-inline-block"}>
                                                                    <i className={"fe fe-trash-2 as-btn red-hover"}
                                                                       onClick={() => {
                                                                           setDeleteId(() => rowItem.careflow_id);
                                                                           showModal("deleteconfirmmodal");
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <td className={"text-center"}
                                                                  style={{minWidth: "75px", maxWidth: "100px"}}></td>
                                                        }
                                                        <td className={"text-center"}
                                                            style={{minWidth: "75px", maxWidth: "100px"}}>
                                                            <div className={"d-inline-block"}>
                                                                <input style={{height: "1.25rem", width: "1.25rem"}}
                                                                       name={`on-checkbox-default${i}`}
                                                                       id={`on-checkbox-default${i}`}
                                                                       className={"form-check-input mx-3"}
                                                                       type={"checkbox"}
                                                                       checked={rowItem?.enabled} readOnly={true}
                                                                       disabled={true}/>
                                                            </div>
                                                        </td>
                                                        <td className={"text-center"} style={{maxWidth: "225px"}}>
                                                            <input className={"form-control"} type={"text"}
                                                                   style={{minWidth: "175px"}}
                                                                   name={`careflow-description-default${i}`}
                                                                   id={`careflow-description-default${i}`}
                                                                   defaultValue={rowItem?.careflow_description}
                                                                   pattern={"[a-zA-Z0-9_ \\-\\/]+"}
                                                                   onChange={() => {
                                                                       recordRecordEdit("default", i);
                                                                   }}/>
                                                            <div className={"invalid-feedback"}>
                                                                Enter a valid description. Characters, numbers, and - _
                                                                / characters allowed.
                                                            </div>
                                                            <input className={"d-none"} type={"hidden"}
                                                                   id={`careflow-event-type-default${i}`}
                                                                   value={rowItem?.event_category_id}/>
                                                        </td>
                                                        <td className={"text-center"}>
                                                            <div className={"d-inline-block"}>
                                                                <input style={{height: "1.25rem", width: "1.25rem"}}
                                                                       name={`allday-checkbox-default${i}`}
                                                                       id={`allday-checkbox-default${i}`}
                                                                       className={"form-check-input mx-3"}
                                                                       type={"checkbox"}
                                                                       defaultChecked={rowItem?.enable_start === 0 && rowItem?.enable_end === 86400}
                                                                       onChange={(e) => {
                                                                           let startItem, endItem;
                                                                           startItem = document.getElementById(`start-time-default${i}`);
                                                                           endItem = document.getElementById(`end-time-default${i}`);
                                                                           if (e.target.checked) {
                                                                               startItem.value = "00:00";
                                                                               startItem.readOnly = true;
                                                                               endItem.value = "23:59";
                                                                               endItem.step = "0"
                                                                               endItem.readOnly = true;
                                                                           } else {
                                                                               startItem.readOnly = false;
                                                                               endItem.readOnly = false;
                                                                               startItem.value = convertSectoHHMM(rowItem?.enable_start);
                                                                               endItem.value = rowItem?.enable_end !== 86400 ? convertSectoHHMM(rowItem?.enable_end) : "23:59";
                                                                               if (rowItem?.enable_end === 86400) {
                                                                                   endItem.step = "0"
                                                                               } else {
                                                                                   endItem.step = "300"
                                                                                   endItem.stepUp()
                                                                               }
                                                                           }
                                                                           recordRecordEdit("default", i);
                                                                       }}/>
                                                            </div>
                                                        </td>
                                                        <td className={"text-center"}>
                                                            <div className={"me-3"} style={{maxWidth: "185px"}}>
                                                                <input className={"form-control"} type={"time"}
                                                                       id={`start-time-default${i}`}
                                                                       defaultValue={rowItem?.enable_start ? convertSectoHHMM(rowItem.enable_start) : "00:00"}
                                                                       readOnly={rowItem?.enable_start === 0 && rowItem?.enable_end === 86400}
                                                                       step={"300"} min={"00:00"} max={"23:59"}
                                                                       onFocus={(e) => {
                                                                           e.target.showPicker();
                                                                       }}
                                                                       onBlur={(e) => {
                                                                           if (!e.target.checkValidity()) {
                                                                               e.target.stepUp();
                                                                           }
                                                                           recordRecordEdit("default", i);
                                                                       }}/>
                                                                <div className={"invalid-feedback"}>
                                                                    Time required, must be in 5 minute increments.
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className={"text-center"}>
                                                            <div className={"me-3"} style={{maxWidth: "185px"}}>
                                                                <input className={"form-control"} type={"time"}
                                                                       id={`end-time-default${i}`}
                                                                       defaultValue={rowItem?.enable_end !== 86400 ? convertSectoHHMM(rowItem.enable_end) : "23:59"}
                                                                       readOnly={rowItem?.enable_start === 0 && rowItem?.enable_end === 86400}
                                                                       step={![86400, 86340].includes(rowItem?.enable_end) ? "300" : "0"}
                                                                       min={"00:00"} max={"23:59"}
                                                                       onFocus={(e) => {
                                                                           e.target.showPicker();
                                                                       }}
                                                                       onBlur={(e) => {
                                                                           if (e.target.value === "23:59") {
                                                                               e.target.step = "0";
                                                                           } else {
                                                                               e.target.step = "300";
                                                                           }
                                                                           if (!e.target.checkValidity()) {
                                                                               e.target.stepUp();
                                                                           }
                                                                           recordRecordEdit("default", i);
                                                                       }}/>
                                                                <div className={"invalid-feedback"}>
                                                                    Time required, must be in 5 minute increments.
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {globalOutputs.includes("palmobile")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`palmobile-on-default${i}`}
                                                                           id={`palmobile-on-default${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.palmobile?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("default", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputModal("palmobile", i);
                                                                                   if (document.getElementById(`mute-on-default${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-default${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`palmobile-icon-default${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`palmobile-on-default${i}`).checked && showOutputModal("palmobile", i, "default")
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("text")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`text-on-default${i}`}
                                                                           id={`text-on-default${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.textmessages?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("default", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputPhoneModal("textmessages", i);
                                                                                   if (document.getElementById(`mute-on-default${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-default${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`text-icon-default${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`text-on-default${i}`).checked && showOutputPhoneModal("textmessages", i, "default")
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("voice")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`voice-on-default${i}`}
                                                                           id={`voice-on-default${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.voicemessages?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("default", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputPhoneModal("voicemessages", i);
                                                                                   if (document.getElementById(`mute-on-default${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-default${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`voice-icon-default${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`voice-on-default${i}`).checked && showOutputPhoneModal("voicemessages", i, "default")
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("email")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`email-on-default${i}`}
                                                                           id={`email-on-default${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.email?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("default", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputEmailModal("email", i);
                                                                                   if (document.getElementById(`mute-on-default${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-default${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`email-icon-default${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`email-on-default${i}`).checked && showOutputEmailModal("email", i, "default")
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("pager")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`pager-on-default${i}`}
                                                                           id={`pager-on-default${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.pager?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("default", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputModal("pager", i);
                                                                                   if (document.getElementById(`mute-on-default${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-default${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`pager-icon-default${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`pager-on-default${i}`).checked && showOutputModal("pager", i, "default")
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("serial")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`serial-on-default${i}`}
                                                                           id={`serial-on-default${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.serial?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("default", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputModal("serial", i);
                                                                                   if (document.getElementById(`mute-on-default${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-default${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`serial-icon-default${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`serial-on-default${i}`).checked && showOutputModal("serial", i, "default")
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {globalOutputs.includes("offsite")
                                                            ? <td className={"text-center"}>
                                                                <div className={"d-inline-block"}>
                                                                    <input style={{
                                                                        height: "1.25rem",
                                                                        width: "1.25rem",
                                                                        verticalAlign: "sub"
                                                                    }} name={`offsite-on-default${i}`}
                                                                           id={`offsite-on-default${i}`}
                                                                           className={"form-check-input mx-2"}
                                                                           defaultChecked={rowItem?.outputs?.offsitemonitoring?.enabled}
                                                                           type={"checkbox"}
                                                                           onChange={(e) => {
                                                                               recordRecordEdit("default", i);
                                                                               if (e.target.checked) {
                                                                                   showOutputTimeModal("offsitemonitoring", i);
                                                                                   if (document.getElementById(`mute-on-default${i}`).checked === true){
                                                                                       document.getElementById(`mute-on-default${i}`).click();
                                                                                   }
                                                                               }
                                                                           }}/>
                                                                    <i className={"fe fe-settings as-btn mx-2"}
                                                                       style={{verticalAlign: "sub"}}
                                                                       id={`offsite-icon-default${i}`}
                                                                       onClick={() => {
                                                                           document.getElementById(`offsite-on-default${i}`).checked && showOutputTimeModal("offsitemonitoring", i, "default")
                                                                       }}></i>
                                                                </div>
                                                            </td>
                                                            : <></>
                                                        }
                                                        <td className={"text-center d-none"}>
                                                            <div>
                                                                <input style={{height: "1.25rem", width: "1.25rem"}}
                                                                       name={`reporting-default${i}`}
                                                                       id={`reporting-default${i}`}
                                                                       className={"form-check-input mx-2"}
                                                                       defaultChecked={rowItem?.outputs?.reporting?.enabled}
                                                                       type={"checkbox"}
                                                                       onChange={(e) => {
                                                                           recordRecordEdit("default", i);
                                                                           if (e.target.checked){
                                                                               if (document.getElementById(`mute-on-default${i}`).checked === true){
                                                                                   document.getElementById(`mute-on-default${i}`).click();
                                                                               }
                                                                           }
                                                                       }}/>
                                                            </div>
                                                        </td>
                                                        <td className={"text-center"}>
                                                            <div>
                                                                <input style={{height: "1.25rem", width: "1.25rem"}}
                                                                       name={`mute-on-default${i}`}
                                                                       id={`mute-on-default${i}`}
                                                                       className={"form-check-input mx-2"}
                                                                       defaultChecked={rowItem?.outputs?.mute?.enabled}
                                                                       type={"checkbox"}
                                                                       onChange={(e) => {
                                                                           recordRecordEdit("default", i);
                                                                           if (e.target.checked === true){
                                                                               document.getElementById(`reporting-default${i}`).checked = false;
                                                                               if (globalOutputs.includes("offsite")){
                                                                                   document.getElementById(`offsite-on-default${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("serial")){
                                                                                   document.getElementById(`serial-on-default${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("pager")){
                                                                                   document.getElementById(`pager-on-default${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("email")){
                                                                                   document.getElementById(`email-on-default${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("voice")){
                                                                                   document.getElementById(`voice-on-default${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("text")){
                                                                                   document.getElementById(`text-on-default${i}`).checked = false;
                                                                               }
                                                                               if (globalOutputs.includes("palmobile")){
                                                                                   document.getElementById(`palmobile-on-default${i}`).checked = false;
                                                                               }
                                                                           }
                                                                       }}/>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            } else {
                                                return <></>
                                            }
                                        })}
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"outputmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title text-capitalize"} id={"staticBackdropLabel"}>Assign Caregiver
                                Groups ({staticLabel})</h2>
                            <button type={"button"} className={"btn-close"} id={"outputClose"} data-bs-dismiss={"modal"}
                                    aria-label={"Close"}/>
                        </div>
                        <form id={"outputForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3"}>
                                    <input type={"number"} className={"form-control"} id={"messageTime"}
                                           min={0} max={120} step={1} required={true}
                                           title={"0 = Immediate Send, 1-60 = Minutes from alarm generation when notification will send."}
                                           onKeyUp={() => formCheck("outputForm", "outputSubmit")}/>
                                    <label htmlFor={"messageTime"}>Message After/Mins</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter number of minutes 0 - 120
                                    </div>
                                </div>
                                <div className={"d-flex justify-content-start mt-4"} id={"repeatContainer"}>
                                    <div className={"form-check form-switch w-50 ps-0 pt-2"}>
                                        <input name={`repeatEnabled`} id={`repeatEnabled`}
                                               className={"form-check-input mx-2"} type={"checkbox"}
                                               onChange={(e) => {
                                                   let repeatItem = document.getElementById("repeatTime")
                                                   if (e.target.checked === true) {
                                                       repeatItem.readOnly = false;
                                                       repeatItem.required = true;
                                                   } else {
                                                       repeatItem.value = 0;
                                                       repeatItem.readOnly = true;
                                                       repeatItem.required = false;
                                                   }
                                                   formCheck("outputForm", "outputSubmit");
                                               }}/>
                                        <label htmlFor={"repeatEnabled"}>Send Repeats</label>
                                    </div>
                                    <div className={"form-floating mb-3 w-100"}>
                                        <input type={"number"} className={"form-control"} id={"repeatTime"}
                                               min={0} max={60} step={1} required={true}
                                               title={"Interval at which repeat messages will go out, 0 = no repeats"}
                                               onKeyUp={() => formCheck("outputForm", "outputSubmit")}/>
                                        <label htmlFor={"repeatTime"}>Repeat Message Interval</label>
                                        <div className={"invalid-feedback"}>
                                            Please enter number of minutes to send repeat notifications, 0 = no repeats.
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div id={"groupsdiv"} className={"mt-1"}>
                                    <h3>Groups</h3>
                                    <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                    <input type={"search"} id={"search3E"} className={"form-control search-input"}
                                               value={search3}
                                               onChange={(e) => setSearch3(e.target.value)}/>
                                        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                    </div>
                                    <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                        <table className={"table table-sm table-white table-hover"}>
                                            <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                            {groupTableHead}
                                            </thead>
                                            <tbody>
                                            {groupTableRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelOutputBtn"}
                                            data-bs-dismiss={"modal"}>Cancel
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Loading"} id={"loadOutputSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"outputSubmit"}
                                            onClick={() => performOutputSave()}>Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"outputtimemodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title text-capitalize"} id={"staticBackdropLabel"}>Set Operation Time ({staticLabel})</h2>
                            <button type={"button"} className={"btn-close"} id={"outputTimeClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"outputTimeForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3"}>
                                    <input type={"number"} className={"form-control"} id={"messageTime0"}
                                           min={0} max={120} step={1} required={true} title={"0 = Immediate Send, 1-60 = Minutes from alarm generation when notification will send."}
                                           onKeyUp={() => formCheck("outputTimeForm", "outputTimeSubmit")}/>
                                    <label htmlFor={"messageTime0"}>Message After/Mins</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter number of minutes 0 - 120
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelOutputTimeBtn"}  data-bs-dismiss={"modal"}>Cancel</button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadOutputTimeSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"outputTimeSubmit"} onClick={() => performOutputTimeSave()}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"outputphonemodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title text-capitalize"} id={"staticBackdropLabel"}>Assign Phone Numbers ({staticLabel})</h2>
                            <button type={"button"} className={"btn-close"} id={"outputPhoneClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"outputPhoneForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3"}>
                                    <input type={"number"} className={"form-control"} id={"messageTime2"}
                                           min={0} max={120} step={1} required={true} title={"0 = Immediate Send, 1-60 = Minutes from alarm generation when notification will send."}
                                           onKeyUp={() => formCheck("outputPhoneForm", "outputPhoneSubmit")}/>
                                    <label htmlFor={"messageTime2"}>Message After/Mins</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter number of minutes 0 - 120
                                    </div>
                                </div>
                                <hr />
                                <div className={"list-group-item"}>
                                    <Button type={"button"} class={"btn btn-primary mb-3"} text={"New Number"} onClick={() => {
                                        setNewNumbers((prevState) => {return [...prevState, {id: uuidv4(), number: ""}]});
                                    }} />
                                    {newNumbers.map((item) => {
                                        return (
                                        <div key={`new-phone-item${item.id}`}>
                                            <i className={"fe fe-trash as-btn d-inline-block red-hover"} onClick={() => {
                                                setNewNumbers((prevState) => {
                                                    return prevState.filter(i => i.id !== item.id);
                                                })
                                            }}></i>
                                            <div className={"form-floating d-inline-block w-75 mb-3 mx-3"}>
                                                <input type={"text"} name={"phone-input"} className={"form-control"} id={`new-phone-${item.id}`}
                                                       onKeyUp={(e) => {
                                                           setNewNumbers((prevState) => {
                                                               return prevState.map((i) => {
                                                                   if (i.id === item.id){
                                                                       return {id: i.id, number: e.target.value};
                                                                   }
                                                                   return i;
                                                               });
                                                           });
                                                           formCheck("outputPhoneForm", "outputPhoneSubmit");
                                                       }} required={true}
                                                       pattern={"[0-9]{3}-?[0-9]{3}-?[0-9]{4}"} defaultValue={item.number} />
                                                <label htmlFor={`tmp-phone-${item.id}`}>Phone Number</label>
                                            </div>
                                        </div>)
                                    })}
                                </div>
                                <hr />
                                <div className={"list-group-item"}>
                                    {curNumbers.map((item) => {
                                        return (
                                            <div key={`stored-phone-${item.id}`}>
                                                <i className={"fe fe-trash as-btn d-inline-block red-hover"} onClick={() => {
                                                    setCurNumbers((prevState) => {
                                                        return prevState.filter(i => i?.id !== item.id);
                                                        })
                                                    }}></i>
                                                <div className={"form-floating d-inline-block w-75 mb-3 mx-3"}>
                                                    <input type={"text"} name={"phone-input"} className={"form-control"} id={`tmp-phone-${item.id}`} required={true}
                                                           pattern={"[0-9]{3}-?[0-9]{3}-?[0-9]{4}"} value={item.number} readOnly={true} />
                                                    <label htmlFor={`tmp-phone-${item.id}`}>Phone Number</label>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelOutputPhoneBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadOutputPhoneSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"outputPhoneSubmit"} onClick={() => performOutputPhoneSave()}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"outputemailmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title text-capitalize"} id={"staticBackdropLabel"}>Assign Email Address</h2>
                            <button type={"button"} className={"btn-close"} id={"outputEmailClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"outputEmailForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3"}>
                                    <input type={"number"} className={"form-control"} id={"messageTime3"}
                                           min={0} max={120} step={1} required={true} title={"0 = Immediate Send, 1-60 = Minutes from alarm generation when notification will send."}
                                           onKeyUp={() => formCheck("outputEmailForm", "outputEmailSubmit")}/>
                                    <label htmlFor={"messageTime3"}>Message After/Mins</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter number of minutes 0 - 120
                                    </div>
                                </div>
                                <hr />
                                <div className={"list-group-item"}>
                                    <Button type={"button"} class={"btn btn-primary mb-3"} text={"New Email"} onClick={() => {
                                        setNewEmails((prevState) => {return [...prevState, {id: uuidv4(), email: ""}]});
                                    }} />
                                    {newEmails.map((item) => {
                                        return (
                                        <div key={`new-email-item${item.id}`}>
                                            <i className={"fe fe-trash as-btn d-inline-block red-hover"} onClick={() => {
                                                setNewEmails((prevState) => {
                                                    return prevState.filter(i => i.id !== item.id);
                                                });
                                            }}></i>
                                            <div className={"form-floating d-inline-block w-75 mb-3 mx-3"}>
                                                <input type={"text"} name={"email-input"} className={"form-control"} id={`new-email-${item.id}`}
                                                       onKeyUp={(e) => {
                                                           setNewEmails((prevState) => {
                                                               return prevState.map((i) => {
                                                                   if (i.id === item.id){
                                                                       return {id: i.id, email: e.target.value};
                                                                   }
                                                                   return i;
                                                               });
                                                           })
                                                           formCheck("outputEmailForm", "outputEmailSubmit");
                                                       }} required={true}
                                                       pattern={"[a-z0-9._%+\\-]+@[a-z0-9.\\-]+\\.[a-z]{2,}$"} defaultValue={item.email} />
                                                <label htmlFor={`new-email-${item.id}`}>Email Address</label>
                                            </div>
                                        </div>);
                                    })}
                                </div>
                                <hr />
                                <div className={"list-group-item"}>
                                    {curEmails.map((item) => {
                                        return (
                                            <div key={`stored-email-${item.id}`}>
                                                <i className={"fe fe-trash as-btn d-inline-block red-hover"} onClick={() => {
                                                    setCurEmails((prevState) => {
                                                        return prevState.filter(i => i?.id !== item.id);
                                                        })
                                                    }}></i>
                                                <div className={"form-floating d-inline-block w-75 mb-3 mx-3"}>
                                                    <input type={"text"} name={"email-input"} className={"form-control"} id={`tmp-email-${item.id}`} required={true}
                                                           pattern={"[a-z0-9._%+\\-]+@[a-z0-9.\\-]+\\.[a-z]{2,}$"} value={item.email} readOnly={true} />
                                                    <label htmlFor={`tmp-email-${item.id}`}>Email Address</label>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelOutputEmailBtn"}  data-bs-dismiss={"modal"}>Cancel</button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadOutputEmailSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"outputEmailSubmit"} onClick={() => performOutputEmailSave()}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"addmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title text-capitalize"} id={"staticBackdropLabel"}>New Careflow</h2>
                            <button type={"button"} className={"btn-close"} id={"addClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"addForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <input className={"d-none"} defaultValue={""} id={"add-escalation-type"}/>
                                <div className={"form-floating mb-3"} id={"add-event-type-div"}>
                                    <select className={"form-select"} id={"add-event-type"}
                                            onChange={() => formCheck("addForm", "addSubmit")} required={true}>
                                        {escTypeOptions}
                                    </select>
                                    <label htmlFor={"add-event-type"}>Active Alert Type</label>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"add-description"}
                                           onKeyUp={() => formCheck("addForm", "addSubmit")} required={true}
                                           pattern={"[a-zA-Z0-9_ \\-\\/]+"}/>
                                    <label htmlFor={"add-description"}>Description</label>
                                    <div className={"invalid-feedback"}>
                                        Enter a valid description. Characters, numbers, and - _ / characters allowed.
                                    </div>
                                </div>
                                <div className={"list-group-item py-3"} id={"add-time-pick-div"}>
                                    <div>
                                        <h3 className={"d-inline-block me-4"}>All Day</h3>
                                        <div className={"d-inline-block form-check form-switch align-middle"}>
                                            <input name={"toggle-startend"} id={"toggle-startend"}
                                                   defaultChecked={false}
                                                   className={"form-check-input"} type={"checkbox"} onClick={(e) => {
                                                let start, end, block;
                                                start = document.getElementById("add-starttime");
                                                end = document.getElementById("add-endtime");
                                                block = document.getElementById("time-div");
                                                if (e.target.checked) {
                                                    start.value = "00:00";
                                                    end.value = "23:59";
                                                    start.readOnly = true;
                                                    end.readOnly = true;
                                                    block.classList.add("d-none");
                                                } else {
                                                    start.value = "";
                                                    end.value = "";
                                                    start.readOnly = false;
                                                    end.readOnly = false;
                                                    block.classList.remove("d-none");
                                                }
                                                formCheck("addForm", "addSubmit");
                                            }}/>
                                        </div>
                                    </div>
                                    <div className={"d-flex justify-content-around"} id={"time-div"}>
                                        <div className={"form-floating mb-3 d-inline-block"} style={{width: "49%"}}>
                                            <input type={"time"} className={"form-control"} id={"add-starttime"}
                                                   required={true} step={"300"} min={"00:00"} max={"23:59"}
                                                   onFocus={(e) => {
                                                       e.target.showPicker();
                                                   }}
                                                   onBlur={(e) => {
                                                       if (!e.target.checkValidity()) {
                                                           e.target.stepUp();
                                                       }
                                                       formCheck("addForm", "addSubmit");
                                                   }}/>
                                            <label htmlFor={"add-starttime"}>Start Time</label>
                                            <div className={"invalid-feedback"}>
                                                Time required, must be in 5 minute increments.
                                            </div>
                                        </div>
                                        <div className={"form-floating mb-3 d-inline-block"} style={{width: "49%"}}>
                                            <input type={"time"} className={"form-control"} id={"add-endtime"}
                                                   required={true} step={"300"} min={"00:00"} max={"23:59"}
                                                   onFocus={(e) => {
                                                       e.target.showPicker();
                                                   }}
                                                   onBlur={(e) => {
                                                       if (e.target.value === "23:59") {
                                                           e.target.step = "0";
                                                       } else {
                                                           e.target.step = "300";
                                                       }
                                                       if (!e.target.checkValidity()) {
                                                           e.target.stepUp();
                                                       }
                                                       formCheck("addForm", "addSubmit")
                                                   }}/>
                                            <label htmlFor={"add-endtime"}>End Time</label>
                                            <div className={"invalid-feedback"}>
                                                Time required, must be in 5 minute increments.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {globalOutputs.includes("palmobile")
                                    ?
                                    <div className={"list-group-item py-3"}>
                                        <div>
                                            <h3 className={"d-inline-block"} style={{minWidth: "120px"}}>Palcare
                                                Mobile</h3>
                                            <div className={"d-inline-block form-check form-switch ms-5 align-middle"}>
                                                <input name={"add-palmobile-on"} id={"add-palmobile-on"}
                                                       defaultChecked={true}
                                                       className={"form-check-input"} type={"checkbox"}
                                                       onChange={(e) => {
                                                           addOutputHandler("palmobile", e.target.checked);
                                                           if (e.target.checked === true){
                                                               if (document.getElementById("add-mute-on").checked === true){
                                                                   document.getElementById("add-mute-on").click();
                                                               }
                                                           }
                                                       }}/>
                                            </div>
                                        </div>
                                        <div className={"form-floating d-inline-block w-50 me-5 align-middle"}>
                                            <input type={"number"} className={"form-control"} id={"add-palmobile-time"}
                                                   defaultValue={0}
                                                   onKeyUp={() => formCheck("addForm", "addSubmit")}
                                                   step={1} max={60} min={0}/>
                                            <label htmlFor={"add-palmobile-time"}>Message After/Mins</label>
                                            <div className={"invalid-feedback"}>
                                                Enter minutes 0 - 60 for when mobile will receive the message from alert
                                                start.
                                            </div>
                                        </div>
                                        <div className={"d-inline"} id={"add-palmobile-icon"}>
                                            <i className={"fe fe-settings as-btn align-bottom me-2"}
                                               id={"add-palmobile-groups"} onClick={() => {
                                                showGroupAddModal("palmobile");
                                            }}/>
                                            <h4 className={"d-inline-block align-bottom"}>{mobileEntities.length}/{groupData.length} selected</h4>
                                        </div>
                                        <div className={"form-floating d-block w-50 align-middle mb-3 mt-3"}>
                                            <input type={"number"} className={"form-control"}
                                                   id={"add-palmobile-repeat"}
                                                   min={0} max={60} step={1} defaultValue={5} required={true}
                                                   title={"Interval at which repeat messages will go out, 0 = no repeats"}
                                                   onKeyUp={() => formCheck("addForm", "addSubmit")}/>
                                            <label htmlFor={"add-palmobile-repeat"}>Repeat Message Interval
                                                (Min)</label>
                                            <div className={"invalid-feedback"}>
                                                Please enter number of minutes to send repeat notifications, 0 = no
                                                repeats.
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <div className={"d-none"} id={"add-palmobile-icon"}>
                                            <input type={"checkbox"} className={"d-none"} id={"add-palmobile-on"}
                                                   value={false} readOnly={true}/>
                                            <input type={"hidden"} className={"d-none"} id={"add-palmobile-time"}
                                                   value={0} readOnly={true}/>
                                            <input type={"hidden"} className={"d-none"} id={"add-palmobile-repeat"}
                                                   value={0} readOnly={true}/>
                                        </div>
                                    </>
                                }
                                {globalOutputs.includes("text")
                                    ?
                                    <div className={"list-group-item py-3"}>
                                        <div>
                                            <h3 className={"d-inline-block"} style={{minWidth: "120px"}}>Text
                                                Messaging</h3>
                                            <div className={"d-inline-block form-check form-switch ms-5 align-middle"}>
                                                <input name={"add-text-on"} id={"add-text-on"} defaultChecked={true}
                                                       className={"form-check-input"} type={"checkbox"}
                                                       onChange={(e) => {
                                                           addOutputHandler("text", e.target.checked);
                                                           if (e.target.checked === true){
                                                               if (document.getElementById("add-mute-on").checked === true){
                                                                   document.getElementById("add-mute-on").click();
                                                               }
                                                           }
                                                       }}/>
                                            </div>
                                        </div>
                                        <div className={"form-floating d-inline-block w-50 me-5"}>
                                            <input type={"number"} className={"form-control"} id={"add-text-time"}
                                                   defaultValue={0}
                                                   onKeyUp={() => formCheck("addForm", "addSubmit")} step={1} max={60}
                                                   min={0}/>
                                            <label htmlFor={"add-text-time"}>Message After/Mins</label>
                                            <div className={"invalid-feedback"}>
                                                Enter minutes 0 - 60 for when text messages go out from alert start.
                                            </div>
                                        </div>
                                        <div className={"d-inline"} id={"add-text-icon"}>
                                            <i className={"fe fe-settings as-btn align-bottom me-2"}
                                               id={"add-text-groups"} onClick={() => {
                                                showPhoneAddModal("text");
                                            }}/>
                                            <h4 className={"d-inline-block align-bottom"}>{textEntities.length} numbers
                                                set</h4>
                                        </div>
                                    </div>
                                    :
                                    <div className={"d-none"} id={"add-text-icon"}>
                                        <input type={"checkbox"} className={"d-none"} id={"add-text-on"} value={false}
                                               readOnly={true}/>
                                        <input type={"hidden"} className={"d-none"} id={"add-text-time"} value={0}
                                               readOnly={true}/>
                                    </div>
                                }
                                {globalOutputs.includes("voice")
                                    ?
                                    <div className={"list-group-item py-3"}>
                                        <div>
                                            <h3 className={"d-inline-block"} style={{minWidth: "120px"}}>Voice
                                                Calls</h3>
                                            <div className={"d-inline-block form-check form-switch ms-5 align-middle"}>
                                                <input name={"add-voice-on"} id={"add-voice-on"} defaultChecked={true}
                                                       className={"form-check-input"} type={"checkbox"}
                                                       onChange={(e) => {
                                                           addOutputHandler("voice", e.target.checked);
                                                           if (e.target.checked === true){
                                                               if (document.getElementById("add-mute-on").checked === true){
                                                                   document.getElementById("add-mute-on").click();
                                                               }
                                                           }
                                                       }}/>
                                            </div>
                                        </div>
                                        <div className={"form-floating d-inline-block w-50 me-5"}>
                                            <input type={"number"} className={"form-control"} id={"add-voice-time"}
                                                   defaultValue={0}
                                                   onKeyUp={() => formCheck("addForm", "addSubmit")} step={1} max={60}
                                                   min={0}/>
                                            <label htmlFor={"add-voice-time"}>Message After/Mins</label>
                                            <div className={"invalid-feedback"}>
                                                Enter minutes 0 - 60 for when a phone call will go out from alert start.
                                            </div>
                                        </div>
                                        <div className={"d-inline"} id={"add-voice-icon"}>
                                            <i className={"fe fe-settings as-btn align-bottom me-2"}
                                               id={"add-voice-groups"} onClick={() => {
                                                showPhoneAddModal("voice");
                                            }}/>
                                            <h4 className={"d-inline-block align-bottom"}>{voiceEntities.length} numbers
                                                set</h4>
                                        </div>
                                    </div>
                                    :
                                    <div className={"d-none"} id={"add-voice-icon"}>
                                        <input type={"checkbox"} className={"d-none"} id={"add-voice-on"} value={false}
                                               readOnly={true}/>
                                        <input type={"hidden"} className={"d-none"} id={"add-voice-time"} value={0}
                                               readOnly={true}/>
                                    </div>
                                }
                                {globalOutputs.includes("email")
                                    ?
                                    <div className={"list-group-item py-3"}>
                                        <div>
                                            <h3 className={"d-inline-block"} style={{minWidth: "120px"}}>Email</h3>
                                            <div className={"d-inline-block form-check form-switch ms-5 align-middle"}>
                                                <input name={"add-email-on"} id={"add-email-on"} defaultChecked={true}
                                                       className={"form-check-input"} type={"checkbox"}
                                                       onChange={(e) => {
                                                           addOutputHandler("email", e.target.checked);
                                                           if (e.target.checked === true){
                                                               if (document.getElementById("add-mute-on").checked === true){
                                                                   document.getElementById("add-mute-on").click();
                                                               }
                                                           }
                                                       }}/>
                                            </div>
                                        </div>
                                        <div className={"form-floating d-inline-block w-50 me-5"}>
                                            <input type={"number"} className={"form-control"} id={"add-email-time"}
                                                   defaultValue={0}
                                                   onKeyUp={() => formCheck("addForm", "addSubmit")} step={1} max={60}
                                                   min={0}/>
                                            <label htmlFor={"add-email-time"}>Message After/Mins</label>
                                            <div className={"invalid-feedback"}>
                                                Enter minutes 0 - 60 for when emails will get sent out from alert start.
                                            </div>
                                        </div>
                                        <div className={"d-inline"} id={"add-email-icon"}>
                                            <i className={"fe fe-settings as-btn align-bottom me-2"}
                                               id={"add-email-groups"} onClick={() => {
                                                showEmailAddModal();
                                            }}/>
                                            <h4 className={"d-inline-block align-bottom"}>{emailEntities.length} emails
                                                set</h4>
                                        </div>
                                    </div>
                                    :
                                    <div className={"d-none"} id={"add-email-icon"}>
                                        <input type={"checkbox"} className={"d-none"} id={"add-email-on"} value={false}
                                               readOnly={true}/>
                                        <input type={"hidden"} className={"d-none"} id={"add-email-time"} value={0}
                                               readOnly={true}/>
                                    </div>
                                }
                                {globalOutputs.includes("pager")
                                    ?
                                    <div className={"list-group-item py-3"}>
                                        <div>
                                            <h3 className={"d-inline-block"} style={{minWidth: "120px"}}>Pager</h3>
                                            <div className={"d-inline-block form-check form-switch ms-5 align-middle"}>
                                                <input name={"add-pager-on"} id={"add-pager-on"} defaultChecked={true}
                                                       className={"form-check-input"} type={"checkbox"}
                                                       onChange={(e) => {
                                                           addOutputHandler("pager", e.target.checked);
                                                           if (e.target.checked === true){
                                                               if (document.getElementById("add-mute-on").checked === true){
                                                                   document.getElementById("add-mute-on").click();
                                                               }
                                                           }
                                                       }}/>
                                            </div>
                                        </div>
                                        <div className={"form-floating d-inline-block w-50 me-5"}>
                                            <input type={"number"} className={"form-control"} id={"add-pager-time"}
                                                   defaultValue={0}
                                                   onKeyUp={() => formCheck("addForm", "addSubmit")} step={1} max={60}
                                                   min={0}/>
                                            <label htmlFor={"add-pager-time"}>Message After/Mins</label>
                                            <div className={"invalid-feedback"}>
                                                Enter minutes 0 - 60 for when pagers will get sent out from alert start.
                                            </div>
                                        </div>
                                        <div className={"d-inline"} id={"add-pager-icon"}>
                                            <i className={"fe fe-settings as-btn align-bottom me-2"}
                                               id={"add-pager-groups"} onClick={() => {
                                                showGroupAddModal("pager");
                                            }}/>
                                            <h4 className={"d-inline-block align-bottom"}>{pagerEntities.length}/{groupData.length} selected</h4>
                                        </div>
                                        <div className={"form-floating d-block w-50 align-middle mb-3 mt-3"}>
                                            <input type={"number"} className={"form-control"} id={"add-pager-repeat"}
                                                   min={0} max={60} step={1} defaultValue={3} required={true}
                                                   title={"Interval at which repeat messages will go out, 0 = no repeats"}
                                                   onKeyUp={() => formCheck("addForm", "addSubmit")}/>
                                            <label htmlFor={"add-pager-repeat"}>Repeat Message Interval (Min)</label>
                                            <div className={"invalid-feedback"}>
                                                Please enter number of minutes to send repeat notifications, 0 = no
                                                repeats.
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className={"d-none"} id={"add-pager-icon"}>
                                        <input type={"checkbox"} className={"d-none"} id={"add-pager-on"} value={false}
                                               readOnly={true}/>
                                        <input type={"hidden"} className={"d-none"} id={"add-pager-time"} value={0}
                                               readOnly={true}/>
                                        <input type={"hidden"} className={"d-none"} id={"add-pager-repeat"} value={0}
                                               readOnly={true}/>
                                    </div>
                                }
                                {globalOutputs.includes("offsite")
                                    ?
                                    <div className={"list-group-item py-3"}>
                                        <div>
                                            <h3 className={"d-inline-block"} style={{minWidth: "120px"}}>Offsite
                                                Monitoring</h3>
                                            <div className={"d-inline-block form-check form-switch ms-5 align-middle"}>
                                                <input name={"add-offsite-on"} id={"add-offsite-on"}
                                                       defaultChecked={true}
                                                       className={"form-check-input"} type={"checkbox"}
                                                       onChange={(e) => {
                                                           addOutputHandler("offsite", e.target.checked);
                                                           if (e.target.checked === true){
                                                               if (document.getElementById("add-mute-on").checked === true){
                                                                   document.getElementById("add-mute-on").click();
                                                               }
                                                           }
                                                       }}/>
                                            </div>
                                        </div>
                                        <div className={"form-floating d-inline-block w-50 me-5"}>
                                            <input type={"number"} className={"form-control"} id={"add-offsite-time"}
                                                   defaultValue={0}
                                                   onKeyUp={() => formCheck("addForm", "addSubmit")} step={1} max={60}
                                                   min={0}/>
                                            <label htmlFor={"add-offsite-time"}>Message After/Mins</label>
                                            <div className={"invalid-feedback"}>
                                                Enter minutes 0 - 60 for when pagers will get sent out from alert start.
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className={"d-none"} id={"add-offsite-icon"}>
                                        <input type={"checkbox"} className={"d-none"} id={"add-offsite-on"}
                                               value={false} readOnly={true}/>
                                        <input type={"hidden"} className={"d-none"} id={"add-offsite-time"} value={0}
                                               readOnly={true}/>
                                    </div>
                                }
                                <div className={"list-group-item py-3 d-none"}>
                                    <div>
                                        <h3 className={"d-inline-block"} style={{minWidth: "120px"}}>Reporting</h3>
                                        <div className={"d-inline-block form-check form-switch ms-5 align-middle"}>
                                            <input name={"add-report-on"} id={"add-report-on"} defaultChecked={true}
                                                   className={"form-check-input"} type={"checkbox"}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={"list-group-item py-3"}>
                                    <div>
                                        <h3 className={"d-inline-block"} style={{minWidth: "120px"}}>Mute</h3>
                                        <div className={"d-inline-block form-check form-switch ms-5 align-middle"}>
                                            <input name={"add-mute-on"} id={"add-mute-on"} defaultChecked={false}
                                                   className={"form-check-input"} type={"checkbox"}
                                                   onChange={(e) => {
                                                       if (e.target.checked === true){
                                                           if (globalOutputs.includes("offsite")){
                                                               if (document.getElementById("add-offsite-on").checked === true){
                                                                   document.getElementById("add-offsite-on").click();
                                                               }
                                                           }
                                                           if (globalOutputs.includes("serial")){
                                                               if (document.getElementById("add-serial-on").checked === true){
                                                                   document.getElementById("add-serial-on").click();
                                                               }
                                                           }
                                                           if (globalOutputs.includes("pager")){
                                                               if (document.getElementById("add-pager-on").checked === true){
                                                                   document.getElementById("add-pager-on").click();
                                                                   setPagerEntities(() => []);
                                                               }
                                                           }
                                                           if (globalOutputs.includes("text")){
                                                               if (document.getElementById("add-text-on").checked === true){
                                                                   document.getElementById("add-text-on").click();
                                                                   setTextEntities(() => []);
                                                               }
                                                           }
                                                           if (globalOutputs.includes("email")){
                                                               if (document.getElementById("add-email-on").checked === true){
                                                                   document.getElementById("add-email-on").click();
                                                                   setEmailEntities(() => []);
                                                               }
                                                           }
                                                           if (globalOutputs.includes("voice")){
                                                               if (document.getElementById("add-voice-on").checked === true){
                                                                   document.getElementById("add-voice-on").click();
                                                                   setVoiceEntities(() => []);
                                                               }
                                                           }
                                                           if (globalOutputs.includes("palmobile")){
                                                               if (document.getElementById("add-palmobile-on").checked === true){
                                                                   document.getElementById("add-palmobile-on").click();
                                                                   setMobileEntities(() => []);
                                                               }
                                                           }
                                                       }
                                                   }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div id={"devDivAddOptions"} className={"d-none mt-1"}>
                                    <hr/>
                                    <h3 className={"text-center"}>Devices</h3>
                                    <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                        <input type={"search"} id={"search"} className={"form-control search-input"}
                                               value={search}
                                               onChange={(e) => setSearch(e.target.value)}/>
                                        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                    </div>
                                    <div className={"mt-3"}
                                         style={{maxHeight: "400px", overflowY: "auto", display: "block"}}>
                                        <table className={"table table-sm table-white table-hover"}>
                                            <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                            {devAddTableHead}
                                            </thead>
                                            <tbody>
                                            {devAddTableRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div id={"resDivAddOptions"} className={"d-none mt-1"}>
                                    <hr/>
                                    <h3 className={"text-center"}>Resident Groups</h3>
                                    <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                        <input type={"search"} id={"search2"} className={"form-control search-input"}
                                               value={search2}
                                               onChange={(e) => setSearch2(e.target.value)}/>
                                        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                    </div>
                                    <div className={"mt-3"} style={{maxHeight: "400px", overflowY: "auto"}}>
                                        <table className={"table table-sm table-white table-hover"}>
                                            <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                            {resAddTableHead}
                                            </thead>
                                            <tbody>
                                            {resTableRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div id={"areaDivAddOptions"} className={"d-none mt-1"}>
                                    <hr/>
                                    <h3 className={"text-center"}>Area Groups</h3>
                                    <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                        <input type={"search"} id={"search4"} className={"form-control search-input"}
                                               value={search4}
                                               onChange={(e) => setSearch4(e.target.value)}/>
                                        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                    </div>
                                    <div className={"mt-3"} style={{maxHeight: "400px", overflowY: "auto"}}>
                                        <table className={"table table-sm table-white table-hover"}>
                                            <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                            {areaAddTableHead}
                                            </thead>
                                            <tbody>
                                            {areaGroupTableRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelAddBtn"}
                                            data-bs-dismiss={"modal"}>Cancel
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Loading"} id={"loadAddSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"addSubmit"}
                                            onClick={() => performAddSave()}>Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"resgroupmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title text-capitalize"} id={"staticBackdropLabel"}>Set Resident's Groups</h2>
                            <button type={"button"} className={"btn-close"} id={"resGroupClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"resGroupForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div id={"resgroupsdiv"} className={"mt-1"}>
                                    <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                        <input type={"search"} id={"search2E"} className={"form-control search-input"}
                                               value={search2}
                                               onChange={(e) => setSearch2(e.target.value)} />
                                        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                    </div>
                                    <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                        <table className={"table table-sm table-white table-hover"}>
                                            <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                {resTableHead}
                                            </thead>
                                            <tbody>
                                                {resTableRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelBtn-resident"} data-bs-dismiss={"modal"}>Cancel</button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadRefSubmit-resident"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"refSubmit-resident"} onClick={() => performSaveEntities("resident")}>Set</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"areagroupmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title text-capitalize"} id={"staticBackdropLabel"}>Set Area's Groups</h2>
                            <button type={"button"} className={"btn-close"} id={"areaGroupClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"resGroupForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div id={"areagroupsdiv"} className={"mt-1"}>
                                    <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                        <input type={"search"} id={"search4E"} className={"form-control search-input"}
                                               value={search4}
                                               onChange={(e) => setSearch4(e.target.value)} />
                                        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                    </div>
                                    <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                        <table className={"table table-sm table-white table-hover"}>
                                            <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                {areaTableHead}
                                            </thead>
                                            <tbody>
                                                {areaGroupTableRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelBtn-area"}
                                            data-bs-dismiss={"modal"}>Cancel
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Loading"} id={"loadRefSubmit-area"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"refSubmit-area"} onClick={() => performSaveEntities("area")}>Set</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"devgroupmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title text-capitalize"} id={"staticBackdropLabel"}>Set Devices</h2>
                            <button type={"button"} className={"btn-close"} id={"devGroupClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"devGroupForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div id={"devgroupsdiv"} className={"mt-1"}>
                                    <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                        <input type={"search"} id={"searchE"} className={"form-control search-input"}
                                               value={search}
                                               onChange={(e) => setSearch(e.target.value)} />
                                        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                    </div>
                                    <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                        <table className={"table table-sm table-white table-hover"}>
                                            <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                {devTableHead}
                                            </thead>
                                            <tbody>
                                                {devTableRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelBtn-device"} data-bs-dismiss={"modal"}>Cancel</button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadRefSubmit-device"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"refSubmit-device"} onClick={() => performSaveEntities("device")}>Set</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"addgroupmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title text-capitalize"} id={"staticBackdropLabel"}>Set Entities</h2>
                            <button type={"button"} className={"btn-close"} id={"addGroupClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"groupForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div id={"addgroupsdiv"} className={"mt-1"}>
                                    <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                        <input type={"search"} id={"search3"} className={"form-control search-input"}
                                               value={search3}
                                               onChange={(e) => setSearch3(e.target.value)} />
                                        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                    </div>
                                    <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                        <table className={"table table-sm table-white table-hover"}>
                                            <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                {groupAddTableHead}
                                            </thead>
                                            <tbody>
                                                {groupTableRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelAddGroupBtn"} onClick={() => {swapModal("addGroupClose", "addmodal")}}>Cancel</button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadAddGroupSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"addGroupSubmit"} onClick={() => setAddGroupItems()}>Set</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"addphonemodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title text-capitalize"} id={"staticBackdropLabel"}>Set Phone Numbers</h2>
                            <button type={"button"} className={"btn-close"} id={"addPhoneClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"addPhoneForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <input id={"add-output-type"} type={"hidden"} className={"d-none"} />
                                <div className={"list-group-item"}>
                                    <Button type={"button"} class={"btn btn-primary mb-3"} text={"New Number"} onClick={() => {
                                        setAddNewNumbers((prevState) => {return [...prevState, {id: uuidv4(), number: ""}]});
                                        formCheck("addPhoneForm", "addPhoneSubmit");
                                    }} />
                                    {addNewNumbers.map((item) => {
                                        return (
                                            <div key={`add-new-phone-item${item.id}`}>
                                                <i className={"fe fe-trash as-btn d-inline-block red-hover"} onClick={() => {
                                                    setAddNewNumbers((prevState) => {
                                                        return prevState.filter(i => i.id !== item.id);
                                                    })
                                                }}></i>
                                                <div className={"form-floating d-inline-block w-75 mb-3 mx-3"}>
                                                    <input type={"text"} name={"add-phone-input"} className={"form-control"} id={`add-new-phone-${item.id}`}
                                                           onKeyUp={(e) => {
                                                               setAddNewNumbers((prevState) => {
                                                                   return prevState.map((i) => {
                                                                       if (i.id === item.id){
                                                                           return {id: i.id, number: e.target.value}
                                                                       }
                                                                       return i
                                                                   })
                                                               })
                                                               formCheck("addPhoneForm", "addPhoneSubmit");
                                                           }} required={true}
                                                           pattern={"[0-9]{3}-?[0-9]{3}-?[0-9]{4}"} defaultValue={item.number} />
                                                    <label htmlFor={`tmp-phone-${item.id}`}>Phone Number</label>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <hr />
                                <div className={"list-group-item"}>
                                    {addCurNumbers.map((item) => {
                                        return (
                                            <div key={`add-stored-phone-${item.id}`}>
                                                <i className={"fe fe-trash as-btn d-inline-block red-hover"} onClick={() => {
                                                    setAddCurNumbers((prevState) => {
                                                        return prevState.filter(i => i?.id !== item.id);
                                                        })
                                                    }}></i>
                                                <div className={"form-floating d-inline-block w-75 mb-3 mx-3"}>
                                                    <input type={"text"} name={"add-phone-input"} className={"form-control"} id={`add-tmp-phone-${item.id}`} required={true}
                                                           pattern={"[0-9]{3}-?[0-9]{3}-?[0-9]{4}"} value={item.number} readOnly={true} />
                                                    <label htmlFor={`add-tmp-phone-${item.id}`}>Phone Number</label>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelAddPhoneBtn"} onClick={() => {swapModal("addPhoneClose", "addmodal")}}>Cancel</button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadAddPhoneSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"addPhoneSubmit"} onClick={() => setAddPhoneItems()}>Set</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"addemailmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title text-capitalize"} id={"staticBackdropLabel"}>Set Emails</h2>
                            <button type={"button"} className={"btn-close"} id={"addEmailClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"addEmailForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"list-group-item"}>
                                    <Button type={"button"} class={"btn btn-primary mb-3"} text={"New Email"} onClick={() => {
                                        setAddNewEmails((prevState) => {return [...prevState, {id: uuidv4(), email: ""}]});
                                        formCheck("addEmailForm", "addEmailSubmit");
                                    }} />
                                    {addNewEmails.map((item) => {
                                        return (
                                            <div key={`add-new-email-item${item.id}`}>
                                                <i className={"fe fe-trash as-btn d-inline-block red-hover"} onClick={() => {
                                                    setAddNewEmails((prevState) => {
                                                        return prevState.filter(i => i.id !== item.id);
                                                    })
                                                }}></i>
                                                <div className={"form-floating d-inline-block w-75 mb-3 mx-3"}>
                                                    <input type={"text"} name={"add-email-input"} className={"form-control"} id={`add-new-email-${item.id}`}
                                                           onKeyUp={(e) => {
                                                               setAddNewEmails((prevState) => {
                                                                   return prevState.map((i) => {
                                                                       if (i.id === item.id){
                                                                           return {id: i.id, email: e.target.value}
                                                                       }
                                                                       return i
                                                                   })
                                                               })
                                                               formCheck("addEmailForm", "addEmailSubmit");
                                                           }} required={true}
                                                            pattern={"[a-z0-9._%+\\-]+@[a-z0-9.\\-]+\\.[a-z]{2,}$"} defaultValue={item.email} />
                                                    <label htmlFor={`tmp-email-${item.id}`}>Email Address</label>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <hr />
                                <div className={"list-group-item"}>
                                    {addCurEmails.map((item) => {
                                        return (
                                            <div key={`add-stored-email-${item.id}`}>
                                                <i className={"fe fe-trash as-btn d-inline-block red-hover"} onClick={() => {
                                                    setAddCurEmails((prevState) => {
                                                        return prevState.filter(i => i?.id !== item.id);
                                                        })
                                                    }}></i>
                                                <div className={"form-floating d-inline-block w-75 mb-3 mx-3"}>
                                                    <input type={"text"} name={"add-email-input"} className={"form-control"} id={`add-tmp-email-${item.id}`} required={true}
                                                           pattern={"[a-z0-9._%+\\-]+@[a-z0-9.\\-]+\\.[a-z]{2,}$"} value={item.email} readOnly={true} />
                                                    <label htmlFor={`add-tmp-email-${item.id}`}>Email Address</label>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelAddEmailBtn"} onClick={() => {swapModal("addEmailClose", "addmodal")}}>Cancel</button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadAddEmailSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"addEmailSubmit"} onClick={() => setAddEmailItems()}>Set</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"deleteconfirmmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={"-1"} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Confirm Delete</h2>
                            <button type={"button"} className={"btn-close"} id={"deleteConfirmClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <div className={"modal-body"}>
                            <p>Are you sure you wish to delete this careflow profile?</p>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}} id={"deleteConfirmCancelBtn"} data-bs-dismiss={"modal"}>No</button>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadDeleteConfirmSubmit"}/>
                            <button type={"button"} className={"btn btn-primary ms-3"} id={"deleteConfirmSubmit"} onClick={() => deleteSubmit()}>YES</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"wizard1"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={"-1"} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Name Your Careflow</h2>
                            <button type={"button"} className={"btn-close"} id={"wiz1ConfirmClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"wizard1Form"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating d-inline-block w-75 mb-3 mx-3"}>
                                    <input type={"text"} name={"wiz-description"} className={"form-control"}
                                           id={"wiz-description"} required={true}
                                           pattern={"[a-zA-Z0-9 _\\-]+$"} defaultValue={""} onChange={() => {
                                               formCheck("wizard1Form", "wiz1Submit");
                                    }} />
                                    <label htmlFor={"wiz-description"}>Careflow Name</label>
                                </div>
                                <div className={"mb-0 mt-4"}>
                                    <h4>*Pro Tip: Name what is actually happening & a timeframe</h4>
                                </div>
                            </div>
                            <div className={"modal-footer d-flex justify-content-between"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary float-start"} style={{width: "75px"}}
                                        id={"wiz1CancelBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                </div>
                                <div>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"wiz1Submit"} onClick={() => {
                                        setWizDescription(() => document.getElementById("wiz-description").value);
                                        formCheck("wizard2Form", "wiz2Submit");
                                        swapModal("wiz1CancelBtn", "wizard2");
                                    }}>Next</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"wizard2"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={"-1"} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Choose Your Output</h2>
                            <button type={"button"} className={"btn-close"} id={"wiz2ConfirmClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"wizard2Form"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <h3>How do you want to receive the alerts?</h3>
                                {globalOutputs.includes("palmobile")
                                    ? <div className={"form-check d-inline-block w-75 mb-3 mx-3"}>
                                        <input type={"radio"} name={"wiz-output"} className={"form-check-input"}
                                               id={"wiz-output-mobile"} required={true} defaultChecked={false}
                                               value={"mobile"}
                                               onChange={() => {
                                                   formCheck("wizard2Form", "wiz2Submit")
                                               }}/>
                                        <label className={"form-check-label"}
                                               htmlFor={"wiz-output-mobile"}>Mobile</label>
                                    </div>
                                    : <></>
                                }
                                {globalOutputs.includes("pager")
                                    ? <div className={"form-check d-inline-block w-75 mb-3 mx-3"}>
                                        <input type={"radio"} name={"wiz-output"} className={"form-check-input"}
                                               id={"wiz-output-pager"} required={true} defaultChecked={false}
                                               value={"pager"}
                                               onChange={() => {
                                                   formCheck("wizard2Form", "wiz2Submit")
                                               }}/>
                                        <label className={"form-check-label"} htmlFor={"wiz-output-pager"}>Pager</label>
                                    </div>
                                    : <></>
                                }
                                {globalOutputs.includes("email")
                                    ? <div className={"form-check d-inline-block w-75 mb-3 mx-3"}>
                                        <input type={"radio"} name={"wiz-output"} className={"form-check-input"}
                                               id={"wiz-output-email"} required={true} defaultChecked={false}
                                               value={"email"}
                                               onChange={() => {
                                                   formCheck("wizard2Form", "wiz2Submit")
                                               }}/>
                                        <label className={"form-check-label"} htmlFor={"wiz-output-email"}>Email</label>
                                    </div>
                                    : <></>
                                }
                                {globalOutputs.includes("text")
                                    ? <div className={"form-check d-inline-block w-75 mb-3 mx-3"}>
                                        <input type={"radio"} name={"wiz-output"} className={"form-check-input"}
                                               id={"wiz-output-text"} required={true} defaultChecked={false}
                                               value={"text"}
                                               onChange={() => {
                                                   formCheck("wizard2Form", "wiz2Submit")
                                               }}/>
                                        <label className={"form-check-label"} htmlFor={"wiz-output-text"}>Text
                                            Message</label>
                                    </div>
                                    : <></>
                                }
                                {globalOutputs.includes("voice")
                                    ? <div className={"form-check d-inline-block w-75 mb-3 mx-3"}>
                                        <input type={"radio"} name={"wiz-output"} className={"form-check-input"}
                                               id={"wiz-output-voice"} required={true} defaultChecked={false}
                                               value={"voice"}
                                               onChange={() => {
                                                   formCheck("wizard2Form", "wiz2Submit")
                                               }}/>
                                        <label className={"form-check-label"} htmlFor={"wiz-output-voice"}>Voice
                                            Call</label>
                                    </div>
                                    : <></>
                                }
                                {globalOutputs.includes("offsite")
                                    ? <div className={"form-check d-inline-block w-75 mb-3 mx-3"}>
                                        <input type={"radio"} name={"wiz-output"} className={"form-check-input"}
                                               id={"wiz-output-offsite"} required={true} defaultChecked={false}
                                               value={"offsite"}
                                               onChange={() => {
                                                   formCheck("wizard2Form", "wiz2Submit")
                                               }}/>
                                        <label className={"form-check-label"} htmlFor={"wiz-output-offsite"}>Offsite
                                            Monitoring</label>
                                    </div>
                                    : <></>
                                }
                                {globalOutputs.includes("serial")
                                    ? <div className={"form-check d-inline-block w-75 mb-3 mx-3"}>
                                        <input type={"radio"} name={"wiz-output"} className={"form-check-input"}
                                               id={"wiz-output-serial"} required={true} defaultChecked={false}
                                               value={"serial"}
                                               onChange={() => {
                                                   formCheck("wizard2Form", "wiz2Submit")
                                               }}/>
                                        <label className={"form-check-label"}
                                               htmlFor={"wiz-output-serial"}>Serial</label>
                                    </div>
                                    : <></>
                                }
                                <div className={"form-check d-inline-block w-75 mb-3 mx-3"}>
                                    <input type={"radio"} name={"wiz-output"} className={"form-check-input"}
                                           id={"wiz-output-mute"} required={true} defaultChecked={false}
                                           value={"mute"}
                                           onChange={() => {
                                               formCheck("wizard2Form", "wiz2Submit")
                                           }}/>
                                    <label className={"form-check-label"} htmlFor={"wiz-output-mute"}>Don't receive alerts (muted)</label>
                                </div>
                                <div className={"mb-0 mt-4"}>
                                    <h4>*Pro Tip: Consider what action you want taken. Different Devices have different
                                        best
                                        practices. See Careflow Guide for more examples.</h4>
                                </div>
                            </div>
                            <div className={"modal-footer d-flex justify-content-between"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                            id={"wiz2CancelBtn"} data-bs-dismiss={"modal"}>Cancel
                                    </button>
                                </div>
                                <div>
                                    <Button text={"Back"} class={"btn btn-primary mx-3"} id={"wiz2Back"}
                                            onClick={() => {
                                                swapModal("wiz2CancelBtn", "wizard1");
                                            }}/>
                                    <button type={"button"} className={"btn btn-danger ms-3"} id={"wiz2Submit"}
                                        onClick={() => {
                                            let outputs, output = "";
                                            outputs = document.getElementsByName("wiz-output")
                                            for (let i=0; i < outputs.length; i++){
                                                if (outputs[i].checked){
                                                    output = outputs[i].value;
                                                    break
                                                }
                                            }
                                            setWizOutput(() => output);
                                            formCheck("wizard3Form", "wiz3Submit");
                                            swapModal("wiz2CancelBtn", "wizard3");
                                    }}>Next</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"wizard3"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={"-1"} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Choose Your Careflow Type</h2>
                            <button type={"button"} className={"btn-close"} id={"wiz3ConfirmClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"wizard3Form"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <h3>How would you like to route alerts by?</h3>
                                <div className={"form-check d-inline-block w-75 mb-3 mx-3"}>
                                    <input type={"radio"} name={"wiz-careflow-type"} className={"form-check-input"}
                                           id={"wiz-caretype-alert"} required={true} defaultChecked={false} value={"1"}
                                           onChange={() => {
                                               formCheck("wizard3Form", "wiz3Submit")
                                    }} />
                                    <label className={"form-check-label"} htmlFor={"wiz-caretype-alert"}>
                                        Alert Type - <span className={"fs-5"}>Any alert that falls under a chosen category</span>
                                    </label>
                                </div>
                                <div className={"form-check d-inline-block w-75 mb-3 mx-3"}>
                                    <input type={"radio"} name={"wiz-careflow-type"} className={"form-check-input"}
                                           id={"wiz-caretype-dev"} required={true} defaultChecked={false} value={"3"}
                                           onChange={() => {
                                               formCheck("wizard3Form", "wiz3Submit")
                                    }} />
                                    <label className={"form-check-label"} htmlFor={"wiz-caretype-dev"}>
                                        Device - <span className={"fs-5"}>Any alert from a chosen device</span>
                                    </label>
                                </div>
                                <div className={"form-check d-inline-block w-75 mb-3 mx-3"}>
                                    <input type={"radio"} name={"wiz-careflow-type"} className={"form-check-input"}
                                           id={"wiz-caretype-res"} required={true} defaultChecked={false} value={"4"}
                                           onChange={() => {
                                               formCheck("wizard3Form", "wiz3Submit")
                                    }} />
                                    <label className={"form-check-label"} htmlFor={"wiz-caretype-res"}>
                                        Resident - <span className={"fs-5"}>Any alert produced for a resident within a chosen group</span>
                                    </label>
                                </div>
                                <div className={"form-check d-inline-block w-75 mb-3 mx-3"}>
                                    <input type={"radio"} name={"wiz-careflow-type"} className={"form-check-input"}
                                           id={"wiz-caretype-area"} required={true} defaultChecked={false} value={"5"}
                                           onChange={() => {
                                               formCheck("wizard3Form", "wiz3Submit")
                                    }} />
                                    <label className={"form-check-label"} htmlFor={"wiz-caretype-area"}>
                                        Area - <span className={"fs-5"}>Any alert produced from an area within a chosen group</span>
                                    </label>
                                </div>
                                <div className={"form-check d-inline-block w-75 mb-3 mx-3"}>
                                    <input type={"radio"} name={"wiz-careflow-type"} className={"form-check-input"}
                                           id={"wiz-caretype-default"} required={true} defaultChecked={false} value={"0"}
                                           onChange={() => {
                                               formCheck("wizard3Form", "wiz3Submit")
                                    }} />
                                    <label className={"form-check-label"} htmlFor={"wiz-caretype-default"}>
                                        Default - <span className={"fs-5"}>Any alert not handled by above options</span>
                                    </label>
                                </div>
                            </div>
                            <div className={"modal-footer d-flex justify-content-between"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                        id={"wiz3CancelBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                </div>
                                <div>
                                    <Button text={"Back"} class={"btn btn-primary mx-3"} id={"wiz3Back"} onClick={() => {
                                        swapModal("wiz3CancelBtn", "wizard2");
                                    }} />
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"wiz3Submit"} onClick={() => {
                                        let caretype, caretypes;
                                        caretypes = document.getElementsByName("wiz-careflow-type");
                                        for (let i=0; i < caretypes.length; i++){
                                            if (caretypes[i].checked){
                                                caretype = caretypes[i].value;
                                                break
                                            }
                                        }
                                        setWizCareflowType(() => parseInt(caretype));
                                        if (caretype.toString() === "0"){
                                            setWizEventType(1);
                                            formCheck("wizard5Form", "wiz5Submit");
                                            swapModal("wiz3CancelBtn", "wizard5");
                                        }else{
                                            if (caretype.toString() === "1"){
                                                setWizEntities([]);
                                                formCheck("wizard4-1Form", "wiz4-1Submit");
                                                swapModal("wiz3CancelBtn", "wizard4-1");
                                            }else{
                                                setWizEventType(1);
                                                formCheck("wizard4-2Form", "wiz4-2Submit");
                                                swapModal("wiz3CancelBtn", "wizard4-2");
                                            }
                                        }
                                    }}>Next</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"wizard4-1"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={"-1"} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Choose Your Alert Type</h2>
                            <button type={"button"} className={"btn-close"} id={"wiz4-1ConfirmClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"wizard4-1Form"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3"}>
                                    <select className={"form-select"} id={"wiz-event-type"} title={"The type of alert this careflow applies for"}
                                            onChange={() => formCheck("wizard4-1Form", "wiz4-1Submit")} required={true}>
                                        {wizEscTypeOptions}
                                    </select>
                                    <label htmlFor={"wiz-event-type"}>Active Alert Type</label>
                                </div>
                            </div>
                            <div className={"modal-footer d-flex justify-content-between"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                        id={"wiz4-1CancelBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                </div>
                                <div>
                                    <Button text={"Back"} class={"btn btn-primary mx-3"} id={"wiz4-1Back"} onClick={() => {
                                        swapModal("wiz4-1CancelBtn", "wizard3");
                                    }} />
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"wiz4-1Submit"} onClick={() => {
                                        setWizEventType(() => document.getElementById("wiz-event-type").value);
                                        formCheck("wizard5Form", "wiz5Submit");
                                        swapModal("wiz4-1CancelBtn", "wizard5");
                                    }}>Next</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"wizard4-2"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={"-1"} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Attach For <span className={"text-capitalize"}>{wizOutput}</span></h2>
                            <button type={"button"} className={"btn-close"} id={"wiz4-2ConfirmClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"wizard4-2Form"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                {wizCareflowType === 3
                                    ?   <div className={"mt-3"}>
                                        <h3>Select Device(s) for Routing.</h3>
                                            <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                                <input type={"search"} id={"search"} className={"form-control search-input"}
                                                       value={search}
                                                       onChange={(e) => setSearch(e.target.value)} />
                                                <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                        style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                            </div>
                                            <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                                <table className={"table table-sm table-white table-hover"}>
                                                    <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                        {devWizTableHead}
                                                    </thead>
                                                    <tbody>
                                                        {devWizTableRows}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    :   <></>
                                }
                                {wizCareflowType === 4
                                    ? <div className={"mt-3"}>
                                        <h3>Select Resident Groups for Routing.</h3>
                                        <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                            <input type={"search"} id={"search2"}
                                                   className={"form-control search-input"}
                                                   value={search2}
                                                   onChange={(e) => setSearch2(e.target.value)}/>
                                            <Button text={""} type={"button"}
                                                    class={"btn btn-primary smooth-radius-left"}
                                                    style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                        </div>
                                        <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                            <table className={"table table-sm table-white table-hover"}>
                                                <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                {resWizTableHead}
                                                </thead>
                                                <tbody>
                                                {resTableRows}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    :   <></>
                                }
                                {wizCareflowType === 5
                                    ? <div className={"mt-3"}>
                                        <h3>Select Area Groups for Routing.</h3>
                                        <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                            <input type={"search"} id={"search4"}
                                                   className={"form-control search-input"}
                                                   value={search4}
                                                   onChange={(e) => setSearch4(e.target.value)}/>
                                            <Button text={""} type={"button"}
                                                    class={"btn btn-primary smooth-radius-left"}
                                                    style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                        </div>
                                        <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                            <table className={"table table-sm table-white table-hover"}>
                                                <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                {areaWizTableHead}
                                                </thead>
                                                <tbody>
                                                {areaGroupTableRows}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    :   <></>
                                }
                            </div>
                            <div className={"modal-footer d-flex justify-content-between"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                        id={"wiz4-2CancelBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                </div>
                                <div>
                                    <Button text={"Back"} class={"btn btn-primary mx-3"} id={"wiz4-2Back"} onClick={() => {
                                        swapModal("wiz4-2CancelBtn", "wizard3");
                                    }} />
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"wiz4-2Submit"} onClick={() => {
                                        formCheck("wizard5Form", "wiz5Submit");
                                        swapModal("wiz4-2CancelBtn", "wizard5");
                                    }}>Next</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"wizard5"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={"-1"} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>When is your Careflow Active?</h2>
                            <button type={"button"} className={"btn-close"} id={"wiz5ConfirmClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"wizard5Form"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"mb-3"}>
                                    <Button text={"All Day"} class={"btn btn-toggle mx-2"} id={"wiz-allday"} onClick={() => {
                                        let start, end;
                                        document.getElementById("wiz-time-div").classList.add("d-none");
                                        document.getElementById("wiz-allday").classList.add("btn-active");
                                        document.getElementById("wiz-specific").classList.remove("btn-active");
                                        start = document.getElementById("wiz-starttime");
                                        end = document.getElementById("wiz-endtime");
                                        start.value = "00:00";
                                        end.value = "23:59";
                                        start.readOnly = true;
                                        end.readOnly = true;
                                        start.required = false;
                                        end.required = false;
                                        formCheck("wizard5Form", "wiz5Submit");
                                    }} />
                                    <Button text={"Specific Times"} class={"btn btn-toggle mx-2"} id={"wiz-specific"} onClick={() => {
                                        let start, end;
                                        document.getElementById("wiz-time-div").classList.remove("d-none");
                                        document.getElementById("wiz-specific").classList.add("btn-active");
                                        document.getElementById("wiz-allday").classList.remove("btn-active");
                                        start = document.getElementById("wiz-starttime");
                                        end = document.getElementById("wiz-endtime");
                                        start.value = "";
                                        end.value = "";
                                        start.readOnly = false;
                                        end.readOnly = false;
                                        start.required = true;
                                        end.required = true;
                                        formCheck("wizard5Form", "wiz5Submit");
                                    }} />
                                </div>
                                <div className={"d-none d-flex justify-content-around"} id={"wiz-time-div"}>
                                    <div className={"form-floating mb-3 d-inline-block"} style={{width: "49%"}}>
                                        <input type={"time"} className={"form-control"} id={"wiz-starttime"}
                                               required={true} step={"300"} min={"00:00"} max={"23:59"}
                                               onFocus={(e) => {
                                                   e.target.showPicker();
                                               }}
                                               onBlur={(e) => {
                                                   if (!e.target.checkValidity()){
                                                        e.target.stepUp();
                                                    }
                                                   document.getElementById("wiz-endtime").min = e.target.value;
                                                   formCheck("wizard5Form", "wiz5Submit");
                                               }} />
                                        <label htmlFor={"wiz-starttime"}>Start Time</label>
                                        <div className={"invalid-feedback"}>
                                            Start time must be prior to end time and in 5 minute increments.
                                        </div>
                                    </div>
                                    <div className={"form-floating mb-3 d-inline-block"} style={{width: "49%"}}>
                                        <input type={"time"} className={"form-control"} id={"wiz-endtime"}
                                               required={true} step={"300"} min={"00:00"} max={"23:59"}
                                               onFocus={(e) => {
                                                   e.target.showPicker();
                                               }}
                                               onBlur={(e) => {
                                                   if (!e.target.checkValidity()){
                                                        e.target.stepUp();
                                                    }
                                                   document.getElementById("wiz-starttime").max = e.target.value;
                                                   formCheck("wizard5Form", "wiz5Submit")
                                               }} />
                                        <label htmlFor={"wiz-endtime"}>End Time</label>
                                        <div className={"invalid-feedback"}>
                                            End time must be after start time and in 5 minute increments.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer d-flex justify-content-between"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                        id={"wiz5CancelBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                </div>
                                <div>
                                    <Button text={"Back"} class={"btn btn-primary mx-3"} id={"wiz5Back"} onClick={() => {
                                        if (wizCareflowType === 1){
                                            swapModal("wiz5CancelBtn", "wizard4-1");
                                        }else{
                                            swapModal("wiz5CancelBtn", "wizard4-2");
                                        }
                                    }} />
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"wiz5Submit"} onClick={() => {
                                        setWizStart(() => document.getElementById("wiz-starttime").value);
                                        setWizEnd(() => document.getElementById("wiz-endtime").value);
                                        formCheck("wizard6Form", "wiz6Submit");
                                        swapModal("wiz5CancelBtn", "wizard6");
                                    }}>Next</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"wizard6"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={"-1"} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>
                                Choose When to Send to <span className={"text-capitalize"}>{wizOutput}</span>
                            </h2>
                            <button type={"button"} className={"btn-close"} id={"wiz6ConfirmClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"wizard6Form"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"mb-3"}>
                                    <Button text={"Immediate"} class={"btn btn-toggle mx-2"} id={"wiz-immediate"} onClick={() => {
                                        let msgTime = document.getElementById("wiz-messageTime");
                                        document.getElementById("wiz-msgtime-div").classList.add("d-none");
                                        document.getElementById("wiz-immediate").classList.add("btn-active");
                                        document.getElementById("wiz-delayed").classList.remove("btn-active");
                                        msgTime.value = 0;
                                        msgTime.readOnly = true;
                                        msgTime.required = false;
                                        formCheck("wizard6Form", "wiz6Submit");
                                    }} />
                                    <Button text={"Delay"} class={"btn btn-toggle mx-2"} id={"wiz-delayed"} onClick={() => {
                                        let msgTime = document.getElementById("wiz-messageTime");
                                        document.getElementById("wiz-msgtime-div").classList.remove("d-none");
                                        document.getElementById("wiz-delayed").classList.add("btn-active");
                                        document.getElementById("wiz-immediate").classList.remove("btn-active");
                                        msgTime.readOnly = false;
                                        msgTime.required = true;
                                        msgTime.value = "";
                                        formCheck("wizard6Form", "wiz6Submit");
                                    }} />
                                </div>
                                <div id={"wiz-msgtime-div"} className={"d-none"}>
                                    <div className={"form-floating mb-3"}>
                                        <input type={"number"} className={"form-control"} id={"wiz-messageTime"}
                                               min={0} max={60} step={1} required={true}
                                               title={"0 = Immediate Send, 1-60 = Minutes from alarm generation when notification will send."}
                                               onKeyUp={() => formCheck("wizard6Form", "wiz6Submit")}
                                               onChange={() => formCheck("wizard6Form", "wiz6Submit")} />
                                        <label htmlFor={"wiz-messageTime"}>Message After/Mins</label>
                                        <div className={"invalid-feedback"}>
                                            Please enter number of minutes 0 - 60
                                        </div>
                                    </div>
                                    {wizOutput === "pager"
                                        ?   <div className={"form-floating mb-3"}>
                                                <input type={"number"} className={"form-control"} id={"wiz-interval"}
                                                       min={0} max={60} step={1} required={true}
                                                       title={"Set number of minutes pager will repeat notification"}
                                                       onKeyUp={() => formCheck("wizard6Form", "wiz6Submit")}
                                                       onChange={() => formCheck("wizard6Form", "wiz6Submit")} />
                                                <label htmlFor={"wiz-messageTime"}>Pager Repeat Interval (Minutes)</label>
                                                <div className={"invalid-feedback"}>
                                                    Please enter number of minutes 0 - 60
                                                </div>
                                            </div>
                                        :   <></>
                                    }
                                </div>
                            </div>
                            <div className={"modal-footer d-flex justify-content-between"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                        id={"wiz6CancelBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                </div>
                                <div>
                                    <Button text={"Back"} class={"btn btn-primary mx-3"} id={"wiz6Back"} onClick={() => {
                                        swapModal("wiz6CancelBtn", "wizard5");
                                    }} />
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"wiz6Submit"} onClick={() => {
                                        if (wizOutput === "pager"){
                                            setWizInterval(() => document.getElementById("wiz-interval").value);
                                        }
                                        setWizMsgTime(() => document.getElementById("wiz-messageTime").value);
                                        if (["serial", "offsite"].includes(wizOutput)){
                                            formCheck("wizard8Form", "wiz8Submit");
                                            swapModal("wiz6CancelBtn", "wizard8");
                                        }else{
                                            formCheck("wizard7Form", "wiz7Submit");
                                            swapModal("wiz6CancelBtn", "wizard7");
                                        }
                                    }}>Next</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"wizard7"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={"-1"} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Who Does This Go To</h2>
                            <button type={"button"} className={"btn-close"} id={"wiz7ConfirmClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"wizard7Form"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                {["mobile", "pager"].includes(wizOutput)
                                    ?   <>
                                            <h3 className={"mb-3"}>Set Group</h3>
                                            <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                                <input type={"search"} id={"search3"} className={"form-control search-input"}
                                                       value={search3}
                                                       onChange={(e) => setSearch3(e.target.value)} />
                                                <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                        style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                            </div>
                                            <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                                <table className={"table table-sm table-white table-hover"}>
                                                    <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                        {groupWizTableHead}
                                                    </thead>
                                                    <tbody>
                                                        {groupTableRows}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    : <></>
                                }
                                {["text", "voice"].includes(wizOutput)
                                    ? <>
                                        <h3 className={"mb-3"}>Set Phone Numbers</h3>
                                        <div className={"list-group-item"}>
                                            <Button type={"button"} class={"btn btn-primary mb-3"} text={"New Number"}
                                                    onClick={() => {
                                                        setNewWizNumbers((prevState) => {
                                                            return [...prevState, {id: uuidv4(), number: ""}]});
                                                }} />
                                                {newWizNumbers.map((item) => {
                                                    return (
                                                    <div key={`new-wiz-phone-item${item.id}`}>
                                                        <i className={"fe fe-trash as-btn d-inline-block red-hover"} onClick={() => {
                                                            setNewWizNumbers((prevState) => {
                                                                return prevState.filter(i => i.id !== item.id);
                                                            });
                                                        }}></i>
                                                        <div className={"form-floating d-inline-block w-75 mb-3 mx-3"}>
                                                            <input type={"text"} name={"phone-input"} className={"form-control"} id={`new-wiz-phone-${item.id}`}
                                                                   onKeyUp={(e) => {
                                                                       setNewWizNumbers((prevState) => {
                                                                           return prevState.map((i) => {
                                                                               if (i.id === item.id){
                                                                                   return {id: i.id, number: e.target.value};
                                                                               }
                                                                               return i;
                                                                           });
                                                                       });
                                                                       formCheck("wizard7Form", "wiz7Submit");
                                                                   }} required={true}
                                                                   pattern={"[0-9]{3}-?[0-9]{3}-?[0-9]{4}"} defaultValue={item.number} />
                                                            <label htmlFor={`new-wiz-phone-${item.id}`}>Phone Number</label>
                                                        </div>
                                                    </div>);
                                                })}
                                            </div>
                                            <hr />
                                            <div className={"list-group-item"}>
                                                {curWizNumbers.map((item) => {
                                                    return (
                                                        <div key={`stored-phone-${item.id}`}>
                                                            <i className={"fe fe-trash as-btn d-inline-block red-hover"} onClick={() => {
                                                                setCurWizNumbers((prevState) => {
                                                                    return prevState.filter(i => i?.id !== item.id);
                                                                });
                                                            }}></i>
                                                            <div className={"form-floating d-inline-block w-75 mb-3 mx-3"}>
                                                                <input type={"text"} name={"phone-input"} className={"form-control"} id={`tmp-wiz-phone-${item.id}`} required={true}
                                                                       pattern={"[0-9]{3}-?[0-9]{3}-?[0-9]{4}"} value={item.number} readOnly={true} />
                                                                <label htmlFor={`tmp-wiz-phone-${item.id}`}>Phone Number</label>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </>
                                    :   <></>
                                }
                                {wizOutput === "email"
                                    ?   <>
                                            <h3 className={"mb-3"}>Set Email Addresses</h3>
                                            <div className={"list-group-item"}>
                                                <Button type={"button"} class={"btn btn-primary mb-3"} text={"New Email"} onClick={() => {
                                                    setNewWizEmails((prevState) => {return [...prevState, {id: uuidv4(), email: ""}]});
                                                }} />
                                                {newWizEmails.map((item) => {
                                                    return (
                                                    <div key={`new-email-item${item.id}`}>
                                                        <i className={"fe fe-trash as-btn d-inline-block red-hover"} onClick={() => {
                                                            setNewWizEmails((prevState) => {
                                                                return prevState.filter(i => i.id !== item.id);
                                                            });
                                                        }}></i>
                                                        <div className={"form-floating d-inline-block w-75 mb-3 mx-3"}>
                                                            <input type={"text"} name={"email-input"} className={"form-control"} id={`new-wiz-email-${item.id}`}
                                                                   onKeyUp={(e) => {
                                                                       setNewWizEmails((prevState) => {
                                                                           return prevState.map((i) => {
                                                                               if (i.id === item.id){
                                                                                   return {id: i.id, email: e.target.value};
                                                                               }
                                                                               return i;
                                                                           });
                                                                       });
                                                                       formCheck("wizard7Form", "wiz7Submit");
                                                                   }} required={true}
                                                                   pattern={"[a-z0-9._%+\\-]+@[a-z0-9.\\-]+\\.[a-z]{2,}$"} defaultValue={item.email} />
                                                            <label htmlFor={`new-wiz-email-${item.id}`}>Email Address</label>
                                                        </div>
                                                    </div>);
                                                })}
                                            </div>
                                            <hr />
                                            <div className={"list-group-item"}>
                                                {curWizEmails.map((item) => {
                                                    return (
                                                        <div key={`stored-email-${item.id}`}>
                                                            <i className={"fe fe-trash as-btn d-inline-block red-hover"} onClick={() => {
                                                                setCurWizEmails((prevState) => {
                                                                    return prevState.filter(i => i?.id !== item.id);
                                                                });
                                                            }}></i>
                                                            <div className={"form-floating d-inline-block w-75 mb-3 mx-3"}>
                                                                <input type={"text"} name={"email-input"} className={"form-control"} id={`tmp-wiz-email-${item.id}`} required={true}
                                                                       pattern={"[a-z0-9._%+\\-]+@[a-z0-9.\\-]+\\.[a-z]{2,}$"} value={item.email} readOnly={true} />
                                                                <label htmlFor={`tmp-wiz-email-${item.id}`}>Email Address</label>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </>
                                    :   <></>
                                }
                            </div>
                            <div className={"modal-footer d-flex justify-content-between"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                        id={"wiz7CancelBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                </div>
                                <div>
                                    <Button text={"Back"} class={"btn btn-primary mx-3"} id={"wiz7Back"} onClick={() => {
                                        swapModal("wiz7CancelBtn", "wizard6");
                                    }} />
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"wiz7Submit"} onClick={() => {
                                        if (wizOutput === "mobile"){
                                            setMobileEntities(() => groupIdList);
                                        }else if (wizOutput === "pager"){
                                            setPagerEntities(() => groupIdList);
                                        }else if (wizOutput === "text"){
                                            setTextEntities(() => curWizNumbers);
                                        }else if (wizOutput === "email"){
                                            setTextEntities(() => curWizEmails);
                                        }else if (wizOutput === "voice"){
                                            setVoiceEntities(() => curWizNumbers);
                                        }
                                        formCheck("wizard8Form", "wiz8Submit");
                                        swapModal("wiz7CancelBtn", "wizard8");
                                    }}>Next</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"wizard8"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={"-1"} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Summary of Careflow</h2>
                            <button type={"button"} className={"btn-close"} id={"wiz8ConfirmClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"wizard8Form"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <h3><p className={"fw-bold mb-0"} style={{width: "135px"}}>Careflow Name:</p> {wizDescription}</h3>
                                <h3><p className={"fw-bold mb-0"} style={{width: "135px"}}>Careflow Type:</p> {getCareflowTypeName(wizCareflowType, wizEventType)}</h3>
                                <h3><p className={"fw-bold mb-0"} style={{width: "135px"}}>For Alert Type:</p> {getEventCategoryById(wizEventType)}</h3>
                                {["0", "1"].includes(wizCareflowType.toString())
                                    ?   <h3><p className={"fw-bold mb-0"} style={{width: "135px"}}>Connected Items:</p> {wizEntities.length}</h3>
                                    :   <></>
                                }
                                <h3><p className={"fw-bold mb-0"} style={{width: "135px"}}>Careflow active:</p> {makeTimeDisplay(wizStart, wizEnd)}</h3>
                                <h3 className={"mt-5"}>Send notification {wizMsgTime === '0' ? 'immediately' : `after ${wizMsgTime} minutes`} to {wizOutput}</h3>
                                {wizOutput === "pager" && wizInterval !== 0
                                    ?   <h3>Repeat notification every {wizInterval} minutes</h3>
                                    :   <></>
                                }
                                {wizOutput === "pager"
                                    ?   <h3>Pages sent to capcodes: {unpackCapcodes(groupIdList)}</h3>
                                    :   <></>
                                }
                                {wizOutput === "mobile"
                                    ?   <h3>Notifications sent to groups: {unpackGroupNames(groupIdList)}</h3>
                                    :   <></>
                                }
                                {wizOutput === "email"
                                    ?   <h3>Emails sent to {curWizEmails.join(", ")}</h3>
                                    :   <></>
                                }
                                {wizOutput === "text"
                                    ?   <h3>Messages sent to {curWizNumbers.join(", ")}</h3>
                                    :   <></>
                                }
                                {wizOutput === "voice"
                                    ?   <h3>Calls sent to {curWizNumbers.join(", ")}</h3>
                                    :   <></>
                                }

                            </div>
                            <div className={"modal-footer d-flex justify-content-between"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary float-start"} style={{width: "75px"}}
                                        id={"wiz8CancelBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                </div>
                                <div>
                                    <Button text={"Back"} class={"btn btn-primary mx-3"} id={"wiz7Back"} onClick={() => {
                                        swapModal("wiz8CancelBtn", "wizard7");
                                    }} />
                                    <Button type={"button"} class={"btn btn-primary d-none me-5"} disabled={true} spinner={true}
                                            text={"Loading"} id={"loadWiz8Submit"} />
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"wiz8Submit"} onClick={() => {
                                        performWizSubmit();
                                    }}>Confirm & Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"wizard9"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={"-1"} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Summary of Careflow</h2>
                            <button type={"button"} className={"btn-close"} id={"wiz9ConfirmClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"wizard9Form"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"text-center"}>
                                    <button type={"button"} className={"btn btn-primary w-25 mb-3"} id={"wiz9CancelBtn"} data-bs-dismiss={"modal"}>Exit</button>
                                </div>
                                <div className={"text-center"}>
                                    <Button text={"Add Another"} class={"btn btn-primary w-25 mb-3"} id={"wiz9Submit"} onClick={() => {
                                        clearWizard();
                                        swapModal("wiz9CancelBtn", "wizard1");
                                    }}>Add Another</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </>
    );
};

export default EscalationSettings;