import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import Header from "./Header";
import {useState, useEffect, Suspense} from "react";
import useSound from 'use-sound';
import emergSound from "../sound/emergency_tone.mp3"
import ApiRequester from "./ApiRequester";
import {
    durationToNow, getUTCTimestamp, sortFunc, handleSubmitActions, formSuccess, formFail, setSoundsMute
} from "../libs";
import Button from "./component-assets/Button";
import {CSVLink} from "react-csv";
import {ToastContainer} from "react-toastify";
import Footer from "./Footer";
import TableHeaders from "./component-assets/TableHeaders";
import CampusDropDown from "./component-assets/CampusDropDown";
import TableBody from "./component-assets/TableBody";


const CurrentCloudAlerts = (props) => {

    let dData, headerData, csvHeaders;

    const [emergencySound] = useSound(emergSound, {volume: .5});

    headerData = [
        {label: "Event Start", key: "event_datetime_utc", align: "center", type: "datetime",
            border: {enabled: true, borderType: "left", borderColorKey: "event_category", borderWidth: "6px"}},
        {label: "Duration", key: "elapsed", align: "center"},
        {label: "Alert Category", key: "event_category", align: "center"},
        {label: "Alert Type", key: "event_type", align: "center"},
        {label: "Device / Desc", key: "device_name", align: "center",
            link: {enabled: false, type: "conditional", conditionKey: "manufacture_id",
                condition: {
                    1: {linkto: "/profiles/inovonics", linkkey: "device_id"},
                    5: {linkto: "/profiles/alexa", linkkey: "device_id"}
                }}},
        {label: "Description", key: "device_description", align: "left"},
        {label: "Area", key: "area_name", align: "center",
            link: {enabled: true, type: "standard", linkto: "/profiles/area", linkkey: "area_id"}},
        {label: "Resident", key: "resident_name", align: "left",
            link: {enabled: true, type: "standard", linkto: "/profiles/resident", linkkey: "resident_id"}},
        {label: "Accepted By / Time", key: "accepted_display", align: "left"},
        {label: "Device Cleared", key: "ack_datetime_utc", align: "left"},
        {label: "Action", key: "event_id", align: "center", type: "button",
            button: {clickFunc: clearMaint, text: "Clear", className: "btn btn-primary",
                    id: "clearSubmit"}}
    ];

    csvHeaders = [
        {label: "Event Time", key: "event_datetime_utc"},
        {label: "Alert Category", key: "event_category"},
        {label: "Alert Type", key: "event_type"},
        {label: "Device Name", key: "device_name"},
        {label: "Device Description", key: "device_description"},
        {label: "Device Maker", key: "manufacture_name"},
        {label: "Device Unique ID", key: "manufacture_uuid"},
        {label: "Area", key: "area_name"},
        {label: "Resident First Name", key: "resident_first_name"},
        {label: "Resident Last Name", key: "resident_last_name"},
        {label: "Accepted User", key: "accepted_username"},
        {label: "Accepted User Display Name", key: "accepted_display"},
        {label: "Accepted Time", key: "accepted_datetime_utc"},
        {label: "Device Cleared Time", key: "ack_datetime_utc"},
        {label: "Vendor Invoked", key: "vendor"},
        {label: "Device Cleared Time", key: "ack_datetime_utc"}
    ];

    if (ReactSession.get("PAL") != null){
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    const [dataOrigin, setDataOrigin] = useState([]);
    const [activeData, setActiveData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [sorter, setSorter] = useState({
        sortColumn: "event_datetime_utc",
        sortOrder: "asc"
    });
    const [count, setCount] = useState(0);
    const [soundOff, setSoundOff] = useState(dData.soundsOff);
    const [soundInit, setSoundInit] = useState(true);
    const [alertKeyList, setAlertKeyList] = useState([]);
    const [campusId, setCampusId] = useState(dData.acnt);
    const [soundOn, setSoundOn] = useState(true);
    const [csvLink, setLink] = useState({
        filename: `${props?.toFetch}_alarms.csv`,
        headers: [],
        data: []
    });
    const [csvButton, setCSVButton] = useState(
        <CSVLink {...csvLink} uFEFF={false}>
            <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"} class={"btn btn-primary ms-2 px-4 mt-1"}/>
        </CSVLink>);

    useEffect(() => {
        setCount(count => 0);
    }, [props.toFetch]);

    useEffect(() => {
        let interval;
        interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "get-alerts-cloud", CampusId: dData.acnt, ToGet: props?.toFetch,
                                UserId: dData.user}).then(data => {
                    setDataOrigin(data ? data : []);
            })
            setCount((count) => 7);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        // Performs realtime search of data as location types.
        let tmpData;
        tmpData = [];
        for (let i=0; i<dataOrigin.length; i++){
                tmpData.push(dataOrigin[i]);
        }
        setActiveData(tmpData);
    }, [dataOrigin]);

    useEffect(() => {
        let tmp = [], playSound = false, newKeys = [];
        for (let i=0; i < activeData?.length; i++){
            if (props?.toFetch === "all" || props?.toFetch === activeData[i]?.event_category?.toLowerCase()){
                activeData[i].elapsed = durationToNow(activeData[i].event_date_time);
                tmp.push(activeData[i]);
                if (!alertKeyList.includes(activeData[i]?.event_id) && !newKeys.includes(activeData[i]?.event_id)){
                    newKeys.push(activeData[i]?.event_id)
                    if (!soundInit){
                        playSound = true;
                    }
                }
            }
        }
        if (newKeys.length > 0){
            setAlertKeyList((prevState) => {
                let items = [...prevState]
                for (let i=0; i < newKeys.length; i++){
                    items.push(newKeys[i])
                }
                return items
            })
        }
        if (tmp?.length > 0) {
            tmp.sort((a, b) => sortFunc(a, b, sorter.sortColumn, sorter.sortOrder));
        }
        setTableRows(<TableBody start={0} end={tmp?.length ? tmp.length : 0} tableData={tmp}
                                checkbox={false} writeAccess={false} unk={"a-"}
                                actionAppearance={() => {}} tableHeaders={headerData} editModal={() => {}}
                                parentComponent={"Alerts"} dData={dData} checkboxlist={[]} setCheckboxId={() => {}}
                                checkidkey={"event_id"} />);
        setLink(prevState => {
        return {...prevState,
            headers: csvHeaders,
            data: dataOrigin}
        });
        if (playSound && !soundOff){
            emergencySound();
        }
        if (soundInit){
            setSoundInit(false);
        }
    }, [activeData, sorter, count]);

    useEffect(() => {
        if (props?.toFetch === "maintenance"){
            setCSVButton(
                <CSVLink {...csvLink} uFEFF={false}>
                    <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"} class={"btn btn-primary me-4"}/>
                </CSVLink>
            );
        }else{
            setCSVButton("");
        }
    }, [csvLink]);

    function clearMaint(eventId, rowIndex){
        let ackDateTime, payload, clearBtn, loadBtn;
        clearBtn = `clearSubmit${rowIndex}`;
        loadBtn = `clearSubmitLoad${rowIndex}`;
        handleSubmitActions(clearBtn, loadBtn);
        ackDateTime = getUTCTimestamp();
        payload = [{"campus_id": campusId, "event_id": eventId, "finish_datetime_utc": ackDateTime,
                    "end_datetime_utc": ackDateTime}];
        ApiRequester({reqEndpoint: "handle-event", ReqType: "finish-completed", Payload: payload,
                            CampusId: campusId}).then(data => {
            if(data[0]){
                formSuccess("Alert cleared", clearBtn, loadBtn);
                setCount(0);
            }
            else{
                formFail("Error encountered, clear failed", clearBtn, loadBtn);
            }
        });
    }

    const tableHeaders = <TableHeaders checkbox={false} headerData={headerData} sortOrder={sorter.sortOrder}
                                       borderBuffer={true} sortColumn={sorter.sortColumn} sorter={sorter}
                                       setSorter={setSorter} />

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div>
                {csvButton}
            </div>
            <CampusDropDown campusId={campusId}
                            setCampusId={(item) => setCampusId(item)}
                            setCount={(item) => setCount(item)}
                            pageAction={() => {window.location.reload(true)}} />
        </div>
    );
    return (
        <div className={"main-content"}>
            <Header preTitle={"Alerts 4.0"} Title={props.toFetch} content={headerContent}/>
            <div className={"container-fluid"}>
                {!dData?.campusData[campusId.toString()]?.soundsOff &&
                    <div className={"form-check mb-3"}>
                        <input className={"form-check-input table-checkbox"} type={"checkbox"} checked={soundOff}
                               name={"sound-on-checkbox"} id={"sound-on-checkbox"} style={{width: "20px", height: "20px"}}
                               onChange={(e) => {
                                   setSoundOff(e.target.checked);
                                   setSoundsMute(dData, e.target.checked);
                               }} />
                        <label className={"form-check-label"} htmlFor={"sound-on-checkbox"}>Mute Sounds</label>
                    </div>
                }
                <div className={"row"}>
                    <table className={"table table-sm table-white table-hover"} style={{borderCollapse: "collapse"}}>
                        <thead className={dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString() ? "sticky-table-headX" : "sticky-table-head"}>
                            {tableHeaders}
                        </thead>
                        <tbody>
                            {tableRows}
                        </tbody>
                    </table>
                </div>
                <div className={"footer-spacer"}>

                </div>
            </div>
            <Footer />
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </div>
    );
};

export default CurrentCloudAlerts;