import {Link, Navigate} from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import ApiRequester from "./ApiRequester";
import {toast, ToastContainer} from "react-toastify";
import {Modal} from 'bootstrap';
import {CSVLink} from "react-csv";
import Header from "./Header";
import Button from "./component-assets/Button";
import {
    paginateData, sortFunc, formFail, handleSubmitActions, actionAppearance, deleteModalSingle,
    bulkDeleteModal, checkAll, formCheck, formSuccess, showModal, iAmHigherAuthority, roleWeightByName
} from "../libs"
import {Pagination} from "react-bootstrap";
import PaginationItems from "./component-assets/PaginationItems";
import Footer from "./Footer";
import TableBody from "./component-assets/TableBody";
import TableHeaders from "./component-assets/TableHeaders";
import BulkDeleteModal from "./component-assets/BulkDeleteModal";
import SingleDeleteModal from "./component-assets/SingleDeleteModal";
import PerPageModal from "./component-assets/PerPageModal";
import CampusDropDown from "./component-assets/CampusDropDown";

const UserGroups = (props) => {
    let dData, bData, headerData, csvHeaders, headerData2;

    headerData = [
        {label: "Group", key: "group_name", align: "left"},
        {label: "Capcode", key: "capcode", align: "center"}
    ];

    csvHeaders = [
        {label: "Group", key: "group_name"},
        {label: "Capcode", key: "capcode"},
    ];

    headerData2 = [
        {label: "Username", key: "username", align: "left"},
        {label: "Display Name", key: "display_name", align: "left"},
        {label: "Role", key: "user_role_name", align: "left"}
    ];

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    // Permission variables
    const [addRights, setAddRights] = useState(iAmHigherAuthority(10, dData.role_weight));
    const [editRights, setEditRights] = useState(iAmHigherAuthority(10, dData.role_weight));
    const [deleteRights, setDeleteRights] = useState(iAmHigherAuthority(10, dData.role_weight));
    // Main Table Variables
    const [dataOrigin, setDataOrigin] = useState([]);
    const [activeData, setActiveData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [loadState, setLoadState] = useState("groups");
    const [count, setCount] = useState(0);
    const [higherRole, setHigherRole] = useState(false);
    const [storePage, setStorePage] = useState(1);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(dData.perPage);
    const [tmpPerPage, setTmpPerPage] = useState(dData.perPage);
    const [dContent, setDContent] = useState([]);
    const [search, setSearch] = useState("");
    const [campusId, setCampusId] = useState(dData.acnt);
    const [groupIdList, setGroupIdList] = useState([]);
    const [roleData, setRoleData] = useState([]);
    const [sorter, setSorter] = useState({
        sortColumn: "group_name",
        sortOrder: "asc"
    });
    const [ppRules, setPRules] = useState({
        start: 0,
        end: 1,
        beginDot: false,
        endDot: false,
        pageMin: 1,
        pageMax: 1,
        max: 1
    });
    // User Picker Variables
    const [userData, setUserData] = useState([]);
    const [userIdList, setUserIdList] = useState([]);
    const [userDataCnt, setUserDataCnt] = useState(0);
    const [userTableRows, setUserTableRows] = useState([]);
    const [storedIndex, setStoredIndex] = useState(0);
    const [search2, setSearch2] = useState("")
    const [sorter2, setSorter2] = useState({
        sortColumn: "username",
        sortOrder: "asc"
    });
    // Other Enact Once Variables
    const [csvLink, setLink] = useState({
        filename: "Groups.csv",
        headers: [],
        data: []
    });
    const [csvButton, setCSVButton] = useState(
        <CSVLink {...csvLink} uFEFF={false}>
            <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"} class={"btn btn-primary ms-2 px-4 mt-1"}/>
        </CSVLink>);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "sp_dev_getusersbycampusid", reqType: "stored"}).then(data => {
                            setUserData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "sp_dev_getgroupsandusersbycampusid", reqType: "stored"}).then(data => {
                            setDataOrigin(data)
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId, Tbl: "att_user_roles",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setRoleData(data);
            });
            setCount(count => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        let pRules = paginateData(activeData, page, perPage);
        setPRules(pRules);
        if (activeData.length > 0) {
            activeData.sort((a, b) => sortFunc(a, b, sorter.sortColumn, sorter.sortOrder));
        }
        setTableRows(<TableBody start={pRules.start} end={pRules.end} tableData={activeData} checkbox={deleteRights}
                                writeAccess={editRights} tableHeaders={headerData} unk={"a-"}
                                actionAppearance={() => actionAppearance(pRules.start, pRules.end, ["deleteBtn"])}
                                editModal={editModal} parentComponent={"Groups"} dData={dData} modal={true}
                                checkboxlist={groupIdList} setCheckboxId={(value) => {setGroupIdList(value)}}
                                checkidkey={"group_id"}  />);
    }, [activeData, sorter, page, perPage, groupIdList, userData]);

    useEffect(() => {
        setLink(prevState => {
            return {...prevState,
                headers: csvHeaders,
                data: dataOrigin}
        });
    }, [dataOrigin]);

    useEffect(() => {
        setCSVButton(
            <CSVLink {...csvLink} uFEFF={false}>
                <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"} class={"btn btn-primary ms-2 px-4 mt-1"}/>
            </CSVLink>
        );
    }, [csvLink]);

    useEffect(() => {
        let tmpGroups, input, pRules;
        tmpGroups = [];
        input = search.toString().toLowerCase();
        for (let i=0; i < dataOrigin.length; i++){
            if ((dataOrigin[i]?.group_name && dataOrigin[i]?.group_name?.toLowerCase()?.search(input) !== -1)){
                tmpGroups.push(dataOrigin[i]);
            }
        }
        pRules = paginateData(tmpGroups, page, perPage);
        if (input && page > pRules.pageMax){
            setPage(pRules.pageMax);
        }else if (!input){
            setPage(storePage);
        }
        setPRules(pRules);
        setActiveData(tmpGroups);
    }, [search, dataOrigin]);

    useEffect(() => {
        let i, tmp = [], input;
        input = search2.toString().toLowerCase();
        for (i = 0; i < userData.length; i++){
            if ((userData[i]?.username && userData[i]?.username?.toLowerCase()?.search(input) !== -1) ||
                (userData[i]?.display_name && userData[i]?.display_name?.toLowerCase()?.search(input) !== -1)){
                tmp.push(userData[i]);
            }
        }
        setUserDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, sorter2.sortColumn, sorter2.sortOrder));
        setUserTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={editRights} writeAccess={editRights}
                                    actionAppearance={() => {}} tableHeaders={headerData2} checkboxId={"uCheckGroup"}
                                    modal={false} editModal={() => {}} unk={"x-"}
                                    parentComponent={"UserManagement"} dData={dData} checkboxlist={userIdList}
                                    setCheckboxId={(value) => {setUserIdList(value)}} checkidkey={"user_id"} />);
    }, [userData, userIdList, sorter2, search2, activeData]);

    function editModal(groupIndex){
        let groupId, groupname, dataIndex, modal, delBtn, uIdList = [];
        if (editRights){
            dataIndex = parseInt(groupIndex);
            setSearch2("");
            setStoredIndex(dataIndex);
            document.getElementById("lastIndex").value = dataIndex;
            groupname = document.getElementById("groupNameE");
            groupId = document.getElementById("groupIdE");
            delBtn = document.getElementById("deleteSingleBtn");
            document.getElementById("uCheckHead2").checked = false;
            for (let i2=0; i2<activeData[dataIndex]?.users?.length; i2++){
                uIdList.push(activeData[dataIndex]?.users[i2]?.user_id.toString());
            }
            setUserIdList(uIdList);
            document.getElementById("uCheckHead2").checked = uIdList && uIdList.length === userData.length;
            document.getElementById("capcodeE").value = activeData[dataIndex]?.capcode;
            groupname.value = activeData[dataIndex]?.group_name;
            groupId.value = activeData[dataIndex]?.group_id;
            delBtn.onclick = () => {
                setDContent([[groupId.value, ""]]);
                deleteModalSingle(groupname.value, groupname.value);
            };
            formCheck("editForm", "editSubmit");
            modal = new Modal(document.getElementById("editmodal"), {});
            modal.show();
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function filterSubmit(){
        handleSubmitActions("filterSubmit", "loadFilterSubmit");
        setPerPage(tmpPerPage);
        setGroupIdList([]);
        dData.perPage = tmpPerPage;
        setPage(1);
        ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
        formSuccess("Page parameters stored to session.", "filterSubmit", "loadFilterSubmit", "cancelFilterBtn");
        setCount(0);
    }

    function formAddSubmit(){
        // User add form submit function
        let groupname, payload, capcode, cognitoIds = [];
        if (addRights){
            handleSubmitActions("addSubmit", "loadAddSubmit");
            groupname = document.getElementById("groupname").value;
            capcode = document.getElementById("capcode").value;
            for (let i = 0; i < userData.length; i++){
                if (userIdList.includes(userData[i]?.user_id.toString())){
                    cognitoIds.push(userData[i]?.cognito_id);
                }
            }
            payload = [{campus_id: campusId, group_name: groupname, capcode: capcode, user_ids: userIdList,
                        cognito_ids: cognitoIds}];
            ApiRequester({reqEndpoint: "mytransaction", TransType: "group_add", Payload: payload,
                                CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
                    if (data[0] === true){
                        setCount(0);
                        setGroupIdList([]);
                        formSuccess("Group has been added.", "addSubmit", "loadAddSubmit", "cancelAddBtn", "addForm");
                    }
                    else{
                        formFail(`Error during add, error code: ${data[1]}`, "addSubmit", "loadAddSubmit");
                    }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function formEditSubmit(){
        // User add form submit function
        let groupId, groupname, capcode, payload, cognitoIds = [], removeList = [], found;
        if (editRights){
            handleSubmitActions("editSubmit", "loadEditSubmit");
            groupId = document.getElementById("groupIdE").value;
            groupname = document.getElementById("groupNameE").value;
            capcode = document.getElementById("capcodeE").value;
            // Special process here to determine who has been removed for dynamoDB due to no trash and load mechanism.
            for (let i = 0; i < userData.length; i++){
                found = false
                if (userIdList.includes(userData[i]?.user_id.toString())){
                    cognitoIds.push(userData[i]?.cognito_id);
                }else{
                    for (let i2=0; i2 < activeData[storedIndex]?.users?.length; i2++){
                        if (userData[i]?.user_id.toString() === activeData[storedIndex]?.users[i2]?.user_id.toString()){
                            found = true;
                            break
                        }
                    }
                    if (found){
                        removeList.push(userData[i]?.cognito_id);
                    }
                }
            }
            payload = [{group_name: groupname, group_id: parseInt(groupId), capcode: capcode, user_ids: userIdList,
                        cognito_ids: cognitoIds, campus_id: dData.acnt, remove_list: removeList}];
            ApiRequester({reqEndpoint: "mytransaction", TransType: "group_edit", Payload: payload,
                                CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
                if (data[0]){
                    setCount(0);
                    setGroupIdList([]);
                    formSuccess("Group updated.", "editSubmit", "loadEditSubmit", "cancelEditBtn");
                }
                else{
                    formFail(`Error during save, error code: ${data[1]}`, "editSubmit", "loadEditSubmit");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function checkUserAll(start, end, dData={}, checkboxlist=[], setcheckboxlist=() => {}, headerField="uCheckHead", rowCheckId="uCheck"){
        // Checks and unchecks all boxes from header checkbox with user validation setting.
        let status, nxtCheck, nxtRole, entityList = [];
        status = document.getElementById(headerField);
        if (status.checked){
            for (let i=start; i < end; i++){
                nxtCheck = document.getElementById(rowCheckId+i.toString());
                nxtRole = document.getElementById("user_role_name"+i.toString());
                if (nxtCheck !== undefined){
                    if (nxtRole !== undefined){
                        if (iAmHigherAuthority(roleWeightByName(nxtRole.innerText, roleData), dData?.role_weight)){
                            entityList.push(nxtCheck.value.toString());
                        }else{
                            if (checkboxlist.includes(nxtCheck.value.toString())){
                                entityList.push(nxtCheck.value.toString());
                            }
                        }
                    }else{
                        entityList.push(nxtCheck.value.toString());
                    }
                }
            }
            setcheckboxlist(entityList);
        }else{
            for (let i=start; i < end; i++){
                nxtCheck = document.getElementById(rowCheckId+i.toString());
                nxtRole = document.getElementById("user_role_name"+i.toString());
                if (nxtCheck !== undefined){
                    if (nxtRole !== undefined){
                        if (!iAmHigherAuthority(roleWeightByName(nxtRole.innerText, roleData), dData?.role_weight)){
                            if (checkboxlist.includes(nxtCheck.value.toString())){
                                entityList.push(nxtCheck.value.toString())
                            }
                        }
                    }
                }
            }
            setcheckboxlist(entityList);
        }
    }

    function unpackUsers(groupId){
        let ret = {userIds: [], cognitoIds: []};
        for (let i = 0; i < activeData.length; i++){
            if (activeData[i]?.users?.length > 0){
                for (let i2 = 0; i2 < activeData[i].users.length; i2++){
                    ret.userIds.push(activeData[i].users[i2].user_id);
                    ret.cognitoIds.push(activeData[i].users[i2].cognito_id);
                }
            }
        }
        return ret;
    }

    function deletePerform(submitBtn, loadBtn, closeId, start, end){
        let payload = [], uIds;
        if (deleteRights){
            handleSubmitActions(submitBtn, loadBtn);
            for (let i=0; i < dContent.length; i++){
                uIds = unpackUsers(dContent[i][0]);
                payload.push({group_id: dContent[i][0], user_ids: uIds.userIds, cognito_ids: uIds.cognitoIds});
            }
            ApiRequester({reqEndpoint: "mytransaction", TransType: "group_delete", Payload: payload,
                                CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data=> {
                if (data[0]){
                    formSuccess("Groups have been deleted.", submitBtn, loadBtn, closeId);
                    setCount(0);
                    setGroupIdList([]);
                    document.getElementById("uCheckHead").checked = false;
                    checkAll(start, end);
                    actionAppearance(start, end, ["deleteBtn"]);
                }
                else{
                    formFail(`Error during delete, error code: ${data[1]}`, submitBtn, loadBtn);
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    const tableHeaders = <TableHeaders checkbox={deleteRights} headerData={headerData} checkAll={() => checkAll(ppRules.start, ppRules.end)}
                                       writeAccess={editRights} sortOrder={sorter.sortOrder} modal_name={"Edit"}
                                       sortColumn={sorter.sortColumn} sorter={sorter} setSorter={setSorter} modal={editRights}
                                       actionAppearance={() => actionAppearance(ppRules.start, ppRules.end, ["deleteBtn"])}
                                       checkboxlist={groupIdList} />

    const userTableHead = <TableHeaders checkbox={editRights} headerData={headerData2} checkboxlist={userIdList}
                                         checkAll={() => checkUserAll(0, userDataCnt ? userDataCnt : 0, dData, userIdList,
                                                                        setUserIdList, "uCheckHead2", "uCheckGroup")}
                                         writeAccess={editRights} sortOrder={sorter2.sortOrder} checkboxHeaderId={"uCheckHead2"}
                                         sortColumn={sorter2.sortColumn} sorter={sorter2} setSorter={setSorter2}
                                         actionAppearance={() => {}} />
    // Must have separate header component per table so rendered header ID's do not conflict
    const userAddTableHead = <TableHeaders checkbox={addRights} headerData={headerData2} checkboxlist={userIdList}
                                         checkAll={() => checkUserAll(0, userDataCnt ? userDataCnt : 0, dData, userIdList,
                                                                        setUserIdList, "uCheckHead3", "uCheckGroup")}
                                         writeAccess={addRights} sortOrder={sorter2.sortOrder} checkboxHeaderId={"uCheckHead3"}
                                         sortColumn={sorter2.sortColumn} sorter={sorter2} setSorter={setSorter2}
                                         actionAppearance={() => {}} />

    const contentHeader = (
        <div className={"d-flex justify-content-between"}>
            <div style={{minWidth: "360px"}}>
                {deleteRights && <Button text={"Delete"} type={"button"} class={"btn btn-danger ms-2 hide mt-1"}
                                     id={"deleteBtn"} icon={true} iconClass={"fe fe-trash-2 me-2"}
                                     onClick={() => bulkDeleteModal(ppRules.start, ppRules.end, activeData,
                                         "group_name", ["group_id", ""], "group_name", setDContent)} />
                }
                <div className={"form-outline d-inline-flex align-bottom ms-2"}>
                    <input type={"search"} id={"search1"} className={"form-control search-input mt-1"}
                         onKeyUp={(e) => setSearch(e.target.value)} />
                    <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left mt-1"}
                            style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                </div>
                {csvButton}
                {addRights && <Button text={"New Group"} type={"button"} class={"btn btn-primary ms-2 mt-1"}
                                     icon={true} iconClass={"fe fe-plus me-2"}
                                     onClick={() => {
                                         setUserIdList([]);
                                         setSearch2("");
                                         setStoredIndex(undefined);
                                         document.getElementById("uCheckHead3").checked = false;
                                         showModal("addmodal");
                                         formCheck("addForm", "addSubmit");}} />
                }
            </div>
            <CampusDropDown campusId={campusId} setCampusId={(item) => setCampusId(item)} setCount={(item) => setCount(item)} pageAction={() => {}}/>
        </div>
    );

    const footer = (
        <div>
            <Pagination>
                <PaginationItems setPage={setPage} page={page} beginDot={ppRules.beginDot}
                                 endDot={ppRules.endDot} pageMin={ppRules.pageMin} pageMax={ppRules.pageMax}
                                 max={ppRules.max}/>
            </Pagination>
            <Button class={"btn btn-secondary fs-5 ms-4"} text={perPage} onClick={() => showModal("filtermodal")}/>
        </div>
    );

    return (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Community"} Title={"Users"} content={contentHeader} />
                <div className={"container-fluid"}>
                    <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                        <li className={"nav-item"}>
                            <Link to={"/users/usermanagement"}>
                                <Button text={"Individuals"} class={loadState === "individuals" ? "nav-link active" : "nav-link"}
                                    onClick={() => setLoadState("individuals")} />
                            </Link>
                        </li>
                        <li className={"nav-item"}>
                            <Link to={"/users/groups"}>
                                <Button text={"Groups"} class={loadState === "groups" ? "nav-link active" : "nav-link"}
                                    onClick={() => setLoadState("groups")} />
                            </Link>
                        </li>
                    </ul>
                    <table className={"table table-sm table-white table-hover"}>
                        <thead className={dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString() ? "sticky-table-headX" : "sticky-table-head"}>
                            {tableHeaders}
                        </thead>
                        <tbody id={"tBody"}>
                            {tableRows}
                        </tbody>
                    </table>
                    <div className={"footer-spacer"}>

                    </div>
                </div>
                <Footer center={footer} />
            </div>
            <div className={"modal fade"} id={"addmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Add Group</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"addForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"groupname"}
                                           onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           required={true} pattern={"[a-zA-Z ]{0,100}"}/>
                                    <label htmlFor={"groupname"}>Group</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a valid group name.
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"capcode"}
                                            onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           required={true} pattern={"[0-9]{7}"}/>
                                    <label htmlFor={"capcode"}>Capcode</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a valid capcode!
                                    </div>
                                </div>
                                <hr />
                                <div className={"mt-1"}>
                                    <h3>Users</h3>
                                    <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                        <input type={"search"} id={"search2"} className={"form-control search-input"}
                                               value={search2}
                                               onChange={(e) => setSearch2(e.target.value)} />
                                        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                    </div>
                                    <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                        <table className={"table table-sm table-white table-hover"}>
                                            <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                {userAddTableHead}
                                            </thead>
                                            <tbody>
                                                {userTableRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <hr/>
                                <div>
                                    <h3 className={"fw-bold"}>*Remember to build your escalation list on your server to
                                        match any groups
                                        created. If you have questions, please contact our Customer Experience
                                        Team*</h3>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <button type={"button"} className={"btn btn-secondary"} id={"cancelAddBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                {addRights &&
                                    <>
                                        <Button type={"button"} class={"btn btn-primary d-none"} disabled={true} spinner={true} text={"Loading"} id={"loadAddSubmit"}/>
                                        <button type={"button"} className={"btn btn-primary"} onClick={() => formAddSubmit()} id={"addSubmit"}>Submit</button>
                                    </>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"editmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Edit Group</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"editForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <input type={"hidden"} id={"groupIdE"} />
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"groupNameE"}
                                           onKeyUp={() => formCheck("editForm", "editSubmit")}
                                           required={true} pattern={"[a-zA-Z ]{0,100}"}/>
                                    <label htmlFor={"groupNameE"}>Group</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a group name!
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"capcodeE"}
                                           onKeyUp={() => formCheck("editForm", "editSubmit")}
                                           required={true} pattern={"[0-9]{7}"} />
                                    <label htmlFor={"capcodeE"}>Capcode</label>
                                    <div className={"invalid-feedback"}>
                                        Enter a valid capcode!
                                    </div>
                                </div>
                                <hr />
                                <div className={"mt-1"}>
                                    <h3>Users</h3>
                                    <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                        <input type={"search"} id={"search2E"} className={"form-control search-input"}
                                               value={search2}
                                               onChange={(e) => setSearch2(e.target.value)} />
                                        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                    </div>
                                    <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                        <table className={"table table-sm table-white table-hover"}>
                                            <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                {userTableHead}
                                            </thead>
                                            <tbody>
                                                {userTableRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"} style={{justifyContent: "space-between"}}>
                                <div>
                                    {deleteRights && <Button text={"Delete"} type={"button"} class={"btn btn-danger me-3"}
                                                         id={"deleteSingleBtn"} icon={true}
                                                         iconClass={"fe fe-trash-2 me-2"}/>
                                    }
                                </div>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelEditBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                    {editRights &&
                                        <>
                                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadEditSubmit"}/>
                                            <button type={"button"} className={"btn btn-primary ms-3"} onClick={() => formEditSubmit()} id={"editSubmit"}>Submit</button>
                                        </>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <input className={"d-none"} type={"hidden"} id={"lastIndex"} />
            <SingleDeleteModal deletePerform={() => deletePerform("deleteSubmit", "loadDeleteSubmit",
                                                                "confirmClose", ppRules.start, ppRules.end)} header={"Delete Group"}/>
            <BulkDeleteModal deletePerform={() => deletePerform("bulkDeleteSubmit", "loadBulkDeleteSubmit",
                                                                "bulkConfirmClose", ppRules.start, ppRules.end)}
                             start={ppRules.start} end={ppRules.end} header={"Delete Groups"} />
            <PerPageModal filterSubmit={() => filterSubmit()} tmpPerPage={tmpPerPage}
                          setTmpPerPage={(sudocode) => setTmpPerPage(sudocode)} setPage={(sudocode) => setPage(sudocode)}/>
            <input type={"hidden"} id={"uContent"}/>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </>
    );
};

export default UserGroups;