import {Link} from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import Header from "../Header";
import Button from "../component-assets/Button";
import ApiRequester from "../ApiRequester";
import MaterialIcon from "material-icons-react";
import {
    actionAppearance, bulkDeleteModal,
    checkAll, convertIsoToLocal, iAmHigherAuthority,
    formCheck, formFail, formSuccess, handleSubmitActions, paginateData, showModal, sortFunc, swapModal, toggleCollapse
} from "../../libs";
import TableBody from "../component-assets/TableBody";
import TableHeaders from "../component-assets/TableHeaders";
import PaginationItems from "../component-assets/PaginationItems";
import {Pagination} from "react-bootstrap";
import BulkDeleteModal from "../component-assets/BulkDeleteModal";
import {v4 as uuidv4} from "uuid";

const ServerProfile = (props) => {
    let dData, bData, serverHeaderData, matchHeaderData, mqHeaderData;

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    serverHeaderData = [
        {label: "Config Name", key: "config_name", align: "left"},
        {label: "Value", key: "config_value", align: "left"},
        {label: "Data Type", key: "data_type", align: "center"},
        {label: "Description", key: "description", align: "left"}
    ]

    matchHeaderData = [
        {label: "Alert Trigger/\nAlert Replace", key: ["create_pattern", "create_replacement"], align: "center", type: "stack"},
        {label: "Repeat Trigger/\nRepeat Replace", key: ["repeat_pattern", "repeat_replacement"], align: "center", type: "stack"},
        {label: "Done Trigger/\nRepeat Replace", key: ["done_pattern", "done_replacement"], align: "center", type: "stack"},
        {label: "Ignore Trigger", key: "ignore_pattern", align: "center"},
        {label: "Device Trigger/\nDevice Replace", key: ["device_pattern", "device_replacement"], align: "center", type: "stack"},
        {label: "Area Trigger/\nArea Replace", key: ["area_pattern", "area_id"], align: "center", type: "stack"},
        {label: "Case Sensitive", key: "is_case_sensitive", align: "center", type: "bool", t: "True", f: "False"},
    ];

    // Permission Variables
    const [editRights, setEditRights] = useState(iAmHigherAuthority(10, dData.role_weight));
    // Main Page Variables
    const [serverId, setServerId] = useState(props?.id);
    const [count, setCount] = useState(0);
    const [pCount, setPCount] = useState(0);
    const [iCount, setICount] = useState(0);
    const [campusId, setCampusId] = useState(dData.acnt);
    const [deviceData, setDeviceData] = useState([]);
    const [dbValid, setDbValid] = useState(null);
    const [dbData, setDbData] = useState([]);
    const [localData, setLocalData] = useState([]);
    const [serverActiveDb, setServerActiveDb] = useState([]);
    const [serverOriginDb, setServerOriginDb] = useState([]);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [tabItem, setTabItem] = useState("basic");
    // Basic Information Variables
    const [basicData, setBasicData] = useState([]);
    const [serverName, setServerName] = useState("");
    const [macAddr, setMacAddr] = useState("");
    const [product, setProduct] = useState("");
    const [productId, setProductId] = useState("");
    const [areaId, setAreaId] = useState("");
    const [areaName, setAreaName] = useState("");
    const [isActive, setIsActive] = useState(0);
    const [interfaceName, setInterfaceName] = useState("");
    const [interfaceId, setInterfaceId] = useState(0);
    const [os, setOS] = useState("");
    const [hardware, setHardware] = useState("");
    const [ipAddr, setIPAddr] = useState("");
    const [locationData, setLocationData] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);
    const [basicLoaded, setBasicLoaded] = useState(false);
    const [areaLoaded, setAreaLoaded] = useState(false);
    // Active Table Variables
    const [storePage, setStorePage] = useState(1);
    const [page, setPage] = useState(1);
    const [page2, setPage2] = useState(1);
    const [perPage, setPerPage] = useState(dData.perPage);
    const [search, setSearch] = useState("");
    const [sorter, setSorter] = useState({
        sortColumn: "config_name",
        sortOrder: "asc"
    });
    const [ppRules, setPRules] = useState({
        start: 0,
        end: 1,
        beginDot: false,
        endDot: false,
        pageMin: 1,
        pageMax: 1,
        max: 1
    });
    // Match Term Variables
    const [matchTermsRows, setMatchTermsRows] = useState([]);
    const [matchOrigin, setMatchOrigin] = useState([]);
    const [matchActive, setMatchActive] = useState([]);
    const [matchIdList, setMatchIdList] = useState([])
    const [storedMatchIndex, setStoredMatchIndex] = useState(0);
    const [matchDeviceMap, setMatchDeviceMap] = useState([]);
    const [tmpDeviceMap, setTmpDeviceMap] = useState([]);
    const [matchAreaMap, setMatchAreaMap] = useState([]);
    const [tmpAreaMap, setTmpAreaMap] = useState([]);
    const [dContent, setDContent] = useState([]);
    const [serverTableRows, setServerTableRows] = useState([]);
    const [sorter2, setSorter2] = useState({
        sortColumn: "match_pattern",
        sortOrder: "asc"
    });
    const [ppRules2, setPRules2] = useState({
        start: 0,
        end: 1,
        beginDot: false,
        endDot: false,
        pageMin: 1,
        pageMax: 1,
        max: 1
    });
    // MQ Status Table Variables
    const [mqOriginData, setMqOriginData] = useState([]);
    const [uptime, setUptime] = useState("");
    const [lastReboot, setLastReboot] = useState("");
    const [activeAlarmsCount, setActiveAlarmsCount] = useState("");
    const [recentAlarmTime, setRecentAlarmTime] = useState("");
    const [lastHeartbeat, setLastHeartbeat] = useState("");
    const [evActive, setEvActive] = useState(false);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", payload: {device_filter: serverId?.toString(),
                                campus_id: campusId}, procedure: "sp_dev_getPalcareDevicesByCampusID",
                                reqType: "stored"}).then(data => {
                setBasicData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId,
                                procedure: "sp_dev_getareasbycampusid", reqType: "stored"}).then(data => {
                setLocationData(data);
                setAreaLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId,
                                payload: {device_filter: serverId, campus_id: campusId},
                                procedure: "getserverstatushistory", reqType: "stored"}).then(data => {
                setDeviceData(data);
            })
            setCount(() => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setPCount(count => count - 1);
        }, 1000);
        if (pCount >= 0 && pCount % 5 === 0 && macAddr && macAddr?.length > 0){
            ApiRequester({reqEndpoint: "myfetch", pKey: "mac_addr", pVal: macAddr.replaceAll(":", ""),
                                Tbl: "core_active_event_snapshots", procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                setLocalData(data ? data : []);
                if (data && data.length > 0){
                    setPCount(-1);
                }
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "server_id", pVal: macAddr.replaceAll(":", ""),
                                Tbl: "core_config", procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                setServerOriginDb(data ? data : []);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "palcare_server_id",
                                pVal: macAddr.replaceAll(":", ""),
                                payload: {palcare_server_id: macAddr.replaceAll(":", "")},
                                procedure: "getserverstatusbymac", reqType: "stored"}).then(data => {
                setMqOriginData(data ? data : []);
            })
        }
        return () => clearInterval(interval);
    }, [pCount, macAddr]);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setICount(count => count - 1);
        }, 1000);
        if (iCount <= 0 && [1, 2].includes(interfaceId)){
            ApiRequester({reqEndpoint: "myfetch", pKey: "palcare_device_id", pVal: serverId,
                                Tbl: "core_matchterms", procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                setMatchOrigin(data ? data : []);
            });
            setICount(() => 600);
        }
        return () => clearInterval(interval);
    }, [iCount, interfaceId]);

    useEffect(() => {
        if (![basicLoaded, areaLoaded].includes(false)){
            setPageLoaded(true);
        }
    }, [basicLoaded, areaLoaded]);

    useEffect(() => {
        let pRules = paginateData(serverActiveDb, page, perPage);
        setPRules(pRules);
        if (serverActiveDb.length > 0) {
            serverActiveDb.sort((a, b) => sortFunc(a, b, sorter.sortColumn, sorter.sortOrder));
        }
        setServerTableRows(<TableBody start={pRules.start} end={pRules.end} tableData={serverActiveDb} checkbox={false} unk={"a-"}
                                      writeAccess={false} addlEditKey={""} addlEditCheck={false} actionAppearance={() => {}}
                                      tableHeaders={serverHeaderData} editModal={() => {}} parentComponent={"Server"} dData={dData}
                                      modal={false} checkboxlist={[]} setCheckboxId={() => {}} checkidkey={""} />);
    }, [serverActiveDb, sorter, page, perPage]);

    useEffect(() => {
        let pRules = paginateData(matchActive, page2, perPage);
        setPRules2(pRules);
        if (matchActive.length > 0) {
            matchActive.sort((a, b) => sortFunc(a, b, sorter2.sortColumn, sorter2.sortOrder));
        }
        setMatchTermsRows(<TableBody start={pRules.start} end={pRules.end} tableData={matchActive} checkbox={editRights} unk={"b-"}
                                     writeAccess={editRights} addlEditKey={""} addlEditCheck={false}
                                     actionAppearance={() => actionAppearance(pRules.start, pRules.end, ["deleteBtn"])}
                                     tableHeaders={matchHeaderData} editModal={editMatchModal} parentComponent={"Server"} dData={dData}
                                     modal={true} checkboxlist={matchIdList} setCheckboxId={(value) => {setMatchIdList(value)}}
                                     checkidkey={"matchterm_id"} />);
    }, [matchActive, sorter2, page2, perPage, matchIdList]);

    useEffect(() => {
        // Performs realtime search of data as location types.
        let tmpData, input, pRules;
        tmpData = [];
        input = document.getElementById("search1").value.toString().toLowerCase();
        for (let i=0; i < serverOriginDb.length; i++){
            if ((serverOriginDb[i]?.config_name && serverOriginDb[i]?.config_name?.toString()?.toLowerCase()?.search(input) !== -1) ||
                (serverOriginDb[i]?.config_value && serverOriginDb[i]?.config_value?.toString()?.toLowerCase()?.search(input) !== -1) ||
                (serverOriginDb[i]?.config_value && serverOriginDb[i]?.description?.toString()?.toLowerCase()?.search(input) !== -1)){
                tmpData.push(serverOriginDb[i]);
            }
            if (serverOriginDb[i]?.config_name.toLowerCase() === "config_palsitename"){
                document.getElementById("siteNameId").value = serverOriginDb[i]?.config_id;
            }
        }
        pRules = paginateData(tmpData, page, perPage);
        if (input && page > pRules.pageMax){
            setPage(pRules.pageMax);
        }else if (!input){
            setPage(storePage);
        }
        setPRules(pRules);
        setServerActiveDb(tmpData);
    }, [search, serverOriginDb]);

    useEffect(() => {
        // Area to perform search and front end filtering.
        let tmpData = [];
        for (let i=0; i < matchOrigin.length; i++){
            tmpData.push(matchOrigin[i]);
        }
        setMatchActive(tmpData);
    }, [matchOrigin]);

    useEffect(() => {
        // Performs realtime search of data into active filtered data
        if (mqOriginData.length > 0){
            setRecentAlarmTime(() => mqOriginData[0]?.recent_alarm_input_time !== undefined ? convertIsoToLocal(mqOriginData[0]?.recent_alarm_input_time) : "");
            setActiveAlarmsCount(() => mqOriginData[0]?.count_active_alarms !== undefined ? mqOriginData[0]?.count_active_alarms : "");
            setLastReboot(() => mqOriginData[0]?.last_reboot_date !== undefined ? convertIsoToLocal(mqOriginData[0]?.last_reboot_date) : "");
            setUptime(() => mqOriginData[0]?.uptime_hrs !== undefined ? mqOriginData[0]?.uptime_hrs : "");
            setLastHeartbeat(() => mqOriginData[0]?.create_time !== undefined ? convertIsoToLocal(mqOriginData[0]?.create_time) : "")
        }
    }, [mqOriginData]);

    useEffect(() => {
        if (basicData.length > 0){
            setServerName(() => basicData[0]?.description ? basicData[0].description : "");
            setMacAddr(() => basicData[0]?.mac_addr ? basicData[0].mac_addr : "");
            setProduct(() => basicData[0]?.product ? basicData[0].product : "");
            setProductId(() => basicData[0]?.palcare_product_id ? basicData[0].palcare_product_id : "");
            setAreaId(() => basicData[0]?.area_id ? basicData[0].area_id : "");
            setAreaName(() => basicData[0]?.area_name ? basicData[0].area_name : "");
            setInterfaceName(() => basicData[0]?.palcare_interface_name ? basicData[0].palcare_interface_name : "");
            setInterfaceId(() => basicData[0]?.palcare_interface_id ? basicData[0].palcare_interface_id : "");
            setOS(() => basicData[0]?.os ? basicData[0].os : "");
            setHardware(() => basicData[0]?.hardware ? basicData[0].hardware : "");
            setIPAddr(() => basicData[0]?.ip_address ? basicData[0].ip_address : "");
            setIsActive(() => basicData[0]?.is_active ? 1 : 0);
            setBasicLoaded(true);
        }
    }, [basicData]);

    useEffect(() => {
        if (macAddr && macAddr !== ""){
            ApiRequester({reqEndpoint: "myvalidation", payload: {server_id: macAddr.replaceAll(":", ""), campus_id: campusId},
                                reqType: "ping"}).then(data => {
                setDbValid(data?.length > 0 ? data[0] : {});
            });
        }
    }, [macAddr]);

    useEffect(() => {
        let i, tmp = [];
        for (i = 0; i < locationData?.length; i++){
            tmp.push(
                <option key={"location-option"+i.toString()} value={locationData[i].area_id}>{locationData[i].area_name}</option>
            );
        }
        setLocationOptions(() => tmp);
    }, [locationData]);

    function editDBModal(){
        for (let i=0; i < serverActiveDb.length; i++){
            if (serverActiveDb[i]?.config_name.toLowerCase() === "in_serial_portname"){
                document.getElementById("serialIn").value = serverActiveDb[i]?.config_value;
                document.getElementById("serialInId").value = serverActiveDb[i]?.config_id;
            }else if (serverActiveDb[i]?.config_name.toLowerCase() === "out_serial_portname"){
                document.getElementById("serialOut").value = serverActiveDb[i]?.config_value;
                document.getElementById("serialOutId").value = serverActiveDb[i]?.config_id;
            }else if (serverActiveDb[i]?.config_name.toLowerCase() === "in_serial_msgdelimiter"){
                document.getElementById("in_serial_delimiter").value = serverActiveDb[i]?.config_value;
                document.getElementById("in_serial_delimiter_id").value = serverActiveDb[i]?.config_id;
            }else if (serverActiveDb[i]?.config_name.toLowerCase() === "out_serial_msgdelimiter"){
                document.getElementById("out_serial_delimiter").value = serverActiveDb[i]?.config_value;
                document.getElementById("out_serial_delimiter_id").value = serverActiveDb[i]?.config_id;
            }else if (serverActiveDb[i]?.config_name.toLowerCase() === "in_serial_baud"){
                document.getElementById("in_serial_baud").value = serverActiveDb[i]?.config_value;
                document.getElementById("in_serial_baud_id").value = serverActiveDb[i]?.config_id;
            }else if (serverActiveDb[i]?.config_name.toLowerCase() === "out_serial_baud"){
                document.getElementById("out_serial_baud").value = serverActiveDb[i]?.config_value;
                document.getElementById("out_serial_baud_id").value = serverActiveDb[i]?.config_id;
            }else if (serverActiveDb[i]?.config_name.toLowerCase() === "config_ttl"){
                document.getElementById("config_ttl").value = serverActiveDb[i]?.config_value;
                document.getElementById("config_ttl_id").value = serverActiveDb[i]?.config_id;
            }else if (serverActiveDb[i]?.config_name.toLowerCase() === "config_env_sample_rate"){
                document.getElementById("config_env_sample_rate").value = parseInt(serverActiveDb[i]?.config_value) / 60;
                document.getElementById("config_env_sample_rate_id").value = serverActiveDb[i]?.config_id;
            }else if (serverActiveDb[i]?.config_name.toLowerCase() === "config_environmental_processing"){
                document.getElementById("config_env_processing").checked = serverActiveDb[i]?.config_value === "YES";
                setEvActive(serverActiveDb[i]?.config_value === "YES");
                document.getElementById("config_env_processing_id").value = serverActiveDb[i]?.config_id;
            }else if (serverActiveDb[i]?.config_name.toLowerCase() === "config_inohightemp"){
                document.getElementById("config_high_temp").value = serverActiveDb[i]?.config_value;
                document.getElementById("config_high_temp_id").value = serverActiveDb[i]?.config_id;
            }else if (serverActiveDb[i]?.config_name.toLowerCase() === "config_inolowtemp"){
                document.getElementById("config_low_temp").value = serverActiveDb[i]?.config_value;
                document.getElementById("config_low_temp_id").value = serverActiveDb[i]?.config_id;
            }else if (serverActiveDb[i]?.config_name.toLowerCase() === "config_inolowbat"){
                document.getElementById("config_low_bat").value = serverActiveDb[i]?.config_value;
                document.getElementById("config_low_bat_id").value = serverActiveDb[i]?.config_id;
            }
        }
        showModal("dbeditmodal");
    }

    function basicActionHandling(){
        document.getElementById("name-static").classList.toggle("d-none");
        document.getElementById("name-edit").classList.toggle("d-none");
        document.getElementById("interface-static").classList.toggle("d-none");
        document.getElementById("interface-edit").classList.toggle("d-none");
        document.getElementById("area-static").classList.toggle("d-none");
        document.getElementById("area-edit").classList.toggle("d-none");
        document.getElementById("status-static").classList.toggle("d-none");
        document.getElementById("status-edit").classList.toggle("d-none");
        if (document.getElementById("edit-basic-btn").innerText === "Edit"){
            document.getElementById("edit-basic-btn").innerText = "Cancel";
            document.getElementById("save-basic-btn").classList.toggle("d-none");
            document.getElementById("name-edit").value = serverName;
            document.getElementById("interface-edit").value = interfaceId;
            document.getElementById("area-edit").value = areaId;
            document.getElementById("status-edit").value = isActive ? 1 : 0;
        }else{
            document.getElementById("edit-basic-btn").innerText = "Edit";
            document.getElementById("save-basic-btn").classList.toggle("d-none");
        }
        formCheck("basic-form", "save-basic-btn");
    }

    function basicSubmit(){
        let nameX, areaIdX, interfaceIdX, productChange=false, nameChange=false, payload, configId, status;
        if (editRights){
            handleSubmitActions("save-basic-btn", "load-basic-btn");
            nameX = document.getElementById("name-edit").value;
            areaIdX = document.getElementById("area-edit").value;
            interfaceIdX = document.getElementById("interface-edit").value;
            configId = document.getElementById("siteNameId").value;
            status = document.getElementById("status-edit").value;
            if (interfaceId.toString() !== interfaceIdX.toString()){
                productChange = true;
            }
            if (serverName.toLowerCase() !== nameX.toLowerCase()){
                nameChange = true
            }
            payload = [{area_id: parseInt(areaIdX), description: nameX, mac_addr: macAddr, config_id: parseInt(configId),
                        palcare_interface_id: parseInt(interfaceIdX), interface_change: productChange,
                        palcare_device_id: serverId, name_change: nameChange, is_active: parseInt(status)}];
            ApiRequester({reqEndpoint: "mytransaction", TransType: "server_edit", Payload: payload,
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
                if (data && data[0]){
                    setCount(0);
                    setPCount(0);
                    formSuccess("Basic information saved.", "save-basic-btn", "load-basic-btn",
                        "edit-basic-btn");
                }
                else{
                    formFail(`Error during save, error code: ${data && data?.length > 1 ? data[1] : ""}`,
                        "save-basic-btn", "load-basic-btn");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function basicDBSubmit(){
        let payload, serialOutPort, serialInPort, serialInId, serialOutId, serialInBaud, serialInBaudId,
            serialOutDelimiterId, serialOutBaud, serialInDelimiter, serialInDelimiterId, serialOutDelimiter,
            configTtlId, serialOutBaudId, configTtl, envOn, envOnId, sampleRate, sampleRateId, highTemp, highTempId,
            lowTemp, lowTempId, lowBat, lowBatId;
        if (editRights) {
            handleSubmitActions("editDBSubmit", "loadEditDBSubmit");
            serialInPort = document.getElementById("serialIn").value;
            serialInId = document.getElementById("serialInId").value;
            serialInBaud = document.getElementById("in_serial_baud").value;
            serialInBaudId = document.getElementById("in_serial_baud_id").value;
            serialInDelimiter = document.getElementById("in_serial_delimiter").value;
            serialInDelimiterId = document.getElementById("in_serial_delimiter_id").value;
            serialOutId = document.getElementById("serialOutId").value;
            serialOutPort = document.getElementById("serialOut").value;
            serialOutBaud = document.getElementById("out_serial_baud").value;
            serialOutBaudId = document.getElementById("out_serial_baud_id").value;
            serialOutDelimiter = document.getElementById("out_serial_delimiter").value;
            serialOutDelimiterId = document.getElementById("out_serial_delimiter_id").value;
            configTtl = document.getElementById("config_ttl").value;
            configTtlId = document.getElementById("config_ttl_id").value;
            envOn = document.getElementById("config_env_processing").checked;
            envOnId = document.getElementById("config_env_processing_id").value;
            sampleRate = document.getElementById("config_env_sample_rate").value;
            sampleRateId = document.getElementById("config_env_sample_rate_id").value;
            highTemp = document.getElementById("config_high_temp").value;
            highTempId = document.getElementById("config_high_temp_id").value;
            lowTemp = document.getElementById("config_low_temp").value;
            lowTempId = document.getElementById("config_low_temp_id").value;
            lowBat = document.getElementById("config_low_bat").value;
            lowBatId = document.getElementById("config_low_bat_id").value;

            payload = [{config_value: serialInPort, config_id: parseInt(serialInId)},
                       {config_value: serialOutPort, config_id: parseInt(serialOutId)},
                       {config_value: serialInBaud, config_id: parseInt(serialInBaudId)},
                       {config_value: serialInDelimiter, config_id: parseInt(serialInDelimiterId)},
                       {config_value: serialOutBaud, config_id: parseInt(serialOutBaudId)},
                       {config_value: serialOutDelimiter, config_id: parseInt(serialOutDelimiterId)},
                       {config_value: configTtl, config_id: parseInt(configTtlId)},
                       {config_value: envOn ? "YES" : "NO", config_id: parseInt(envOnId)},
                       {config_value: (parseInt(sampleRate) * 60).toString(), config_id: parseInt(sampleRateId)},
                       {config_value: highTemp, config_id: parseInt(highTempId)},
                       {config_value: lowTemp, config_id: parseInt(lowTempId)},
                       {config_value: lowBat, config_id: parseInt(lowBatId)}];
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_config", PKey: "config_id",
                            Condition: "primary", urlType: "POST", Payload: payload, User: dData.user,
                            CampusId: campusId, ClientId: dData.sessionId}).then(data => {
                if (data && data[0]){
                    setPCount(0);
                    formSuccess("Server database updated.", "editDBSubmit", "loadEditDBSubmit",
                        "cancelEditDBBtn");
                }
                else{
                    formFail(`Error during save, error code: ${data && data?.length > 1 ? data[1] : ""}`,
                        "editDBSubmit", "loadEditDBSubmit");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }

    }

    function validateSubmit(mac){
        handleSubmitActions("validate-btn", "load-validate-btn");
        ApiRequester({reqEndpoint: "myvalidation", payload: {server_id: mac.replaceAll(":", ""),
                            campus_id: campusId, interface_id: interfaceId}, reqType: "validate"}).then(data => {
            setDbData(data);
            handleSubmitActions("load-validate-btn", "validate-btn");
        });
    }

    function fixDatabase(mac, missingConfigs, xtraConfigs){
        if (editRights){
            handleSubmitActions("validate-fix-btn", "load-validate-fix-btn");
            ApiRequester({reqEndpoint: "myvalidation", payload: {server_id: mac.replaceAll(":", ""),
                                campus_id: campusId, interface_id: interfaceId, config_items: missingConfigs,
                                config_extras: xtraConfigs}, reqType: "fix"}).then(data => {
                handleSubmitActions("load-validate-fix-btn", "validate-fix-btn");
                document.getElementById("validate-btn").click();
                setPCount(0);
            });
        }
    }

    function pingPEvents(mac){
        let msg;
        handleSubmitActions("local-btn", "load-local-btn");
        ApiRequester({reqEndpoint: "mqtt", Operation: "getActiveAlert", CampusId: campusId,
                            ClientId: dData.sessionId, ServerId: mac.replaceAll(":", "")}).then(data => {
            if(data[0]){
                setPCount(31);
                msg = "Interrogate successful, may take a moment to update.";
                formSuccess(msg, "local-btn", "load-local-btn");
            }
            else{
                msg = "Error interrogating server, contact support for assistance if error persists.";
                formFail(msg, "local-btn", "load-local-btn");
            }
        });
    }

    function editMatchModal(editIndex){
        let dataIndex, cPattern, rPattern, dPattern, cReplace, rReplace, dReplace;
        if (editRights){
            dataIndex = parseInt(editIndex);
            setStoredMatchIndex(dataIndex);
            document.getElementById("matchterm_idE").value = matchActive[dataIndex]?.matchterm_id;
            cPattern = document.getElementById("create_patternE");
            cReplace = document.getElementById("create_replaceE");
            cPattern.value = matchActive[dataIndex]?.create_pattern;
            cReplace.value = matchActive[dataIndex]?.create_replacement;
            if (cPattern.value !== ""){
                if (cPattern.value === cReplace.value){
                    cReplace.readOnly = true;
                    document.getElementById("create_sameE").checked = true;
                    document.getElementById("create_removeE").checked = false;
                }else if(cReplace.value === ""){
                    cReplace.readOnly = true;
                    document.getElementById("create_removeE").checked = true;
                    document.getElementById("create_sameE").checked = false;
                }
            }else{
                document.getElementById("create_removeE").checked = false;
                document.getElementById("create_sameE").checked = false;
            }
            rPattern = document.getElementById("repeat_patternE");
            rReplace = document.getElementById("repeat_replaceE");
            rPattern.value = matchActive[dataIndex]?.repeat_pattern;
            rReplace.value = matchActive[dataIndex]?.repeat_replacement;
            if (rPattern.value !== ""){
                if (rPattern.value === rReplace.value){
                    rReplace.readOnly = true;
                    document.getElementById("repeat_sameE").checked = true;
                    document.getElementById("repeat_removeE").checked = false;
                }else if(rReplace.value === ""){
                    rReplace.readOnly = true;
                    document.getElementById("repeat_removeE").checked = true;
                    document.getElementById("repeat_sameE").checked = false;
                }
            }else{
                document.getElementById("repeat_sameE").checked = false;
                document.getElementById("repeat_removeE").checked = false;
            }
            dPattern = document.getElementById("done_patternE");
            dReplace = document.getElementById("done_replaceE");
            dPattern.value = matchActive[dataIndex]?.done_pattern;
            dReplace.value = matchActive[dataIndex]?.done_replacement;
            if (dPattern.value !== ''){
                if (dPattern.value === dReplace.value){
                    dReplace.readOnly = true;
                    document.getElementById("done_sameE").checked = true;
                    document.getElementById("done_removeE").checked = false;
                }else if(dReplace.value === ""){
                    dReplace.readOnly = true;
                    document.getElementById("done_removeE").checked = true;
                    document.getElementById("done_sameE").checked = false;
                }
            }else{
                document.getElementById("done_removeE").checked = false;
                document.getElementById("done_sameE").checked = false;
            }
            document.getElementById("iscaseE").checked = matchActive[dataIndex]?.is_case_sensitive;
            document.getElementById("ignore_patternE").value = matchActive[dataIndex]?.ignore_pattern;
            document.getElementById("device_patternE").value = matchActive[dataIndex]?.device_pattern;
            document.getElementById("area_patternE").value = matchActive[dataIndex]?.area_pattern;
            document.getElementById("matchAreaE").value = matchActive[dataIndex]?.area_id;
            document.getElementById("matchDeviceE").value = matchActive[dataIndex]?.device_replacement;
            formCheck("editMatchForm", "editMatchSubmit");
            showModal("editmatchmodal");
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function formEditMatchSubmit(){
        let tCreate, tRepeat, tDone, rCreate, rRepeat, rDone, caseSensetive, aArea, aDevice, payload, termsId,
            devicePattern, areaPattern;
        if (editRights){
            handleSubmitActions("editMatchSubmit", "loadEditMatchSubmit");
            termsId = document.getElementById("matchterm_idE").value;
            tCreate = document.getElementById("create_patternE").value;
            tRepeat = document.getElementById("repeat_patternE").value;
            tDone = document.getElementById("done_patternE").value;
            rCreate = document.getElementById("create_replaceE").value;
            rRepeat = document.getElementById("repeat_replaceE").value;
            rDone = document.getElementById("done_replaceE").value;
            devicePattern = document.getElementById("device_patternE").value;
            areaPattern = document.getElementById("area_patternE").value;
            caseSensetive = document.getElementById("iscaseE").checked;
            aArea = document.getElementById("matchAreaE").value;
            aDevice = document.getElementById("matchDeviceE").value;
            payload = [{create_pattern: tCreate, repeat_pattern: tRepeat, done_pattern: tDone,
                is_case_sensitive: caseSensetive, create_replacement: rCreate, repeat_replacement: rRepeat,
                done_replacement: rDone, area_id: parseInt(aArea), device_replacement: aDevice, matchterm_id: parseInt(termsId),
                device_pattern: devicePattern, area_pattern: areaPattern}];
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_matchterms", Payload: payload, urlType: "POST", User: dData.user,
                                PKey: "matchterm_id", Condition: "primary", CampusId: campusId, ClientId: dData.sessionId}).then(data => {
                if (data[0]){
                    setICount(0);
                    setMatchIdList([]);
                    formSuccess("Match terms updated successfully", "editMatchSubmit",
                        "loadEditMatchSubmit", "cancelMatchEditBtn", "editMatchForm");
                }
                else{
                    formFail(`Failed to add new match term: ${data[1]}`, "editMatchSubmit", "loadEditMatchSubmit");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function formAddMatchSubmit(){
        let tCreate, tRepeat, tDone, tIgnore, rCreate, rRepeat, rDone, caseSensetive, deviceMaps,
            payload = [];
        if (editRights){
            handleSubmitActions("addMatchSubmit", "loadAddMatchSubmit");
            tCreate = document.getElementById("create_pattern").value;
            tRepeat = document.getElementById("repeat_pattern").value;
            tDone = document.getElementById("done_pattern").value;
            tIgnore = document.getElementById("ignore_pattern").value;
            rCreate = document.getElementById("create_replace").value;
            rRepeat = document.getElementById("repeat_replace").value;
            rDone = document.getElementById("done_replace").value;
            caseSensetive = document.getElementById("iscase").value;
            deviceMaps = matchDeviceMap.length > 0;
            if (matchAreaMap.length > 0){
                for (let i=0; i < matchAreaMap.length; i++){
                    for (let i2=0; i2 < matchDeviceMap.length; i2++){
                        payload.push({
                            campus_id: campusId, create_pattern: tCreate, repeat_pattern: tRepeat, done_pattern: tDone,
                            ignore_pattern: tIgnore, create_replacement: rCreate, repeat_replacement: rRepeat,
                            done_replacement: rDone, is_case_sensitive: caseSensetive,
                            device_pattern: matchDeviceMap[i2]?.pattern, device_replacement: matchDeviceMap[i2]?.replacement,
                            area_pattern: matchAreaMap[i]?.pattern, area_id: parseInt(matchAreaMap[i].replacement),
                            palcare_device_id: parseInt(serverId)
                        });
                    }
                    if (!deviceMaps){
                        payload.push({
                            campus_id: campusId, create_pattern: tCreate, repeat_pattern: tRepeat, done_pattern: tDone,
                            ignore_pattern: tIgnore, create_replacement: rCreate, repeat_replacement: rRepeat,
                            done_replacement: rDone, is_case_sensitive: caseSensetive,
                            device_pattern: "", device_replacement: "",
                            area_pattern: matchAreaMap[i]?.pattern, area_id: parseInt(matchAreaMap[i].replacement),
                            palcare_device_id: parseInt(serverId)
                        });
                    }
                }
            }else{
                payload = [{
                    campus_id: campusId, create_pattern: tCreate, repeat_pattern: tRepeat, done_pattern: tDone,
                    ignore_pattern: tIgnore, create_replacement: rCreate, repeat_replacement: rRepeat,
                    done_replacement: rDone, is_case_sensitive: caseSensetive,
                    device_pattern: "", device_replacement: "", area_pattern: "", area_id: 0,
                    palcare_device_id: parseInt(serverId)
                }];
            }

            if (payload.length > 0){
                ApiRequester({reqEndpoint: "mysave", Tbl: "core_matchterms", Payload: payload, urlType: "PUT", User: dData.user,
                                PKey: "matchterm_id", Condition: "primary", CampusId: campusId, ClientId: dData.sessionId}).then(data => {
                    if (data[0]){
                        setICount(0);
                        setMatchIdList([]);
                        setMatchDeviceMap([]);
                        setMatchAreaMap([]);
                        formSuccess("Match terms added successfully", "addMatchSubmit",
                            "loadAddMatchSubmit", "cancelAddMatchBtn", "addMatchForm");
                    }
                    else{
                        formFail(`Failed to add new match term: ${data[1]}`, "addMatchSubmit", "loadAddMatchSubmit");
                    }
                });
            }else{
                handleSubmitActions("loadAddMatchSubmit", "addMatchSubmit");
                toast.warn("No areas mapped, must map areas to add new match terms.")
            }
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function deleteMatchPerform(submitBtn, loadBtn, closeId, start, end){
        let payload = [];
        if (editRights){
            handleSubmitActions(submitBtn, loadBtn);
            for (let i=0; i < dContent.length; i++){
                payload.push({matchterm_id: dContent[i][0]});
            }
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_matchterms", Payload: payload, urlType: "DELETE", User: dData.user,
                                PKey: "matchterm_id", Condition: "primary", CampusId: campusId, ClientId: dData.sessionId}).then(data => {
                if (data[0]){
                    setICount(0);
                    setMatchIdList([]);
                    formSuccess("Match terms deleted successfully", submitBtn, loadBtn, closeId);
                    document.getElementById("uCheckHead").checked = false;
                    checkAll(start, end);
                    actionAppearance(start, end, ["deleteBtn"]);
                }
                else{
                    formFail(`Failed to delete with error: ${data[1]}`, submitBtn, loadBtn);
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function deviceMapUpdater() {
        // This function is triggered from the phone number add modal save which takes the current phone numbers
        // display items and then new numbers display items and sets those values into their appropriate output
        // entity holder text or voice.
        let tmp = [];
        for (let i = 0; i < matchDeviceMap.length; i++) {
            tmp.push(matchDeviceMap[i]);
        }
        for (let i2 = 0; i2 < tmpDeviceMap.length; i2++) {
            tmp.push(tmpDeviceMap[i2]);
        }
        setMatchDeviceMap(() => tmp);
        document.getElementById("cancelDeviceMapBtn").click();
    }

    function areaMapUpdater() {
        // This function is triggered from the phone number add modal save which takes the current phone numbers
        // display items and then new numbers display items and sets those values into their appropriate output
        // entity holder text or voice.
        let tmp = [];
        for (let i = 0; i < matchAreaMap.length; i++) {
            tmp.push(matchAreaMap[i]);
        }
        for (let i2 = 0; i2 < tmpAreaMap.length; i2++) {
            tmp.push(tmpAreaMap[i2]);
        }
        setMatchAreaMap(() => tmp);
        document.getElementById("cancelAreaMapBtn").click();
    }

    const tableHeaders = (<TableHeaders checkbox={false} headerData={serverHeaderData} checkAll={() => {}}
                                        writeAccess={false} sortOrder={sorter.sortOrder}
                                        sortColumn={sorter.sortColumn} sorter={sorter} setSorter={setSorter}
                                        modal={false} checkboxlist={[]} actionAppearance={() => {}}/>);

    const matchTermsHeaders = (<TableHeaders checkbox={editRights} headerData={matchHeaderData}
                                             checkAll={() => checkAll(ppRules2.start, ppRules2.end, matchIdList, setMatchIdList)}
                                             writeAccess={editRights} sortOrder={sorter2.sortOrder} modal_name={"Edit"}
                                             sortColumn={sorter2.sortColumn} sorter={sorter2} setSorter={setSorter2}
                                             modal={true} checkboxlist={matchIdList} checkboxHeaderId={"uCheckHead"}
                                             actionAppearance={() => actionAppearance(ppRules2.start, ppRules2.end, ["deleteBtn"])} />);

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
        </div>
    )

    return (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Server Profile"} Title={serverName} content={headerContent}/>
                <div className={"container-fluid"}>
                    <div className={"col"}>
                        <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                            <li key={"set-basic"} className={"nav-item"}>
                                <Button text={"Basic Info"} class={tabItem === "basic" ? "nav-link active" : "nav-link"}
                                        onClick={() => setTabItem("basic")}/>
                            </li>
                            <li key={"set-database"} className={"nav-item"}>
                                <Button text={"Database"} class={tabItem === "database" ? "nav-link active" : "nav-link"}
                                        onClick={() => setTabItem("database")}/>
                            </li>
                            {[1, 2].includes(interfaceId)
                                ?   <li key={"set-match"} className={"nav-item"}>
                                        <Button text={"Match Terms"}
                                                class={tabItem === "match" ? "nav-link active" : "nav-link"}
                                                onClick={() => setTabItem("match")}/>
                                    </li>
                                :   <></>
                            }
                            <li key={"set-debug"} className={"nav-item"}>
                                <Button text={"Troubleshooting"}
                                        class={tabItem === "debug" ? "nav-link active" : "nav-link"}
                                        onClick={() => setTabItem("debug")}/>
                            </li>
                        </ul>
                    </div>
                    <div className={tabItem === "basic" ? "d-block" : "d-none"}>
                        <div className={"card"} style={{minWidth: "550px"}}>
                            <div className={"card-header d-flex justify-content-between"}>
                                <div>
                                    <h3 className={"card-header-title d-inline-block"}>Basic Information</h3>
                                </div>
                                <div>
                                    <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"basic-card-btn"}
                                       onClick={() => toggleCollapse("basic-card-btn", "basic-card")}></i>
                                </div>
                            </div>
                            <div className={"card-body collapse collapse-content show"} id={"basic-card"}>
                                <form className={"d-inline align-top form-control-plaintext was-validated"}
                                      id={"basic-form"}>
                                    <div className={"d-flex justify-content-start"}>
                                        <div className={"mx-5 mt-2 align-top d-inline-block"} id={"basic-col1-div"}
                                             style={{minWidth: "375px"}}>
                                            <div className={"d-flex"}>
                                                <p style={{minWidth: "125px"}}>Description:</p>
                                                <p className={"ms-4"} id={"name-static"}>{serverName}</p>
                                                <input className={"ms-4 d-none form-control form-control-sm"}
                                                       required={true}
                                                       style={{height: "30px", width: "225px"}} id={"name-edit"}
                                                       defaultValue={serverName} pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                                <input type={"hidden"} id={"siteNameId"}/>
                                            </div>
                                            <div className={"d-flex"}>
                                                <p style={{minWidth: "125px"}}>MAC:</p>
                                                <p className={"ms-4"} id={"mac-static"}>{macAddr}</p>
                                            </div>
                                            <div className={"d-flex"}>
                                                <p style={{minWidth: "125px"}}>Product Interface:</p>
                                                <p className={"ms-4"} id={"interface-static"}>{interfaceName}</p>
                                                <select className={"ms-4 d-none form-select form-select-sm"}
                                                        onChange={() => formCheck("basic-form", "save-basic-btn")}
                                                        style={{height: "30px", width: "225px"}} id={"interface-edit"}
                                                        defaultValue={interfaceId}>
                                                    <option value={3}>Inovonics</option>
                                                    <option value={1}>Hard-Wired</option>
                                                    <option value={2}>Firepanel</option>
                                                </select>
                                            </div>
                                            <div className={"d-flex"}>
                                                <p style={{minWidth: "125px"}}>Hardware:</p>
                                                <p className={"ms-4"} id={"hardware-static"}>{hardware}</p>
                                            </div>
                                            <div className={"d-flex"}>
                                                <p style={{minWidth: "125px"}}>System OS:</p>
                                                <p className={"ms-4"} id={"os-static"}>{os}</p>
                                            </div>
                                            <div className={"d-flex"}>
                                                <p style={{minWidth: "125px"}}>Local IP:</p>
                                                <p className={"ms-4"} id={"os-static"}>{ipAddr}</p>
                                            </div>
                                            <div className={"d-flex"}>
                                                <p style={{minWidth: "125px"}}>Implementation:</p>
                                                <p className={"ms-4"}
                                                   id={"status-static"}>{isActive ? "Main" : "Backup"}</p>
                                                <select className={"ms-4 d-none form-select form-select-sm"}
                                                        onChange={() => formCheck("basic-form", "save-basic-btn")}
                                                        style={{height: "30px", width: "225px"}} id={"status-edit"}
                                                        defaultValue={isActive}>
                                                    <option value={1}>Main</option>
                                                    <option value={0}>Backup</option>
                                                </select>
                                            </div>
                                            <div className={"d-flex"}>
                                                <p style={{minWidth: "125px"}}>Area:</p>
                                                <p className={"ms-4"} id={"area-static"}>
                                                    <Link to={`/profiles/area/${areaId}`}>{areaName}</Link>
                                                </p>
                                                <select className={"ms-4 d-none form-select form-select-sm"}
                                                        onChange={() => formCheck("basic-form", "save-basic-btn")}
                                                        style={{height: "30px", width: "225px"}} id={"area-edit"}
                                                        defaultValue={areaId}>
                                                    {locationOptions}
                                                </select>
                                            </div>
                                        </div>
                                        <div className={"mx-5 mt-2 align-top d-inline-block"} id={"basic-col2-div"}>
                                            <div>
                                                <div className={"d-flex"}>
                                                    <p style={{minWidth: "125px"}}>Last Heartbeat:</p>
                                                    <p className={"ms-4"} id={"lastAlarm-static"}>{lastHeartbeat}</p>
                                                </div>
                                                <div className={"d-flex"}>
                                                    <p style={{minWidth: "125px"}}>Last Alarm:</p>
                                                    <p className={"ms-4"} id={"lastAlarm-static"}>{recentAlarmTime}</p>
                                                </div>
                                                <div className={"d-flex"}>
                                                    <p style={{minWidth: "125px"}}>Active Alarms:</p>
                                                    <p className={"ms-4"}
                                                       id={"activeCount-static"}>{activeAlarmsCount}</p>
                                                </div>
                                                <div className={"d-flex"}>
                                                    <p style={{minWidth: "125px"}}>Last Rebooted:</p>
                                                    <p className={"ms-4"} id={"lastReboot-static"}>{lastReboot}</p>
                                                </div>
                                                <div className={"d-flex"}>
                                                    <p style={{minWidth: "125px"}}>Uptime (Hrs):</p>
                                                    <p className={"ms-4"} id={"uptime-static"}>{uptime}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"ms-auto"}>
                                            {pageLoaded && editRights &&
                                                <>
                                                    <Button text={"Edit"} class={"btn btn-primary float-end mt-1"}
                                                        id={"edit-basic-btn"}
                                                        disabled={!editRights} onClick={() => {
                                                        if (editRights) {
                                                            basicActionHandling();
                                                        }
                                                    }}/>
                                                    <Button text={"Save"} class={"btn btn-primary float-end mt-1 me-3 d-none"}
                                                            id={"save-basic-btn"}
                                                            disabled={!editRights} onClick={() => {
                                                        if (editRights) {
                                                            basicSubmit();
                                                        }
                                                    }}/>
                                                    <Button type={"button"} class={"btn btn-primary float-end mt-1 mx-3 d-none"}
                                                            disabled={true}
                                                            spinner={true} text={""} id={"load-basic-btn"}/>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className={tabItem === "database" ? "d-block" : "d-none"}>
                        <div className={"card"} style={{minWidth: "500px"}}>
                            <div className={"card-header d-flex justify-content-between"}>
                                <div>
                                    <h3 className={"card-header-title d-inline-block"}
                                        style={{minWidth: "80px"}}>Database</h3>
                                    <div className={"d-none"} style={{display: "inline-block"}} id={"db-header-data"}>
                                        {dbValid !== null
                                            ? dbValid?.valid
                                                ? <><h3 className={"ms-6 my-0 d-inline-block text-success"}>Database
                                                    Exists <i className={"fe fe-check-circle text-success"}></i></h3></>
                                                : <><h3 className={"ms-6 my-0 d-inline-block text-danger"}>Database
                                                    Missing <i className={"fe fe-alert-octagon text-danger"}></i>
                                                </h3></>
                                            : <></>
                                        }
                                        <h3 className={"ms-6 my-0 d-inline-block text-success"}></h3>
                                    </div>
                                </div>
                                <div>
                                    <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"db-card-btn"}
                                       onClick={() => {
                                           toggleCollapse("db-card-btn", "db-card");
                                           document.getElementById("db-header-data").classList.toggle("d-none");
                                       }}></i>
                                </div>
                            </div>
                            <div className={"card-body collapse collapse-content show"} id={"db-card"}
                                 style={{maxHeight: "650px", overflowY: "auto"}}>
                                <div>
                                    {dbValid !== null
                                        ? dbValid?.valid
                                            ? <>
                                                <div className={"d-inline-block"}>
                                                    <h3 className={"text-success"}>Database Exists
                                                        <i className={"fe fe-check-circle text-success"}></i>
                                                    </h3>
                                                    <Button text={"Validate DB"} class={"btn btn-primary mt-2"}
                                                            id={"validate-btn"} onClick={() => {
                                                        validateSubmit(macAddr);
                                                    }}/>
                                                    <Button type={"button"} class={"btn btn-primary mt-2 d-none"}
                                                            disabled={true} spinner={true} text={"Validating"}
                                                            id={"load-validate-btn"}/>
                                                </div>
                                                {dbData?.validated
                                                    ? (dbData?.missing?.length > 0 || dbData?.extras?.length > 0) && editRights
                                                        ? <div className={"d-inline-block"}>
                                                            <Button text={"Fix DB"} class={"btn btn-primary mt-2"}
                                                                    id={"validate-fix-btn"} icon={true}
                                                                    iconType={"google"}
                                                                    iconClass={"material-icons md-18 md-dark me-2 align-text-bottom"}
                                                                    iconSize={"tiny"} iconColor={"#FFFFFF"}
                                                                    iconName={"handyman"} onClick={() => {
                                                                fixDatabase(macAddr, dbData?.missing ? dbData.missing : [],
                                                                    dbData?.extras ? dbData.extras : []);
                                                            }}/>
                                                            <Button type={"button"}
                                                                    class={"btn btn-primary mt-2 d-none"}
                                                                    disabled={true} spinner={true} text={"Fixing"}
                                                                    id={"load-validate-fix-btn"}/>
                                                        </div>
                                                        : <></>
                                                    : <></>
                                                }
                                                {editRights &&
                                                    <div className={"d-inline"} style={{verticalAlign: "top"}}>
                                                        <Button text={"Edit"} class={"btn btn-primary float-end"}
                                                                id={"edit-db-btn"} disabled={!editRights} onClick={() => {
                                                            if (editRights) {
                                                                editDBModal();
                                                            }
                                                        }}/>
                                                    </div>
                                                }
                                            </>
                                            : <div><h3 className={"text-danger"}>Database Missing <i
                                                className={"fe fe-alert-octagon text-danger"}></i></h3></div>
                                        : <></>
                                    }
                                </div>
                                <div className={"mt-4"}>
                                    {dbData?.validated
                                        ? dbData?.missing?.length > 0
                                            ? <h4 className={"text-danger"}>Database Invalid:</h4>
                                            : <h4 className={"text-success"}>Database Valid</h4>
                                        : <></>
                                    }
                                    <ul style={{listStyle: "none"}}>
                                        {dbData?.missing?.map((missingRec, i) => {
                                            return (
                                                <li key={`missing-${i}`} className={"text-danger"}>{missingRec} -
                                                    Missing
                                                    Requirement</li>);
                                        })}
                                        {dbData?.extras?.map((extraRec, i) => {
                                            return (
                                                <li key={`extras-${i}`} className={"text-warning"}>{extraRec} - Warning
                                                    extra config</li>);
                                        })}
                                        {dbData?.dupes?.map((dupeRec, i) => {
                                            return (
                                                <li key={`dupes-${i}`} className={"text-warning"}>{dupeRec} - Warning
                                                    duplicate config</li>);
                                        })}
                                    </ul>
                                </div>
                                <div>
                                    <div className={"form-outline d-inline-flex align-bottom ms-2 mb-3"}>
                                        <input type={"search"} id={"search1"}
                                               className={"form-control search-input mt-1"}
                                               onKeyUp={(e) => setSearch(e.target.value)}/>
                                        <Button text={""} type={"button"}
                                                class={"btn btn-primary smooth-radius-left mt-1"}
                                                style={{height: "42px"}}
                                                icon={true} iconClass={"fe fe-search"}/>
                                    </div>
                                    <div>
                                        {dbValid !== null
                                            ? <>
                                                <table className={"table table-sm table-white table-hover"}>
                                                    <thead className={"sticky-table-head"} style={{top: "-25px"}}>
                                                    {tableHeaders}
                                                    </thead>
                                                    <tbody id={"tBody"}>
                                                    {serverTableRows}
                                                    </tbody>
                                                </table>
                                                <div className={"text-center"}>
                                                    <Pagination>
                                                        <PaginationItems setPage={setPage} page={page}
                                                                         beginDot={ppRules.beginDot}
                                                                         endDot={ppRules.endDot}
                                                                         pageMin={ppRules.pageMin}
                                                                         pageMax={ppRules.pageMax}
                                                                         max={ppRules.max}/>
                                                    </Pagination>
                                                </div>
                                            </>
                                            : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={tabItem === "debug" ? "d-block" : "d-none"}>
                        {interfaceId === 3
                            ? <>
                                <div className={"card"} style={{minWidth: "500px"}}>
                                    <div className={"card-header d-flex justify-content-between"}>
                                        <div>
                                            <h3 className={"card-header-title d-inline-block"}
                                                style={{minWidth: "80px"}}>Server Status</h3>
                                            <div className={"d-none"} style={{display: "inline-block"}} id={"status-header-data"}>
                                                <h3 className={"ms-6 my-0 d-inline-block text-success"}>{deviceData.length} Statuses</h3>
                                            </div>
                                        </div>
                                        <div>
                                            <i className={"fe fe-minus as-btn collapse-toggle p-2"}
                                               id={"status-card-btn"}
                                               onClick={() => {
                                                   toggleCollapse("status-card-btn", "status-card");
                                                   document.getElementById("status-header-data").classList.toggle("d-none");
                                               }}></i>
                                        </div>
                                    </div>
                                    <div className={"card-body collapse collapse-content show"} id={"status-card"}
                                         style={{maxHeight: "650px", overflowY: "auto"}}>
                                        <table className={"table table-sm table-responsive-sm table-hover"}>
                                            <thead className={"table-header"}>
                                            <tr>
                                                <td className={"text-center"}>Process Count</td>
                                                <td className={"text-center"}>Aggregate Count</td>
                                                <td className={"text-center"}>Eman Count</td>
                                                <td className={"text-center"}>Maint. Count</td>
                                                <td className={"text-center"}>Other Count</td>
                                                <td className={"text-center"}>Alarm Time</td>
                                                <td className={"text-center"}>Alarm Msg Raw/Parsed</td>
                                                <td className={"text-center"}>Last Updated</td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {deviceData.map((device, i) => {
                                                return <tr key={`device-count-row-${i}`}>
                                                    <td className={"text-center"}>{device?.count_process_queue}</td>
                                                    <td className={"text-center"}>{device?.count_aggregate_repeater_queue}</td>
                                                    <td className={"text-center"}>{device?.count_eman_event_queue}</td>
                                                    <td className={"text-center"}>{device?.count_maint_queue}</td>
                                                    <td className={"text-center"}>{device?.count_other_queue}</td>
                                                    <td className={"text-center"}>{device?.recent_alarm_input_time}</td>
                                                    <td className={"text-center"}>
                                                        <MaterialIcon className={"material-icons as-btn-2x me-2"}
                                                                      onClick={() => {
                                                                          toast.info(device?.recent_alarm_input_raw);
                                                                          navigator?.clipboard?.writeText(device?.recent_alarm_input_raw);
                                                                      }}
                                                                      icon={"raw_on"}
                                                                      title={device?.recent_alarm_input_raw}/>
                                                        <MaterialIcon className={"material-icons as-btn-2x"}
                                                                      onClick={() => {
                                                                          toast.info(device?.recent_alarm_input_parsed);
                                                                          navigator?.clipboard?.writeText(device?.recent_alarm_input_parsed);
                                                                      }}
                                                                      icon={"data_object"}
                                                                      title={device?.recent_alarm_input_parsed}/>
                                                    </td>
                                                    <td className={"text-center"}>{device?.modify_time}</td>
                                                </tr>
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </>
                            : <></>
                        }

                        <div className={"card"} style={{minWidth: "500px"}}>
                            <div className={"card-header d-flex justify-content-between"}>
                                <div>
                                    <h3 className={"card-header-title d-inline-block"}
                                        style={{minWidth: "80px"}}>Local Alerts</h3>
                                    <div style={{display: "inline-block"}} id={"local-header-data"}>
                                        <h3 className={"ms-6 my-0 d-inline-block text-success"}>{localData?.length} Alerts</h3>
                                    </div>
                                </div>
                                <div>
                                    <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"local-card-btn"}
                                       onClick={() => {
                                           toggleCollapse("local-card-btn", "local-card");
                                           document.getElementById("local-header-data").classList.toggle("d-none");
                                       }}></i>
                                </div>
                            </div>
                            <div className={"card-body collapse collapse-content show"} id={"local-card"}>
                                <Button text={"Local Alerts"} icon={true} iconClass={"fe fe-loader me-2"}
                                        class={"btn btn-primary mt-2"} id={"local-btn"}
                                        onClick={() => {
                                            pingPEvents(macAddr);
                                        }}/>
                                <Button type={"button"} class={"btn btn-primary mt-2 d-none"} disabled={true}
                                        spinner={true} text={"Interrogating"} id={"load-local-btn"}/>
                                <table className={"table table-sm table-responsive-sm table-hover mt-4"}>
                                    <thead className={"table-header"}>
                                    <tr>
                                        <th className={"text-center"}>Event Date Time</th>
                                        <th className={"text-center"}>Alert Category</th>
                                        <th className={"text-center"}>Alert Type</th>
                                        <th className={"text-center"}>Area Name</th>
                                        <th className={"text-center"}>Device Name</th>
                                        <th className={"text-center"}>Serial Number</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {localData.map((pEvent, i) => {
                                        let jsonObj = JSON.parse(pEvent?.event_json ? pEvent.event_json : '{}')
                                        return <tr key={`local-alert-${i}`}>
                                            <td className={"text-center"}>{jsonObj?.eventDateTime}</td>
                                            <td className={"text-center"}>{jsonObj?.type}</td>
                                            <td className={"text-center"}>{jsonObj?.sType}</td>
                                            <td className={"text-center"}>{jsonObj?.location?.name}</td>
                                            <td className={"text-center"}>{jsonObj?.device?.name}</td>
                                            <td className={"text-center"}>{jsonObj?.device?.serialNum}</td>
                                        </tr>
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {[1, 2].includes(interfaceId)
                        ? <>
                            <div className={tabItem === "match" ? "d-block" : "d-none"}>
                                <div className={"card"} style={{minWidth: "500px"}}>
                                    <div className={"card-header d-flex justify-content-between"}>
                                        <div>
                                            <h3 className={"card-header-title d-inline-block"}
                                                style={{minWidth: "80px"}}>Match Terms</h3>
                                            <div style={{display: "inline-block"}} id={"match-header-data"}>
                                                <h3 className={"ms-6 my-0 d-inline-block text-success"}>
                                                    {matchActive?.length ? matchActive.length : 0} Terms Defined
                                                </h3>
                                            </div>
                                        </div>
                                        <div>
                                            <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"match-card-btn"}
                                               onClick={() => {
                                                   toggleCollapse("match-card-btn", "match-card");
                                                   document.getElementById("match-header-data").classList.toggle("d-none");
                                               }}></i>
                                        </div>
                                    </div>
                                    <div className={"card-body collapse collapse-content show"} id={"match-card"}
                                         style={{maxHeight: "500px", overflowY: "auto"}}>
                                        <div className={"d-flex justify-content-between"}>
                                            <div>

                                            </div>
                                            <div>
                                                {editRights && <Button text={"Disable"} type={"button"}
                                                                     class={"btn btn-danger me-2 hide"}
                                                                     id={"deleteBtn"} icon={true}
                                                                     iconClass={"fe fe-trash-2 me-2"}
                                                                     onClick={() => bulkDeleteModal(ppRules2.start, ppRules2.end, matchActive,
                                                                         ["device_type", "area_name"], ["matchterm_id", ""], "", setDContent)}/>}
                                                {editRights && <Button text={"New Term"} type={"button"}
                                                                     class={"btn btn-primary ms-2 mt-1"}
                                                                     icon={true} iconClass={"fe fe-plus me-2"}
                                                                     onClick={() => {
                                                                         showModal("addmatchmodal");
                                                                     }}/>}
                                            </div>
                                        </div>
                                        <table className={"table table-sm table-responsive-sm table-hover mt-4"}>
                                            <thead className={"sticky-table-head"} style={{top: "-25px"}}>
                                            {matchTermsHeaders}
                                            </thead>
                                            <tbody>
                                            {matchTermsRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </>
                        : <></>
                    }
                </div>
            </div>
            <div className={"modal fade"} id={"addmatchmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={"-1"} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Add Match Term</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"}
                                    aria-label={"Close"}/>
                        </div>
                        <form id={"addMatchForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"d-flex justify-content-start"}>
                                    <div className={"w-75"}>
                                        <div className={"form-floating mb-3 d-inline-block align-top w-50 pe-2"}>
                                            <input type={"text"} className={"form-control"} id={"create_pattern"}
                                                   onKeyUp={(e) => {
                                                       if (document.getElementById("create_same").checked) {
                                                           document.getElementById("create_replace").value = e.target.value;
                                                       }
                                                       formCheck("addMatchForm", "addMatchSubmit");
                                                   }}
                                                   required={false}/>
                                            <label htmlFor={"create_pattern"}>Create Match Term</label>
                                            <div className={"invalid-feedback"}>
                                                Word to trigger alarm on.
                                            </div>
                                        </div>
                                        <div className={"form-floating mb-3 d-inline-block align-top w-50 px-2"}>
                                            <input type={"text"} className={"form-control"} id={"create_replace"}
                                                   onKeyUp={(e) => {
                                                       formCheck("addMatchForm", "addMatchSubmit");
                                                   }}
                                                   required={false}/>
                                            <label htmlFor={"create_replace"}>Create Replacement</label>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"align-top ms-3 mt-1"}>
                                            <input className={"form-check-input mt-1"}
                                                   style={{width: "1.25rem", height: "1.25rem"}}
                                                   id={"create_same"} type={"checkbox"}
                                                   onChange={(e) => {
                                                       if (e.target.checked) {
                                                           document.getElementById("create_replace").value = document.getElementById("create_pattern").value;
                                                           document.getElementById("create_replace").readOnly = true;
                                                           document.getElementById("create_remove").checked = false
                                                       } else {
                                                           document.getElementById("create_replace").readOnly = false;
                                                       }
                                                       formCheck("addMatchForm", "addMatchSubmit");
                                                   }}/>
                                            <label className={"form-check-label ms-2 fs-3"} style={{color: "#1d1d1d"}}
                                                   htmlFor={"create_same"}>Same</label>
                                        </div>
                                        <div className={"align-top ms-3 mt-2"}>
                                            <input className={"form-check-input mt-1"}
                                                   style={{width: "1.25rem", height: "1.25rem"}}
                                                   id={"create_remove"} type={"checkbox"}
                                                   onChange={(e) => {
                                                       if (e.target.checked) {
                                                           document.getElementById("create_replace").value = ""
                                                           document.getElementById("create_replace").readOnly = true
                                                           document.getElementById("create_same").checked = false;
                                                       } else {
                                                           document.getElementById("create_replace").readOnly = false
                                                       }
                                                       formCheck("addMatchForm", "addMatchSubmit");
                                                   }}/>
                                            <label className={"form-check-label ms-2 fs-3"} style={{color: "#1d1d1d"}}
                                                   htmlFor={"create_remove"}>Remove</label>
                                        </div>
                                    </div>
                                </div>
                                <div className={"d-flex justify-content-start"}>
                                    <div className={"w-75"}>
                                        <div className={"form-floating mb-3 d-inline-block align-top w-50 pe-2"}>
                                            <input type={"text"} className={"form-control"} id={"repeat_pattern"}
                                                   onKeyUp={(e) => {
                                                       if (document.getElementById("repeat_same").checked) {
                                                           document.getElementById("repeat_replace").value = e.target.value;
                                                       }
                                                       formCheck("addMatchForm", "addMatchSubmit");
                                                   }}
                                                   required={false}/>
                                            <label htmlFor={"repeat_pattern"}>Repeat Match Term</label>
                                            <div className={"invalid-feedback"}>
                                                Word to trigger repeat on.
                                            </div>
                                        </div>
                                        <div className={"form-floating mb-3 d-inline-block align-top w-50 ps-2"}>
                                            <input type={"text"} className={"form-control"} id={"repeat_replace"}
                                                   onKeyUp={(e) => {
                                                       formCheck("addMatchForm", "addMatchSubmit");
                                                   }}
                                                   required={false}/>
                                            <label htmlFor={"repeat_replace"}>Repeat Replacement</label>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"align-top ms-3 mt-1"}>
                                            <input className={"form-check-input mt-1"}
                                                   style={{width: "1.25rem", height: "1.25rem"}}
                                                   id={"repeat_same"} type={"checkbox"}
                                                   onChange={(e) => {
                                                       if (e.target.checked) {
                                                           document.getElementById("repeat_replace").value = document.getElementById("repeat_pattern").value;
                                                           document.getElementById("repeat_replace").readOnly = true;
                                                           document.getElementById("repeat_remove").checked = false
                                                       } else {
                                                           document.getElementById("repeat_replace").readOnly = false;
                                                       }
                                                       formCheck("addMatchForm", "addMatchSubmit");
                                                   }}/>
                                            <label className={"form-check-label ms-2 fs-3"} style={{color: "#1d1d1d"}}
                                                   htmlFor={"repeat_same"}>Same</label>
                                        </div>
                                        <div className={"align-top ms-3 mt-2"}>
                                            <input className={"form-check-input mt-1"}
                                                   style={{width: "1.25rem", height: "1.25rem"}}
                                                   id={"repeat_remove"} type={"checkbox"}
                                                   onChange={(e) => {
                                                       if (e.target.checked) {
                                                           document.getElementById("repeat_replace").value = ""
                                                           document.getElementById("repeat_replace").readOnly = true
                                                           document.getElementById("repeat_same").checked = false;
                                                       } else {
                                                           document.getElementById("repeat_replace").readOnly = false
                                                       }
                                                       formCheck("addMatchForm", "addMatchSubmit");
                                                   }}/>
                                            <label className={"form-check-label ms-2 fs-3"} style={{color: "#1d1d1d"}}
                                                   htmlFor={"repeat_remove"}>Remove</label>
                                        </div>
                                    </div>
                                </div>
                                <div className={"d-flex justify-content-start"}>
                                    <div className={"w-75"}>
                                        <div className={"form-floating mb-3 d-inline-block align-top w-50 pe-2"}>
                                            <input type={"text"} className={"form-control"} id={"done_pattern"}
                                                   onKeyUp={(e) => {
                                                       if (document.getElementById("done_same").checked) {
                                                           document.getElementById("done_replace").value = e.target.value;
                                                       }
                                                       formCheck("addMatchForm", "addMatchSubmit");
                                                   }}/>
                                            <label htmlFor={"done_pattern"}>Done Match Term</label>
                                            <div className={"invalid-feedback"}>
                                                Word to trigger clear on.
                                            </div>
                                        </div>
                                        <div className={"form-floating mb-3 d-inline-block align-top w-50 ps-2"}>
                                            <input type={"text"} className={"form-control"} id={"done_replace"}
                                                   onKeyUp={(e) => {
                                                       formCheck("addMatchForm", "addMatchSubmit");
                                                   }}
                                                   required={false}/>
                                            <label htmlFor={"done_replace"}>Done Replacement</label>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"align-top ms-3 mt-1"}>
                                            <input className={"form-check-input mt-1"}
                                                   style={{width: "1.25rem", height: "1.25rem"}}
                                                   id={"done_same"} type={"checkbox"}
                                                   onChange={(e) => {
                                                       if (e.target.checked) {
                                                           document.getElementById("done_replace").value = document.getElementById("done_pattern").value;
                                                           document.getElementById("done_replace").readOnly = true;
                                                           document.getElementById("done_remove").checked = false
                                                       } else {
                                                           document.getElementById("done_replace").readOnly = false;
                                                       }
                                                       formCheck("addMatchForm", "addMatchSubmit");
                                                   }}/>
                                            <label className={"form-check-label ms-2 fs-3"} style={{color: "#1d1d1d"}}
                                                   htmlFor={"done_same"}>Same</label>
                                        </div>
                                        <div className={"align-top ms-3 mt-2"}>
                                            <input className={"form-check-input mt-1"}
                                                   style={{width: "1.25rem", height: "1.25rem"}}
                                                   id={"done_remove"} type={"checkbox"}
                                                   onChange={(e) => {
                                                       if (e.target.checked) {
                                                           document.getElementById("done_replace").value = ""
                                                           document.getElementById("done_replace").readOnly = true
                                                           document.getElementById("done_same").checked = false;
                                                       } else {
                                                           document.getElementById("done_replace").readOnly = false
                                                       }
                                                       formCheck("addMatchForm", "addMatchSubmit");
                                                   }}/>
                                            <label className={"form-check-label ms-2 fs-3"} style={{color: "#1d1d1d"}}
                                                   htmlFor={"done_remove"}>Remove</label>
                                        </div>
                                    </div>
                                </div>
                                <div className={"d-flex justify-content-start"}>
                                    <div className={"w-75"}>
                                        <div className={"form-floating mb-3 d-inline-block align-top w-50 pe-2"}>
                                            <input type={"text"} className={"form-control"} id={"ignore_pattern"}
                                                   onKeyUp={(e) => {
                                                       formCheck("addMatchForm", "addMatchSubmit");
                                                   }}
                                                   required={false}/>
                                            <label htmlFor={"ignore_pattern"}>Ignore Match Term</label>
                                            <div className={"invalid-feedback"}>
                                                Word to trigger ignores on.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"mb-5"}>
                                    <div className={"align-top ms-3 mt-2"}>
                                        <input className={"form-check-input mt-1"}
                                               style={{width: "1.25rem", height: "1.25rem"}}
                                               id={"iscase"} type={"checkbox"}/>
                                        <label className={"form-check-label ms-2 fs-3"} style={{color: "#1d1d1d"}}
                                               htmlFor={"iscase"}>Case Sensitive</label>
                                    </div>
                                </div>
                                <div className={"d-flex justify-content-around"}>
                                    <div style={{width: "150px"}}>
                                        {editRights &&
                                            <Button text={"Map Devices"} class={"btn btn-primary w-100"}
                                                onClick={() => {
                                                    swapModal("cancelAddMatchBtn", "devicemapmodal");
                                                }}/>
                                        }
                                        <h5 className={"text-center mt-1"}>{matchDeviceMap.length} Mapped</h5>
                                    </div>
                                    <div style={{width: "150px"}}>
                                        {editRights &&
                                            <Button text={"Map Areas"} class={"btn btn-primary w-100"}
                                                onClick={() => {
                                                    swapModal("cancelAddMatchBtn", "areamapmodal");
                                                }}/>
                                        }
                                        <h5 className={"text-center mt-1"}>{matchAreaMap.length} Mapped</h5>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <button type={"button"} className={"btn btn-secondary"} id={"cancelAddMatchBtn"}
                                        data-bs-dismiss={"modal"}>Cancel
                                </button>
                                <Button type={"button"} class={"btn btn-primary d-none"} disabled={true} spinner={true}
                                        text={"Loading"} id={"loadAddMatchSubmit"}/>
                                <button type={"button"} className={"btn btn-primary"}
                                        onClick={() => formAddMatchSubmit()} id={"addMatchSubmit"}>Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"devicemapmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title text-capitalize"} id={"staticBackdropLabel"}>Map Device Patterns
                                Matching</h2>
                            <button type={"button"} className={"btn-close"} id={"deviceMapClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <form id={"deviceMapForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"list-group-item"}>
                                    {editRights &&
                                        <Button type={"button"} class={"btn btn-primary mb-3"} text={"New Device Match"}
                                            onClick={() => {
                                                setTmpDeviceMap((prevState) => {
                                                    return [...prevState, {id: uuidv4(), pattern: "", replacement: ""}]
                                                });
                                            }}/>
                                    }
                                    {tmpDeviceMap.map((item) => {
                                        return (
                                            <div key={`new-device-item${item.id}`}
                                                 className={"d-flex justify-content-start"}>
                                                <i className={"fe fe-trash as-btn d-inline-block red-hover"}
                                                   onClick={() => {
                                                       setTmpDeviceMap((prevState) => {
                                                           return prevState.filter(i => i.id !== item.id);
                                                       });
                                                   }}></i>
                                                <div className={"form-floating d-inline-block w-50 mb-3 mx-3"}>
                                                    <input type={"text"} name={"device-pattern-input"}
                                                           className={"form-control"}
                                                           id={`tmp-device-pattern${item.id}`}
                                                           onKeyUp={(e) => {
                                                               setTmpDeviceMap((prevState) => {
                                                                   return prevState.map((i) => {
                                                                       if (i.id === item.id) {
                                                                           return {
                                                                               id: i.id,
                                                                               pattern: e.target.value,
                                                                               replacement: i.replacement
                                                                           };
                                                                       }
                                                                       return i;
                                                                   });
                                                               })
                                                               formCheck("deviceMapForm", "deviceMapSubmit");
                                                           }} required={false}
                                                           defaultValue={item.pattern}/>
                                                    <label htmlFor={`tmp-device-pattern${item.id}`}>Match
                                                        Pattern</label>
                                                </div>
                                                <div className={"form-floating mb-3 w-50 pe-2"}>
                                                    <select className={"form-select"} defaultValue={item.replacement}
                                                            required={false} id={`tmp-device-replacement${item.id}`}
                                                            onChange={(e) => {
                                                                setTmpDeviceMap((prevState) => {
                                                                    return prevState.map((i) => {
                                                                        if (i.id === item.id) {
                                                                            return {
                                                                                id: i.id,
                                                                                pattern: i.pattern,
                                                                                replacement: e.target.value
                                                                            };
                                                                        }
                                                                        return i;
                                                                    });
                                                                })
                                                                formCheck("deviceMapForm", "deviceMapSubmit")
                                                            }}>
                                                        <option value={""}></option>
                                                        <option value={"Pull Cord"}>Pull Cord</option>
                                                        <option value={"Push Cord"}>Push Cord</option>
                                                        <option value={"Door"}>Door</option>
                                                        <option value={"Delayed Egress"}>Delayed Egress</option>
                                                    </select>
                                                    <label htmlFor={`tmp-device-replacement${item.id}`}>Device
                                                        Type</label>
                                                </div>
                                            </div>);
                                    })}
                                </div>
                                <hr/>
                                <div className={"list-group-item"}>
                                    <h4>Current Mapped Patterns</h4>
                                    <h5 className={"mb-4"}>
                                        * Removing the below patterns will remove them without submit action.
                                    </h5>
                                    {matchDeviceMap.map((item) => {
                                        return (
                                            <div key={`stored-deviceMap-${item.id}`}
                                                 className={"d-flex justify-content-start"}>
                                                <i className={"fe fe-trash as-btn d-inline-block red-hover"}
                                                   onClick={() => {
                                                       setMatchDeviceMap((prevState) => {
                                                           return prevState.filter(i => i?.id !== item.id);
                                                       });
                                                   }}></i>
                                                <div className={"form-floating d-inline-block w-50 mb-3 mx-3"}>
                                                    <input type={"text"} name={"device-pattern-input"}
                                                           className={"form-control"}
                                                           id={`tmp-device-pattern-${item.id}`}
                                                           value={item.pattern} readOnly={true}/>
                                                    <label htmlFor={`tmp-device-pattern-${item.id}`}>Match
                                                        Pattern</label>
                                                </div>
                                                <div className={"form-floating d-inline-block w-50 mb-3 mx-3"}>
                                                    <input type={"text"} name={"device-replacement-input"}
                                                           className={"form-control"}
                                                           id={`tmp-device-replacement${item.id}`}
                                                           value={item.replacement} readOnly={true}/>
                                                    <label htmlFor={`tmp-device-replacement${item.id}`}>Device
                                                        Type</label>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelDeviceMapBtn"}
                                            data-bs-dismiss={"modal"} onClick={() => {
                                        setTmpDeviceMap([]);
                                        swapModal("devicemapmodal", "addmatchmodal")
                                    }}>Cancel
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Loading"} id={"loadDeviceMapSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"deviceMapSubmit"}
                                            onClick={() => deviceMapUpdater()}>Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"areamapmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title text-capitalize"} id={"staticBackdropLabel"}>Map Area Patterns
                                Matching</h2>
                            <button type={"button"} className={"btn-close"} id={"deviceMapClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <form id={"areaMapForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"list-group-item"}>
                                    {editRights &&
                                        <Button type={"button"} class={"btn btn-primary mb-3"} text={"New Area Match"}
                                            onClick={() => {
                                                setTmpAreaMap((prevState) => {
                                                    return [...prevState, {id: uuidv4(), pattern: "", replacement: "",
                                                                            display: ""}]
                                                });
                                            }}/>
                                    }
                                    {tmpAreaMap.map((item) => {
                                        return (
                                            <div key={`new-area-item${item.id}`}
                                                 className={"d-flex justify-content-start"}>
                                                <i className={"fe fe-trash as-btn d-inline-block red-hover"}
                                                   onClick={() => {
                                                       setTmpAreaMap((prevState) => {
                                                           return prevState.filter(i => i.id !== item.id);
                                                       });
                                                   }}></i>
                                                <div className={"form-floating d-inline-block w-50 mb-3 mx-3"}>
                                                    <input type={"text"} name={"area-pattern-input"}
                                                           className={"form-control"}
                                                           id={`tmp-area-pattern${item.id}`}
                                                           onKeyUp={(e) => {
                                                               setTmpAreaMap((prevState) => {
                                                                   return prevState.map((i) => {
                                                                       if (i.id === item.id) {
                                                                           return {
                                                                               id: i.id,
                                                                               pattern: e.target.value,
                                                                               replacement: i.replacement,
                                                                               display: i.display
                                                                           };
                                                                       }
                                                                       return i;
                                                                   });
                                                               })
                                                               formCheck("areaMapForm", "areaMapSubmit");
                                                           }} required={false}
                                                           defaultValue={item.pattern}/>
                                                    <label htmlFor={`tmp-area-pattern${item.id}`}>Match Pattern</label>
                                                </div>
                                                <div className={"form-floating mb-3 w-50 pe-2"}>
                                                    <select className={"form-select"} defaultValue={item.replacement}
                                                            required={false} id={`tmp-device-replacement${item.id}`}
                                                            onChange={(e) => {
                                                                setTmpAreaMap((prevState) => {
                                                                    return prevState.map((i) => {
                                                                        if (i.id === item.id) {
                                                                            return {
                                                                                id: i.id,
                                                                                pattern: i.pattern,
                                                                                replacement: e.target.value,
                                                                                display: e.target[e.target.selectedIndex].innerText
                                                                            };
                                                                        }
                                                                        return i;
                                                                    });
                                                                })
                                                                formCheck("areaMapForm", "areaMapSubmit")
                                                            }}>
                                                        <option value={""}></option>
                                                        {locationOptions}
                                                    </select>
                                                    <label htmlFor={`tmp-area-replacement${item.id}`}>Area</label>
                                                </div>
                                            </div>);
                                    })}
                                </div>
                                <hr/>
                                <div className={"list-group-item"}>
                                    <h4>Current Mapped Patterns</h4>
                                    <h5 className={"mb-4"}>* Removing the below patterns will remove them without submit
                                        action.</h5>
                                    {matchAreaMap.map((item) => {
                                        return (
                                            <div key={`stored-areaMap-${item.id}`}
                                                 className={"d-flex justify-content-start"}>
                                                <i className={"fe fe-trash as-btn d-inline-block red-hover"}
                                                   onClick={() => {
                                                       setMatchAreaMap((prevState) => {
                                                           return prevState.filter(i => i?.id !== item.id);
                                                       })
                                                   }}></i>
                                                <div className={"form-floating d-inline-block w-50 mb-3 mx-3"}>
                                                    <input type={"text"} name={"area-pattern-input"}
                                                           className={"form-control"}
                                                           id={`tmp-area-pattern-${item.id}`}
                                                           value={item.pattern} readOnly={true}/>
                                                    <label htmlFor={`tmp-area-pattern-${item.id}`}>Match
                                                        Pattern</label>
                                                </div>
                                                <div className={"form-floating d-inline-block w-50 mb-3 mx-3"}>
                                                    <input type={"text"} name={"area-replacement-input"}
                                                           className={"form-control"}
                                                           id={`tmp-area-replacement${item.id}`}
                                                           value={item.display} readOnly={true}/>
                                                    <label htmlFor={`tmp-area-replacement${item.id}`}>Area</label>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    {editRights &&
                                        <>
                                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                                    spinner={true} text={"Loading"} id={"loadAreaMapSubmit"}/>
                                            <button type={"button"} className={"btn btn-primary ms-3"} id={"areaMapSubmit"}
                                                    onClick={() => areaMapUpdater()}>Submit
                                            </button>
                                        </>
                                    }
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelAreaMapBtn"}
                                            data-bs-dismiss={"modal"} onClick={() => {
                                        setTmpAreaMap([]);
                                        swapModal("areamapmodal", "addmatchmodal")
                                    }}>Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"editmatchmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={"-1"} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Edit Match Term</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"}
                                    aria-label={"Close"}/>
                        </div>
                        <form id={"editMatchForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"d-flex justify-content-start"}>
                                    <div className={"w-75"}>
                                        <div className={"form-floating mb-3 d-inline-block align-top w-50 pe-2"}>
                                            <input type={"text"} className={"form-control"} id={"create_patternE"}
                                                   onKeyUp={(e) => {
                                                       if (document.getElementById("create_sameE").checked) {
                                                           document.getElementById("create_replaceE").value = e.target.value;
                                                       }
                                                       formCheck("editMatchForm", "editMatchSubmit");
                                                   }}
                                                   required={false}/>
                                            <label htmlFor={"create_patternE"}>Create Match Term</label>
                                            <div className={"invalid-feedback"}>
                                                Word to trigger alarm on.
                                            </div>
                                        </div>
                                        <div className={"form-floating mb-3 d-inline-block align-top w-50 px-2"}>
                                            <input type={"text"} className={"form-control"} id={"create_replaceE"}
                                                   onKeyUp={(e) => {
                                                       formCheck("editMatchForm", "editMatchSubmit");
                                                   }}
                                                   required={false}/>
                                            <label htmlFor={"create_replaceE"}>Create Replacement</label>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"align-top ms-3 mt-1"}>
                                            <input className={"form-check-input mt-1"}
                                                   style={{width: "1.25rem", height: "1.25rem"}}
                                                   id={"create_sameE"} type={"checkbox"}
                                                   onChange={(e) => {
                                                       if (e.target.checked) {
                                                           document.getElementById("create_replaceE").value = document.getElementById("repeat_patternE").value;
                                                           document.getElementById("create_replaceE").readOnly = true;
                                                           document.getElementById("create_removeE").checked = false;
                                                       } else {
                                                           document.getElementById("create_replaceE").readOnly = false;
                                                       }
                                                       formCheck("editMatchForm", "editMatchSubmit");
                                                   }}/>
                                            <label className={"form-check-label ms-2 fs-3"} style={{color: "#1d1d1d"}}
                                                   htmlFor={"create_sameE"}>Same</label>
                                        </div>
                                        <div className={"align-top ms-3 mt-2"}>
                                            <input className={"form-check-input mt-1"}
                                                   style={{width: "1.25rem", height: "1.25rem"}}
                                                   id={"create_removeE"} type={"checkbox"}
                                                   onChange={(e) => {
                                                       if (e.target.checked) {
                                                           document.getElementById("create_replaceE").value = "";
                                                           document.getElementById("create_replaceE").readOnly = true;
                                                           document.getElementById("create_sameE").checked = false;
                                                       } else {
                                                           document.getElementById("create_replaceE").readOnly = false;
                                                       }
                                                       formCheck("editMatchForm", "editMatchSubmit");
                                                   }}/>
                                            <label className={"form-check-label ms-2 fs-3"} style={{color: "#1d1d1d"}}
                                                   htmlFor={"create_removeE"}>Remove</label>
                                        </div>
                                    </div>
                                </div>
                                <div className={"d-flex justify-content-start"}>
                                    <div className={"w-75"}>
                                        <div className={"form-floating mb-3 d-inline-block align-top w-50 pe-2"}>
                                            <input type={"text"} className={"form-control"} id={"repeat_patternE"}
                                                   onKeyUp={(e) => {
                                                       if (document.getElementById("repeat_sameE").checked) {
                                                           document.getElementById("repeat_replaceE").value = e.target.value;
                                                       }
                                                       formCheck("editMatchForm", "editMatchSubmit");
                                                   }}
                                                   required={false}/>
                                            <label htmlFor={"repeat_patternE"}>Repeat Match Term</label>
                                            <div className={"invalid-feedback"}>
                                                Word to trigger repeat on.
                                            </div>
                                        </div>
                                        <div className={"form-floating mb-3 d-inline-block align-top w-50 ps-2"}>
                                            <input type={"text"} className={"form-control"} id={"repeat_replaceE"}
                                                   onKeyUp={(e) => {
                                                       formCheck("editMatchForm", "editMatchSubmit");
                                                   }}
                                                   required={false}/>
                                            <label htmlFor={"repeat_replaceE"}>Repeat Replacement</label>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"align-top ms-3 mt-1"}>
                                            <input className={"form-check-input mt-1"}
                                                   style={{width: "1.25rem", height: "1.25rem"}}
                                                   id={"repeat_sameE"} type={"checkbox"}
                                                   onChange={(e) => {
                                                       if (e.target.checked) {
                                                           document.getElementById("repeat_replaceE").value = document.getElementById("repeat_patternE").value;
                                                           document.getElementById("repeat_replaceE").readOnly = true;
                                                           document.getElementById("repeat_removeE").checked = false;
                                                       } else {
                                                           document.getElementById("repeat_replaceE").readOnly = false;
                                                       }
                                                       formCheck("editMatchForm", "editMatchSubmit");
                                                   }}/>
                                            <label className={"form-check-label ms-2 fs-3"} style={{color: "#1d1d1d"}}
                                                   htmlFor={"repeat_sameE"}>Same</label>
                                        </div>
                                        <div className={"align-top ms-3 mt-2"}>
                                            <input className={"form-check-input mt-1"}
                                                   style={{width: "1.25rem", height: "1.25rem"}}
                                                   id={"repeat_removeE"} type={"checkbox"}
                                                   onChange={(e) => {
                                                       if (e.target.checked) {
                                                           document.getElementById("repeat_replaceE").value = ""
                                                           document.getElementById("repeat_replaceE").readOnly = true;
                                                           document.getElementById("repeat_sameE").checked = false;
                                                       } else {
                                                           document.getElementById("repeat_replaceE").readOnly = false;
                                                       }
                                                       formCheck("editMatchForm", "editMatchSubmit");
                                                   }}/>
                                            <label className={"form-check-label ms-2 fs-3"} style={{color: "#1d1d1d"}}
                                                   htmlFor={"repeat_removeE"}>Remove</label>
                                        </div>
                                    </div>
                                </div>
                                <div className={"d-flex justify-content-start"}>
                                    <div className={"w-75"}>
                                        <div className={"form-floating mb-3 d-inline-block align-top w-50 pe-2"}>
                                            <input type={"text"} className={"form-control"} id={"done_patternE"}
                                                   onKeyUp={(e) => {
                                                       if (document.getElementById("done_sameE").checked) {
                                                           document.getElementById("done_replaceE").value = e.target.value;
                                                       }
                                                       formCheck("editMatchForm", "editMatchSubmit");
                                                   }}
                                                   required={false}/>
                                            <label htmlFor={"done_patternE"}>Done Match Term</label>
                                            <div className={"invalid-feedback"}>
                                                Word to trigger clear on.
                                            </div>
                                        </div>
                                        <div className={"form-floating mb-3 d-inline-block align-top w-50 ps-2"}>
                                            <input type={"text"} className={"form-control"} id={"done_replaceE"}
                                                   onKeyUp={(e) => {
                                                       formCheck("editMatchForm", "editMatchSubmit");
                                                   }}
                                                   required={false}/>
                                            <label htmlFor={"done_replaceE"}>Done Replacement</label>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"align-top ms-3 mt-1"}>
                                            <input className={"form-check-input mt-1"}
                                                   style={{width: "1.25rem", height: "1.25rem"}}
                                                   id={"done_sameE"} type={"checkbox"}
                                                   onChange={(e) => {
                                                       if (e.target.checked) {
                                                           document.getElementById("done_replaceE").value = document.getElementById("done_patternE").value;
                                                           document.getElementById("done_replaceE").readOnly = true;
                                                           document.getElementById("done_removeE").checked = false;
                                                       } else {
                                                           document.getElementById("done_replaceE").readOnly = false;
                                                       }
                                                       formCheck("editMatchForm", "editMatchSubmit");
                                                   }}/>
                                            <label className={"form-check-label ms-2 fs-3"} style={{color: "#1d1d1d"}}
                                                   htmlFor={"done_sameE"}>Same</label>
                                        </div>
                                        <div className={"align-top ms-3 mt-2"}>
                                            <input className={"form-check-input mt-1"}
                                                   style={{width: "1.25rem", height: "1.25rem"}}
                                                   id={"done_removeE"} type={"checkbox"}
                                                   onChange={(e) => {
                                                       if (e.target.checked) {
                                                           document.getElementById("done_replaceE").value = "";
                                                           document.getElementById("done_replaceE").readOnly = true;
                                                           document.getElementById("done_sameE").checked = false;
                                                       } else {
                                                           document.getElementById("done_replaceE").readOnly = false;
                                                       }
                                                       formCheck("editMatchForm", "editMatchSubmit");
                                                   }}/>
                                            <label className={"form-check-label ms-2 fs-3"} style={{color: "#1d1d1d"}}
                                                   htmlFor={"done_removeE"}>Remove</label>
                                        </div>
                                    </div>
                                </div>
                                <div className={"d-flex justify-content-start"}>
                                    <div className={"w-75"}>
                                        <div className={"form-floating mb-3 d-inline-block align-top w-50 pe-2"}>
                                            <input type={"text"} className={"form-control"} id={"ignore_patternE"}
                                                   onKeyUp={(e) => {
                                                       formCheck("editMatchForm", "editMatchSubmit");
                                                   }}
                                                   required={false}/>
                                            <label htmlFor={"ignore_patternE"}>Ignore Match Term</label>
                                            <div className={"invalid-feedback"}>
                                                Word to trigger ignores on.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"mb-5"}>
                                    <div className={"align-top ms-3 mt-2"}>
                                        <input className={"form-check-input mt-1"}
                                               style={{width: "1.25rem", height: "1.25rem"}}
                                               id={"iscaseE"} type={"checkbox"}/>
                                        <label className={"form-check-label ms-2 fs-3"} style={{color: "#1d1d1d"}}
                                               htmlFor={"iscaseE"}>Case Sensitive</label>
                                    </div>
                                </div>
                                <div className={"d-flex justify-content-start"}>
                                    <div className={"form-floating mb-3 d-inline-block align-top w-50 pe-2"}>
                                        <input type={"text"} className={"form-control"} id={"device_patternE"}
                                               onKeyUp={(e) => {
                                                   formCheck("editMatchForm", "editMatchSubmit");
                                               }}
                                               required={false}/>
                                        <label htmlFor={"device_patternE"}>Device Pattern</label>
                                    </div>
                                    <div className={"form-floating mb-3 w-50 ps-2"}>
                                        <select className={"form-select"} value={undefined}
                                                id={"matchDeviceE"}
                                                onChange={() => formCheck("editMatchForm", "editMatchSubmit")}>
                                            <option value={""}></option>
                                            <option value={"Pull Cord"}>Pull Cord</option>
                                            <option value={"Push Cord"}>Push Cord</option>
                                            <option value={"Door"}>Door</option>
                                            <option value={"Delayed Egress"}>Delayed Egress</option>
                                        </select>
                                        <label htmlFor={"matchDeviceE"}>Device Type</label>
                                    </div>
                                </div>
                                <div className={"d-flex justify-content-start"}>
                                    <div className={"form-floating mb-3 d-inline-block align-top w-50 pe-2"}>
                                        <input type={"text"} className={"form-control"} id={"area_patternE"}
                                               onKeyUp={(e) => {
                                                   formCheck("editMatchForm", "editMatchSubmit");
                                               }}
                                               required={false}/>
                                        <label htmlFor={"area_patternE"}>Area Pattern</label>
                                    </div>
                                    <div className={"form-floating mb-3 w-50 ps-2"}>
                                        <select className={"form-select"} value={undefined}
                                                id={"matchAreaE"}
                                                onChange={() => formCheck("editMatchForm", "editMatchSubmit")}>
                                            <option value={""}></option>
                                            {locationOptions}
                                        </select>
                                        <label htmlFor={"matchAreaE"}>Area</label>
                                    </div>
                                </div>
                                <input id={"matchterm_idE"} type={"hidden"} className={"d-none"}/>
                            </div>
                            <div className={"modal-footer"}>
                                <button type={"button"} className={"btn btn-secondary"} id={"cancelMatchEditBtn"}
                                        data-bs-dismiss={"modal"}>Cancel
                                </button>
                                {editRights &&
                                    <>
                                        <Button type={"button"} class={"btn btn-primary d-none"} disabled={true} spinner={true}
                                                text={"Loading"} id={"loadEditMatchSubmit"}/>
                                        <button type={"button"} className={"btn btn-primary"}
                                                onClick={() => formEditMatchSubmit()} id={"editMatchSubmit"}>Submit
                                        </button>
                                    </>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"dbeditmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={"-1"} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Edit Server Database</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"}
                                    aria-label={"Close"}/>
                        </div>
                        <form id={"editDBForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <h3 className={"ms-1 mb-2"}>Serial In</h3>
                                <div className={"d-inline-block form-floating mb-3 w-50 pe-2 align-top"}>
                                    <input type={"text"} className={"form-control"} id={"serialIn"}
                                           onKeyUp={() => formCheck("editDBForm", "editDBSubmit")}
                                           pattern={"[ -~]+"}/>
                                    <input type={"hidden"} id={"serialInId"}/>
                                    <label htmlFor={"serialIn"}>Port Address</label>
                                    <div className={"invalid-feedback"}>
                                        Enter a valid port!
                                    </div>
                                </div>
                                <div className={"d-inline-block form-floating mb-3 w-50 ps-2 align-top"}>
                                    <input type={"text"} className={"form-control"} id={"in_serial_delimiter"}
                                           onKeyUp={() => formCheck("editDBForm", "editDBSubmit")}
                                           pattern={"(<CR>|<LF>|<HT>|<NULL>|<BS>|<FF>|<VT>)*"}/>
                                    <input type={"hidden"} id={"in_serial_delimiter_id"}/>
                                    <label htmlFor={"in_serial_delimiter"}>Message Delimiters</label>
                                    <div className={"invalid-feedback"}>
                                        Enter valid delimiters
                                        <br/>
                                        Examples {'<CR>|<LF>|<HT>|<NULL>|<BS>|<FF>|<VT> no spaces or punctuation'}!
                                    </div>
                                </div>
                                <div className={"form-floating mb-5"}>
                                    <select className={"form-select"}
                                            onChange={() => formCheck("editDBForm", "editDBSubmit")}
                                            id={"in_serial_baud"} defaultValue={"600"}>
                                        <option value="110">110</option>
                                        <option value="300">300</option>
                                        <option value="600">600</option>
                                        <option value="1200">1200</option>
                                        <option value="2400">2400</option>
                                        <option value="4800">4800</option>
                                        <option value="9600">9600</option>
                                        <option value="14400">14400</option>
                                        <option value="19200">19200</option>
                                        <option value="38400">38400</option>
                                        <option value="57600">57600</option>
                                        <option value="115200">115200</option>
                                        <option value="128000">128000</option>
                                    </select>
                                    <input type={"hidden"} id={"in_serial_baud_id"}/>
                                    <label htmlFor={"in_serial_baud"}>Baud Rate</label>
                                </div>
                                <h3 className={"ms-1 mb-2"}>Serial Out</h3>
                                <div className={"d-inline-block form-floating mb-3 w-50 pe-2 align-top"}>
                                    <input type={"text"} className={"form-control"} id={"serialOut"}
                                           onKeyUp={() => formCheck("editDBForm", "editDBSubmit")}
                                           pattern={"[ -~]+"}/>
                                    <input type={"hidden"} id={"serialOutId"}/>
                                    <label htmlFor={"serialOut"}>Port Address</label>
                                    <div className={"invalid-feedback"}>
                                        Enter a valid port!
                                    </div>
                                </div>
                                <div className={"d-inline-block form-floating mb-3 w-50 ps-2 align-top"}>
                                    <input type={"text"} className={"form-control"} id={"out_serial_delimiter"}
                                           onKeyUp={() => formCheck("editDBForm", "editDBSubmit")}
                                           pattern={"(<CR>|<LF>|<HT>|<NULL>|<BS>|<FF>|<VT>)*"}/>
                                    <input type={"hidden"} id={"out_serial_delimiter_id"}/>
                                    <label htmlFor={"out_serial_delimiter"}>Message Delimiters</label>
                                    <div className={"invalid-feedback"}>
                                        Enter valid delimiters
                                        <br/>
                                        Examples {'<CR>|<LF>|<HT>|<NULL>|<BS>|<FF>|<VT> no spaces or punctuation'}!
                                    </div>
                                </div>
                                <div className={"form-floating mb-5"}>
                                    <select className={"form-select"}
                                            onChange={() => formCheck("editDBForm", "editDBSubmit")}
                                            id={"out_serial_baud"} defaultValue={"600"}>
                                        <option value="110">110</option>
                                        <option value="300">300</option>
                                        <option value="600">600</option>
                                        <option value="1200">1200</option>
                                        <option value="2400">2400</option>
                                        <option value="4800">4800</option>
                                        <option value="9600">9600</option>
                                        <option value="14400">14400</option>
                                        <option value="19200">19200</option>
                                        <option value="38400">38400</option>
                                        <option value="57600">57600</option>
                                        <option value="115200">115200</option>
                                        <option value="128000">128000</option>
                                    </select>
                                    <input type={"hidden"} id={"out_serial_baud_id"}/>
                                    <label htmlFor={"out_serial_baud"}>Baud Rate</label>
                                </div>
                                <h3 className={"ms-1 mb-2"}>Event Config</h3>
                                <div className={"form-floating mb-5"}>
                                    <input type={"text"} className={"form-control"} id={"config_ttl"}
                                           onKeyUp={() => formCheck("editDBForm", "editDBSubmit")}
                                           pattern={"[0-9]+"}/>
                                    <input type={"hidden"} id={"config_ttl_id"}/>
                                    <label htmlFor={"config_ttl"}>Global Auto-Clear Time to Live</label>
                                    <div className={"invalid-feedback"}>
                                        Enter a ttl, numbers only!
                                    </div>
                                </div>
                                <h3 className={"ms-1 mb-2"}>Local Environment Config</h3>
                                <div className={"d-flex justify-content-between mb-3"}>
                                    <div className={`p-2 border ${evActive ? "border-success" : ""} rounded`}>
                                        <h4 className={"mb-2 me-1"}>Environment Processing</h4>
                                        <div className={"form-check form-switch"}>
                                            <input type={"checkbox"} className={"form-check-input"}
                                                   id={"config_env_processing"}
                                                   onChange={(e) => {
                                                       setEvActive(e.target.checked);
                                                       formCheck("editDBForm", "editDBSubmit");
                                                   }} />
                                            <input type={"hidden"} id={"config_env_processing_id"}/>
                                            <label htmlFor={"config_env_processing"} >
                                                <span id={"in-label-off"}
                                                      className={!evActive ? "text-danger fw-bold" : ""}>
                                                    Off
                                                </span>/
                                                <span id={"in-label-on"}
                                                      className={evActive ? "text-success-bright fw-bold" : ""}>
                                                    On
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className={"form-floating ps-2"} style={{width: "33.33%"}}>
                                        <input type={"number"} className={"form-control"} id={"config_env_sample_rate"}
                                               onChange={() => {
                                                   formCheck("editDBForm", "editDBSubmit");
                                               }}
                                               min={30} max={1800} step={1}/>
                                        <input type={"hidden"} id={"config_env_sample_rate_id"}/>
                                        <label htmlFor={"config_env_sample_rate"}>Sample Rate</label>
                                        <div className={"invalid-feedback"}>
                                            Enter how often in minutes to collect environment data from devices.
                                            30 minute minimum.
                                        </div>
                                    </div>
                                </div>
                                <div className={"d-flex justify-content-between mb-3"}>
                                    <div className={"form-floating pe-2"} style={{width: "33.33%"}}>
                                        <input type={"number"} className={"form-control"} id={"config_low_temp"}
                                               onChange={(e) => {
                                                   document.getElementById("config_high_temp").min = parseInt(e.target.value) + 1;
                                                   formCheck("editDBForm", "editDBSubmit");
                                               }}
                                               min={0} max={120} step={1}/>
                                        <input type={"hidden"} id={"config_low_temp_id"}/>
                                        <label htmlFor={"config_low_temp"}>Low Temperature</label>
                                        <div className={"invalid-feedback"}>
                                            Enter valid temperature below high temp
                                        </div>
                                    </div>
                                    <div className={"form-floating px-1"} style={{width: "33.33%"}}>
                                        <input type={"number"} className={"form-control"} id={"config_high_temp"}
                                               onChange={(e) => {
                                                   document.getElementById("config_low_temp").max = parseInt(e.target.value) - 1;
                                                   formCheck("editDBForm", "editDBSubmit");
                                               }} min={0} max={120} step={1}/>
                                        <input type={"hidden"} id={"config_high_temp_id"}/>
                                        <label htmlFor={"config_high_temp"}>High Temperature</label>
                                        <div className={"invalid-feedback"}>
                                            Enter valid temperature above low temp
                                        </div>
                                    </div>
                                    <div className={"form-floating ps-2"} style={{width: "33.33%"}}>
                                        <input type={"number"} className={"form-control"} id={"config_low_bat"}
                                               onChange={() => formCheck("editDBForm", "editDBSubmit")}
                                               min={1} max={6} step={.1}/>
                                        <input type={"hidden"} id={"config_low_bat_id"}/>
                                        <label htmlFor={"config_low_bat"}>Low Battery</label>
                                        <div className={"invalid-feedback"}>
                                            Enter valid battery voltage level
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"} style={{justifyContent: "space-between"}}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelEditDBBtn"}
                                            data-bs-dismiss={"modal"}>Cancel
                                    </button>
                                    {editRights &&
                                        <>
                                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                                    spinner={true} text={"Loading"} id={"loadEditDBSubmit"}/>
                                            <button type={"button"} className={"btn btn-primary ms-3"} id={"editDBSubmit"}
                                                    onClick={() => basicDBSubmit()}>Submit
                                            </button>
                                        </>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <BulkDeleteModal
                deletePerform={() => deleteMatchPerform("bulkDeleteSubmit", "loadBulkDeleteSubmit",
                            "bulkConfirmClose", ppRules2.start, ppRules2.end)}
                start={ppRules.start} end={ppRules.end} header={"Delete Match Term"}/>
            <input type={"hidden"} id={"dContent"}/>
            <input type={"hidden"} id={"uContent"}/>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true}
                                autoClose={10000}/>
            </Suspense>
        </>
    );
};

export default ServerProfile;