import { Navigate } from 'react-router-dom';
import Navigation from "./Navigation";
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import { useParams } from "react-router";
import Corporations from "./Corporations";
import Campuses from "./Campuses";
import HeaderBanner from "./component-assets/HeaderBanner";
import AllServers from "./AllServers";
import {iAmHigherAuthority} from "../libs";

const Customers = (props) => {
    let pageContent, dData, paramVars, bData;
    paramVars = useParams();

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    if (paramVars?.dType === "corporations") {
        pageContent = <Corporations/>
    }else if (paramVars?.dType === "campuses"){
        pageContent = <Campuses />
    }else if (paramVars?.dType === "servers"){
        pageContent = <AllServers />
    }else{
        pageContent = (
            <div>
                404 Page not found
            </div>
        );
    }

    return (iAmHigherAuthority(80, dData?.role_weight)) ? (
        <>
            <Navigation />
            {dData?.myVersion !== dData?.currentVersion && dData?.cloud ? <HeaderBanner /> : <></>}
            {pageContent}
        </>
    ) : <Navigate to={"/"}/>;
};

export default Customers;