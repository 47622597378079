import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import Header from "./Header";
import {Suspense, useEffect, useState} from "react";
import Footer from "./Footer";
import CampusDropDown from "./component-assets/CampusDropDown";
import ApiRequester from "./ApiRequester";
import {ToastContainer} from "react-toastify";
import Button from "./component-assets/Button";
import TableHeaders from "./component-assets/TableHeaders";
import TableBody from "./component-assets/TableBody";
import {
    checkAll,
    formCheck,
    formFail,
    formSuccess,
    handleSubmitActions,
    hexToRGB,
    rgbToHex,
    showModal,
    sortFunc
} from "../libs";

const LightCtrlSettings = (props) => {

    let dData, channelHeaderData, deviceHeaders;
    if (ReactSession.get("PAL") != null) {
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    channelHeaderData = [
        {label: "Channel ID", key: "channel_id", align: "center"},
        {label: "Channel Name", key: "channel_name", align: "center"},
        {label: "Device", key: "devices", align: "center",
            link: {enabled: true, type: "conditional-obj", conditionKey: "manufacture_id",
                condition: {"1": {linkto: "/profiles/inovonics", linkkey: "device_id", displayKey: "device_name"}}}},
        {label: "Area", key: "area_name", align: "center",
            link: {enabled: true, linkto: "/profiles/area", linkkey: "area_id"}},
        {label: "Light Behavior", key: "is_flashing", align: "center", type: "bool", f: "Static", t: "Flashing"},
        {label: "Color", key: ["red_intensity", "green_intensity", "blue_intensity"], align: "center", type: "color"}
    ];

    deviceHeaders = [
        {label: "Name", key: "device_name", align: "left"},
        {label: "Serial Number", key: "manufacture_uuid", align: "left"},
        {label: "Area", key: "area_name", align: "left"},
    ];

    // Global Page Variables
    const [lightData, setLightData] = useState([]);
    const [pageContent, setPageContent] = useState(<></>);
    const [loaded, setLoaded] = useState(false);
    const [campusId, setCampusId] = useState(dData.acnt);
    const [count, setCount] = useState(1);
    const [tabItem, setTabItem] = useState("");
    const [channelData, setChannelData] = useState([]);
    const [channelOptions, setChannelOptions] = useState([]);
    const [lightBoxOptions, setLightBoxOptions] = useState([]);
    const [deviceOptions, setDeviceOptions] = useState([]);
    const [lightCtrlIndex, setLightCtrlIndex] = useState(0);
    const [lightCtrlId, setLightCtrlId] = useState(0);
    const [storedIndex, setStoredIndex] = useState(0);
    const [relayId, setRelayId] = useState(0);
    const [colorOptionsE, setColorOptionsE] = useState([]);
    const [colorValueE, setColorValueE] = useState("0");
    // Device Picker Table Variables
    const [devOriginData, setDevOriginData] = useState([]);
    const [devTableRows, setDevTableRows] = useState([]);
    const [devDataCnt, setDevDataCnt] = useState(0);
    const [devIdList, setDevIdList] = useState([]);
    const [devSearch, setDevSearch] = useState("");
    const [devSorter, setDevSorter] = useState({
        sortColumn: "device_name",
        sortOrder: "asc"
    });
    // Location Section Variables
    const [locationData, setLocationData] = useState([]);
    const [locationOptions, setLocationOptions] = useState("");
    const [locationSorter, setLocationSorter] = useState({
        sortColumn: "area_name",
        sortOrder: "asc"
    });
    const [sorter, setSorter] = useState({
        sortColumn: "channel_id",
        sortOrder: "asc"
    });


    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0) {
            ApiRequester({reqEndpoint: "myfetch", payload: {campus_id: campusId},
                          procedure: "getlightctrlsettings", reqType: "stored"}).then(data => {
                setLightData(data ? data : []);
                if (tabItem === "" && data && data.length > 0){
                    setTabItem(data[0]?.description);
                }
                setLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "sp_dev_getareasbycampusid", reqType: "stored"}).then(data => {
                setLocationData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "getsnfdevices", reqType: "stored"}).then(data => {
                setDevOriginData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "att_channel_types",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setChannelData(data);
            });
            setCount(count => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        let tmp = [];
        if (lightData.length > 0){
            for (let i = 0; i < lightData.length; i++){
                lightData[i]?.channels?.sort((a, b) => sortFunc(a, b, sorter.sortColumn, sorter.sortOrder));
                tmp.push(
                    <option key={`light-option-${i}`} value={lightData[i].light_controller_id}>
                        {lightData[i].description}
                    </option>
                );
            }
            setLightData(lightData);
            setLightBoxOptions(tmp);
        }
    }, [lightData]);

    useEffect(() => {
        let tmp = [];
        locationData.sort((a, b) => sortFunc(a, b, locationSorter.sortColumn, locationSorter.sortOrder));
        for (let i = 0; i < locationData.length; i++){
            tmp.push(
                <option key={`location-option-${i}`} value={locationData[i].area_id}>
                    {locationData[i].area_name}
                </option>
            );
        }
        setLocationOptions(() => tmp);
    }, [locationData]);

    useEffect(() => {
        let i, tmp = [], input;
        input = devSearch?.toString()?.toLowerCase();
        for (i = 0; i < devOriginData?.length; i++){
            if ((devOriginData[i]?.device_name && devOriginData[i]?.device_name?.toLowerCase()?.search(input) !== -1)){
                tmp.push(devOriginData[i]);
            }
        }
        setDevDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, devSorter.sortColumn, devSorter.sortOrder));
        setDevTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                   actionAppearance={() => {}} tableHeaders={deviceHeaders} checkboxId={"uCheckDev"}
                                   parentComponent={"EventReports"} dData={dData} checkboxlist={devIdList}
                                   modal={false} editModal={() => {}} unk={"device-x-"}
                                   setCheckboxId={(value) => {setDevIdList(value)}} checkidkey={"device_id"} />);
    }, [devOriginData, devSorter, devIdList, devSearch]);

    useEffect(() => {
        let tmp = [];
        for (let i = 0; i < channelData.length; i++){
            tmp.push(
                <option key={`channel-option-${i}`} value={channelData[i].light_channel_type_id}>
                    {channelData[i].light_channel_type_name}
                </option>
            );
        }
        setChannelOptions(() => tmp);
    }, [channelData]);

    useEffect(() => {
        formCheck("editForm", "editSubmit");
    }, [colorValueE]);

    function formAddSubmit(){
        let name, lcId, typeId, areaId, payload, channelCnt;
        handleSubmitActions("addSubmit", "loadAddSubmit");
        name = document.getElementById("name").value;
        lcId = document.getElementById("lcid").value;
        typeId = document.getElementById("channelType").value;
        areaId = document.getElementById("locationId").value;
        for (let i=0; i < channelData.length; i++){
            if (channelData[i].light_channel_type_id === parseInt(typeId)){
                channelCnt = channelData[i].max_channels;
            }
        }
        payload = [{campus_id: campusId, lc_id: parseInt(lcId), description: name, channel_type_id: parseInt(typeId),
                    area_id: parseInt(areaId), max_channels: parseInt(channelCnt)}];
        ApiRequester({reqEndpoint: "mytransaction", TransType: "lcbox_add", Payload: payload,
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
            if (data && data[0] === true){
                formSuccess(`Light controller box ${name} has been added`, "addSubmit",
                    "loadAddSubmit", "cancelAddBtn", "addForm");
                setCount(0);
            }
            else{
                formFail(`Error during add, error code: ${data[1]}`, "addSubmit", "loadAddSubmit");
            }
        });
    }

    function formEditSubmit(){
        let payload, name, r, g, b, isFlash;
        handleSubmitActions("editSubmit", "loadEditSubmit");
        name = document.getElementById("nameE").value;
        r = document.getElementById("redIntensityE").value;
        g = document.getElementById("greenIntensityE").value;
        b = document.getElementById("blueIntensityE").value;
        isFlash = document.getElementById("isFlashingE").checked;
        payload = [{light_relay_id: relayId, channel_name: name, is_flashing: isFlash ? 1 : 0, red_intensity: r,
                    green_intensity: g, blue_intensity: b, device_ids: devIdList, campus_id: campusId,
                    light_controller_id: lightCtrlId}];
        ApiRequester({reqEndpoint: "mytransaction", TransType: "lcbox_edit", Payload: payload,
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
            if (data && data[0] === true){
                setCount(0);
                formSuccess("Channel updated", "editSubmit", "loadEditSubmit", "cancelEditBtn");
            }
            else{
                formFail("Failed to update channel");
            }
        });
    }

    function editModal(editIndex){
        let dataIndex, r, g, b, ctype, newColorsOptions, dIdList = [];
        dataIndex = parseInt(editIndex);
        setStoredIndex(dataIndex);

        document.getElementById("uCheckHeadDev").checked = false;
        for (let i2 = 0; i2 < lightData[lightCtrlIndex]?.channels[dataIndex]?.devices?.length; i2++){
            dIdList.push(lightData[lightCtrlIndex]?.channels[dataIndex]?.devices[i2]?.device_id?.toString());
        }
        setDevIdList(dIdList);
        document.getElementById("uCheckHeadDev").checked = dIdList && dIdList.length !== 0 && dIdList.length === devOriginData.length;

        setLightCtrlId(lightData[lightCtrlIndex].light_controller_id);
        setRelayId(lightData[lightCtrlIndex].channels[dataIndex].light_relay_id);
        r = lightData[lightCtrlIndex].channels[dataIndex].red_intensity;
        g = lightData[lightCtrlIndex].channels[dataIndex].green_intensity;
        b = lightData[lightCtrlIndex].channels[dataIndex].blue_intensity;
        ctype = lightData[lightCtrlIndex].channel_type_id;
        document.getElementById("nameE").value = lightData[lightCtrlIndex]?.channels[dataIndex]?.channel_name ? lightData[lightCtrlIndex].channels[dataIndex].channel_name : "";
        document.getElementById("redIntensityE").value = r
        document.getElementById("greenIntensityE").value = g
        document.getElementById("blueIntensityE").value = b
        document.getElementById("isFlashingE").checked = lightData[lightCtrlIndex].channels[dataIndex].is_flashing;
        if (ctype === 1){
            document.getElementById("color-msg").classList.add("d-none");
            newColorsOptions = [
                <option key={"color1"} value={"1"}>White</option>,
                <option key={"color2"} value={"2"}>Red</option>,
                <option key={"color3"} value={"3"}>Green</option>,
                <option key={"color4"} value={"4"}>Blue</option>,
                <option key={"color5"} value={"5"}>Custom</option>
            ];
        }else if (ctype === 2){
            document.getElementById("color-msg").classList.remove("d-none");
            newColorsOptions = [
                <option key={"color1"} value={"1"}>White</option>
            ];
        }else{
            document.getElementById("color-msg").classList.remove("d-none");
            newColorsOptions = [
                <option key={"color1"} value={"1"}>White</option>,
                <option key={"color2"} value={"2"}>Red</option>,
                <option key={"color3"} value={"3"}>Green</option>,
                <option key={"color4"} value={"4"}>Blue</option>
            ];
        }
        setColorOptionsE(newColorsOptions);

        document.getElementById("colorpickerE").value = rgbToHex([r, g, b]);
        if ([r, g, b].every((color) => {return color === 255})){
            document.getElementById("redIntensityE").readOnly = true;
            document.getElementById("greenIntensityE").readOnly = true;
            document.getElementById("blueIntensityE").readOnly = true;
            document.getElementById("colorpickerE").disabled = true;
            setColorValueE("1");
        }else if (r === 255 && g === 0 && b === 0){
            document.getElementById("redIntensityE").readOnly = true;
            document.getElementById("greenIntensityE").readOnly = true;
            document.getElementById("blueIntensityE").readOnly = true;
            document.getElementById("colorpickerE").disabled = true;
            setColorValueE("2");
        }else if (r === 0 && g === 255 && b === 0){
            document.getElementById("redIntensityE").readOnly = true;
            document.getElementById("greenIntensityE").readOnly = true;
            document.getElementById("blueIntensityE").readOnly = true;
            document.getElementById("colorpickerE").disabled = true;
            setColorValueE("3");
        }else if (r === 0 && g === 0 && b === 255){
            document.getElementById("redIntensityE").readOnly = true;
            document.getElementById("greenIntensityE").readOnly = true;
            document.getElementById("blueIntensityE").readOnly = true;
            document.getElementById("colorpickerE").disabled = true;
            setColorValueE("4");
        }else{
            document.getElementById("redIntensityE").readOnly = false;
            document.getElementById("greenIntensityE").readOnly = false;
            document.getElementById("blueIntensityE").readOnly = false;
            document.getElementById("colorpickerE").disabled = false
            setColorValueE("5");
        }
        formCheck("editForm", "editSubmit");
        showModal("editmodal");
    }

    function deletePerform(){
        let lId, payload;
        handleSubmitActions("deleteSubmit", "loadDeleteSubmit");
        lId = document.getElementById("dLightbox").value;
        payload = [{campus_id: campusId, light_controller_id: parseInt(lId)}];
        ApiRequester({reqEndpoint: "mytransaction", TransType: "lcbox_delete", Payload: payload,
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
            if (data && data[0] === true){
                formSuccess(`Light controller has been deleted`, "deleteSubmit",
                    "loadDeleteSubmit", "cancelDeleteBtn", "deleteForm");
                setCount(0);
            }
            else{
                formFail(`Error during add, error code: ${data[1]}`, "deleteSubmit", "loadDeleteSubmit");
            }
        });
    }

    const deviceTableHeaders = <TableHeaders checkbox={true} headerData={deviceHeaders} checkboxlist={devIdList}
                                             checkAll={() => checkAll(0, devDataCnt ? devDataCnt : 0, devIdList, setDevIdList, "uCheckHeadDev", "uCheckDev")}
                                             writeAccess={true} sortOrder={devSorter.sortOrder} checkboxHeaderId={"uCheckHeadDev"}
                                             sortColumn={devSorter.sortColumn} sorter={devSorter} setSorter={setDevSorter}
                                             actionAppearance={() => {}} />

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div>
                <Button text={"Delete Controller"} type={"button"} class={"btn btn-danger ms-2 mt-1"}
                        icon={true} iconClass={"fe fe-trash-2 me-2"}
                        onClick={() => {
                            formCheck("deleteForm", "deleteSubmit");
                            showModal("deletemodal");}} />
                <Button text={"New Controller"} type={"button"} class={"btn btn-primary ms-2 mt-1"}
                        icon={true} iconClass={"fe fe-plus me-2"}
                        onClick={() => {
                            document.getElementById("name").value = "";
                            document.getElementById("channelType").value = "";
                            document.getElementById("lcid").value = "";
                            document.getElementById("locationId").value = "";
                            formCheck("addForm", "addSubmit");
                            showModal("addmodal");}} />
            </div>
            <CampusDropDown campusId={campusId} setCampusId={(item) => setCampusId(item)}
                            setCount={(item) => setCount(item)} pageAction={() => {
                setCount(0);
            }} />
        </div>
    );



    return (
        <div className={"main-content"}>
            <Header preTitle={"Settings"} Title={"Light Controller"} content={headerContent} />
            <div className={"container-fluid"}>
                {lightData?.length > 0
                    ? <>
                        <div className={"col"}>
                            <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                                {lightData.map((box, i) => {
                                    return (<li key={`box-${i}`} className={"nav-item"}>
                                        <Button text={box?.description}
                                                class={tabItem === box?.description ? "nav-link active" : "nav-link"}
                                                onClick={() => {
                                                    setLightCtrlIndex(i);
                                                    setTabItem(box?.description);
                                                }}/>
                                    </li>);
                                })}
                            </ul>
                        </div>
                        {lightData.map((box, i) => {
                            return (
                            <div key={`box-display-${i}`} className={tabItem === box?.description ? "d-block" : "d-none"}>
                                <h4>Controller Type: <span className={"ms-3"}>{box?.light_channel_type_name}</span></h4>
                                <table className={"table table-sm table-white table-hover d-sm-table"}>
                                    <thead className={"sticky-table-head"} style={{top: 0}}>
                                    <TableHeaders checkbox={false} headerData={channelHeaderData}
                                                  checkAll={() => {}}
                                                  writeAccess={true} sortOrder={sorter.sortOrder}
                                                  checkboxHeaderId={`uCheckHead${i}`} sortColumn={sorter.sortColumn}
                                                  sorter={sorter}
                                                  setSorter={() => {}} modal={true} checkboxlist={[]}
                                                  actionAppearance={() => {}}/>
                                    </thead>
                                    <tbody>
                                    <TableBody start={0} end={box?.channels.length} tableData={box?.channels}
                                               checkbox={false}
                                               writeAccess={dData?.access?.Community?.Residents?.Write} unk={`ax${i}-`}
                                               actionAppearance={() => {}} parentComponent={"LightCtrlSettings"}
                                               tableHeaders={channelHeaderData} editModal={editModal} modal={true}
                                               dData={dData} checkboxlist={[]}
                                               setCheckboxId={() => {}} checkidkey={"channel_id"}/>
                                    </tbody>
                                </table>
                            </div>);
                        })}
                    </>
                    : <h4>{loaded ? "No light controllers set up" : ""}</h4>
                }
                <div className={"footer-spacer"}></div>
            </div>
            <div className={"modal fade"} id={"addmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Add Light Controller</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"}
                                    aria-label={"Close"}/>
                        </div>
                        <form id={"addForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"name"}
                                           onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           onChange={() => formCheck("addForm", "addSubmit")}
                                           required={true} pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                    <label htmlFor={"name"}>Controller Name</label>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <select className={"form-select"} id={"channelType"} defaultValue={undefined}
                                            required={true} onChange={(e) => {
                                                formCheck("addForm", "addSubmit");
                                            }}>
                                        <option value={""}></option>
                                        {channelOptions}
                                    </select>
                                    <label htmlFor={"channelType"}>Controller Type</label>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"number"} className={"form-control"} id={"lcid"} required={true}
                                           onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           onChange={() => formCheck("addForm", "addSubmit")}
                                           step={1} min={1} max={100}
                                           title={"Limits number of residents assigned to area."}/>
                                    <label htmlFor={"lcid"}>Controller ID</label>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <select className={"form-select"} defaultValue={undefined} required={true}
                                            id={"locationId"}
                                            onChange={() => formCheck("addForm", "addSubmit")}>
                                        <option value={""}></option>
                                        {locationOptions}
                                    </select>
                                    <label htmlFor={"locationId"}>Area</label>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <button type={"button"} className={"btn btn-secondary"} id={"cancelAddBtn"}
                                        data-bs-dismiss={"modal"}>Cancel
                                </button>
                                <Button type={"button"} class={"btn btn-primary d-none"} disabled={true} spinner={true}
                                        text={"Loading"} id={"loadAddSubmit"}/>
                                <button type={"button"} className={"btn btn-primary"} onClick={() => formAddSubmit()}
                                        id={"addSubmit"}>Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"editmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Edit Channel</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"}
                                    aria-label={"Close"}/>
                        </div>
                        <form id={"editForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-4"}>
                                    <input type={"text"} className={"form-control"} id={"nameE"}
                                           onKeyUp={() => formCheck("editForm", "editSubmit")}
                                           onChange={() => formCheck("editForm", "editSubmit")}
                                           pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                    <label htmlFor={"nameE"}>Channel Name</label>
                                </div>
                                <h4 className={"d-none"} id={"color-msg"}>*Colors are informational only for record
                                    keeping with this style of controller</h4>
                                <div className={"d-flex form-floating mb-3"}>
                                    <select className={"form-select"} id={"colorItemE"} value={colorValueE}
                                            required={true} onChange={(e) => {
                                        if (e.target.value === "5") {
                                            document.getElementById("redIntensityE").readOnly = false
                                            document.getElementById("greenIntensityE").readOnly = false
                                            document.getElementById("blueIntensityE").readOnly = false
                                            document.getElementById("colorpickerE").disabled = false
                                        } else {
                                            if (e.target.value === "1") {
                                                document.getElementById("redIntensityE").value = 255;
                                                document.getElementById("greenIntensityE").value = 255;
                                                document.getElementById("blueIntensityE").value = 255;
                                                document.getElementById("colorpickerE").value = rgbToHex([255, 255, 255]);
                                                document.getElementById("colorpickerE").disabled = true
                                            } else if (e.target.value === "2") {
                                                document.getElementById("redIntensityE").value = 255;
                                                document.getElementById("greenIntensityE").value = 0;
                                                document.getElementById("blueIntensityE").value = 0;
                                                document.getElementById("colorpickerE").value = rgbToHex([255, 0, 0]);
                                                document.getElementById("colorpickerE").disabled = true
                                            } else if (e.target.value === "3") {
                                                document.getElementById("redIntensityE").value = 0;
                                                document.getElementById("greenIntensityE").value = 255;
                                                document.getElementById("blueIntensityE").value = 0;
                                                document.getElementById("colorpickerE").value = rgbToHex([0, 255, 0]);
                                                document.getElementById("colorpickerE").disabled = true
                                            } else if (e.target.value === "4") {
                                                document.getElementById("redIntensityE").value = 0;
                                                document.getElementById("greenIntensityE").value = 0;
                                                document.getElementById("blueIntensityE").value = 255;
                                                document.getElementById("colorpickerE").value = rgbToHex([0, 0, 255]);
                                                document.getElementById("colorpickerE").disabled = true
                                            }
                                            document.getElementById("redIntensityE").readOnly = true;
                                            document.getElementById("greenIntensityE").readOnly = true;
                                            document.getElementById("blueIntensityE").readOnly = true;
                                            setColorValueE(e.target.value);
                                        }
                                        formCheck("editForm", "editSubmit");
                                    }}>
                                        {colorOptionsE}
                                    </select>
                                    <label htmlFor={"colorItemE"}>Color Option</label>
                                </div>
                                <div className={"d-flex justify-content-between"}>
                                    <div className={"form-floating mb-3"} style={{width: "30%"}}>
                                        <input type={"number"} className={"form-control"} id={"redIntensityE"}
                                               onKeyUp={(e) => {
                                                   let g = document.getElementById("greenIntensityE").value;
                                                   let b = document.getElementById("blueIntensityE").value;
                                                   document.getElementById("colorpickerE").value = rgbToHex([e.target.value, g, b]);
                                                   formCheck("editForm", "editSubmit");
                                               }}
                                               onChange={(e) => {
                                                   let g = document.getElementById("greenIntensityE").value;
                                                   let b = document.getElementById("blueIntensityE").value;
                                                   document.getElementById("colorpickerE").value = rgbToHex([e.target.value, g, b]);
                                                   formCheck("editForm", "editSubmit");
                                               }}
                                               step={1} min={0} max={255} required={true}/>
                                        <label htmlFor={"redIntensityE"}>Red Intensity</label>
                                    </div>
                                    <div className={"form-floating mb-3"} style={{width: "30%"}}>
                                        <input type={"number"} className={"form-control"} id={"greenIntensityE"}
                                               onKeyUp={(e) => {
                                                   let r = document.getElementById("redIntensityE").value;
                                                   let b = document.getElementById("blueIntensityE").value;
                                                   document.getElementById("colorpickerE").value = rgbToHex([r, e.target.value, b]);
                                                   formCheck("editForm", "editSubmit");
                                               }}
                                               onChange={(e) => {
                                                   let r = document.getElementById("redIntensityE").value;
                                                   let b = document.getElementById("blueIntensityE").value;
                                                   document.getElementById("colorpickerE").value = rgbToHex([r, e.target.value, b]);
                                                   formCheck("editForm", "editSubmit");
                                               }}
                                               step={1} min={0} max={255} required={true}/>
                                        <label htmlFor={"greenIntensityE"}>Green Intensity</label>
                                    </div>
                                    <div className={"form-floating mb-3"} style={{width: "30%"}}>
                                        <input type={"number"} className={"form-control"} id={"blueIntensityE"}
                                               onKeyUp={(e) => {
                                                   let r = document.getElementById("redIntensityE").value;
                                                   let g = document.getElementById("greenIntensityE").value;
                                                   document.getElementById("colorpickerE").value = rgbToHex([r, g, e.target.value]);
                                                   formCheck("editForm", "editSubmit");
                                               }}
                                               onChange={(e) => {
                                                   let r = document.getElementById("redIntensityE").value;
                                                   let g = document.getElementById("greenIntensityE").value;
                                                   document.getElementById("colorpickerE").value = rgbToHex([r, g, e.target.value]);
                                                   formCheck("editForm", "editSubmit");
                                               }}
                                               step={1} min={0} max={255} required={true}/>
                                        <label htmlFor={"blueIntensityE"}>Blue Intensity</label>
                                    </div>
                                </div>
                                <input id={"colorpickerE"} type={"color"} className={"w-100"} style={{height: "40px"}}
                                       onBlur={(e) => {
                                           let rgb = hexToRGB(e.target.value);
                                           document.getElementById("redIntensityE").value = rgb[0];
                                           document.getElementById("greenIntensityE").value = rgb[1];
                                           document.getElementById("blueIntensityE").value = rgb[2];
                                       }}/>
                                <div className={"form-check form-switch mt-4"}>
                                    <input className={"form-check-input"} id={"isFlashingE"} type={"checkbox"}/>
                                    <label className={"form-check-label text-dark"} htmlFor={"isFlashingE"}>
                                        Flashing Light
                                    </label>
                                </div>
                                <hr/>
                                <div className={"mt-1"}>
                                    <h3>Devices</h3>
                                    <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                        <input type={"search"} id={"search2"} className={"form-control search-input"}
                                               value={devSearch} onChange={(e) => setDevSearch(e.target.value)} />
                                        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                    </div>
                                    <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                        <table className={"table table-sm table-white table-hover d-sm-table"}>
                                            <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                {deviceTableHeaders}
                                            </thead>
                                            <tbody>
                                                {devTableRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <button type={"button"} className={"btn btn-secondary"} id={"cancelEditBtn"}
                                        data-bs-dismiss={"modal"}>Cancel
                                </button>
                                <Button type={"button"} class={"btn btn-primary d-none"} disabled={true} spinner={true}
                                        text={"Loading"} id={"loadEditSubmit"}/>
                                <button type={"button"} className={"btn btn-primary"} onClick={() => formEditSubmit()}
                                        id={"editSubmit"}>Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"deletemodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticDeleteLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <form id={"deleteForm"} className={"was-validated"}>
                            <div className={"modal-header bg-pal"}>
                                <h2 className={"modal-title"} id={"staticDeleteLabel"}>Delete Light Controller</h2>
                                <button type={"button"} className={"btn-close"} id={"deleteClose"}
                                        data-bs-dismiss={"modal"} aria-label={"Close"}/>
                            </div>
                            <div className={"modal-body"}>
                                <h4>Select light controller to delete.</h4>
                                <div className={"form-floating mb-3"}>
                                    <select className={"form-select"} id={"dLightbox"} defaultValue={undefined}
                                            onChange={() => formCheck("deleteForm", "deleteSubmit")}
                                            required={true}>
                                        <option value={""}></option>
                                        {lightBoxOptions}
                                    </select>
                                    <label htmlFor={"dLightbox"}>Light Controller</label>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <button type={"button"} className={"btn btn-secondary"} id={"cancelDeleteBtn"}
                                        data-bs-dismiss={"modal"}>Cancel
                                </button>
                                <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                        spinner={true}
                                        text={"Loading"} id={"loadDeleteSubmit"}/>
                                <Button type={"button"} text={"Delete"} class={"btn btn-danger ms-3"}
                                        id={"deleteSubmit"}
                                        onClick={() => deletePerform()}/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer/>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true}
                                autoClose={10000}/>
            </Suspense>
        </div>
    );
};

export default LightCtrlSettings;